import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';

import { ApiUrl, request } from '../helpers';

import { RefererType } from './e-app';
import {
  AdditionalAssuredProductEnum,
  AdditionalMicProductEnum,
  GenderEnum,
  IdentityDocumentTypeEnum,
  InsuranceTypeEnum,
  QuotationEntity,
} from './quotation';
import { ComboEnum } from './reverse';
import { APIError } from './types';

export const LEADS_QUERY_KEY = 'LEADS';

export enum InsuranceMethodEnum {
  DIRECT = 'DIRECT',
  REFER = 'REFER',
}
const ICS_QUERY_KEY = 'ICS';
export type LeadsInsuranceMethodFormData = {
  insurance_method: InsuranceMethodEnum;
};

export type LeadSearchEntity = {
  content: LeadsCustomerFormData[];
  size: number;
  total_elements: number;
  total_pages: number;
  page: number;
};

export type GetQueryParams = {
  q?: string;
  date?: string;
  page?: number;
  size?: number;
  from?: string;
  to?: string;
  type?: LeadsFlowEnum | ReferOrDirectTabEnum;
};

export type CreateLeadsCustomerInput = {
  id?: number;
  full_name: string;
  dob: string;
  gender: GenderEnum;
  phone_number: string;
  input_amount: number;
  cared_product: {
    mbal: InsuranceTypeEnum[];
    sup_mbal: AdditionalAssuredProductEnum[];
    mic: AdditionalMicProductEnum[];
  };
  potential_customer_full_name?: string;
  potential_customer_phone_number?: string;
  sale?: RefererType;
  created_date?: string;
  state?: string;
  note?: string;
  identification_type: IdentityDocumentTypeEnum;
  identification_id: string;
  occupation_id: number;
  lead_id?: string;
};

export type LeadsCustomerEntity = {
  lead_id?: string;
  id: number;
  user_combo_id?: string;
  full_name: string;
  dob: string;
  gender: GenderEnum;
  phone_number: string;
  input_amount: number;
  cared_product: {
    mbal: InsuranceTypeEnum[];
    sup_mbal: AdditionalAssuredProductEnum[];
    mic: AdditionalMicProductEnum[];
  };
  created_date: string;
  email?: string;
  identification_type: IdentityDocumentTypeEnum;
  identification_id: string;
  occupation_id: number;
  note?: string;
  rm_info?: RefererType;
};

export type LeadsCustomerFormData = CreateLeadsCustomerInput & {
  direct_id?: number;
  user_combo_id?: string;
  reason?: string;
  mic_package_selected?: any;
  direct_state?: string;
  app_status?: string;
  contract_state?: string;
  lead_status?: string;
  lead_status_num?: string;
  is_loan?: boolean;
};

type CheckReferOrDirectInput = {
  id: string;
  req: {
    amount: number;
  };
};

export enum LeadsFlowEnum {
  NEW = 'NEW',
  ALL = 'ALL',
  OVERVIEW = 'OVERVIEW',
  REFER = 'REFER',
  DIRECT = 'DIRECT',
}
export enum ReferOrDirectTabEnum {
  PROFILE = 'PROFILE',
  CONTRACT = 'CONTRACT',
}

export enum LeadsContractState {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
  DRAFT = 'DRAFT',
}
export enum DirectStateEnum {
  CREATE_BMHN = 'CREATE_BMHN',
  CREATE_BMHX = 'CREATE_BMHX',
  INIT_DIRECT = 'INIT_DIRECT',
  PENDING = 'PENDING',
  DRAFT = 'DRAFT',
}

export type ReferCustomerToSaleInput = {
  id: number;
  req: {
    ic_code: string;
    name: string;
    phone_number?: string;
    reason?: string;
  };
};
export type LeadsReferFormData = {
  ic_code: string;
  ic_search: string;
  ic_new: IC;
};

export type LeadsReferReasonFormData = {
  reason_id: string;
  reason_content: string;
};

export type IC = {
  code: string;
  icCode?: string;
  full_name: string;
  phone_number: string;
};

export const useGetLeadsId = (id?: number | string, options?: UseQueryOptions<LeadsCustomerFormData, APIError>) => {
  return useQuery(
    ['leadsId', id] as QueryKey,
    () =>
      request.get(ApiUrl.leadsid(id)).then((res) => ({
        ...res.data,
        product_cared_list: (res.data.product_cared_list ?? []).map((it: any) =>
          Object.assign({}, ...Object.keys(it).map((k) => ({ [k]: it[k] < 0 ? 0 : it[k] }))),
        ),
      })),
    options,
  );
};

export const useCreateLeadsCustomer = (
  options?: UseMutationOptions<LeadsCustomerEntity, Error, CreateLeadsCustomerInput>,
) => {
  return useMutation((input) => request.post(ApiUrl.leads, input).then((res) => res.data), options);
};

export const useUpdateLeadsCustomer = (
  options?: UseMutationOptions<LeadsCustomerEntity, Error, CreateLeadsCustomerInput>,
) => {
  return useMutation((input) => request.put(ApiUrl.leads, input).then((res) => res.data), options);
};
export enum HealthCheckupEnum {
  REQUIRED = 'REQUIRED',
  OPTIONAL = 'OPTIONAL',
  NONE = 'NONE',
}
export const useCheckReferOrDirect = (
  options?: UseMutationOptions<
    { flow: LeadsFlowEnum; health_checkup: HealthCheckupEnum; tsa: number },
    Error,
    CheckReferOrDirectInput
  >,
) => {
  return useMutation(
    (input) =>
      request.post(ApiUrl.checkReferOrDirect(input.id), input.req).then((res) => {
        let health_checkup = HealthCheckupEnum.NONE;
        switch (res?.data?.health_checkup) {
          case true:
            health_checkup = HealthCheckupEnum.REQUIRED;
            break;
          case false:
            health_checkup = HealthCheckupEnum.OPTIONAL;
            break;
          default:
            break;
        }
        return { ...res.data, health_checkup };
      }),
    options,
  );
};
export const useReferCustomerToSale = (
  options?: UseMutationOptions<LeadReferorDirectEntity, Error, ReferCustomerToSaleInput>,
) => {
  return useMutation(
    (input) => request.post(ApiUrl.referCustomerToSale(input.id), input.req).then((res) => res.data),
    options,
  );
};

export const useGetAllICs = (options?: UseQueryOptions<IC[], APIError>) => {
  return useQuery(ICS_QUERY_KEY as QueryKey, () => request.get(ApiUrl.getICslist).then((res) => res.data), options);
};
export const useSearchICcode = (input: { icCode?: string }, options?: UseQueryOptions<IC[], APIError>) => {
  return useQuery(
    ['searchIC', input] as QueryKey,
    () =>
      request
        .get(ApiUrl.searchICCode, {
          params: {
            ...input,
          },
        })
        .then((res) => (res?.data ? [res?.data] : [])),
    options,
  );
};

//Xóa Leads
export type DeleteLeadsInput = {
  ids: number[];
  type?: LeadsFlowEnum | ReferOrDirectTabEnum;
};
export const useDeleteLeadsMutation = (options?: UseMutationOptions<number[], APIError, DeleteLeadsInput>) => {
  return useMutation<number[], APIError, DeleteLeadsInput>((payload) => {
    const { ids } = payload;
    if (payload.type === LeadsFlowEnum.REFER) {
      return request.post(ApiUrl.refer + '/delete', { ids });
    }
    if (payload.type === LeadsFlowEnum.DIRECT) {
      return request.post(ApiUrl.direct + '/delete', { ids });
    }
    if (payload.type === LeadsFlowEnum.ALL) {
      return request.post(ApiUrl.leads + '/delete', { ids });
    }
    return Promise.reject('Invalid payload or type');
  }, options);
};

//Init DIRECT
export type InitDirectInput = {
  potential_customer_id: number;
};
export const useInitDirect = (options?: UseMutationOptions<{ direct_id: number }, Error, InitDirectInput>) => {
  return useMutation((input) => request.post(ApiUrl.direct + '/init', input).then((res) => res.data), options);
};

//Tạo DIRECT từ 3 PA BMH ngược
export type DirectCreateReserveInput = {
  combo_code: ComboEnum;
  direct_id: number;
};
export type DirectCreateReserveOutput = {
  data: string;
  id: number;
};
export type UserComboSuggestionResp = {
  attributes: { total_benefit?: string };
  code: ComboEnum;
};
export const useGetTotalBenefit = (id?: number, options?: UseQueryOptions<UserComboSuggestionResp[], APIError>) => {
  return useQuery(
    ['total_Benefit', id] as QueryKey,
    () =>
      request
        .get(
          ApiUrl.leads +
            '/' +
            id +
            `/combo/suggest?${[ComboEnum.MUST_TRY, ComboEnum.SIGNATURE, ComboEnum.SPECIAL_OFFER]
              .map((n) => `combo=${n}`)
              .join('&')}`,
        )
        .then((res) => res?.data),
    options,
  );
};

export const useDirectCreateReserve = (
  options?: UseMutationOptions<DirectCreateReserveOutput, Error, DirectCreateReserveInput>,
) => {
  return useMutation((input) => request.post(ApiUrl.leads + '/create-bmhn', input).then((res) => res.data), options);
};
export const useGetLeadsQuotationExample = (
  id: number,
  flex?: boolean,
  options?: UseQueryOptions<QuotationEntity, APIError>,
) => {
  return useQuery(
    [LEADS_QUERY_KEY, id] as QueryKey,
    () =>
      request.get(ApiUrl.direct + '/' + id + '/quotation/example', { params: { flex } }).then((res) => ({
        ...res.data,
        details: (res.data.details ?? []).map((it: any) =>
          Object.assign({}, ...Object.keys(it).map((k) => ({ [k]: it[k] < 0 ? 0 : it[k] }))),
        ),
      })),
    options,
  );
};

//LEAD REFER
export enum ReferStateEnum {
  // Tao moi, cho xac nhan
  SENT = 'SENT',
  // Da nhan
  RECEIVED = 'RECEIVED',
  // Goi dien/ email, Da gap
  CONTACTED = 'CONTACTED',
  // Da nop HSYCBH
  WAITTING = 'WAITTING',
  // Da phat hanh, Co so HDBH
  COMPLETED = 'COMPLETED',
  // Cho phan giao RM, RM tra lai, Tra lai
  ERROR = 'ERROR',
  // Tu choi
  REJECT = 'REJECT',
}

export enum ReferStatusColor {
  S_1 = '1',
  S_2 = '2',
  S_3 = '3',
  S_4 = '4',
  S_5 = '5',
  S_6 = '6',
  S_7 = '7',
  S_8 = '8',
  S_9 = '10',
  S_10 = '11',
  S_11 = '13',
}

export interface LeadsDirectInfo {
  direct_id: number;
  leads_id: string;
  note?: string;
  product_package?: string;
}
export interface LeadReferorDirectEntity {
  app_status: LeadsContractState;
  cif?: string;
  created_date: string;
  contract_id: string;
  contract_pending_note?: string;
  contract_state: LeadsContractState;
  direct_state?: DirectStateEnum;
  ic_info: RefererType;
  id: number;
  lead_id?: string;
  lead_status?: string;
  lead_status_detail?: string[];
  lead_status_num?: string;
  lead_status_num_detail?: string[];
  potential_customer: LeadsCustomerEntity;
  reason?: string;
  referrer?: {
    code: string;
    email: string;
    name: string;
    phone_number: string;
  };
  rm_info?: RefererType;
  sale?: RefererType;
  state?: ReferStateEnum;
  user_combo_id?: number;
  direct_id?: number;
  quotation?: QuotationEntity & {
    combo_name?: string;
    direct?: LeadsDirectInfo;
  };
  user_combo_name?: string;
}

export const useGetLeadReferId = (
  id?: number | string,
  options?: UseQueryOptions<LeadReferorDirectEntity, APIError>,
) => {
  return useQuery(
    ['GetLeadReferId', id] as QueryKey,
    () =>
      request
        .get(ApiUrl.leadReferId(id))
        .then((res) => ({ ...res?.data, app_status: res?.data?.app_status || res?.data?.contract_state })),
    options,
  );
};

export const useGetLeadDirectId = (
  id?: number | string,
  options?: UseQueryOptions<LeadReferorDirectEntity, APIError>,
) => {
  return useQuery(
    ['GetLeadDirectId', id] as QueryKey,
    () =>
      request
        .get(ApiUrl.leadsDirectId(id))
        .then((res) => ({ ...res?.data, app_status: res?.data?.app_status || res?.data?.contract_state })),
    options,
  );
};

export const useGetLeads = (input: GetQueryParams, options?: UseInfiniteQueryOptions<LeadSearchEntity, APIError>) => {
  const { type, ...res } = input;
  return useInfiniteQuery(
    ['GetLeads', input] as QueryKey,
    async ({ pageParam }) => {
      if (type === LeadsFlowEnum.DIRECT) {
        return request
          .get(ApiUrl.direct + '/search', {
            params: { ...res, page: pageParam || 0 },
          })
          .then((res) => ({
            ...res.data,
            content: res?.data?.content?.map((it: LeadsCustomerFormData) => ({
              ...it,
              id: it?.direct_id || it?.id,
              app_status: it?.app_status || it?.contract_state || 'PENDING',
            })),
          }));
      }
      if (type === LeadsFlowEnum.REFER) {
        return request
          .get(ApiUrl.refer + '/search', {
            params: { ...res, page: pageParam || 0 },
          })
          .then((res) => ({
            ...res.data,
            content: res.data?.content?.map((it: LeadsCustomerFormData) => ({
              ...it,
              full_name: it?.potential_customer_full_name || it?.full_name,
              phone_number: it?.potential_customer_phone_number || it?.phone_number,
            })),
          }));
      }
      if (type === LeadsFlowEnum.ALL) {
        return request
          .get(ApiUrl.leads + '/search', {
            params: { ...res, page: pageParam || 0 },
          })
          .then((res) => res.data);
      }
    },
    options,
  );
};
