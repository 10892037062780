import { SelectItem, Timeline } from '@mantine/core';
import classNames from 'classnames';
import { memo, useMemo } from 'react';

import { LeadsContractState, ReferStateEnum, ReferStatusColor } from '../../../../services';

type Props = {
  steps: SelectItem[];
  stepDone: ReferStateEnum | LeadsContractState;
  statusNums?: string[];
  stepDoneDescription?: string;
};

export const getLeadsStateFromStatus = (leadStatusNum?: string) => {
  switch (leadStatusNum) {
    case ReferStatusColor.S_1:
    case ReferStatusColor.S_2:
      return ReferStateEnum.SENT;
    case ReferStatusColor.S_3:
      return ReferStateEnum.RECEIVED;
    case ReferStatusColor.S_4:
    case ReferStatusColor.S_6:
      return ReferStateEnum.CONTACTED;
    case ReferStatusColor.S_5:
    case ReferStatusColor.S_9:
    case ReferStatusColor.S_10:
      return ReferStateEnum.ERROR;
    case ReferStatusColor.S_7:
      return ReferStateEnum.WAITTING;
    case ReferStatusColor.S_8:
      return ReferStateEnum.COMPLETED;
    case ReferStatusColor.S_11:
      return ReferStateEnum.REJECT;
    default:
      return ReferStateEnum.SENT;
  }
};

export const StatusStepsVertical = memo(({ steps, stepDone, stepDoneDescription }: Props) => {
  const stepDoneIndex = useMemo(() => {
    return steps.findIndex((step) => step.value === stepDone);
  }, [steps, stepDone]);

  const stepsDisplayed = useMemo(() => {
    // Các trạng thái khách hàng bình thường
    if (stepDone !== ReferStateEnum.REJECT && stepDone !== ReferStateEnum.ERROR) {
      return steps
        .filter((step) => step.value !== ReferStateEnum.REJECT && step.value !== ReferStateEnum.ERROR)
        .slice(0, stepDoneIndex + 2);
    }
    // Trạng thái khách hàng IC nhập lỗi hoặc KH từ chối
    else {
      return [...steps.slice(0, 3), steps.find((step) => step.value === stepDone) as SelectItem];
    }
  }, [stepDone, stepDoneIndex, steps]);

  return (
    <div className="mt-20px">
      <Timeline active={stepDoneIndex} bulletSize={28} lineWidth={1}>
        {stepsDisplayed.map((step, i) => (
          <Timeline.Item
            key={i}
            bullet={<span className="w-20px h-20px text-center text-white">{i + 1}</span>}
            title={<span className="text-primary font-semibold text-14px">{step.label}</span>}>
            <div className={classNames('text-c_767676 text-14px h-[22px]')}>
              {stepDoneDescription && step.value === stepDone ? stepDoneDescription : step.description}
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
});
