import { debounce } from 'lodash';
import { memo, useCallback, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { TextInput } from '@mantine/core';

import { useDialog } from '../../../contexts';
import { getMessage } from '../../../helpers';
import { useGetMicContractMutation } from '../../../services';
import { FormLabel } from '../../../components';
import { SpinLoader } from '../../../components/spin-loader';
import { GuideModal } from '../components/guide-modal';

export type Props = {
  control: any;
  watch: any;
  setValue: any;
  errorText?: string;
  contractNum: string;
  name: string;
  dob: string;
  identificationNumber: string;
  phoneNumber: string;
  onResetMicSelected?: () => void;
  onLoading?: (loading: boolean) => void;
};

export const SearchInsuranceHealth = memo<Props>(
  ({ control, watch, setValue, contractNum, name, dob, identificationNumber, phoneNumber, errorText, onLoading }) => {
    const { showDialog } = useDialog();
    const resetDataMicContract = useCallback(() => {
      setValue(name, '');
      setValue(dob, '');
      setValue(identificationNumber, '');
      setValue(phoneNumber, '');
    }, [dob, identificationNumber, name, phoneNumber, setValue]);

    const onReset = useCallback(() => {
      setValue(contractNum, '');
      resetDataMicContract();
    }, [contractNum, resetDataMicContract, setValue]);

    const { mutateAsync, isLoading } = useGetMicContractMutation({
      onError: async (error) => {
        const err = await showDialog({
          type: 'ALERT',
          message: getMessage(error),
        });
        err && onReset();
      },
      onSuccess: (res) => {
        setValue(name, res?.ten);
        setValue(dob, res?.ng_sinh);
        setValue(identificationNumber, res?.cmt);
        setValue(phoneNumber, res?.mobi);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onDebounce = useCallback(
      debounce((value: string) => {
        if (!value) return;
        mutateAsync({
          contractNum: value,
        });
      }, 400),
      [mutateAsync],
    );

    useEffect(() => {
      onLoading?.(isLoading);
    }, [isLoading, onLoading]);

    return (
      <>
        <div>
          <FormLabel>
            <div className="flex flex-items-center">
              Giấy chứng nhận BH sức khỏe của Bố mẹ
              <GuideModal />
            </div>
          </FormLabel>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <TextInput
                  onBlur={() => {
                    const val = value?.toUpperCase()?.trim();
                    onChange(val);
                    onDebounce(val);
                  }}
                  onChange={(e) => {
                    const val = e?.target?.value?.toUpperCase()?.trim();
                    onChange(val);
                    resetDataMicContract();
                  }}
                  value={value}
                  placeholder="Nhập giấy chứng nhận BH"
                  error={errorText}
                  maxLength={50}
                />
                <p className="text-13px text-primary italic mb-16px">
                  * Nhập GCNBH Bố mẹ để giảm 30% phí bảo hiểm cho con
                </p>
              </>
            )}
            name={contractNum}
          />
        </div>
        <SpinLoader spining={isLoading}>
          {watch(name) && (
            <div>
              <FormLabel>Họ và tên Bố/Mẹ</FormLabel>
              <Controller
                control={control}
                render={({ field: { value } }) => <TextInput value={value} placeholder="--" disabled />}
                name={name}
              />
            </div>
          )}

          {watch(dob) && (
            <div>
              <FormLabel>Ngày tháng năm sinh Bố/Mẹ</FormLabel>
              <Controller
                control={control}
                render={({ field: { value } }) => <TextInput value={value} placeholder="--" disabled />}
                name={dob}
              />
            </div>
          )}

          {watch(identificationNumber) && (
            <div>
              <FormLabel>Số giấy tờ tùy thân Bố/Mẹ</FormLabel>
              <Controller
                control={control}
                render={({ field: { value } }) => <TextInput value={value} placeholder="--" disabled />}
                name={identificationNumber}
              />
            </div>
          )}

          {watch(phoneNumber) && (
            <div>
              <FormLabel>Số điện thoại Bố/Mẹ</FormLabel>
              <Controller
                control={control}
                render={({ field: { value } }) => <TextInput value={value} placeholder="--" disabled />}
                name={phoneNumber}
              />
            </div>
          )}
        </SpinLoader>
      </>
    );
  },
);
