import { NumberInput, TextInput } from '@mantine/core';
import classNames from 'classnames';
import React, { Fragment, useContext, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { UserComponent } from '..';
import { Question } from '../../../../../components';
import { vi } from '../../../../../i18n/vi';
import { PlusRoundWhiteSVG } from '../../../../../icons';
import { dataStepsQuestion, MixQuestionsContext, QuestionTypeEnum, useIllustration } from '../../../form';
import { CollapseHeader } from '../../collapse-header';

import { FooterQuestion } from './footer';

type Props = {
  currentIndex: number;
  questionName: string;
};
export const StepsQuestion1Screen = React.memo<Props>(({ currentIndex, questionName }) => {
  const dataIndex = dataStepsQuestion[currentIndex];
  const dataQuestions = dataIndex.questions;
  const { currentAssured } = useIllustration();
  const { stepLabel, handleChangeStepLabel, handleSubmitRoot, dataDefine: rootData } = useContext(MixQuestionsContext);
  const dataDefine = useMemo(() => rootData?.[currentAssured?.id ?? ''], [rootData, currentAssured]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    resetField,
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        questions: dataQuestions?.map((item, idx) => {
          return {
            response: dataDefine?.[questionName]?.questions[idx]?.response || item?.default || 'K',
            subQuestions: dataQuestions[idx]?.subQuestions?.map((_, i) => {
              return {
                response: dataDefine?.[questionName]?.questions[idx]?.subQuestions[i].response || '',
              };
            }),
          };
        }),
      };
    }, [dataDefine, dataQuestions, questionName]),
  });

  const onNext = (stepGo: number) => {
    handleChangeStepLabel(stepGo);
    const questions = watch('questions');
    const params = {};
    Object.assign(params, {
      [questionName]: {
        height: questions[0].subQuestions[0].response,
        weight: questions[0].subQuestions[1].response,
        change: questions[1].response,
        change_value: questions[1].subQuestions[0].response,
        reason_change: questions[1].subQuestions[1].response,
      },
    });

    handleSubmitRoot(currentAssured?.id ?? '', {
      [questionName]: {
        questions,
      },
      body: params,
      assured: currentAssured,
    });
  };

  const onSubmit = () => {
    onNext(stepLabel + 1);
  };

  const { fields } = useFieldArray({
    control,
    name: 'questions',
  });

  return (
    <>
      <div className="flex-1">
        <CollapseHeader icon={<PlusRoundWhiteSVG />} title={dataIndex.type} className="custom-collapse-header">
          <div className="">
            <UserComponent />
            <div className="wrapQuestion">
              <div className="questionViewTitle">
                <div className="questionIndex">
                  <p className="questionIndexText">{currentIndex + 1}</p>
                </div>
                <p className="questionTxtTitle">{dataIndex.name}</p>
              </div>
              {fields?.map((item, index) => (
                <Fragment key={item?.id}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Question
                          onChange={(newVal) => {
                            onChange(newVal);
                            clearErrors(`questions.${index}`);
                            resetField(`questions.${index}.subQuestions`);
                          }}
                          value={value}
                          desc={dataQuestions[index].name || ''}
                          style="questionItem"
                          isShow={dataQuestions[index].type === QuestionTypeEnum.INPUT ? false : true}
                          data={dataQuestions?.[index]?.selections}
                          isBox={!!dataQuestions?.[index]?.selections}>
                          {watch('questions')?.[index].response !== 'K' && (
                            <div className={classNames(dataQuestions[index].style)}>
                              {item?.subQuestions?.map((_, i) => (
                                <Fragment key={dataQuestions[index]?.subQuestions?.[i]?.id}>
                                  <div
                                    className={classNames([
                                      dataQuestions[index].type === QuestionTypeEnum.INPUT && 'px-6px',
                                    ])}>
                                    <Controller
                                      control={control}
                                      render={({ field: { onChange, value } }) => {
                                        const errorText =
                                          errors?.questions?.[index]?.subQuestions?.[
                                            i
                                          ]?.response?.message?.toString() || '';
                                        const minError =
                                          errors?.questions?.[index]?.subQuestions?.[i]?.message?.toString() || '';
                                        if (dataQuestions[index].subQuestions[i].type === QuestionTypeEnum.BOOLEAN) {
                                          return (
                                            <Question
                                              onChange={(newVal) => onChange(newVal)}
                                              value={value}
                                              desc={dataQuestions?.[index]?.subQuestions?.[i].name}
                                              style="questionItem"
                                              data={dataQuestions?.[index]?.subQuestions?.[i]?.selections}
                                              errorText={errorText}
                                            />
                                          );
                                        }
                                        if (
                                          [QuestionTypeEnum.NUMBER, QuestionTypeEnum.DECIMAL].includes(
                                            dataQuestions[index].subQuestions[i].type,
                                          )
                                        ) {
                                          return (
                                            <>
                                              <NumberInput
                                                onBlur={() => {
                                                  if ((!!value && value < 1) || !value) {
                                                    setError(`questions.${index}.subQuestions.${i}.response`, {
                                                      type: 'custom',
                                                      message: 'Giá trị nhập không nhỏ hơn 1',
                                                    });
                                                    return;
                                                  }
                                                  clearErrors(`questions.${index}.subQuestions.${i}.response`);
                                                }}
                                                onChange={(newVal) => {
                                                  onChange(newVal);
                                                  clearErrors(`questions.${index}.subQuestions.${i}`);
                                                  if ((!!newVal && newVal < 1) || !newVal) {
                                                    setError(`questions.${index}.subQuestions.${i}`, {
                                                      type: 'minOne',
                                                      message: 'Giá trị nhập không nhỏ hơn 1',
                                                    });
                                                  }
                                                }}
                                                value={value}
                                                placeholder={dataQuestions[index].subQuestions[i].name}
                                                max={dataQuestions[index].subQuestions[i].maxLength}
                                                error={errorText || minError}
                                                precision={QuestionTypeEnum.DECIMAL ? 1 : 0}
                                                step={QuestionTypeEnum.DECIMAL ? 0.1 : 1}
                                                rightSection={<div />}
                                              />
                                            </>
                                          );
                                        }
                                        return (
                                          <TextInput
                                            onBlur={() => onChange(value?.trim())}
                                            onChange={(e) => onChange(e?.target?.value)}
                                            value={value}
                                            placeholder={dataQuestions[index].subQuestions[i].name}
                                            maxLength={dataQuestions[index].subQuestions[i].maxLength}
                                            error={errorText}
                                          />
                                        );
                                      }}
                                      name={`questions.${index}.subQuestions.${i}.response`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: vi.filled,
                                        },
                                      }}
                                    />
                                  </div>
                                </Fragment>
                              ))}
                            </div>
                          )}
                        </Question>
                      );
                    }}
                    name={`questions.${index}.response`}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </CollapseHeader>
      </div>
      <FooterQuestion
        handleChangeStep={(stepGo: number) => handleSubmit(() => onNext(stepGo))()}
        handleSubmit={handleSubmit(onSubmit)}
        disabled={!!errors?.questions}
      />
    </>
  );
});
