/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextInput } from '@mantine/core';
import classNames from 'classnames';
import { forEach } from 'lodash';
import React, { Fragment, useContext, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { UserComponent } from '..';
import { Question } from '../../../../../components';
import { vi } from '../../../../../i18n/vi';
import { PlusRoundWhiteSVG } from '../../../../../icons';
import {
  convertQuestionName,
  dataStepsQuestion,
  MixQuestionsContext,
  QuestionTypeEnum,
  useIllustration,
} from '../../../form';
import { CollapseHeader } from '../../collapse-header';

import { FooterQuestion } from './footer';
type Props = {
  currentIndex: number;
};
export const StepsQuestion7Screen = React.memo<Props>(({ currentIndex }) => {
  const dataQuestions = dataStepsQuestion[currentIndex]?.questions;
  const dataC7 = dataQuestions[0];
  const dataC8 = dataQuestions[1];
  const dataC9 = dataQuestions[2];
  const { currentAssured } = useIllustration();
  const { stepLabel, handleChangeStepLabel, handleSubmitRoot, dataDefine: rootData } = useContext(MixQuestionsContext);
  const dataDefine = useMemo(() => rootData?.[currentAssured?.id ?? ''], [rootData, currentAssured]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    resetField,
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        c7: {
          response: dataDefine?.c7?.response || 'C',
          detail: dataDefine?.c7?.detail || '',
          subQuestions: dataC7?.subQuestions?.map((_, i) => {
            return {
              response: dataDefine?.c7?.subQuestions[i]?.response || 'K',
              detail: dataDefine?.c7?.subQuestions[i]?.detail || '',
            };
          }),
        },
        c8: {
          response: dataDefine?.['c8']?.response || 'K',
          medicine_type: dataDefine?.['c8']?.medicine_type,
          reason: dataDefine?.['c8']?.reason,
        },
        c9: {
          response: dataDefine?.['c9']?.response || 'K',
          detail: dataDefine?.['c9']?.detail || '',
          subQuestions: dataC9?.subQuestions?.map((_, i) => {
            return {
              response: dataDefine?.['c9']?.subQuestions[i]?.response || 'K',
              detail: dataDefine?.['c9']?.subQuestions[i]?.detail || '',
            };
          }),
        },
      };
    }, [dataC7?.subQuestions, dataC9?.subQuestions, dataDefine]),
  });

  const onNext = (stepGo: number) => {
    const c7 = watch('c7');
    const c7Params = { c7: { response: c7.response } };
    forEach(c7.subQuestions, function (elm, idx: number) {
      if (c7.response === 'C') {
        Object.assign(c7Params, {
          [convertQuestionName('c7', idx)]: {
            response: elm.response,
            detail: elm.detail || null,
          },
        });
      } else {
        Object.assign(c7Params, {
          [convertQuestionName('c7', idx)]: {
            response: 'K',
            detail: null,
          },
        });
      }
    });

    const c9 = watch('c9');
    const c9Params = { c9: { response: c9.response } };
    forEach(c9.subQuestions, function (elm, idx: number) {
      if (c9.response === 'C') {
        Object.assign(c9Params, {
          [convertQuestionName('c9', idx)]: {
            response: elm.response,
            detail: elm.detail || null,
          },
        });
      } else {
        Object.assign(c9Params, {
          [convertQuestionName('c9', idx)]: {
            response: 'K',
            detail: null,
          },
        });
      }
    });
    const body = {
      ...c7Params,
      c8: watch('c8'),
      ...c9Params,
    };

    handleSubmitRoot(currentAssured?.id ?? '', {
      ...watch(),
      body,
      assured: currentAssured,
    });

    handleChangeStepLabel(stepGo);
  };

  const onSubmit = () => {
    if (
      !!currentAssured?.age &&
      ((currentAssured?.gender === 'FEMALE' && currentAssured?.age > 12) || currentAssured?.age <= 12)
    ) {
      onNext(stepLabel + 1);
    } else {
      onNext(stepLabel + 2);
    }
  };

  const { fields } = useFieldArray({
    control,
    name: 'c7.subQuestions',
  });
  const { fields: c9Fields } = useFieldArray({
    control,
    name: 'c9.subQuestions',
  });

  return (
    <>
      <div className="flex-1">
        <CollapseHeader
          icon={<PlusRoundWhiteSVG />}
          title={dataStepsQuestion[currentIndex].type}
          className="custom-collapse-header">
          <UserComponent />
          <div className="wrapQuestion">
            <div className="questionViewTitle">
              <div className="questionIndex">
                <p className="questionIndexText">7</p>
              </div>
              <p className="questionTxtTitle">Câu hỏi 7</p>
            </div>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Question
                    onChange={(newVal) => {
                      onChange(newVal);
                      resetField(`c7.subQuestions`);
                    }}
                    value={value}
                    desc={dataC7.name || ''}
                    style="questionItem"
                    isShow={false}
                  />
                );
              }}
              name={`c7.response`}
            />
            {fields?.map((elm, i) => (
              <Fragment key={dataC7?.subQuestions?.[i]?.id}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Question
                        onChange={(newVal) => {
                          onChange(newVal);
                          resetField(`c7.subQuestions.${i}.detail`);
                        }}
                        value={value}
                        desc={dataC7?.subQuestions?.[i]?.name || ''}
                        style="questionItem">
                        {watch('c7').subQuestions?.[i]?.response === 'C' && (
                          <div className="pt-12px">
                            <Controller
                              control={control}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <TextInput
                                    onBlur={() => onChange(value?.trim())}
                                    onChange={(e) => onChange(e?.target?.value)}
                                    value={value}
                                    placeholder="Chi tiết"
                                    maxLength={255}
                                    error={(errors as any)?.c7?.subQuestions?.[i]?.detail?.message?.toString() || ''}
                                  />
                                );
                              }}
                              name={`c7.subQuestions.${i}.detail`}
                              rules={{
                                required: {
                                  value: watch('c7')?.subQuestions?.[i]?.response === 'C',
                                  message: 'Vui lòng nhập đầy đủ thông tin',
                                },
                              }}
                            />
                          </div>
                        )}
                      </Question>
                    );
                  }}
                  name={`c7.subQuestions.${i}.response`}
                />
              </Fragment>
            ))}
          </div>
          <div className="wrapQuestion">
            <div className="questionViewTitle">
              <div className="questionIndex">
                <p className="questionIndexText">8</p>
              </div>
              <p className="questionTxtTitle">Câu hỏi 8</p>
            </div>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Question
                    onChange={(newVal) => {
                      onChange(newVal);
                      resetField(`c8.medicine_type`);
                      resetField(`c8.reason`);
                    }}
                    value={value}
                    desc={dataC8.name || ''}
                    style="questionItem"
                    isShow={dataC8.type === QuestionTypeEnum.INPUT ? false : true}>
                    {watch('c8.response') === 'C' && (
                      <div className={classNames(dataC8.style)}>
                        <div className={classNames([dataC8.type === QuestionTypeEnum.INPUT && 'px-6px'])}>
                          <Controller
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextInput
                                  onBlur={() => onChange(value?.trim())}
                                  onChange={(e) => onChange(e?.target?.value)}
                                  value={value}
                                  placeholder={dataC8.subQuestions[0].name}
                                  maxLength={dataC8.subQuestions[0].maxLength}
                                  error={(errors as any)?.c8?.medicine_type?.message?.toString() || ''}
                                />
                              );
                            }}
                            name="c8.medicine_type"
                            rules={{
                              required: {
                                value: true,
                                message: vi.filled,
                              },
                            }}
                          />
                          <Controller
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextInput
                                  onBlur={() => onChange(value?.trim())}
                                  onChange={(e) => onChange(e?.target?.value)}
                                  value={value}
                                  placeholder={dataC8.subQuestions[1].name}
                                  maxLength={dataC8.subQuestions[1].maxLength}
                                  error={(errors as any)?.c8?.reason?.message?.toString() || ''}
                                />
                              );
                            }}
                            name="c8.reason"
                            rules={{
                              required: {
                                value: true,
                                message: vi.filled,
                              },
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Question>
                );
              }}
              name="c8.response"
            />
          </div>
          <div className="wrapQuestion">
            <div className="questionViewTitle">
              <div className="questionIndex">
                <p className="questionIndexText">9</p>
              </div>
              <p className="questionTxtTitle">Câu hỏi 9</p>
            </div>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Question
                    onChange={(newVal) => {
                      onChange(newVal);
                      resetField(`c9.subQuestions`);
                    }}
                    value={value}
                    desc={dataC9.name || ''}
                    style="questionItem"
                  />
                );
              }}
              name={`c9.response`}
            />
            {watch('c9.response') === 'C' &&
              c9Fields?.map((elm, i) => (
                <Fragment key={dataC9?.subQuestions?.[i]?.id}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Question
                          onChange={(newVal) => {
                            onChange(newVal);
                            resetField(`c9.subQuestions.${i}.detail`);
                          }}
                          value={value}
                          desc={dataC9?.subQuestions?.[i]?.name || ''}
                          style="questionItem">
                          {watch('c9').subQuestions?.[i]?.response === 'C' && (
                            <div className="pt-12px">
                              <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <TextInput
                                      onBlur={() => onChange(value?.trim())}
                                      onChange={(e) => onChange(e?.target?.value)}
                                      value={value}
                                      placeholder="Chi tiết"
                                      maxLength={255}
                                      error={(errors as any)?.c9?.subQuestions?.[i]?.detail?.message?.toString() || ''}
                                    />
                                  );
                                }}
                                name={`c9.subQuestions.${i}.detail`}
                                rules={{
                                  required: {
                                    value: watch('c9')?.subQuestions?.[i]?.response === 'C',
                                    message: 'Vui lòng nhập đầy đủ thông tin',
                                  },
                                }}
                              />
                            </div>
                          )}
                        </Question>
                      );
                    }}
                    name={`c9.subQuestions.${i}.response`}
                  />
                </Fragment>
              ))}
          </div>
        </CollapseHeader>
      </div>
      <FooterQuestion
        handleChangeStep={(stepGo: number) => handleSubmit(() => onNext(stepGo))()}
        handleSubmit={handleSubmit(onSubmit)}
      />
    </>
  );
});
