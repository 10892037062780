import axios from 'axios';

import { ACCESS_TOKEN, REFRESH_TOKEN, TOKEN_EXPIRED_TIME } from '../constants';
import { VerifyCRMQueryResponse } from '../services';

import { ApiUrl } from './api-url';
import { saveToken } from './save-token';

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const request = axios.create({
  // Timeout 30p
  timeout: 30 * 60 * 1000,
  withCredentials: true,
  baseURL,
});

const isTokenExpired = () => {
  const expiredDate = localStorage.getItem(TOKEN_EXPIRED_TIME);

  return expiredDate != null && new Date().getTime() > Number(expiredDate);
};

const refreshToken = async () => {
  const token = localStorage.getItem(REFRESH_TOKEN);

  try {
    const data = await axios.post<VerifyCRMQueryResponse>((baseURL ?? '') + ApiUrl.refresh(token || ''));

    saveToken(data.data);

    return data.data.access_token;
  } catch (error: any) {
    alert(error.response?.data?.message ?? error.message);
  }

  return null;
};

request.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem(ACCESS_TOKEN);

    const isExpired = isTokenExpired();

    if (isExpired) {
      token = await refreshToken();
    }

    if (token != null) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config;
  },
  (error) => Promise.reject(error),
);
