import { ParentContractMicType, ParentInfoMicType } from '../../../../services';

export interface OptionType {
  label?: string;
  value?: string | any;
}
export interface BenefitsType extends OptionType {
  id: string;
  content?: OptionType[];
}

export interface MicInsuranceBenefitType {
  main_one: string;
  main_three: string;
  main_three_five: string;
  main_three_four: string;
  main_three_one: string;
  main_three_seven: string;
  main_three_six: string;
  main_three_three: string;
  main_three_two: string;
  main_two: string;
  sub_four: string;
  sub_one: string;
  sub_one_one: string;
  sub_one_two: string;
  sub_three: string;
  sub_two: string;
}

export interface MicInsuranceBenefitPackageType extends MicInsuranceBenefitType {
  name?: string;
  phi?: number;
  id?: string;
  regular_fee?: number;
}

export interface MiniPackageType {
  backgroundImage?: string;
  cashback?: string;
  deathBenefit?: string;
  id?: string;
  insuranceFee?: number;
  insuranceFeeStr?: string;
  lstUlsp?: {
    display: string;
    packageCode: string;
  }[];
  mic?: string;
  micInsuranceBenefit?: MicInsuranceBenefitPackageType;
  mixPackageName?: string;
  name?: string;
  orderNo?: number;
  packageCode?: string;
  payfrqCd?: string;
  photo?: string;
  productCode?: string;
  productName?: string;
  strDeathBenefit?: string;
  strInsuranceFee?: string;
  totalInsuranceBenefit?: number;
  type?: string;
  voucherAccount?: string;
  mixInsuranceFee?: number;
  strMixInsuranceFee?: string;
  micInsuranceFee?: number;
  code?: string;
  sumAssured?: number;
  accidentBenefit?: number;
  inpatientTreatmentBenefit?: number;
  strTotalInsuranceBenefit?: string;

  deathNoAccidentFrom?: string;
  deathNoAccidentTo?: string;
  deathAccident?: string;
  deathAccidentNoTraffic?: string;
  deathAccidentYesTraffic?: string;
  strDeathAccident?: string;
  strDeathNoAccidentFrom?: string;
  strDeathNoAccidentTo?: string;
}

export interface MiniPackagesRes {
  micInsuranceBenefits: MicInsuranceBenefitPackageType[];
  packages: MiniPackageType[];
}

export interface MiniPackagesReq {
  gender?: string;
  age?: string; // "yyyy-mm-dd",
  ng_sinh?: string; // "yyyy-mm-dd",
  ngay_hl?: string; // "dd/mm/yyyy",
  occupationClass?: number;
  type?: string;
  insurancePackageName?: string;
}

export interface GetMicPackagesReq {
  dob?: string;
  gender?: string;
  parent_contract?: ParentContractMicType;
  parent_info?: ParentInfoMicType;
}

export const packageHealthBenefits = (benifit?: MicInsuranceBenefitType) => {
  return [
    {
      id: 'benefits_1',
      label: 'Tử vong/thương tật vĩnh viễn do tai nạn',
      value: benifit?.main_one || 'Tối đa 0 VND',
      content: [],
    },
    {
      id: 'benefits_2',
      label: 'Chi phí y tế do tai nạn',
      value: benifit?.main_two || 'Tối đa 0 VND',
      content: [],
    },
    {
      id: 'benefits_3',
      label: 'Điều trị nội trú do ốm đau, bệnh tật',
      value: benifit?.main_three || 'Tối đa 0 VND',
      content: [
        {
          label: 'Nằm viện do ốm đau, bệnh tật (tối đa 60 ngày/năm)',
          value: benifit?.main_three_one,
        },
        {
          label: 'Phẫu thuật do ốm đau, bệnh tật hoặc liên quan đến cấy ghép nội tạng',
          value: benifit?.main_three_two,
        },
        {
          label: 'Điều trị trước/sau khi nhập viện hoặc y tế chăm sóc tại nhà sau xuất viện',
          value: benifit?.main_three_three,
        },
        {
          label: 'Trợ cấp nằm viện/ngày (tối đa 60 ngày)',
          value: benifit?.main_three_four,
        },
        {
          label: 'Dịch vụ xe cứu thương ',
          value: benifit?.main_three_five,
        },
        {
          label: 'Phục hồi chức năng',
          value: benifit?.main_three_six,
        },
        {
          label: 'Trợ cấp mai táng phí',
          value: benifit?.main_three_seven,
        },
      ],
    },
  ];
};

export const packageAdditionalBenefits = (benifit?: MicInsuranceBenefitType) => {
  const data: BenefitsType[] = [
    {
      id: 'bs1',
      label: 'Ngoại trú do ốm đau, bệnh tật',
      value: benifit?.sub_one || '',

      content: [
        {
          label: 'Chi phí khám ngoại trú',
          value: benifit?.sub_one_one || '',
        },
        {
          label: 'Vật lý trị liệu',
          value: benifit?.sub_one_two || '',
        },
      ],
    },
    {
      id: 'bs2',
      label: 'Quyền lợi nha khoa',
      value: benifit?.sub_two || '',
      content: [],
    },
    {
      id: 'bs3',
      label: 'Quyền lợi thai sản',
      value: benifit?.sub_three || '',
    },
    {
      id: 'bs4',
      label: 'Tử vong, thương tật toàn bộ vĩnh viễn do ốm đau, bệnh tật',
      value: benifit?.sub_four || '',
    },
  ];
  return data || [];
};

export const mergeArrays = (arr1: any[], arr2: any[]) => {
  let res = [];
  if (arr1.length > arr2.length) {
    res = arr1?.map((obj) => {
      const index = arr2?.findIndex((el) => el['person'] == obj['person']);
      const { ...rest } = index !== -1 ? arr2[index] : {};

      return {
        ...obj,
        ...rest,
      };
    });
  } else {
    res = arr2?.map((obj) => {
      const index = arr1?.findIndex((el) => el['person'] == obj['person']);
      const { ...rest } = index !== -1 ? arr1[index] : {};

      return {
        ...obj,
        ...rest,
      };
    });
  }
  return res;
};
