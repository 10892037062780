import { Button, Modal } from '@mantine/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { uniq } from 'lodash';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { defaultMicBenefits } from '../..';
import { Collapse } from '../../../../components';
import { DATE_FORMAT } from '../../../../constants';
import { formatMoney, getMessage } from '../../../../helpers';
import { logoMic, logoMic2x, logoMic3x } from '../../../../icons';
import {
  ParentContractMicType,
  ParentInfoMicType,
  RelationshipTypeEnum,
  UseCustomMiniPackagesQueryResponse,
  useGetMicPackagesQuery,
} from '../../../../services';
import { AssuredOptionType } from '../../form/insurance-detail-form';

import './index.less';
import { MicInsuranceBenefitPackageType, packageAdditionalBenefits, packageHealthBenefits } from './utils';

type Props = PropsWithChildren<{
  value?: MicInsuranceBenefitPackageType;
  style?: string;
  onChange?: (value?: MicInsuranceBenefitPackageType) => void;
  packages?: UseCustomMiniPackagesQueryResponse;
  micBenefits?: string[];
  onChangeMicBenefits: (value: string[]) => void;
  errorText?: string;
  isChild?: boolean;
  assured?: AssuredOptionType;
  parentContract?: ParentContractMicType;
  parentInfo?: ParentInfoMicType;
  loading?: boolean;
  onLoading?: (loading: boolean) => void;
  onSelectMicPackage?: (value?: MicInsuranceBenefitPackageType) => void;
}>;

export const ChooseMicPackage = React.memo<Props>(
  ({
    value,
    onChange,
    onSelectMicPackage,
    style,
    micBenefits,
    onChangeMicBenefits,
    isChild,
    assured,
    parentContract,
    parentInfo,
    errorText = '',
    loading,
    onLoading,
  }) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [active, setActive] = useState<MicInsuranceBenefitPackageType>();
    const [selected, setSelected] = useState<MicInsuranceBenefitPackageType>();
    const [itemChecked, setItemChecked] = useState<string[]>(micBenefits || []);
    const [error, setError] = useState<string>(errorText);

    const isParentContract = useMemo(
      () => !!parentContract?.ten_bm || !!parentContract?.ng_sinh_bm || !!parentContract?.mobi_bm,
      [parentContract?.mobi_bm, parentContract?.ng_sinh_bm, parentContract?.ten_bm],
    );

    const { data, isLoading } = useGetMicPackagesQuery(
      {
        dob: assured?.dob || dayjs().format(DATE_FORMAT.server),
        gender: assured?.gender,
        parent_contract: isParentContract ? parentContract : undefined,
        parent_info: parentInfo?.full_name ? parentInfo : undefined,
      },
      {
        enabled: !!assured?.dob,
        onError: (errors) => {
          setError(getMessage(errors));
        },
        onSuccess(res: MicInsuranceBenefitPackageType[]) {
          setError('');
          const item = res?.find((it) => it.id === active?.id);
          if (item?.id === active?.id) {
            setSelected(item);
            setActive(item);
            onChange?.(item);
          }
        },
      },
    );

    useEffect(() => {
      onLoading?.(isLoading);
    }, [isLoading, onLoading]);

    useEffect(() => {
      setSelected(value);
      setActive(value);
    }, [value]);

    useEffect(() => {
      setItemChecked(micBenefits || defaultMicBenefits);
    }, [micBenefits]);

    useEffect(() => {
      if (isParentContract) {
        setError('');
      }
    }, [isParentContract]);

    useEffect(() => {
      if (!!value && data && !visible && (!!parentContract || !!parentInfo)) {
        const item = data?.find((it) => it?.id === value?.id);
        if (!!item?.id && item?.phi !== selected?.phi) {
          setSelected(item);
          onChange?.(item);
        }
      }
    }, [data, parentContract, value, visible, onChange, setSelected, selected?.phi, selected, parentInfo]);

    const onPress = useCallback(() => {
      setVisible((prev) => !prev);
    }, [setVisible]);

    const onChoose = useCallback(
      (val: MicInsuranceBenefitPackageType) => {
        if (selected?.id === val.id) {
          setSelected(undefined);
          onSelectMicPackage?.(undefined);
        } else {
          setSelected(val);
          onSelectMicPackage?.(val);
        }
      },
      [onSelectMicPackage, selected?.id],
    );

    const onCheck = useCallback(
      (val: string) => {
        const findIndex = itemChecked?.findIndex((el) => el === val);
        if (findIndex < 0) {
          setItemChecked((prev) => {
            const newChecked = uniq(prev?.concat([val]));
            onChangeMicBenefits?.(newChecked);
            return newChecked;
          });
        } else {
          setItemChecked((prev) => {
            const newChecked = prev?.filter((el) => el !== val);
            onChangeMicBenefits?.(newChecked);
            return newChecked;
          });
        }
      },
      [itemChecked, onChangeMicBenefits],
    );

    const onSubmit = useCallback(() => {
      setVisible((prev) => !prev);
      onChange?.(selected);
    }, [onChange, setVisible, selected]);

    const micLists = useMemo(() => {
      return data;
    }, [data]);

    return (
      <>
        <div className={classNames(['cpm-wrap', style, (!!error || !!errorText) && '!mb-0 hasError'])}>
          {active ? (
            <div>
              <div className={'cpm-highlightText my-0'}>{active?.name?.toUpperCase()}</div>
              <div className="cpm-subHighlightText">{formatMoney(active?.phi || 0)}</div>
              {!!active?.regular_fee && active?.regular_fee !== active?.phi && (
                <div className={classNames(['cpm-subHighlightText !text-14px my-0'])}>
                  <del>{formatMoney(active?.regular_fee || 0)}</del>
                </div>
              )}
            </div>
          ) : (
            <span className={classNames(['cpm-text', 'font-semibold', !!errorText && '!text-error'])}>
              Chưa chọn gói
            </span>
          )}

          <Button
            className="cpm-buttonStyle"
            onClick={onPress}
            disabled={isLoading || !assured?.dob || loading}
            loading={isLoading}>
            Chọn gói
          </Button>
        </div>
        {(!!error || !!errorText) && <div className="errorTextStyle">{error || errorText}</div>}

        {!!active && !isLoading && (
          <>
            <div className={'cpm-benifitTitleText'}>Điều khoản bảo hiểm chính:</div>
            {packageHealthBenefits(active)?.map((el) => (
              <Collapse
                type="outline"
                key={el.id}
                id={el.id}
                label={el.label}
                text={el.value}
                style={classNames(['!mb-12px', 'cpm-elmItemWhite'])}
                isCheckBox={true}
                checked={itemChecked}
              />
            ))}

            <div className={'cpm-benifitTitleText'}>Điều khoản bảo hiểm bổ sung:</div>
            {packageAdditionalBenefits(active)?.map(
              (el) =>
                el.value !== 'Không' && (
                  <Collapse
                    type="outline"
                    key={el?.label}
                    id={el?.id}
                    label={el?.label}
                    text={el?.value}
                    style={classNames(['!mb-12px', 'cpm-elmItemWhite'])}
                    isCheckBox={true}
                    checked={itemChecked}
                    onCheck={onCheck}
                  />
                ),
            )}
          </>
        )}

        <div className="m-0 mb-10px text-c_EB5757 text-14px">
          * Được phát hành bởi MIC <i>(không áp dụng hủy trong 21 ngày)</i>
        </div>

        <Modal
          opened={visible}
          onClose={() => {
            setVisible((prev) => !prev);
          }}
          className={'cpm-modalFull'}
          fullScreen
          title="Gói bảo hiểm MIC">
          <div className={'cpm-wrapModal'}>
            <div className={classNames(['cpm-modalContent'])}>
              <div className={'cpm-modalBody'}>
                <div className={classNames(['cpm-modalList'])}>
                  {micLists?.map((el: MicInsuranceBenefitPackageType, index: number) => (
                    <div key={el?.id} onClick={() => onChoose(el)}>
                      <div className={classNames(['cpm-wrapElement'])}>
                        <div
                          className={classNames([
                            'cpm-elmContent',
                            micLists?.length === index + 1 && 'cpm-elmLast',
                            selected?.id === el.id && 'cpm-elmMBALActive',
                          ])}>
                          <picture>
                            <img
                              src={logoMic}
                              alt=""
                              srcSet={`${logoMic2x} 2x, ${logoMic3x} 3x`}
                              className={'cpm-imageMic'}
                            />
                          </picture>
                          <div className={'cpm-elmRight'}>
                            <div className={classNames(['cpm-highlightText cpm-textMic'])}>{el?.name}</div>
                            <div className={classNames(['cpm-subHighlightText cpm-subTextMic my-0'])}>
                              {formatMoney(el?.phi || 0)}
                            </div>

                            {el?.regular_fee && el?.regular_fee !== el?.phi && (
                              <div className={classNames(['cpm-subHighlightText cpm-subTextMic !text-14px my-0'])}>
                                <del>{formatMoney(el?.regular_fee || 0)}</del>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {(assured?.relationship === RelationshipTypeEnum.COUPLE || assured?.isCustomer) && isChild && (
                    <div className="tw-px-16px tw-text-13px">
                      <i>
                        * Thẻ bảo lãnh viện phí MIC Care của Con được giảm 30% khi quyền lợi của Con và Bố/mẹ giống nhau
                      </i>
                    </div>
                  )}
                </div>
                <div className="p-16px pt-0">
                  <Button fullWidth onClick={onSubmit}>
                    Chọn
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  },
);
