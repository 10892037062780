import { FORMAT_NUMBER } from './regexp';

export const getMessage = (error: any): string => {
  const details = error?.response?.data?.details;
  const error_code = error?.response?.data?.error_code;
  let mess =
    error?.response?.data?.message?.message ||
    error?.response?.data?.message ||
    error?.data?.error?.message ||
    error?.data?.message ||
    error?.error_description ||
    error?.error?.message ||
    error?.message ||
    error?.error?.code ||
    'Lỗi hệ thống. Vui lòng thử lại';
  if (details?.length) {
    mess = details?.[0]?.target + ' ' + details?.[0]?.message;
  }
  if (error_code) {
    return mess + ` (${error_code})`;
  }
  return mess;
};

export const formatMoney = (num?: number | string, sep?: string) => {
  if (!num) return '0 VND';
  return num.toString().replace(FORMAT_NUMBER, `$1${sep || '.'}`) + ' VND';
};

export const getBS = (data?: string[], text?: string) => {
  const findIndex = data?.findIndex((el) => el === text) || -1;
  if (findIndex >= 0) {
    return 'C';
  } else {
    return 'K';
  }
};

export const getMicBenefits = (data?: string[]) => {
  return {
    bs1: getBS(data, 'bs1'),
    bs2: getBS(data, 'bs2'),
    bs3: getBS(data, 'bs3'),
    bs4: getBS(data, 'bs4'),
    num_of_plus_day: 8,
  };
};

export const dataURLtoFile = (dataURL: string, fileName: string) => {
  // dataURL = data:image/jpeg;base64,...
  const arr = dataURL.split(',');
  const mime = arr[0].split(':')[1].split(';')[0];
  // mime = image/jpeg
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const extension = mime.split('/')[1];
  return new File([u8arr], `${fileName}.${extension}`, { type: mime });
};

export const formatPhoneNumber = (data: string) => {
  return data?.replace(/(\d{4})(\d{3})(\d{3,4})/, '$1 $2 $3');
};
