import React, { Fragment, useContext } from 'react';

import { useIllustration } from '../../../form';
import { MixQuestionsContext } from '../../../form/insurance-request';
import { dataStepsQuestion } from '../../../form/insurance-request/mock';
import { SmallQuestionComponent } from '../small';

import {
  StepsQuestion10ForChildScreen,
  StepsQuestion10Screen,
  StepsQuestion11Screen,
  StepsQuestion1Screen,
  StepsQuestion3Screen,
  StepsQuestion6Screen,
  StepsQuestion7Screen,
  StepsQuestionDefaultScreen,
} from '.';
type Props = {};

export const BigQuestionComponent = React.memo<Props>(() => {
  const { currentAssured } = useIllustration();
  const { stepLabel } = useContext(MixQuestionsContext);

  return (
    <>
      {dataStepsQuestion.map((el, index) => {
        const questionName = `c${index + 1}`;
        return (
          <Fragment key={el.id}>
            {stepLabel === index && ![0, 2, 3, 4, 5, 6].includes(index) && (
              <StepsQuestionDefaultScreen currentIndex={index} questionName={questionName} />
            )}
            {stepLabel === 0 && index === 0 && (
              <StepsQuestion1Screen currentIndex={index} questionName={questionName} />
            )}
            {stepLabel === 2 && index === 2 && <StepsQuestion3Screen currentIndex={index} />}
            {stepLabel === 3 && index === 3 && <StepsQuestion6Screen currentIndex={index} questionName="c6" />}
            {stepLabel === 4 && index === 4 && <StepsQuestion7Screen currentIndex={index} />}
            {stepLabel === 5 &&
            index === 5 &&
            currentAssured?.gender === 'FEMALE' &&
            !!currentAssured?.age &&
            currentAssured?.age > 12 ? (
              <StepsQuestion10Screen currentIndex={index} questionName="c10" />
            ) : (
              stepLabel === 5 &&
              index === 5 &&
              !!currentAssured?.age &&
              currentAssured?.age <= 12 && <StepsQuestion10ForChildScreen questionName="c10" />
            )}
            {stepLabel === 6 && index === 6 && <StepsQuestion11Screen currentIndex={index} questionName="c11" />}
          </Fragment>
        );
      })}
      {currentAssured?.app_question_number === 15 && stepLabel === 7 && <SmallQuestionComponent />}
    </>
  );
});
