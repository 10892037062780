/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select, TextInput } from '@mantine/core';
import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { UserComponent } from '..';
import { Question, NumberInput } from '../../../../../components';
import { FORMAT_NUMBER } from '../../../../../helpers';
import { vi } from '../../../../../i18n/vi';
import { ChevronDownSVG, PlusRoundWhiteSVG } from '../../../../../icons';
import {
  dataFrequency,
  dataStepsQuestion,
  MixQuestionsContext,
  QuestionTypeEnum,
  useIllustration,
} from '../../../form';
import { CollapseHeader } from '../../collapse-header';

import { FooterQuestion } from './footer';
type Props = {
  currentIndex: number;
};
export const StepsQuestion3Screen = React.memo<Props>(({ currentIndex }) => {
  const dataIndex = dataStepsQuestion[currentIndex];
  const dataC3 = dataIndex.questions[0];
  const dataC4 = dataIndex.questions[1];
  const dataC5 = dataIndex.questions[2];
  const { currentAssured } = useIllustration();
  const { stepLabel, handleChangeStepLabel, handleSubmitRoot, dataDefine: rootData } = useContext(MixQuestionsContext);
  const dataDefine = useMemo(() => rootData?.[currentAssured?.id ?? ''], [rootData, currentAssured]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    resetField,
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        c3: {
          response: dataDefine?.['c3']?.response || 'K',
          type: dataDefine?.['c3']?.type,
          num_in_day: dataDefine?.['c3']?.num_in_day,
          num_of_years: dataDefine?.['c3']?.num_of_years,
        },
        c4: {
          response: dataDefine?.['c4']?.response || 'K',
          type: dataDefine?.['c4']?.type,
          frequency: dataDefine?.['c4']?.frequency,
          count: dataDefine?.['c4']?.count,
        },
        c5: {
          response: dataDefine?.['c5']?.response || dataC5?.default || 'K',
          detail: dataDefine?.['c5']?.detail,
        },
      };
    }, [dataC5?.default, dataDefine]),
  });

  const onNext = (stepGo: number) => {
    handleChangeStepLabel(stepGo);
    const body = {
      ...watch(),
      c3: {
        ...watch('c3'),
        num_in_day: watch('c3')?.num_in_day?.toString(),
        num_of_years: watch('c3')?.num_of_years?.toString(),
      },
    };
    handleSubmitRoot(currentAssured?.id ?? '', {
      ...body,
      body,
      assured: currentAssured,
    });
  };

  const onSubmit = () => {
    onNext(stepLabel + 1);
  };

  const getTypeIndex = useMemo(() => {
    if (watch('c4.type') === 'BEER') {
      return { count: 1, frequency: 2 };
    }
    return { count: 3, frequency: 4 };
  }, [watch]);

  return (
    <>
      <div className="flex-1">
        <CollapseHeader icon={<PlusRoundWhiteSVG />} title={dataIndex.type} className="custom-collapse-header">
          <UserComponent />
          <div className="wrapQuestion">
            <div className="questionViewTitle">
              <div className="questionIndex">
                <p className="questionIndexText">3</p>
              </div>
              <p className="questionTxtTitle">Câu hỏi 3</p>
            </div>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Question
                    onChange={(newVal) => {
                      onChange(newVal);
                      resetField(`c3.type`);
                      resetField(`c3.num_in_day`);
                      resetField(`c3.num_of_years`);
                    }}
                    value={value}
                    desc={dataC3.name || ''}
                    style="questionItem"
                    isShow={dataC3.type === QuestionTypeEnum.INPUT ? false : true}>
                    {watch('c3.response') === 'C' && (
                      <div className={classNames(dataC3.style)}>
                        <div className={classNames([dataC3.type === QuestionTypeEnum.INPUT && 'px-6px'])}>
                          <Controller
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextInput
                                  onBlur={() => onChange(value?.trim())}
                                  onChange={(e) => onChange(e?.target?.value)}
                                  value={value}
                                  placeholder={dataC5.subQuestions[0].name}
                                  maxLength={dataC5.subQuestions[0].maxLength}
                                  error={(errors as any)?.c3?.type?.message?.toString() || ''}
                                />
                              );
                            }}
                            name="c3.type"
                            rules={{
                              required: {
                                value: true,
                                message: vi.filled,
                              },
                            }}
                          />
                          <Controller
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <NumberInput
                                  onChange={(value) => onChange(value)}
                                  value={value}
                                  placeholder={dataC3.subQuestions[1].name}
                                  max={dataC3.subQuestions[1].maxLength}
                                  error={(errors as any)?.c3?.num_in_day?.message?.toString() || ''}
                                  rightSection={<div />}
                                />
                              );
                            }}
                            name="c3.num_in_day"
                            rules={{
                              required: {
                                value: true,
                                message: vi.filled,
                              },
                              pattern: {
                                value: FORMAT_NUMBER,
                                message: 'Vui lòng chỉ nhập kí tự số',
                              },
                            }}
                          />
                          <Controller
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <NumberInput
                                  onChange={(value) => onChange(value)}
                                  value={value}
                                  placeholder={dataC3.subQuestions[2].name}
                                  max={dataC3.subQuestions[2].maxLength}
                                  error={(errors as any)?.c3?.num_of_years?.message?.toString() || ''}
                                  rightSection={<div />}
                                />
                              );
                            }}
                            name="c3.num_of_years"
                            rules={{
                              required: {
                                value: true,
                                message: vi.filled,
                              },
                              pattern: {
                                value: FORMAT_NUMBER,
                                message: 'Vui lòng chỉ nhập kí tự số',
                              },
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Question>
                );
              }}
              name="c3.response"
            />
          </div>
          <div className="wrapQuestion">
            <div className="questionViewTitle">
              <div className="questionIndex">
                <p className="questionIndexText">4</p>
              </div>
              <p className="questionTxtTitle">Câu hỏi 4</p>
            </div>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Question
                    onChange={(newVal) => {
                      onChange(newVal);
                      resetField(`c4.type`);
                      resetField(`c4.count`);
                      resetField(`c4.frequency`);
                    }}
                    value={value}
                    desc={dataC4.name || ''}
                    style="questionItem"
                    isShow={dataC4.type === QuestionTypeEnum.INPUT ? false : true}>
                    {watch('c4.response') === 'C' && (
                      <div className={classNames(dataC4.style)}>
                        <div className={classNames([dataC4.type === QuestionTypeEnum.INPUT && 'px-6px'])}>
                          <Controller
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Question
                                  onChange={(newVal) => onChange(newVal)}
                                  value={value}
                                  desc={dataC4?.subQuestions?.[0].name}
                                  style="questionItem"
                                  data={dataC4?.subQuestions?.[0]?.selections}
                                  errorText={(errors as any)?.c4?.type?.message?.toString() || ''}
                                />
                              );
                            }}
                            name="c4.type"
                            rules={{
                              required: {
                                value: true,
                                message: vi.filled,
                              },
                            }}
                          />
                          {!!watch('c4.type') && (
                            <>
                              <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <NumberInput
                                      onChange={(value) => onChange(value)}
                                      value={value}
                                      placeholder={dataC4.subQuestions[getTypeIndex.count].name}
                                      maxLength={dataC4.subQuestions[getTypeIndex.count].maxLength}
                                      error={(errors as any)?.c4?.count?.message?.toString() || ''}
                                      rightSection={<div />}
                                    />
                                  );
                                }}
                                name="c4.count"
                                rules={{
                                  required: {
                                    value: true,
                                    message: vi.filled,
                                  },
                                }}
                              />
                              <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <Select
                                      required={true}
                                      placeholder="Tần suất uống"
                                      data={dataFrequency}
                                      value={value}
                                      onChange={onChange}
                                      rightSection={<ChevronDownSVG />}
                                      error={(errors as any)?.c4?.frequency?.message?.toString() || ''}
                                    />
                                  );
                                }}
                                name="c4.frequency"
                                rules={{
                                  required: {
                                    value: true,
                                    message: vi.filled,
                                  },
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </Question>
                );
              }}
              name="c4.response"
            />
          </div>
          <div className="wrapQuestion">
            <div className="questionViewTitle">
              <div className="questionIndex">
                <p className="questionIndexText">5</p>
              </div>
              <p className="questionTxtTitle">Câu hỏi 5</p>
            </div>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Question
                    onChange={(newVal) => {
                      onChange(newVal);
                      resetField(`c5.detail`);
                    }}
                    value={value}
                    desc={dataC5.name || ''}
                    style="questionItem"
                    isShow={dataC5.type === QuestionTypeEnum.INPUT ? false : true}>
                    {watch('c5')?.response === 'C' && (
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <>
                              <TextInput
                                onBlur={() => onChange(value?.trim())}
                                onChange={(e) => onChange(e?.target?.value)}
                                value={value}
                                placeholder={dataC5.subQuestions[0].name}
                                maxLength={dataC5.subQuestions[0].maxLength}
                                error={(errors as any)?.c5?.detail?.message?.toString() || ''}
                              />
                            </>
                          );
                        }}
                        name={`c5.detail`}
                        rules={{
                          required: {
                            value: true,
                            message: vi.filled,
                          },
                        }}
                      />
                    )}
                  </Question>
                );
              }}
              name={`c5.response`}
            />
          </div>
        </CollapseHeader>
      </div>
      <FooterQuestion
        handleChangeStep={(stepGo: number) => handleSubmit(() => onNext(stepGo))()}
        handleSubmit={handleSubmit(onSubmit)}
      />
    </>
  );
});
