import { Checkbox } from '@mantine/core';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes, dateFormat, formatPhoneNumber, noop } from '../../../../helpers';
import { vi } from '../../../../i18n/vi';
import { LiveAreaSVG, TrashSVG } from '../../../../icons';
import {
  InsuranceMethodEnum,
  LeadsCustomerFormData,
  LeadsFlowEnum,
  ReferOrDirectTabEnum,
} from '../../../../services/leads';
import { leadsContractStateOptions, referStateOptions } from '../types';

type Props = {
  data: LeadsCustomerFormData;
  type: LeadsFlowEnum | InsuranceMethodEnum | ReferOrDirectTabEnum;
  isDeleted?: boolean;
  isChecked?: boolean;
  onPick?: (id: number) => void;
  onRemove: (id: number) => void;
};

export const LeadsCard = ({ data, type, isDeleted, isChecked, onPick, onRemove }: Props) => {
  const id = useMemo(() => Number(data?.id || 0), [data]);
  const navigate = useNavigate();

  const statusInfo = useMemo(() => {
    return (
      referStateOptions.find((status) => status.value === data?.state) ||
      leadsContractStateOptions.find((status) => status.value === data?.app_status)
    );
  }, [data?.app_status, data?.state]);

  const statusColor = useMemo(() => {
    const colorStatusValue = data?.lead_status_num;

    const matchingColor = referStateOptions.find((color) => color?.colorStatus === colorStatusValue);

    if (matchingColor) {
      return {
        bgColor: matchingColor?.bgColor,
        fillSVG: matchingColor?.fillSVG,
        text: matchingColor.text,
      };
    }
  }, [data?.lead_status_num]);

  const onDirect = useCallback(() => {
    const idString = id?.toString();
    if (isDeleted) {
      return onPick?.(Number(id));
    }
    if (type === InsuranceMethodEnum.REFER) {
      return navigate(AppRoutes.leads_refer_detail(idString));
    }
    if (type === InsuranceMethodEnum.DIRECT) {
      return navigate(AppRoutes.leads_direct_detail(idString));
    }
    return navigate(AppRoutes.leads_detail(idString));
  }, [id, isDeleted, navigate, onPick, type]);

  return (
    <div
      onClick={onDirect}
      className="flex justify-between p-16px h-auto bg-white rounded-[12px] mt-16px overflow-hidden relative">
      <div className="max-w-xs">
        <p className="text-sm font-bold font-sans whitespace-normal break-all mt-0">{data?.full_name}</p>
        <p className="font-normal text-sm">{formatPhoneNumber(data?.phone_number)}</p>
        <div className="mt-3">
          {type !== LeadsFlowEnum.ALL && statusInfo?.label && (
            <div
              className={`mt-1 px-2 py-1 ${
                statusColor?.bgColor || statusInfo?.bgColor
              } rounded-2xl inline-block w-auto`}>
              <div className="flex items-center">
                <LiveAreaSVG fill={`${statusColor?.fillSVG || statusInfo?.fillSVG}`} />
                <span className={`${statusColor?.text || statusInfo?.text} text-xs ml-1`}>
                  {data?.lead_status || statusInfo?.label}
                </span>
              </div>
            </div>
          )}
          <div className="mt-3">
            {type !== LeadsFlowEnum.ALL && !!data?.lead_id && (
              <div className="flex text-sm">
                <span className=" text-c_01122280">Mã khách hàng</span>:
                <span className="ml-1 font-normal">{data?.lead_id}</span>
              </div>
            )}

            {type === LeadsFlowEnum.ALL && (
              <>
                <div className="flex text-sm">
                  <span className=" text-c_01122280">Giới tính</span>:
                  <span className="ml-1 font-normal">{vi.gender[data?.gender]}</span>
                </div>
              </>
            )}
            <div className="flex text-sm mt-3">
              <span className=" text-c_01122280">Ngày tham gia</span>:
              <span className="ml-1 font-normal">{data?.created_date ? dateFormat(data.created_date) : ''}</span>
            </div>
          </div>
        </div>
      </div>
      {
        <>
          {isDeleted ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                onDirect();
              }}
              className="ml-auto flex-center">
              <Checkbox checked={isChecked} onChange={noop} />
            </div>
          ) : (
            <div className="ml-auto flex-center">
              <TrashSVG
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove(id);
                }}
              />
            </div>
          )}
        </>
      }
    </div>
  );
};
