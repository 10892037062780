import { Button } from '@mantine/core';
import { stringify } from 'qs';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PRODUCT_NAME } from '../../../constants';
import { useLeads } from '../../../contexts';
import { AppRoutes } from '../../../helpers';
import {
  ReverseProductBalanceSVG,
  ReverseProductFlexibleSVG,
  ReverseProductSignatureSVG,
  ReverseProductSpecialSVG,
} from '../../../icons';
import { ComboEnum } from '../../../services';
import { LeadsFormStepEnum } from '../form';

export const reverseComboOptions = [
  {
    value: ComboEnum.SIGNATURE,
    logo: <ReverseProductSignatureSVG />,
    label: 'Sống vui khỏe',
    content: 'Ưu tiên bảo vệ sức khỏe gia đình',
    className: 'bg-c_E7F7FD border-c_E7F7FD',
  },
  {
    value: ComboEnum.SPECIAL_OFFER,
    logo: <ReverseProductSpecialSVG />,
    label: 'Sống cân bằng',
    content: 'Cân bằng bảo vệ sức khỏe và đầu tư',
    className: 'bg-c_FFEEF9 border-c_FFEEF9',
  },
  {
    value: ComboEnum.MUST_TRY,
    logo: <ReverseProductBalanceSVG />,
    label: 'Sống thảnh thơi',
    content: 'Sản phẩm ưu tiên đầu tư sinh lợi nhuận',
    className: 'bg-c_FFF8E3 border-c_FFF8E3',
  },
  {
    value: ComboEnum.FLEXIBLE,
    logo: <ReverseProductFlexibleSVG />,
    label: PRODUCT_NAME.ULRP_3_0,
    content: 'Tùy chọn hình thức bảo hiểm theo mong muốn',
    className: 'bg-c_F3F0FF border-c_F3F0FF',
  },
];

type Props = {
  disabled?: boolean;
};

export const SwitchFlowToReferButton = memo(({ disabled }: Props) => {
  const { leadsCustomer } = useLeads();
  const navigate = useNavigate();

  const onBackToRefer = useCallback(() => {
    navigate({
      pathname: AppRoutes.leads_new,
      search: stringify({
        step: LeadsFormStepEnum.REASON_TO_REFER,
      }),
    });
  }, [navigate]);

  return (
    <>
      {leadsCustomer && (
        <Button variant="light" onClick={onBackToRefer} fullWidth disabled={disabled}>
          Chuyển luồng bán Refer
        </Button>
      )}
    </>
  );
});
