import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Select, SelectItem, TextInput } from '@mantine/core';
import dayjs from 'dayjs';
import { forEach } from 'lodash';
import { stringify } from 'qs';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { Controller, FieldErrors, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { DatePicker, Empty, FormLabel, NumberInput } from '../../../components';
import { DEFAULT_NATIONALITY, useDialog, useInitialData } from '../../../contexts';
import { getAge, validationMessages } from '../../../helpers';
import { useQueryString } from '../../../hooks';
import { vi } from '../../../i18n/vi';
import { CalendarSVG, ChevronDownSVG, PlusSVG, TrashSVG, UserWhiteSVG } from '../../../icons';
import { GenderEnum, IdentityDocumentTypeEnum, RelationshipTypeEnum } from '../../../services';
import {
  CollapseHeader,
  CollapseHeaderType,
  dobValidation,
  emailValidation,
  GenderRadioGroup,
  genderValidation,
  identityDocumentNumberValidation,
  identityDocumentTypeValidation,
  phoneValidation,
  ScreenParams,
} from '../components';
import { PickAdress } from '../../../components/pick-address/pick-address';

import {
  BeneficiariesPersonFormData,
  FormStepEnum,
  identityDocumentTypeOptions,
  useIllustration,
  User,
} from './illustration-form-screen';

export const relationshipTypeOptions: SelectItem[] = [
  {
    value: RelationshipTypeEnum.COUPLE,
    label: 'Vợ/Chồng',
  },
  {
    value: RelationshipTypeEnum.CHILDREN,
    label: 'Con',
  },
  {
    value: RelationshipTypeEnum.PARENT,
    label: 'Bố/Mẹ',
  },
  {
    value: RelationshipTypeEnum.OTHER,
    label: 'Khác',
  },
];
export const BenificiriesPersonForm = memo(() => {
  const { processId = 0 } = useQueryString<ScreenParams>();
  const navigate = useNavigate();

  const { onUpdateBeneficiariesPersonForm, persons, beneficiariesPerson, insuredPerson } = useIllustration();
  const { occupations, defaultOccupation, studentOccupation } = useInitialData();

  useEffect(() => {
    if (beneficiariesPerson && !beneficiariesPerson?.id) {
      beneficiariesFormRef.current?.toggle(true);
    }
  }, [beneficiariesPerson]);

  const validationSchema: yup.ObjectSchema<BeneficiariesPersonFormData> = useMemo(
    () =>
      yup.object({
        person: yup
          .string()
          .trim()
          .test('check-trmr-person', 'Vui lòng chọn người thụ hưởng', (val, context) => {
            return !!beneficiariesPerson || context?.parent?.beneficiaries?.length > 0 || !!val ? true : false;
          })
          .required(),
        relationship_with_main_assured: yup.mixed<RelationshipTypeEnum>().required(),
        benefit_rate: yup
          .number()
          .min(1, 'Tỷ lệ thụ hưởng phải lớn hơn 0')
          .max(100, 'Tỷ lệ thụ hưởng phải nhỏ hơn hoặc bằng 100')
          .required(validationMessages.required),
        beneficiaries: yup.array(
          yup.object({
            full_name: yup.string().required(),
            dob: dobValidation,
            gender: genderValidation,
            occupation_id: yup.number().required(),
            identification_type: identityDocumentTypeValidation,
            identification_id: identityDocumentNumberValidation,
            married: yup.bool().required(),
            phone_number: phoneValidation,
            address: yup.string().required(validationMessages.addressRequired),
            province_name: yup.string().required(validationMessages.provinceNameRequired),
            ward_name: yup.string().required(validationMessages.wardNameRequired),
            district_name: yup.string().required(validationMessages.districtNameRequired),
            email: emailValidation,
            relationship: yup.mixed<RelationshipTypeEnum>().required(),
            benefit_rate: yup
              .number()
              .min(1, 'Tỷ lệ thụ hưởng phải lớn hơn 0')
              .max(100, 'Tỷ lệ thụ hưởng phải nhỏ hơn hoặc bằng 100')
              .required(validationMessages.required),
            nationality_code: yup.string().required(validationMessages.nationalityRequired),
          }),
        ),
      }),
    [beneficiariesPerson],
  );

  const insuredPersonOptions: (SelectItem & { assuredAge: number; assuredDays: number })[] = useMemo(() => {
    return [
      ...(persons ?? [])?.map((it, index) => ({
        value: it?.id ?? `${index + 1}`,
        label: it.full_name,
        assuredAge: getAge(it.dob),
        assuredDays: dayjs().diff(dayjs(it.dob), 'day'),
      })),
      {
        value: 'OTHER',
        label: 'Khác',
        assuredAge: 0,
        assuredDays: 0,
      },
    ];
  }, [persons]);

  const defaultPerson = useMemo(() => {
    if (beneficiariesPerson?.id) return beneficiariesPerson?.id;
    if (beneficiariesPerson && !beneficiariesPerson?.id) return 'OTHER';
    return persons?.[0]?.id;
  }, [beneficiariesPerson, persons]);

  const {
    setFocus,
    trigger,
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<BeneficiariesPersonFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(() => {
      return {
        person: defaultPerson,
        relationship_with_main_assured: beneficiariesPerson?.relationship ?? undefined,
        benefit_rate: beneficiariesPerson?.benefit_rate ?? 100,
        beneficiaries: beneficiariesPerson && !beneficiariesPerson?.id ? [beneficiariesPerson] : [],
      };
    }, [beneficiariesPerson, defaultPerson]),
    mode: 'onChange',
  });

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a: any) => {
      return !!field && !!errors?.[field] ? field : a;
    }, null);
    if (firstError) {
      const firstChildError = Object.keys(errors[firstError]).reduce((f, b: any) => {
        return !!f && !!errors[firstError][f] ? f : b;
      }, null);
      if (firstChildError) {
        const object = errors[firstError][firstChildError] as any;
        setFocus(object?.ref?.name, { shouldSelect: true });
      }
      setFocus(firstError, { shouldSelect: true });
    }
  }, [errors, setFocus]);

  const onSubmit = useCallback(
    (data: BeneficiariesPersonFormData) => {
      if (!data.person && !data.beneficiaries) return;
      const findPerson = persons?.find((el) => el.id === data.person);
      const params: User = {
        full_name: '',
        dob: '',
        gender: GenderEnum.MALE,
        phone_number: '',
        identification_id: '',
        identification_type: IdentityDocumentTypeEnum.NATIONAL_ID,
        occupation_id: 0,
        married: false,
        province_name: '',
        district_name: '',
        ward_name: '',
        nationality_code: DEFAULT_NATIONALITY,
      };
      if (data.beneficiaries && data.person === 'OTHER') {
        forEach(data.beneficiaries, (it) => {
          Object.assign(params, {
            ...it,
          });
        });
      } else if (data.person && data.person !== 'OTHER') {
        Object.assign(params, {
          ...findPerson,
          relationship: data?.relationship_with_main_assured,
          benefit_rate: data?.benefit_rate,
        });
      }

      onUpdateBeneficiariesPersonForm(params);
      navigate({
        search: stringify({
          step: FormStepEnum.DETAIL,
          processId,
        }),
      });
    },
    [persons, onUpdateBeneficiariesPersonForm, navigate, processId],
  );

  const { showDialog } = useDialog();
  const handleRemoveReference = useCallback(
    async (resolve: () => void) => {
      const res = await showDialog({
        message: 'Nếu bạn xác nhận xoá, dữ liệu của bản ghi sẽ không thể khôi phục.',
        type: 'CONFIRM',
      });

      res && resolve();
      res && onUpdateBeneficiariesPersonForm(undefined);
      res && beneficiariesFormRef.current?.toggle(false);
    },
    [showDialog, onUpdateBeneficiariesPersonForm],
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'beneficiaries',
  });

  const beneficiariesFormRef = useRef<CollapseHeaderType>(null);

  const handleFormSubmitError = useCallback((error: FieldErrors<BeneficiariesPersonFormData>) => {
    if (error.beneficiaries != null) {
      beneficiariesFormRef.current?.toggle(true);
    }
  }, []);

  const appendAdditionalAssured = useMemo(() => {
    return {
      full_name: '',
      gender: GenderEnum.MALE,
      identification_type: IdentityDocumentTypeEnum.NATIONAL_ID,
      identification_id: '',
      occupation_id: defaultOccupation?.id,
      phone_number: '',
      married: false,
    } as User;
  }, [defaultOccupation?.id]);

  const availableIdentityDocumentTypeOptions = useCallback((age: number) => {
    if (!age) {
      return identityDocumentTypeOptions?.filter((it) => [IdentityDocumentTypeEnum.NATIONAL_ID].includes(it.value));
    }
    if (age < 14) {
      return identityDocumentTypeOptions?.filter((it) =>
        [IdentityDocumentTypeEnum.BIRTH_CERTIFICATE, IdentityDocumentTypeEnum.PASSPORT].includes(it.value),
      );
    }
    if (age >= 14 && age < 18) {
      return identityDocumentTypeOptions?.filter((it) =>
        [
          IdentityDocumentTypeEnum.NATIONAL_ID,
          IdentityDocumentTypeEnum.CITIZEN_ID,
          IdentityDocumentTypeEnum.PASSPORT,
        ].includes(it.value),
      );
    }
    return identityDocumentTypeOptions?.filter((it) => it.value !== IdentityDocumentTypeEnum.BIRTH_CERTIFICATE);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit, handleFormSubmitError)}>
      <div className="pb-120px">
        <div className="mt-20px p-16px bg-white">
          <div>
            <FormLabel required>Người thụ hưởng</FormLabel>
            <Controller
              control={control}
              name={'person'}
              render={({ field: { ref, value, onChange } }) => (
                <Select
                  ref={ref}
                  placeholder="Chọn người thụ hưởng"
                  data={insuredPersonOptions ?? []}
                  value={value}
                  onChange={(val) => {
                    onChange(val);
                    if (val !== 'OTHER') {
                      remove(0);
                    }
                  }}
                  error={errors?.person?.message}
                  searchable
                  maxDropdownHeight={300}
                  nothingFound={<Empty title="Empty" />}
                  filter={(value, item) => (item.label ?? '').toLowerCase().includes(value.toLowerCase().trim())}
                  rightSection={<ChevronDownSVG />}
                />
              )}
            />
          </div>
          <div>
            <FormLabel required>Mối quan hệ với NĐBH</FormLabel>
            <Controller
              name="relationship_with_main_assured"
              control={control}
              render={({ field: { onBlur, onChange, value } }) => (
                <Select
                  placeholder="Chọn mối quan hệ"
                  data={relationshipTypeOptions}
                  rightSection={<ChevronDownSVG />}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors?.relationship_with_main_assured?.message}
                />
              )}
            />
          </div>
          <div>
            <FormLabel required>Tỷ lệ thụ hưởng</FormLabel>
            <Controller
              control={control}
              name="benefit_rate"
              render={({ field: { value, onChange, onBlur } }) => (
                <NumberInput
                  placeholder="Nhập tỷ lệ"
                  rightSection={<span className="text-16px font-semibold">%</span>}
                  error={errors?.benefit_rate?.message}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  min={1}
                  max={100}
                />
              )}
            />
          </div>
        </div>
        {watch('person') === 'OTHER' && (
          <CollapseHeader
            ref={beneficiariesFormRef}
            title="Thêm người thụ hưởng"
            icon={<UserWhiteSVG />}
            className="mt-16px"
            renderCollapseIcon={(visible) =>
              !visible && (watch('beneficiaries') ?? []).length === 0 ? <PlusSVG /> : undefined
            }
            onCollapseToggle={(visible) => {
              if (visible && (watch('beneficiaries') ?? []).length === 0) {
                append(appendAdditionalAssured);
              }
            }}
            defaultVisible={true}>
            <div className="bg-white p-16px">
              {fields?.map((field, index) => (
                <div key={field.id} className="p-16px border-0.5px border-solid border-c_DDDDDD rounded-6px mb-16px">
                  <div className="mb-16px text-18px font-bold leading-22px">Người thụ hưởng {index + 1}</div>
                  <div>
                    <FormLabel required>Họ và tên</FormLabel>
                    <TextInput
                      placeholder="Nhập Họ và tên"
                      {...register(`beneficiaries.${index}.full_name`)}
                      error={errors.beneficiaries?.[index]?.full_name?.message}
                    />
                  </div>
                  <div>
                    <FormLabel required>Mối quan hệ với NĐBH</FormLabel>
                    <Controller
                      name={`beneficiaries.${index}.relationship`}
                      control={control}
                      render={({ field: { onBlur, onChange, value } }) => (
                        <Select
                          placeholder="Chọn mối quan hệ"
                          data={relationshipTypeOptions}
                          rightSection={<ChevronDownSVG />}
                          value={value}
                          onBlur={onBlur}
                          onChange={(val: RelationshipTypeEnum) => {
                            onChange(val);
                            setValue('relationship_with_main_assured', val);
                          }}
                          error={errors.beneficiaries?.[index]?.relationship?.message}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <FormLabel required>Ngày sinh</FormLabel>
                    <Controller
                      control={control}
                      name={`beneficiaries.${index}.dob`}
                      render={({ field: { onBlur, onChange, value } }) => (
                        <DatePicker
                          inputFormat="DD/MM/YYYY"
                          placeholder={vi.user.enter_dob}
                          labelFormat="MM/YYYY"
                          allowFreeInput
                          value={value != null ? dayjs(value).toDate() : undefined}
                          onChange={(date) => {
                            onChange(date?.toISOString());
                            setValue(`beneficiaries.${index}.identification_id`, '');
                            if (date != null) {
                              const age = getAge(date);
                              if (age < 14) {
                                setValue(
                                  `beneficiaries.${index}.identification_type`,
                                  IdentityDocumentTypeEnum.BIRTH_CERTIFICATE,
                                );
                              } else {
                                setValue(
                                  `beneficiaries.${index}.identification_type`,
                                  IdentityDocumentTypeEnum.NATIONAL_ID,
                                );
                              }
                              if (age <= 18) {
                                studentOccupation &&
                                  setValue(`beneficiaries.${index}.occupation_id`, studentOccupation?.id);
                                setValue(`beneficiaries.${index}.married`, false);
                                setValue(
                                  `beneficiaries.${index}.phone_number`,
                                  insuredPerson?.customer?.phone_number ?? '',
                                );
                              } else {
                                if (watch(`beneficiaries.${index}.occupation_id`) === studentOccupation?.id) {
                                  defaultOccupation &&
                                    setValue(`beneficiaries.${index}.occupation_id`, defaultOccupation?.id);
                                }
                              }
                            }
                          }}
                          onBlur={onBlur}
                          clearable={false}
                          rightSection={<CalendarSVG />}
                          error={errors.beneficiaries?.[index]?.dob?.message}
                          excludeDate={(date) => date.getTime() > new Date().getTime()}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <FormLabel required>Giới tính</FormLabel>
                    <Controller
                      control={control}
                      name={`beneficiaries.${index}.gender`}
                      render={({ field: { onChange, value } }) => (
                        <GenderRadioGroup value={value} onChange={onChange} />
                      )}
                    />
                  </div>

                  <div>
                    <FormLabel required>Nghề nghiệp</FormLabel>
                    <Controller
                      control={control}
                      name={`beneficiaries.${index}.occupation_id`}
                      render={({ field: { value, onBlur, onChange } }) => (
                        <Select
                          data={occupations.map((it) => ({
                            value: it.id?.toString(),
                            label: it.name_vn,
                          }))}
                          searchable
                          maxDropdownHeight={300}
                          nothingFound={<Empty title="Empty" />}
                          filter={(value, item) =>
                            (item.label ?? '').toLowerCase().includes(value.toLowerCase().trim())
                          }
                          rightSection={<ChevronDownSVG />}
                          value={value?.toString()}
                          onBlur={onBlur}
                          onChange={onChange}
                          error={errors.beneficiaries?.[index]?.occupation_id?.message}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <FormLabel required>Loại giấy tờ tùy thân</FormLabel>
                    <Controller
                      name={`beneficiaries.${index}.identification_type`}
                      control={control}
                      render={({ field: { onBlur, onChange, value } }) => (
                        <Select
                          data={availableIdentityDocumentTypeOptions(getAge(watch(`beneficiaries.${index}.dob`)))}
                          rightSection={<ChevronDownSVG />}
                          value={value}
                          onBlur={onBlur}
                          onChange={(val) => {
                            onChange(val);
                            trigger(`beneficiaries.${index}.identification_id`);
                          }}
                          error={errors.beneficiaries?.[index]?.identification_type?.message}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <FormLabel required>Số giấy tờ tùy thân</FormLabel>
                    <TextInput
                      placeholder="Nhập số giấy tờ tuỳ thân"
                      {...register(`beneficiaries.${index}.identification_id`)}
                      error={errors.beneficiaries?.[index]?.identification_id?.message}
                    />
                  </div>
                  {/* <div>
                    <FormLabel required>Địa chỉ</FormLabel>
                    <TextInput
                      placeholder="Nhập nơi ở hiện tại"
                      {...register(`beneficiaries.${index}.address`)}
                      error={errors.beneficiaries?.[index]?.address?.message}
                    />
                  </div> */}
                  <div>
                    <PickAdress
                      setValue={setValue}
                      control={control}
                      watch={watch}
                      clearErrors={clearErrors}
                      nameProvince={`beneficiaries[${index}].province_name`}
                      nameWard={`beneficiaries[${index}].ward_name`}
                      nameDistrict={`beneficiaries[${index}].district_name`}
                      errorTextAddress={errors.beneficiaries?.[index]?.address?.message}
                      nameAdress={`beneficiaries[${index}].address`}
                      errorProvince={errors?.beneficiaries?.[index]?.province_name?.message?.toString()}
                      errorDistrict={errors?.beneficiaries?.[index]?.district_name?.message?.toString()}
                      errorWard={errors?.beneficiaries?.[index]?.ward_name?.message?.toString()}
                    />
                  </div>

                  <div>
                    <FormLabel required>Email</FormLabel>
                    <TextInput
                      placeholder="Nhập email"
                      {...register(`beneficiaries.${index}.email`)}
                      error={errors.beneficiaries?.[index]?.email?.message}
                    />
                  </div>
                  <div>
                    <FormLabel required>Số điện thoại</FormLabel>
                    <TextInput
                      placeholder="Nhập số điện thoại"
                      {...register(`beneficiaries.${index}.phone_number`)}
                      maxLength={10}
                      error={errors.beneficiaries?.[index]?.phone_number?.message}
                    />
                  </div>
                  <div>
                    <FormLabel required>Tỷ lệ thụ hưởng</FormLabel>
                    <Controller
                      control={control}
                      name={`beneficiaries.${index}.benefit_rate`}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <NumberInput
                          placeholder="Nhập tỷ lệ"
                          rightSection={<span className="text-16px font-semibold">%</span>}
                          error={errors.beneficiaries?.[index]?.benefit_rate?.message}
                          onBlur={onBlur}
                          onChange={(val) => {
                            onChange(val);
                            setValue('benefit_rate', val);
                          }}
                          value={value}
                          min={1}
                          max={100}
                        />
                      )}
                    />
                  </div>

                  <div className="flex-center">
                    <div className="flex-center">
                      <Button variant="subtle" color="red" onClick={() => handleRemoveReference(() => remove(index))}>
                        <TrashSVG className="mr-10px" /> Xoá
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </CollapseHeader>
        )}
      </div>

      <div className="bg-white p-16px fixed bottom-0 left-0 w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
        <Button type="submit" fullWidth>
          Tiếp tục
        </Button>
      </div>
    </form>
  );
});
