import { setLocale } from 'yup';

import { validationMessages } from './helpers';

setLocale({
  mixed: {
    required: validationMessages.required,
  },
  string: {},
});
