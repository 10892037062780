import { Button, Select, TextInput } from '@mantine/core';
import classNames from 'classnames';
import React, { useCallback, useContext, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { UserComponent } from '..';
import { Question, NumberInput } from '../../../../../components';
import { useDialog } from '../../../../../contexts';
import { vi } from '../../../../../i18n/vi';
import { ChevronDownSVG, PlusRoundWhiteSVG, TrashSVG } from '../../../../../icons';
import { dataStepsQuestion, MixQuestionsContext, QuestionTypeEnum, useIllustration } from '../../../form';
import { CollapseHeader } from '../../collapse-header';

import { FooterQuestion } from './footer';
type Props = {
  currentIndex: number;
  questionName: string;
};
export const StepsQuestion6Screen = React.memo<Props>(({ currentIndex, questionName }) => {
  const dataIndex = dataStepsQuestion[currentIndex];
  const dataQuestions = dataIndex.questions[0];
  const { currentAssured } = useIllustration();
  const { stepLabel, handleChangeStepLabel, handleSubmitRoot, dataDefine: rootData } = useContext(MixQuestionsContext);
  const dataDefine = useMemo(() => rootData?.[currentAssured?.id ?? ''], [rootData, currentAssured]);
  const defaultItem = useMemo(() => ({ age: undefined, full_name: '', relation: '', status: '' }), []);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        members: [defaultItem],
        response: dataDefine?.[questionName]?.response || 'K',
      };
    }, [dataDefine, defaultItem, questionName]),
  });
  const { showDialog } = useDialog();

  const onNext = (stepGo: number) => {
    handleChangeStepLabel(stepGo);
    handleSubmitRoot(currentAssured?.id ?? '', {
      [questionName]: {
        ...watch(),
      },
      body: {
        [questionName]: {
          ...watch(),
        },
      },
      assured: currentAssured,
    });
  };

  const onSubmit = () => {
    onNext(stepLabel + 1);
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'members',
  });

  const handleRemove = useCallback(
    async (resolve: () => void) => {
      const res = await showDialog({
        message: 'Bạn có chắc chắn muốn xoá thông tin này?',
        type: 'CONFIRM',
      });

      res && resolve();
    },
    [showDialog],
  );

  return (
    <>
      <div className="flex-1">
        <CollapseHeader icon={<PlusRoundWhiteSVG />} title={dataIndex.type} className="custom-collapse-header">
          <UserComponent />
          <div className="wrapQuestion">
            <div className="questionViewTitle">
              <div className="questionIndex">
                <p className="questionIndexText">{currentIndex + 1}</p>
              </div>
              <p className="questionTxtTitle">{dataIndex.name}</p>
            </div>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Question
                    onChange={(newVal) => onChange(newVal)}
                    value={value}
                    desc={dataQuestions.name || ''}
                    style="questionItem"
                    isShow={dataQuestions.type === QuestionTypeEnum.INPUT ? false : true}>
                    {watch('response') === 'C' && (
                      <div className={classNames(dataQuestions.style)}>
                        <div className={classNames([dataQuestions.type === QuestionTypeEnum.INPUT && 'px-6px'])}>
                          {fields.map((elm, idx) => (
                            <div className="questionElementItem" key={elm.id}>
                              <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <TextInput
                                      onBlur={() => onChange(value?.trim())}
                                      onChange={(e) => onChange(e?.target?.value)}
                                      value={value}
                                      placeholder={dataQuestions.subQuestions[0].name}
                                      maxLength={dataQuestions.subQuestions[0].maxLength}
                                      error={errors?.members?.[idx]?.age?.message?.toString() || ''}
                                    />
                                  );
                                }}
                                name={`members.${idx}.full_name`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: vi.filled,
                                  },
                                }}
                              />
                              <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <Select
                                      placeholder={dataQuestions.subQuestions[1].name}
                                      data={dataQuestions.subQuestions?.[1]?.selections || []}
                                      value={value}
                                      onChange={onChange}
                                      rightSection={<ChevronDownSVG />}
                                      error={errors?.members?.[idx]?.age?.message?.toString() || ''}
                                    />
                                  );
                                }}
                                name={`members.${idx}.relation`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: vi.filled,
                                  },
                                }}
                              />
                              <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <NumberInput
                                      onChange={(e: any) => onChange(e)}
                                      value={value}
                                      placeholder={dataQuestions.subQuestions[2].name}
                                      min={0}
                                      max={999}
                                      error={errors?.members?.[idx]?.age?.message?.toString() || ''}
                                      rightSection={<div />}
                                    />
                                  );
                                }}
                                name={`members.${idx}.age`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: vi.filled,
                                  },
                                }}
                              />
                              <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <>
                                      <TextInput
                                        onBlur={() => onChange(value?.trim())}
                                        onChange={(e) => onChange(e?.target?.value)}
                                        value={value}
                                        placeholder={dataQuestions.subQuestions[3].name}
                                        maxLength={dataQuestions.subQuestions[3].maxLength}
                                        error={errors?.members?.[idx]?.age?.message?.toString() || ''}
                                      />
                                    </>
                                  );
                                }}
                                name={`members.${idx}.status`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: vi.filled,
                                  },
                                }}
                              />
                              <Button variant="subtle" color="red" onClick={() => handleRemove(() => remove(idx))}>
                                <TrashSVG className="mr-10px" /> Xoá
                              </Button>
                            </div>
                          ))}
                          <Button
                            fullWidth
                            onClick={(e: any) => {
                              e.preventDefault();
                              append(defaultItem);
                            }}>
                            Thêm
                          </Button>
                        </div>
                      </div>
                    )}
                  </Question>
                );
              }}
              name="response"
            />
          </div>
        </CollapseHeader>
      </div>
      <FooterQuestion
        handleChangeStep={(stepGo: number) => handleSubmit(() => onNext(stepGo))()}
        handleSubmit={handleSubmit(onSubmit)}
      />
    </>
  );
});
