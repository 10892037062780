import { memo, useCallback, useMemo } from 'react';
import { Stepper, Text } from '@mantine/core';

import './index.less';

import { CheckSVG } from '../../../../icons';
import { FormStepEnum } from '../../form';

type Props = {
  step: FormStepEnum;
};

export const IllustrationFormStep = memo(({ step }: Props) => {
  const CompletedIcon = useCallback(({ step }: { step: number }) => {
    return step === 3 ? <CheckSVG /> : <Text>{step + 1}</Text>;
  }, []);

  const active = useMemo(() => Object.values(FormStepEnum).findIndex((it) => it === step), [step]);

  return (
    <Stepper
      active={active + 1}
      breakpoint="sm"
      orientation="horizontal"
      className="illustration-form-step"
      completedIcon={CompletedIcon}>
      <Stepper.Step step={1} icon={<span>1</span>} />
      <Stepper.Step step={2} icon={<span>2</span>} />
      <Stepper.Step step={3} icon={<span>3</span>} />
      <Stepper.Step icon={<CheckSVG />} className="step-finish" />
    </Stepper>
  );
});
