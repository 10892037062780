import { createContext, memo, PropsWithChildren, useContext, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

type ContextProps = {
  value?: string;
};

const DatadogContext = createContext<ContextProps>({
  value: undefined,
});

export const DatadogProvider = memo<PropsWithChildren>(({ children }) => {
  useEffect(() => {
    if (!datadogRum) return;
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID ?? '',

      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? '',

      site: process.env.REACT_APP_DATADOG_SITE,

      service: process.env.REACT_APP_DATADOG_SERVICE,

      env: process.env.REACT_APP_DATADOG_ENV,

      // Specify a version number to identify the deployed version of your application in Datadog

      // version: '1.0.0',

      sessionSampleRate: 100,

      sessionReplaySampleRate: 100,

      trackUserInteractions: true,

      trackResources: true,

      trackLongTasks: true,

      defaultPrivacyLevel: 'mask-user-input',
    });
    datadogRum.startSessionReplayRecording();
  }, []);
  return <DatadogContext.Provider value={{ value: '' }}>{children}</DatadogContext.Provider>;
});

export const useDatadog = () => useContext(DatadogContext);
