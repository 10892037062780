import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mantine/core';
import { stringify } from 'qs';
import { memo, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { RadioCircleGroup } from '../../../components';
import { useLeads } from '../../../contexts';
import { AppRoutes } from '../../../helpers';
import { useQueryString } from '../../../hooks';
import { LoanInformationFormData, LoanTypeEnum } from '../components';

import { LeadsFormStepEnum, LeadsScreenParams } from './leads-form-screen';

export const LoanInformaitonForm = memo(() => {
  const navigate = useNavigate();
  const { leadsId = 0, flow, health_checkup } = useQueryString<LeadsScreenParams>();
  const { loanInformationForm, onUpdateLoanInformationForm } = useLeads();

  const validationSchema = yup.object<LoanInformationFormData>({
    loan: yup.mixed<LoanTypeEnum>().oneOf(Object.values(LoanTypeEnum)).required(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoanInformationFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(() => ({ loan: loanInformationForm?.loan || undefined }), [loanInformationForm?.loan]),
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    async (body: LoanInformationFormData) => {
      onUpdateLoanInformationForm(body);
      if (body.loan == LoanTypeEnum.YES) {
        navigate({
          pathname: AppRoutes.leads_new,
          search: stringify({
            step: LeadsFormStepEnum.PACKAGES,
            flow,
            leadsId,
            health_checkup,
          }),
        });
        return;
      }
      navigate({
        pathname: AppRoutes.leads_new,
        search: stringify({
          step: flow,
          leadsId,
          health_checkup,
        }),
      });
    },
    [flow, health_checkup, leadsId, navigate, onUpdateLoanInformationForm],
  );

  const dataOptions = useMemo(
    () => [
      {
        value: LoanTypeEnum.YES,
        label: 'Có',
      },
      {
        value: LoanTypeEnum.NO,
        label: 'Không',
      },
    ],
    [],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="pb-120px bg-white -mx-16px -my-24px px-16px py-24px">
        <div>
          <p className="text-16px font-semibold my-0 mb-5px">Tuân thủ Thông tư 67/BTC</p>
          <p className="text-14px my-0 mb-20px">
            Khách hàng có khoản vay được giải ngân{' '}
            <span className="text-primary font-semibold">
              trong vòng 60 ngày trước và 60 ngày sau thời điểm tư vấn Bảo hiểm
            </span>{' '}
            không ?
          </p>
          <Controller
            control={control}
            name="loan"
            render={({ field: { onChange, value } }) => (
              <RadioCircleGroup
                options={dataOptions}
                value={value || ''}
                onChange={onChange}
                error={errors?.loan?.message}
              />
            )}
          />
        </div>
      </div>

      <div className="bg-white pt-12px pb-34px px-16px fixed bottom-0 left-0 w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
        <Button type="submit" fullWidth>
          Tiếp tục
        </Button>
      </div>
    </form>
  );
});
