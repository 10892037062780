import { FormStepEnum } from '../form';

export enum ReferenceTypeEnum {
  PARENT = 'PARENT',
  CHILD = 'CHILD',
}
export interface ScreenParams {
  step: FormStepEnum;
  processId?: number;
}

export enum AssuredTypeEnum {
  LIFE_ASSURED = 'LIFE_ASSURED',
  ADDITIONAL_LIVES = 'ADDITIONAL_LIVES',
}

export const MAX_SUM_ASSURED = 2000000000;

export const MAX_LENGTH_AMOUNT = 17; //1000,000,000,000
