import { Button } from '@mantine/core';
import React, { useContext } from 'react';

import { MixQuestionsContext } from '../../../form';

import { StepLine } from './step-line';

type Props = {
  handleSubmit: () => void;
  handleChangeStep: (stepGo: number) => void;
  disabled?: boolean;
};
export const FooterQuestion = React.memo<Props>(({ handleSubmit, handleChangeStep, disabled }) => {
  const { stepLabel } = useContext(MixQuestionsContext);
  return (
    <div className="bg-white p-16px footer-question">
      <StepLine step={stepLabel} handleChangeStep={handleChangeStep} />

      <Button fullWidth onClick={handleSubmit} disabled={disabled}>
        Tiếp theo
      </Button>
    </div>
  );
});
