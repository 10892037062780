import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';

import { ApiUrl, request } from '../helpers';
import {
  GetMicPackagesReq,
  MicInsuranceBenefitPackageType,
  MiniPackagesRes,
} from '../screens/illustrations/components/choose-package/utils';
import { User } from '../screens/illustrations/form';
import { ReferrerEntity } from '../screens/illustrations/form/referrer';

import { GenderEnum, IdentityDocumentTypeEnum, MicAdditionalProductDT0, QuotationEntity } from './quotation';
export enum RefererTypeEnum {
  RM = 'RM',
  IC = 'IC',
  SUPPORTER = 'SUPPORTER',
}

export interface RefererType {
  code?: string;
  name?: string;
  phone_number?: string;
  email?: string;
  branch_code?: string;
  branch_name?: string;
  department_code?: string;
  department_name?: string;
}

export interface SearchRMCodeRes {
  hris_employee: {
    user_name: string;
    email?: string;
  };
  mb_code: string;
  t24_employee: {
    branch_code: string;
    branch_level2: string;
    branch_name: string;
    branch_name_level2: string;
    employee_code: string;
    employee_name: string;
    phone?: string;
  };
}
export interface SearchICCodeRes {
  code: string;
  creation_time: string;
  full_name: string;
  id: number;
  standard_contract: string;
  status: number;
}

export type SearchSPCodeRes = Partial<SearchICCodeRes & SearchRMCodeRes>;

export const useSearchRMCodeMutation = (options?: UseMutationOptions<SearchRMCodeRes, Error, string>) => {
  return useMutation(
    (rmCode) =>
      request.get(ApiUrl.searchRMCode, { params: { type: RefererTypeEnum.RM, rmCode } }).then((res) => res.data),
    options,
  );
};

export const useSearchICCodeMutation = (options?: UseMutationOptions<SearchICCodeRes, Error, string>) => {
  return useMutation(
    (icCode) =>
      request.get(ApiUrl.searchICCode, { params: { type: RefererTypeEnum.IC, icCode } }).then((res) => res.data),
    options,
  );
};

export const useSearchSPCodeMutation = (options?: UseMutationOptions<SearchSPCodeRes, Error, string>) => {
  return useMutation((payload) => {
    if (payload.search('MB') > -1) {
      return request
        .get(ApiUrl.searchRMCode, { params: { type: RefererTypeEnum.SUPPORTER, rmCode: payload } })
        .then((res) => res.data);
    } else {
      return request
        .get(ApiUrl.searchICCode, { params: { type: RefererTypeEnum.SUPPORTER, icCode: payload } })
        .then((res) => res?.data);
    }
  }, options);
};

export enum RelationshipTypeEnum {
  POLICY_HOLDER = 'POLICY_HOLDER',
  INSURED_PERSON = 'INSURED_PERSON',
  COUPLE = 'COUPLE',
  CHILDREN = 'CHILDREN',
  PARENT = 'PARENT',
  MOTHER = 'MOTHER',
  FATHER = 'FATHER',
  OTHER = 'OTHER',
}

export enum InsuranceDelcinesStatusTypeEnum {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
}
export enum InsuranceClaimsStatusTypeEnum {
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  PAUSING = 'PAUSING',
}

export interface HealthInfoRequest {
  assured_id: string;
  combo_small_question?: {
    oqs: string;
    c1: string;
    c2: string;
    c3: string;
  };
  combo_big_question?: any;
}

export interface HealthInfoAssured {
  id?: string;
  mini_assured_id?: string;
  type: string;
  full_name: string;
  gender: GenderEnum;
  app_question_number?: number;
  is_display?: boolean;
  is_child?: boolean;
  is_mic?: boolean;
  age: number;
  is_mbal?: boolean;
  from3_to12?: boolean;
}

export interface AnswersQuizzesType {
  id?: string;
  ordinal: number;
  value: string | number;
}
export interface QuizzesType {
  answers: AnswersQuizzesType[];
  id?: string;
  label: string;
}
export interface FamilyMemberType {
  full_name: string;
  relation: string;
  age: string;
  status: string;
}
export interface FamilyMembersType {
  full_name: string;
  relationshipInsurancePerson: string;
  age: number;
  healthDetail: string;
}

export interface OtherInsuranceType {
  company_name: string;
  insurance_fee: string;
  insurance_no: string;
  reason: string;
  status: string;
  date_time: string;
}

export interface OtherDeclinedContractsType {
  company_name: string;
  amount: number;
  commenceDate: string;
  status: string;
  reason: string;
  policyNumber: string;
}
export interface OtherInsuranceClaimsType {
  company_name: string;
  amount: number;
  dateOfIssue: string;
  status: string;
  reason: string;
  policyNumber: string;
}

export interface OtherActiveContractsType {
  company_name: string;
  amount: number;
  policyEffectiveDate: string;
  contractNo: string;
}
export interface AddressEntity {
  district_name?: string;
  line1?: string;
  line2?: string;
  name?: string;
  phone_number?: string;
  province_name?: string;
  title?: string;
  ward_name?: string;
  zipcode?: string;
}
export interface UserEntity {
  dob: string;
  full_name: string;
  gender: GenderEnum;
  id_issued_place?: string;
  identification_date?: string;
  identification_id?: string;
  identification_number?: string;
  identification_type?: IdentityDocumentTypeEnum;
  occupation_id: number;
  phone_number?: string;
  annual_income?: number;
  benefit_rate?: number;
  relationship_with_main_assured?: RelationshipTypeEnum;
  relationship_with_policy_holder?: RelationshipTypeEnum;
  address?: AddressEntity;
  email?: string;
  nationality_code: string;
}

export type ParentInfoMicType = {
  full_name?: string;
  identification_number?: string;
  phone_number?: string;
  mic_request?: MicRequestDTO;
};

export interface CreateProcessInput {
  customer: UserEntity;
  customer_is_assured?: boolean;
  product_package_code?: string;
  product_package_id?: number;
  direct_id?: number;
}
export interface ParentContractMicType {
  so_hd_bm?: string;
  ten_bm?: string;
  cmt_bm?: string;
  ng_sinh_bm?: string;
  mobi_bm?: string;
}
export interface MicRequestDTO {
  bs1: string;
  bs2: string;
  bs3: string;
  bs4: string;
  nhom: string;
  num_of_plus_day: number;
  parent_contract?: ParentContractMicType;
}
export interface MicResultType {
  code: string;
  message: string;
  mic_transaction_id: string;
  phi: number;
  mic_sum_benefit?: number;
  name?: string;
  full_name?: string;
}

export interface AssuredEntityRes {
  assured: User;
  additional_product: MicAdditionalProductDT0;
}

export const useCreateProcessMutation = (
  options?: UseMutationOptions<{ process_id: number }, Error, CreateProcessInput>,
) => {
  return useMutation((input) => request.post(ApiUrl.createProcess, input).then((res) => res.data), options);
};

export const useConfirmQuotationMutation = (options?: UseMutationOptions<QuotationEntity, Error, string | number>) => {
  return useMutation(
    (process_id) =>
      request
        .post(ApiUrl.confirmQuotation, {
          process_id,
        })
        .then((res) => res.data),
    options,
  );
};
export interface QuizzType {
  detail?: string;
  response?: string;
}
export interface HealthsInput {
  assured_id: string;
  combo_big_question?: {
    c1?: {
      change?: string;
      change_value?: string;
      height?: number;
      reason_change?: string;
      weight?: number;
    };
    c10a?: number;
    c10b?: {
      du_sing?: string;
      response?: string;
      version?: string;
    };
    c10c?: QuizzType;
    c10d?: QuizzType;
    c10e?: QuizzType;
    c11a?: {
      insurance_data?: {
        amount?: number;
        commence_date?: string;
        company_name?: string;
        date_of_issue?: string;
        date_time?: string;
        id?: string;
        insurance_fee?: number;
        insurance_no?: string;
        policy_number?: string;
        reason?: string;
        status?: string;
      }[];
      other_active_contracts?: {
        amount?: number;
        commence_date?: string;
        company_name?: string;
        date_of_issue?: string;
        date_time?: string;
        id?: string;
        insurance_fee?: number;
        insurance_no?: string;
        policy_number?: string;
        reason?: string;
        status?: string;
      }[];
      response?: string;
    };
    c11b?: {
      insurance_data?: {
        amount?: number;
        commence_date?: string;
        company_name?: string;
        date_of_issue?: string;
        date_time?: string;
        id?: string;
        insurance_fee?: number;
        insurance_no?: string;
        policy_number?: string;
        reason?: string;
        status?: string;
      }[];
      other_active_contracts?: {
        amount?: number;
        commence_date?: string;
        company_name?: string;
        date_of_issue?: string;
        date_time?: string;
        id?: string;
        insurance_fee?: number;
        insurance_no?: string;
        policy_number?: string;
        reason?: string;
        status?: string;
      }[];
      response?: string;
    };
    c11c?: {
      insurance_data?: {
        amount?: number;
        company_name: string;
        date_time?: string;
        insurance_fee?: number;
        insurance_no?: string;
        policy_effective_date?: string;
        policy_number?: string;
      }[];
      other_active_contracts?: {
        amount?: number;
        company_name?: string;
        date_time?: string;
        insurance_fee?: number;
        insurance_no?: string;
        policy_effective_date?: string;
        policy_number?: string;
      }[];
      response?: string;
    };
    c2a?: QuizzType;
    c2b?: QuizzType;
    c2c?: QuizzType & {
      national?: string;
    };
    c3?: {
      num_in_day?: string;
      num_of_years?: string;
      response?: string;
      type?: string;
    };
    c4?: {
      count?: string;
      frequency?: string;
      response?: string;
      type?: string;
    };
    c5?: QuizzType;
    c6?: {
      members?: {
        age?: number;
        full_name?: string;
        health_detail?: string;
        relation?: string;
        relationship_insurance_person?: string;
        status?: string;
      }[];
      response?: string;
    };
    c7?: {
      c7a?: QuizzType;
      c7b?: QuizzType;
      c7c?: QuizzType;
      c7d?: QuizzType;
      c7e?: QuizzType;
      c7f?: QuizzType;
      c7g?: QuizzType;
      c7h?: QuizzType;
      c7i?: QuizzType;
      c7j?: QuizzType;
      c7k?: QuizzType;
      c7l?: QuizzType;
      c7m?: QuizzType;
      c7n?: QuizzType;
      response?: string;
    };
    c8?: {
      medicine_type?: boolean;
      reason?: string;
      response?: string;
    };
    c9?: {
      c9a?: QuizzType;
      c9b?: QuizzType;
      c9c?: QuizzType;
      response?: string;
    };
    files?: string[];
    oqs?: string;
  };
  combo_small_question?: {
    c1?: string;
    c2?: string;
    c3?: string;
    oqs?: string;
  };
  from3_to12?: boolean;
}
export interface MicHealthInfoRequest {
  mini_assured_id?: string;
  mic_question?: any;
}
export interface QRCodeInput extends ReferrerEntity {
  healths?: HealthsInput[];
  process_id?: number;
  mic_healths?: MicHealthInfoRequest[];
}
export const useGenQRCodeMutation = (
  options?: UseMutationOptions<
    {
      url: 'string';
    },
    Error,
    QRCodeInput
  >,
) => {
  return useMutation((input) => request.post(ApiUrl.genQR, input).then((res) => res.data), options);
};

export const useGetQRCodeMutation = (
  options?: UseMutationOptions<
    {
      url: 'string';
    },
    Error,
    { processId: number }
  >,
) => {
  return useMutation((input) => request.get(ApiUrl.getQR(input.processId)).then((res) => res.data), options);
};

export const queryGetMicPackagesKey = 'GET_MIC_PACKAGES';

export type UseCustomMiniPackagesQueryResponse = { dataCustom: MiniPackagesRes };
export type UseGetMicPackagesQueryResponse = MicInsuranceBenefitPackageType[];
export type UseGetMicPackagesQueryOptions = UseQueryOptions<UseGetMicPackagesQueryResponse>;

export const useGetMicPackagesQuery = (params: GetMicPackagesReq, options?: UseGetMicPackagesQueryOptions) => {
  return useQuery(
    [queryGetMicPackagesKey, params] as QueryKey,
    () =>
      request.post(ApiUrl.getMic, params).then((res) =>
        res?.data?.map((it: MicInsuranceBenefitPackageType) => ({
          ...it,
          regular_fee: it?.phi !== it?.regular_fee && !!it?.regular_fee ? it?.regular_fee : it?.phi,
        })),
      ),
    options,
  );
};

export interface GetMicContractReq {
  contractNum?: string;
  activeDate?: string;
}

export interface GetMicContractRes {
  cmt?: string;
  mobi?: string;
  ng_sinh?: string;
  ten?: string;
  tt_bm?: string;
}

export const useGetMicContractMutation = (
  options?: UseMutationOptions<GetMicContractRes, Error, GetMicContractReq>,
) => {
  return useMutation(
    (payload) => request.get(ApiUrl.micContract, { params: payload }).then((res) => res?.data?.data),
    options,
  );
};

export type OcrProcessOutput = {
  birth_day?: string;
  birth_place?: string;
  blood_type?: string;
  citizen_id?: string;
  districtName?: string;
  district_name?: string;
  ethnicity?: string;
  features?: string;
  full_name?: string;
  gender?: string;
  identification_id?: string;
  identification_date?: string;
  identificationType?: string;
  identification_type?: string;
  issue_date?: string;
  issue_place?: string;
  line1?: string;
  military_rank?: string;
  military_unit?: string;
  mrz?: string;
  nationality?: string;
  origin_location?: string;
  provinceName?: string;
  province_name?: string;
  template_codes?: string[];
  recent_location?: string;
  religion?: string;
  signee?: string;
  valid_date?: string;
  wardName?: string;
  ward_name?: string;
};

export const useOcrProcessMutation = (options?: UseMutationOptions<OcrProcessOutput, Error, FormData>) => {
  return useMutation((input) => request.post(ApiUrl.ocrProcess, input).then((res) => res.data), options);
};
