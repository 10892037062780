export const regexp = {
  email: new RegExp(/^\w{1,25}([\\.-]?\w{1,25})*@\w{1,25}([\\.-]?\w{1,25})*(\.\w{1,25}){1,25}$/),
  phone: /^0\d{9}$/,
  slug: /^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/,
  letter_number_space: /^[A-Za-z0-9 ]*$/,
  nationalId: /^\d{9}$|^\d{12}$/,
  citizenId: /^\d{12}$/,
  birthdayCertificate: /^[a-zA-Z0-9]{3,20}$/,
  passport: /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]{8,}$/, // passport: /^.{6,}$/,
  militaryId: /^[a-zA-Z0-9]{8,12}$/, // militaryId: /^.{6}$|^.{8}$|^.{10}$|^.{12}$/,
  lessThan100: /^(\d|[1-9]\d)$/,
  number: /^\d*$/,
  referrer: /^MB\d{8,10}$/,
  sale: /^\d{10,}$/,
  supporter: /^MB\d{8,10}$|^\d{10,}$/,
  noEmail: /(noemail|no-email|no_email|noEmail|NoEmail|Noemail|NOEMAIL|yopmail.com|info.com)/,
  centery20: /^(19[0-9][0-9]|1999)$/,
  centery21: /^(20[0-9][0-9]|2099)$/,
  specialLetter: /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/,
  vn_phone: /^0(3[2-9]|5[2|5|6|8|9]|7[0|6-9]|8[1-9]|9[0-4|6-9])[0-9]{7}$/,
};
export const FORMAT_NUMBER = new RegExp(/(\d)(?=(\d{3}){1,20}$)/g);
