import { PropsWithChildren, memo } from 'react';

import { Navbar, NavbarProps } from '../navbar';

type Props = PropsWithChildren<NavbarProps>;

export const Layout = memo(({ title, children, onLeft, onRight }: Props) => {
  return (
    <div className="w-screen min-h-screen bg-c_F1F6F8">
      <Navbar title={title} onLeft={onLeft} onRight={onRight} />
      <div className="w-full h-full relative pt-48px">{children}</div>
    </div>
  );
});
