import { forEach } from 'lodash';
import React, { Fragment, useCallback, useContext, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import { Question } from '../../../../../../components';
import { PlusRoundWhiteSVG } from '../../../../../../icons';
import {
  convertQuestionName,
  dataStepsQuestion,
  isCheckFile,
  MixQuestionsContext,
  QuestionTypeEnum,
  useIllustration,
} from '../../../../form';
import { CollapseHeader } from '../../../collapse-header';
import { UserComponent } from '../../user';
import { FooterQuestion } from '../footer';
import { DATE_FORMAT } from '../../../../../../constants';

import { Fields } from './fields';

type Props = {
  currentIndex: number;
  questionName: string;
};
export const StepsQuestion11Screen = React.memo<Props>(({ currentIndex, questionName }) => {
  const dataIndex = dataStepsQuestion[currentIndex];
  const dataQuestions = dataIndex.questions;
  const { healths, currentAssured, onUpdateCurrentAssured } = useIllustration();
  const { stepLabel, handleChangeStepLabel, handleSubmitRoot, dataDefine: rootData } = useContext(MixQuestionsContext);

  const dataDefine = useMemo(() => rootData?.[currentAssured?.id ?? ''], [rootData, currentAssured]);
  const defaultItem = useMemo(
    () => ({
      company_name: '',
      insurance_fee: '',
      insurance_no: '',
      reason: '',
      status: '',
      date_time: undefined,
    }),
    [],
  );
  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        questions: dataQuestions?.map((item, idx) => {
          return {
            response: dataDefine?.[questionName]?.questions[idx]?.response || item?.default || 'K',
            insurance_data: [defaultItem],
          };
        }),
      };
    }, [dataDefine, dataQuestions, defaultItem, questionName]),
  });

  const handleNextPerson = useCallback(
    (stepGo: number) => {
      if (healths) {
        const lastIndex = healths?.length - 1;
        const findIndex = healths?.findIndex((it) => it.id === currentAssured?.id);
        if (findIndex === lastIndex) {
          handleChangeStepLabel(-1);
        } else if (findIndex < lastIndex && currentAssured?.app_question_number === 12) {
          handleChangeStepLabel(0);
          onUpdateCurrentAssured(healths[findIndex + 1]);
          return false;
        } else {
          handleChangeStepLabel(stepGo);
        }
      }
    },
    [healths, currentAssured?.app_question_number, currentAssured?.id, handleChangeStepLabel, onUpdateCurrentAssured],
  );

  const onNext = (stepGo: number) => {
    const questions = watch('questions');
    const params = {};
    forEach(questions, (item, idx) => {
      Object.assign(params, {
        [convertQuestionName(questionName, idx)]: {
          response: item?.response,
          insurance_data: item?.insurance_data?.map((insurance) => {
            return {
              ...insurance,
              date_time: insurance?.date_time ? dayjs(insurance?.date_time)?.format(DATE_FORMAT.server) : undefined,
            };
          }),
        },
      });
    });
    const root = {
      [questionName]: {
        ...watch(),
      },
      body: {
        ...params,
      },
      assured: currentAssured,
    };

    handleSubmitRoot(currentAssured?.id ?? '', root);
    const dataCheck = {
      ...rootData,
      [currentAssured?.id ?? '']: {
        ...rootData[currentAssured?.id ?? ''],
        ...root,
        body: { ...rootData[currentAssured?.id ?? '']?.body, ...root?.body },
      },
    };
    if (isCheckFile(dataCheck) || currentAssured?.app_question_number === 15) {
      handleChangeStepLabel(stepGo);
    } else {
      handleNextPerson(stepGo);
    }
  };

  const onSubmit = () => {
    onNext(stepLabel + 1);
  };

  const { fields } = useFieldArray({
    control,
    name: 'questions',
  });

  return (
    <>
      <div className="flex-1">
        <CollapseHeader icon={<PlusRoundWhiteSVG />} title={dataIndex.type} className="custom-collapse-header">
          <UserComponent />
          <div className="wrapQuestion">
            <div className="questionViewTitle">
              <div className="questionIndex">
                <p className="questionIndexText">
                  {!!currentAssured?.age &&
                  ((currentAssured?.gender === 'FEMALE' && currentAssured?.age > 12) || currentAssured?.age <= 12)
                    ? 11
                    : 10}
                </p>
              </div>
              <p className="questionTxtTitle">
                Câu hỏi{' '}
                {!!currentAssured?.age &&
                ((currentAssured?.gender === 'FEMALE' && currentAssured?.age > 12) || currentAssured?.age <= 12)
                  ? 11
                  : 10}
              </p>
            </div>
            {fields?.map((item, index) => (
              <Fragment key={item?.id}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Question
                        onChange={(newVal: any) => onChange(newVal)}
                        value={value}
                        desc={dataQuestions[index].name || ''}
                        style="questionItem"
                        isShow={dataQuestions[index].type === QuestionTypeEnum.INPUT ? false : true}>
                        {watch('questions')?.[index].response === 'C' && (
                          <Fields
                            {...{ control, currentIndex, questionName, questionIndex: index }}
                            trigger={trigger}
                            errors={errors}
                          />
                        )}
                      </Question>
                    );
                  }}
                  name={`questions.${index}.response`}
                />
              </Fragment>
            ))}
          </div>
        </CollapseHeader>
      </div>
      <FooterQuestion
        handleChangeStep={(stepGo: number) => handleSubmit(() => onNext(stepGo))()}
        handleSubmit={handleSubmit(onSubmit)}
      />
    </>
  );
});
