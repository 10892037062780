export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const TOKEN_EXPIRED_TIME = 'TOKEN_EXPIRED_TIME';

export const DATE_FORMAT = {
  date: 'DD-MM-YYYY',
  datetime: 'DD-MM-YYYY HH:mm',
  dob: 'DD/MM/YYYY',
  server: 'YYYY-MM-DD',
  fulltime: 'DD/MM/YYYY HH:mm:ss',
  my: 'MM/YYYY',
};

export const MEDIA = {
  MAX_IMAGE_SIZE: 1024 * 1024 * 5,
  MAX_COMPRESSED_IMAGE_SIZE: 1024 * 1024 * 2,
  MAX_VIDEO_SIZE: 1024 * 1024 * 5,
  MAX_FILE_SIZE: 1024 * 1024 * 5,
  UPLOAD_ALLOW_TYPE: ['jpeg', 'jpg', 'png', 'pdf', 'live'],
  UPLOAD_ALLOW_IMAGE_TYPE: [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'image/svg',
    'image/webp',
    'image/svg+xml',
  ],
  UPLOAD_ALLOW_IMAGE_TYPE_BASIC: ['image/jpeg', 'image/jpg', 'image/png'],
  UPLOAD_ALLOW_VIDEO_TYPE: ['video/mp4'],
  UPLOAD_ALLOW_FILE_TYPE: ['pdf', 'doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx'],
  UPLOAD_ALLOW_IMPORT_FILE_TYPE: ['xlsx', 'xls', 'csv'],
  UPLOAD_ACCEPT_IMPORT_FILE_TYPE: '.xlsx, .xls, .csv',
};

//TÊN SẢN PHẨM
export const PRODUCT_NAME = {
  /**
   * Vững bước tương lai - Bảo hiểm Liên kết chung Vững Tương Lai - ULRP
   */
  ULRP: 'Bảo hiểm Liên kết chung Vững Tương Lai',
  /**
   * Kiến Tạo Tương Lai - Bảo hiểm Liên kết chung Kiến Tạo Ước Mơ - ULK
   */
  ULK: 'Bảo hiểm Liên kết chung Kiến Tạo Ước Mơ',
  /**
   * Sống Trọn Ước Mơ - Bảo hiểm Liên kết chung Sống Trọn Ước Mơ
   */
  ULRP_3_0: 'Bảo hiểm Liên kết chung Sống Trọn Ước Mơ',
  /**
   * Món Quà Phú Quý - Bảo hiểm Liên kết chung Món Quà Phú Quý - ULSP
   */
  ULSP: 'Bảo hiểm Liên kết chung Món Quà Phú Quý',
};

export const imageCompressionOptions = {
  maxSizeMB: 2,
  useWebWorker: true,
};
