import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Select, Textarea, TextInput } from '@mantine/core';
import dayjs from 'dayjs';
import { stringify } from 'qs';
import { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { DatePicker, Empty, FormLabel } from '../../../components';
import { DATE_FORMAT } from '../../../constants';
import { useDialog, useInitialData, useLeads } from '../../../contexts';
import { AppRoutes, getAge, getMessage, validationMessages } from '../../../helpers';
import { vi } from '../../../i18n/vi';
import { CalendarSVG, ChevronDownSVG, ThreeDotsCornerSVG } from '../../../icons';
import {
  AdditionalAssuredProductEnum,
  AdditionalMicProductEnum,
  GenderEnum,
  IdentityDocumentTypeEnum,
  InsuranceTypeEnum,
  LeadsCustomerFormData,
  LeadsFlowEnum,
  useCheckReferOrDirect,
  useCreateLeadsCustomer,
  useUpdateLeadsCustomer,
} from '../../../services';
import {
  dobValidation,
  GenderRadioGroup,
  genderValidation,
  identityDocumentNumberValidation,
  identityDocumentTypeValidation,
  nameValidation,
  phoneValidation,
} from '../../illustrations/components';
import { MoneyInputSuggest } from '../../illustrations/components/money-suggestion';
import { identityDocumentTypeOptions } from '../../illustrations/form';
import { LeadsProductOption } from '../components';

import { LeadsFormStepEnum } from './leads-form-screen';

export const additionalProductsOptions: LeadsProductOption[] = [
  {
    value: AdditionalAssuredProductEnum.COI_RIDER,
    label: 'Bảo hiểm tử vong & thương tật toàn bộ vĩnh viễn do tai nạn',
  },
  {
    value: AdditionalAssuredProductEnum.HSCR,
    label: 'Bảo hiểm hỗ trợ viện phí & chi phí phẫu thuật',
  },
  {
    value: AdditionalAssuredProductEnum.CIR,
    label: 'Bảo hiểm bệnh hiểm nghèo',
  },
  {
    value: AdditionalAssuredProductEnum.ADDR,
    label: 'Bảo hiểm tai nạn',
  },
];

export const micProductOptions: LeadsProductOption[] = [
  {
    value: AdditionalMicProductEnum.MIC01,
    label: 'Gói Đồng',
  },
  {
    value: AdditionalMicProductEnum.MIC02,
    label: 'Gói Bạc',
  },
  {
    value: AdditionalMicProductEnum.MIC03,
    label: 'Gói Vàng',
  },
  {
    value: AdditionalMicProductEnum.MIC04,
    label: 'Gói Bạch Kim',
  },
  {
    value: AdditionalMicProductEnum.MIC05,
    label: 'Gói Kim Cương',
  },
];

type Props = {
  edit?: boolean;
  setIsCustomerFormDirty?: Dispatch<SetStateAction<boolean>>;
};

export const LeadsCustomerForm = memo(({ setIsCustomerFormDirty, edit = false }: Props) => {
  const navigate = useNavigate();
  const { showDialog } = useDialog();

  const { leadsCustomer, onUpdateLeadsCustomer } = useLeads();

  const validationSchema = yup.object<LeadsCustomerFormData>({
    full_name: nameValidation,
    dob: dobValidation.test('min-18', validationMessages.ageInvalid18, (val) => getAge(val) >= 18),
    gender: genderValidation,
    phone_number: phoneValidation,
    occupation_id: yup.number().required(),
    identification_type: identityDocumentTypeValidation,
    identification_id: identityDocumentNumberValidation,
    input_amount: yup
      .number()
      .typeError(validationMessages.required)
      .required(validationMessages.required)
      .test(
        'multiples-of-1000000',
        validationMessages.multiplesOfOneMillion,
        (val) => val == null || val % 1000000 === 0,
      )
      .test('min-value', validationMessages.minValueTenMillions, (val) => val >= 10000000),
  });

  const { occupations } = useInitialData();

  const defaultOccupation = occupations.find((it) => it.name_vn === 'Nhân viên văn phòng');

  const {
    watch,
    trigger,
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors, isDirty },
  } = useForm<LeadsCustomerFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(
      () =>
        leadsCustomer ?? {
          gender: GenderEnum.MALE,
          identification_type: IdentityDocumentTypeEnum.NATIONAL_ID,
          occupation_id: defaultOccupation?.id,
          cared_product: {
            mbal: [InsuranceTypeEnum.ULRP_3_0],
          },
        },
      [defaultOccupation?.id, leadsCustomer],
    ),
    mode: 'onChange',
  });

  useEffect(() => {
    if (leadsCustomer) {
      reset(leadsCustomer, { keepDefaultValues: true });
    }
  }, [leadsCustomer, reset]);

  useEffect(() => {
    if (setIsCustomerFormDirty) {
      setIsCustomerFormDirty(isDirty);
    }
  }, [isDirty, setIsCustomerFormDirty]);

  const { mutateAsync: createLeadsCustomerAsync, isLoading: isLoadingCreate } = useCreateLeadsCustomer({
    onError: (error) => {
      showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
    },
    onSuccess: (result) => {
      if (result?.id) {
        checkReferOrDirect({ id: result?.id?.toString(), req: { amount: getValues('input_amount') ?? 0 } });
      }
    },
  });

  const { mutateAsync: checkReferOrDirect, isLoading: isLoadingCheck } = useCheckReferOrDirect({
    onError: (error) => {
      showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
    },
    onSuccess: (result, input) => {
      if (input?.id) {
        navigate({
          pathname: AppRoutes.leads_new,
          search: stringify({
            step: LeadsFormStepEnum.LOAN_INFO,
            flow:
              result?.flow == LeadsFlowEnum.ALL ? LeadsFormStepEnum.METHOD : result?.flow || LeadsFormStepEnum.DIRECT,
            leadsId: input?.id,
            health_checkup: result?.health_checkup,
          }),
        });
      }
    },
  });

  const { mutateAsync: updateLeadsCustomer, isLoading: isLoadingUpdate } = useUpdateLeadsCustomer({
    onError: (error) => {
      showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
    },
    onSuccess: () => {
      if (leadsCustomer) {
        navigate(-1);
      }
    },
  });

  const onSubmit = useCallback(
    async (body: LeadsCustomerFormData) => {
      const data: LeadsCustomerFormData = {
        ...body,
        full_name: body?.full_name?.toLocaleUpperCase(),
      };
      if (edit) {
        const res = await showDialog({
          type: 'CONFIRM',
          message: vi.confirmChanges,
        });
        res &&
          updateLeadsCustomer({
            ...data,
            dob: dayjs(data?.dob ?? dayjs()).format(DATE_FORMAT.server),
            id: leadsCustomer?.id,
          });
        !res && navigate(-1);
      } else {
        onUpdateLeadsCustomer(data);
        createLeadsCustomerAsync({
          ...data,
          dob: dayjs(data?.dob ?? dayjs()).format(DATE_FORMAT.server),
        });
      }
    },
    [
      createLeadsCustomerAsync,
      edit,
      leadsCustomer?.id,
      navigate,
      onUpdateLeadsCustomer,
      showDialog,
      updateLeadsCustomer,
    ],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="pb-120px bg-white -mx-16px -my-24px px-16px py-24px">
        <div className="mb-5">
          <span className="font-bold text-base uppercase">Thông tin khách hàng mới</span>
        </div>
        <div>
          <FormLabel required>Họ và tên</FormLabel>
          <Controller
            control={control}
            name="full_name"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                maxLength={40}
                onBlur={onBlur}
                onChange={(e) => onChange(e?.target?.value)}
                value={value}
                placeholder="Nhập họ tên khách hàng"
                error={errors.full_name?.message}
                styles={{ input: { textTransform: 'uppercase' } }}
                className="placeholder"
              />
            )}
          />
        </div>
        <div className="text-sm m-0">
          <p className="text-blue-500">* Lưu ý: Nhập thông tin họ tên có dấu</p>
        </div>
        <div>
          <FormLabel required>Ngày sinh</FormLabel>
          <Controller
            control={control}
            name="dob"
            render={({ field: { onBlur, onChange, value } }) => (
              <DatePicker
                inputFormat="DD/MM/YYYY"
                placeholder="DD/MM/YYYY"
                labelFormat="MM/YYYY"
                allowFreeInput
                value={value != null ? dayjs(value).toDate() : undefined}
                onChange={(val) => {
                  onChange(val);
                  !!watch('identification_id') && trigger('identification_id');
                }}
                onBlur={onBlur}
                clearable={false}
                rightSection={<CalendarSVG />}
                excludeDate={(date) => date.getTime() > new Date().getTime()}
                error={errors.dob?.message}
              />
            )}
          />
        </div>

        <div>
          <FormLabel required>Giới tính</FormLabel>
          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange, value } }) => (
              <GenderRadioGroup
                value={value}
                onChange={(val) => {
                  onChange(val);
                  !!watch('identification_id') && trigger('identification_id');
                }}
              />
            )}
          />
        </div>

        <div>
          <FormLabel required>Loại giấy tờ tùy thân</FormLabel>
          <Controller
            control={control}
            name="identification_type"
            render={({ field: { onBlur, onChange, value } }) => (
              <Select
                data={identityDocumentTypeOptions?.filter(
                  (it) => it.value !== IdentityDocumentTypeEnum.BIRTH_CERTIFICATE,
                )}
                maxDropdownHeight={300}
                rightSection={<ChevronDownSVG />}
                value={value}
                onBlur={onBlur}
                onChange={(val) => {
                  onChange(val);
                  trigger('identification_id');
                }}
                error={errors?.identification_type?.message}
              />
            )}
          />
        </div>

        <div>
          <FormLabel required>Số giấy tờ tùy thân</FormLabel>
          <TextInput
            placeholder="Nhập số giấy tờ tuỳ thân"
            {...register('identification_id')}
            error={errors?.identification_id?.message}
          />
        </div>

        <div>
          <FormLabel required>Số điện thoại</FormLabel>
          <TextInput
            placeholder="Nhập số điện thoại"
            {...register('phone_number')}
            maxLength={10}
            error={errors.phone_number?.message}
          />
        </div>

        <div>
          <FormLabel required>Nghề nghiệp</FormLabel>
          <Controller
            control={control}
            name="occupation_id"
            render={({ field: { value, onBlur, onChange } }) => (
              <Select
                data={occupations.map((it) => ({
                  value: it.id?.toString(),
                  label: it.name_vn,
                }))}
                searchable
                maxDropdownHeight={300}
                nothingFound={<Empty title="Không có thông tin nghề nghiệp" />}
                filter={(value, item) => (item.label ?? '').toLowerCase().includes(value.toLowerCase().trim())}
                rightSection={<ChevronDownSVG />}
                value={value?.toString()}
                onBlur={onBlur}
                onChange={onChange}
                error={errors?.occupation_id?.message}
              />
            )}
          />
        </div>

        <div>
          <FormLabel required>Số tiền có thể tham gia bảo hiểm/năm</FormLabel>
          <Controller
            control={control}
            name="input_amount"
            render={({ field: { value, onChange, onBlur } }) => (
              <MoneyInputSuggest
                placeholder="Nhập số tiền có thể chi trả"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={errors.input_amount?.message}
              />
            )}
          />
        </div>
        <div>
          <FormLabel>Ghi chú</FormLabel>
          <Textarea
            placeholder="Nhập nội dung ghi chú"
            maxLength={500}
            rightSection={<ThreeDotsCornerSVG />}
            {...register('note')}
          />
        </div>
      </div>

      <div className="bg-white pt-12px pb-34px px-16px fixed bottom-0 left-0 w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
        <Button
          type="submit"
          fullWidth
          loading={isLoadingCreate || isLoadingCheck || isLoadingUpdate}
          disabled={isLoadingCreate || isLoadingCheck || isLoadingUpdate}>
          {edit ? 'Lưu' : 'Lưu và tiếp tục'}
        </Button>
      </div>
    </form>
  );
});
