import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronLeftSVG, CloseSVG, PlusSVG } from '../../icons';

import { NavbarProps } from './navbar';

export type LeadsNavbarProps = NavbarProps & {
  rightButton?: string;
  className?: string;
  titleStyle?: string;
  fillColor?: string;
};

export const LeadsNavbar = memo(
  ({
    title,
    onLeft,
    onRight,
    rightButton,
    className = 'bg-primary text-white',
    fillColor = 'white',
    titleStyle,
  }: LeadsNavbarProps) => {
    const navigate = useNavigate();

    return (
      <div className={`${className} flex items-end justify-between px-15px py-15px fixed top-0 w-full z-50`}>
        <div
          className="w-24px h-24px"
          onClick={() => {
            if (onLeft) {
              onLeft();
            } else {
              navigate(-1);
            }
          }}>
          <ChevronLeftSVG fill={fillColor} />
        </div>
        <span className={`font-bold text-18px mx-auto leading-18px ${titleStyle || ''}`}>{title}</span>
        {rightButton && (
          <div
            className="w-24px h-24px"
            onClick={() => {
              onRight?.();
            }}>
            {rightButton === 'close' ? <CloseSVG fill={fillColor} /> : <PlusSVG fill={fillColor} />}
          </div>
        )}
      </div>
    );
  },
);
