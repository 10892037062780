import dayjs from 'dayjs';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';

import { DATE_FORMAT } from '../../../constants';
import { AppRoutes } from '../../../helpers';
import { useQueryString } from '../../../hooks';
import { ChevronLeftSVG, HomeSVG } from '../../../icons';

export const QrCodeScreen = () => {
  const { url = '', date = '' } = useQueryString<{ url?: string; date?: string }>();
  const navigate = useNavigate();

  return (
    <div className="min-h-screen w-screen bg-[#2B33D8] overflow-y-auto">
      <div className="flex justify-between mx-4 pt-8 align-middle pb-16">
        <div className="w-24px h-24px" onClick={() => navigate(-1)}>
          <ChevronLeftSVG fill="white" />
        </div>
        <div>
          <span className="text-white text-[14px] font-bold">Quét mã QR hoàn tất phát hành HĐBH</span>
        </div>
        <div onClick={() => navigate(AppRoutes.home)}>
          <HomeSVG />
        </div>
      </div>

      <div className="mx-auto sm:max-w-[500px] lg:max-w-[none] pb-8 text-[14px] text-white font-semibold text-left">
        <p className="text-center">Hướng dẫn quét QR code:</p>
        <ul className="list-none flex flex-col items-start mx-auto max-w-[354px] pl-5">
          <li className="mb-2 font-normal">B1: Vào App MB -&gt; Chợ ứng dụng -&gt; Cổng bảo hiểm</li>
          <li className="mb-2  font-normal">
            B2: Chọn <span className="font-bold">Quét mã QR</span> ở Cổng bảo hiểm
          </li>
          <li className="mb-2  font-normal">B3: KH xem lại thông tin & thanh toán</li>
        </ul>
      </div>

      <div className="h-auto my-0 mx-auto max-w-[225px] w-full p-[16px] bg-white rounded-[16px]">
        <QRCode className="h-auto w-full" value={url ?? ''} />
      </div>
      {!!date && (
        <div className="mx-[32px] w-auto sm:max-w-[500px] sm:mx-auto pt-12 text-[14px] text-white font-semibold text-center sm:text-center">
          <p>Lưu ý</p>
          <p>
            QR code có hiệu lực đến hết ngày: {dayjs(date, DATE_FORMAT.server).add(2, 'day').format(DATE_FORMAT.dob)}
          </p>
        </div>
      )}
    </div>
  );
};
