import { ReactNode } from 'react';

import { PRODUCT_NAME } from '../../../constants';
import { AdditionalAssuredProductEnum, InsuranceTypeEnum } from '../../../services';

const APP_URL = process.env.PUBLIC_URL;

const commonBenefits = [
  {
    title: 'Quyền lợi đáo hạn',
    content: <div>Trả Giá trị tài khoản trừ đi các Khoản nợ (nếu có) vào Ngày đáo hạn Hợp đồng.</div>,
  },
  {
    title: 'Các quyền lợi khác',
    content: (
      <ul className="p-start-20px text-14px text-c_767676">
        <li>Thay đổi lựa chọn Quyền lợi Cơ bản/Nâng cao.</li>
        <li>Rút tiền từ Giá trị tài khoản.</li>
        <li>Thay đổi Số tiền bảo hiểm.</li>
        <li>Thay đổi Định kỳ đóng phí.</li>
      </ul>
    ),
  },
];

export const insuranceTypes = [
  {
    type: InsuranceTypeEnum.ULRP_3_0,
    label: PRODUCT_NAME.ULRP_3_0,
    image: APP_URL + '/bancas/images/ulrp30-banner.png',
    image_s: APP_URL + '/bancas/images/ulrp30.png',
    benefits: [
      {
        title: 'Quyền lợi bảo hiểm Tử vong/TTTBVV (*)',
      },
      commonBenefits[0],
      {
        title: 'Thưởng duy trì hợp đồng',
        content: (
          <div>
            <div>
              Nếu Hợp đồng thỏa các điều kiện theo Quy tắc, Điều khoản của Sản phẩm chính thì Khách hàng sẽ được nhận
              (các) khoản thưởng, cộng trực tiếp vào Giá trị tài khoản đóng thêm:
            </div>
            <ul className="p-start-20px text-14px text-c_767676">
              <li>
                Quyền lợi Thưởng duy trì Hợp đồng định kỳ: Chi trả vào các Ngày kỷ niệm năm Hợp đồng thứ 10 và mỗi 5 năm
                sau đó nhưng không vượt quá Ngày đáo hạn Hợp đồng, trả 10% Phí bảo hiểm cơ bản.
              </li>
              <li>
                Quyền lợi Thưởng duy trì Hợp đồng đặc biệt: Chi trả tại Ngày đáo hạn Hợp đồng hoặc ngày xảy ra Sự kiện
                bảo hiểm:
                <div>
                  - Chi trả 10%-40% phí bảo hiểm cơ bản mỗi 5 năm kể từ năm hợp đồng thứ 10 nếu phí bảo hiểm được đóng
                  đầy đủ trong 5 năm hợp đồng đầu tiên, hợp đồng chưa từng bị mất hiệu lực và chưa có bất kỳ giao dịch
                  rút tiền nào từ Giá trị tài khoản cơ bản.
                </div>
                <div>
                  - Đối với Ngày đến hạn trả Quyền lợi là Ngày xảy ra Sự kiện bảo hiểm: tùy theo thời điểm xảy ra Sự
                  kiện bảo hiểm
                </div>
              </li>
            </ul>
          </div>
        ),
      },
      commonBenefits[1],
    ],
  },
  {
    type: InsuranceTypeEnum.ULRP,
    label: 'Vững tương lai',
    image: APP_URL + '/bancas/images/ulrp20-banner.png',
    image_s: APP_URL + '/bancas/images/ulrp20.png',
    benefits: [
      {
        title: 'Quyền lợi bảo hiểm Tử vong/TTTBVV (*)',
      },
      commonBenefits[0],
      {
        title: 'Thưởng duy trì hợp đồng',
        content: (
          <div>
            Thực hiện mỗi 5 năm kể từ Ngày kỷ niệm năm Hợp đồng thứ 5 và khi Hợp đồng đáo hạn hoặc khi xảy ra Sự kiện
            bảo hiểm, trả 20% tổng Khoản lãi tích lũy của Giá trị tài khoản cơ bản trong Thời hạn xét thưởng (lãi suất
            tích lũy Giá trị tài khoản cơ bản sử dụng để tính thưởng tối đa là 7%), được cộng trực tiếp vào Giá trị tài
            khoản cơ bản, với điều kiện hợp đồng đã đóng đầy đủ phí định kỳ đến thời điểm xét thưởng.
          </div>
        ),
      },
      commonBenefits[1],
    ],
  },
  {
    type: InsuranceTypeEnum.ULK,
    label: 'Kiến Tạo Tương Lai',
    image: APP_URL + '/bancas/images/ukl-banner.png',
    disabled: true,
    image_s: APP_URL + '/bancas/images/ukl.png',
    benefits: [
      {
        title: 'Quyền lợi bảo hiểm Tử vong/TTTBVV (*)',
      },
      commonBenefits[0],
      {
        title: 'Thưởng duy trì hợp đồng',
      },
      commonBenefits[1],
    ],
  },
  {
    type: InsuranceTypeEnum.ULSP,
    label: 'Món quà phú quý',
    image: APP_URL + '/bancas/images/ulsp-banner.png',
    disabled: true,
    image_s: APP_URL + '/bancas/images/ulsp.png',
    benefits: [
      {
        title: 'Quyền lợi bảo hiểm Tử vong/TTTBVV (*)',
      },
      commonBenefits[0],
      {
        title: 'Thưởng duy trì hợp đồng',
      },
      commonBenefits[1],
    ],
  },
];

export const additionalAssuredTypes = [
  {
    type: AdditionalAssuredProductEnum.ADDR,
    benefits: [
      {
        title: 'Quyền lợi Bảo hiểm Tử vong và Thương tật do tai nạn',
        content: (
          <ul className="p-start-20px text-14px text-c_767676">
            <li>
              100% Số tiền bảo hiểm sẽ được chi trả nếu Người được bảo hiểm Tử vong hoặc gặp Thương tật gây ra bởi tai
              nạn khi Hợp đồng bổ trợ còn hiệu lực.
            </li>
            <li>Phần trăm Số tiền bảo hiểm chi trả sẽ dựa trên mức độ thương tật.</li>
            <li>
              Quý khách hàng còn được hưởng Quyền lợi hỗ trợ chi phí nằm viện cho mỗi ngày nằm viện trị giá 0,2% Số tiền
              bảo hiểm nhưng không quá 2.000.000 đồng/ngày.
            </li>
            <li>
              Quyền lợi bảo hiểm được nhân đôi (trừ Quyền lợi hỗ trợ chi phí nằm viện) trong trường hợp tai nạn xảy ra ở
              nước ngoài và trên các chuyến bay thương mại.
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    type: AdditionalAssuredProductEnum.COI_RIDER,
    benefits: [
      {
        title: 'Quyền lợi bảo hiểm Tử vong/TTTBVV do Tai nạn (*)',
        content: (
          <ul className="p-start-20px text-14px text-c_767676">
            <li>
              Trong trường hợp Người được bảo hiểm tử vong/TTTBVV do tai nạn trước 66 tuổi, chi trả:
              <div>
                - Giá trị nhỏ hơn giữa 100% Số tiền bảo hiểm và 5 tỉ đồng nếu tai nạn xảy ra khi không tham gia giao
                thông trên các phương tiện giao thông công cộng; hoặc
              </div>
              <div>
                - Giá trị nhỏ hơn giữa 200% Số tiền bảo hiểm và 10 tỉ đồng nếu tai nạn xảy ra khi tham gia giao thông
                trên các phương tiện giao thông công cộng.
              </div>
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    type: AdditionalAssuredProductEnum.CIR,
    benefits: [
      {
        title: 'Quyền lợi bảo hiểm bệnh hiểm nghèo',
        content: (
          <ul className="p-start-20px text-14px text-c_767676">
            <li>48 Bệnh hiểm nghèo (bao gồm ung thư, đột quỵ, đau tim…) được chia vào 4 nhóm</li>
            <li>
              Trả tối đa 100% Số tiền bảo hiểm đối với mỗi bệnh hiểm nghèo giai đoạn sau, trả tối đa 1 lần cho 1 nhóm
              bệnh, lên đến 2 lần đối với 2 bệnh thuộc 2 nhóm khác nhau.
            </li>
            <li>
              Trả 20% Số tiền bảo hiểm đối với mỗi bệnh hiểm nghèo giai đoạn đầu, trả tối đa 1 lần cho 1 nhóm bệnh, lên
              đến 4 lần đối với 4 bệnh thuộc 4 nhóm khác nhau.
            </li>
            <li>
              Trả thêm 20% Số tiền bảo hiểm nếu Người được bảo hiểm mắc một số bệnh Ung thư theo giới tính. Tổng Quyền
              lợi bảo hiểm chi trả tối đa lên tới 220% Số tiền bảo hiểm.
            </li>
          </ul>
        ),
      },
      {
        title: 'Quyền lợi miễn đóng phí',
        content: (
          <ul className="p-start-20px text-14px text-c_767676">
            <li>
              Toàn bộ phí bảo hiểm còn phải đóng của sản phẩm bổ trợ Bệnh hiểm nghèo sẽ được miễn ngay sau khi được chẩn
              đoán mắc bệnh hiểm nghèo giai đoạn sau.
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    type: AdditionalAssuredProductEnum.HSCR,
    benefits: [
      {
        title: 'Quyền lợi Hỗ trợ Viện phí nội trú',
        content: (
          <ul className="p-start-20px text-14px text-c_767676">
            <li>Chi trả 0,2% Số tiền bảo hiểm cho một ngày nằm viện nội trú.</li>
            <li>Chi trả tối đa 150 ngày cho mỗi Thời hạn bảo hiểm.</li>
            <li>Chi trả tối đa 1000 ngày cho toàn bộ Thời hạn tham gia Sản phẩm bổ trợ.</li>
            <li>Chi trả 0,4% Số tiền bảo hiểm cho một ngày nằm viện tại Đơn vị điều trị tích cực (ICU).</li>
            <li>Chi trả 0,6% Số tiền bảo hiểm cho một ngày nằm viện do điều trị Bệnh hiểm nghèo.</li>
          </ul>
        ),
      },
      {
        title: 'Quyền lợi Hỗ trợ Chi phí phẫu thuật',
        content: (
          <ul className="p-start-20px text-14px text-c_767676">
            <li>Bảo hiểm cho hơn 90 loại phẫu thuật khác nhau.</li>
            <li>
              Chi trả 2%/5%/10%/20% Số tiền bảo hiểm tương ứng với độ phức tạp của mỗi phẫu thuật nếu Người được bảo
              hiểm phải Phẫu thuật khi đang Nằm viện và được chấp nhận chi trả Quyền lợi Hỗ trợ viện phí nội trú.
            </li>
            <li>Tổng quyền lợi Hỗ trợ chi phí phẫu thuật là 100% Số tiền bảo hiểm.</li>
          </ul>
        ),
      },
    ],
  },
  {
    type: AdditionalAssuredProductEnum.PWR,
    benefits: [
      {
        title: 'Quyền lợi miễn đóng phí',
        content: (
          <ul className="p-start-20px text-14px text-c_767676">
            <li>Miễn toàn bộ hoặc một phần phí bảo hiểm đến hạn (nếu có) của hợp đồng chính và các sản phẩm bổ trợ</li>
          </ul>
        ),
      },
    ],
  },
  {
    type: AdditionalAssuredProductEnum.TRMR,
    benefits: [
      {
        title: 'Quyền lợi bảo hiểm Tử vong và Thương tật toàn bộ vĩnh viễn',
        content: (
          <ul className="p-start-20px text-14px text-c_767676">
            <li>
              100% Số tiền bảo hiểm được chi trả trong trường hợp Người được bảo hiểm tử vong hoặc gặp Thương tật toàn
              bộ vĩnh viễn khi Hợp đồng bổ trợ đang có hiệu lực.
            </li>
          </ul>
        ),
      },
    ],
  },
];

export enum MicMessageTypeEnum {
  ONLY_PARENT = 'ONLY_PARENT',
  ONLY_CHILD = 'ONLY_CHILD',
  NO_MATCH_PACKAGE = 'NO_MATCH_PACKAGE',
  NO_MATCH_BENEFITS = 'NO_MATCH_BENEFITS',
  MATCH_PACKAGE = 'MATCH_PACKAGE',
  GCN_NO_MATCH_PACKAGE = 'GCN_NO_MATCH_PACKAGE',
  GCN_NO_MATCH_BENEFITS = 'GCN_NO_MATCH_BENEFITS',
}

export const getMICMessage = (type: MicMessageTypeEnum, name?: string) => {
  let t: ReactNode = '';
  switch (type) {
    case 'ONLY_PARENT':
      t = (
        <div>
          <h3>Quà tặng đặc biệt!</h3>
          <b>Giảm 30%</b> phí Thẻ bảo lãnh viện phí MIC CARE khi mua cho Con của bạn. Bạn có muốn tham gia không?
        </div>
      );
      break;
    case 'ONLY_CHILD':
      t = (
        <div>
          <h3>Quà tặng đặc biệt!</h3>
          <b>Giảm 30%</b> phí Thẻ bảo lãnh viện phí MIC CARE của <b>{name}</b> khi Bố/mẹ cùng mua. Bạn có muốn tham gia
          không?
        </div>
      );
      break;
    case 'NO_MATCH_PACKAGE':
      t = (
        <div>
          Thẻ bảo lãnh viện phí MIC Care của <b>{name}</b> không được giảm 30% phí do chọn quyền lợi cao hơn bố mẹ. Bạn
          có muốn tiếp tục?
        </div>
      );
      break;
    case 'NO_MATCH_BENEFITS':
      t = (
        <div>
          Thẻ bảo lãnh viện phí MIC Care của <b>{name}</b> không được giảm 30% phí do chọn quyền lợi bổ sung khác bố mẹ.
          Bạn có muốn tiếp tục?
        </div>
      );
      break;
    case 'MATCH_PACKAGE':
      t = <div>Thẻ bảo lãnh viện phí MIC Care của Con được giảm 30% khi quyền lợi của Con và Bố/mẹ giống nhau</div>;
      break;
    case 'GCN_NO_MATCH_PACKAGE':
      t = (
        <div>
          Thẻ bảo lãnh viện phí MIC Care của <b>{name}</b> không được giảm 30% phí do chọn quyền lợi cao hơn bố mẹ. Bạn
          có muốn điều chỉnh gói của con để nhận ưu đãi giảm phí 30% không?
        </div>
      );
      break;
    case 'GCN_NO_MATCH_BENEFITS':
      t = (
        <div>
          Thẻ bảo lãnh viện phí MIC Care của <b>{name}</b> không được giảm 30% phí do chọn quyền lợi bổ sung khác bố mẹ.
          Bạn có muốn điều chỉnh gói của con để nhận ưu đãi giảm phí 30% không?
        </div>
      );
      break;
    default:
      break;
  }
  return t;
};
