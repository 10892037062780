import { memo, useEffect, useState } from 'react';
import classNames from 'classnames';

import { CheckboxCheckedSVG, CheckboxUncheckSVG } from '../../icons';

type Value = string | number | boolean;

type Option = {
  label: string;
  value: Value;
};

type Props = {
  options: Option[];
  value?: Value;
  onChange?(value?: Value): void;
  className?: string;
  error?: string;
};

export const RadioGroup = memo(({ value, onChange, className, options, error }: Props) => {
  const [selectedValue, setSelectedValue] = useState<Value | undefined>(value);

  useEffect(() => {
    onChange?.(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    if (value !== selectedValue) {
      setSelectedValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="relative">
      <div className="mb-16px -mx-4px flex flex-row">
        {options.map((it, i) => (
          <div className="w-1/2 px-4px" key={i}>
            <div
              className={classNames(
                'h-48px rounded-6px border-solid border-0.5px px-16px flex justify-between items-center',
                className,
                selectedValue === it.value ? 'border-primary bg-primary' : 'border-c_DDDDDD bg-white',
              )}
              onClick={() => setSelectedValue(it.value)}>
              <span
                className={classNames(
                  'text-16px font-semibold leading-4',
                  selectedValue === it.value ? 'text-white' : 'text-c_161616',
                )}>
                {it.label}
              </span>
              {selectedValue === it.value ? <CheckboxCheckedSVG /> : <CheckboxUncheckSVG />}
            </div>
          </div>
        ))}
      </div>
      {error && <span className="text-c_EB5757 text-12px absolute -bottom-20px">{error}</span>}
    </div>
  );
});
