import React, { memo } from 'react';
import { NumberInput as MantineNumberInput, NumberInputProps as MantineNumberInputProps } from '@mantine/core';

import { numberWithCommas } from '../../helpers';

export type NumberInputProps = Omit<MantineNumberInputProps, 'parser' | 'formatter'>;

export const NumberInput = memo((props: NumberInputProps) => {
  return (
    <MantineNumberInput
      {...props}
      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
      formatter={(value) => (!Number.isNaN(parseFloat(value ?? '')) ? numberWithCommas(Number(value)) : '')}
    />
  );
});
