import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { CloseSVG, DownloadSVG } from '../../icons';

type Props = {
  title: string;
  onLeft?: () => void;
  onRight?: () => void;
};

export const PdfViewNavbar = memo(({ title, onLeft, onRight }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white flex justify-between items-center px-16px py-10px fixed top-0 w-full z-10">
      <div className="flex items-center gap-3">
        <div
          className="w-24px h-24px"
          onClick={() => {
            if (onLeft) {
              onLeft();
            } else {
              navigate(-1);
            }
          }}>
          <CloseSVG />
        </div>
        <span className="font-bold mx-auto leading-22px whitespace-normal">{title}</span>
      </div>
      <div
        className="w-24px h-24px"
        onClick={() => {
          if (onRight) {
            onRight();
          }
        }}>
        <DownloadSVG />
      </div>
    </div>
  );
});
