import { MantineProvider } from '@mantine/core';
import { QueryClientProvider } from 'react-query';
import { Suspense, lazy } from 'react';

import { mantineTheme } from './themes';
import { MixQuestionsProvider } from './screens/illustrations/form';
import { DatadogProvider, DialogProvider } from './contexts';
import { useInit } from './init';
import { FullscreenLoader } from './components';
import './yup-locale';
import './styles/index.less';

const AppRouter = lazy(() => import('./app-router'));

export const App = () => {
  const initResult = useInit();

  if (!initResult) return <FullscreenLoader />;

  return (
    <DatadogProvider>
      <QueryClientProvider client={initResult.query}>
        <MantineProvider withGlobalStyles withNormalizeCSS theme={mantineTheme}>
          <DialogProvider>
            <Suspense fallback={<FullscreenLoader />}>
              <MixQuestionsProvider>
                <AppRouter />
              </MixQuestionsProvider>
            </Suspense>
          </DialogProvider>
        </MantineProvider>
      </QueryClientProvider>
    </DatadogProvider>
  );
};
