import classNames from 'classnames';
import { memo } from 'react';

import { ReverseProductCheckboxCheckedSVG, ReverseProductCheckboxUncheckedSVG } from '../../../icons';

type Value = string | number | boolean;

export type ReverseProductEntity = {
  label: string;
  value: Value;
  content: string;
  logo: React.ReactNode;
  className: string;
  total_benefit?: string;
};

type Props = {
  options: ReverseProductEntity[];
  value?: Value;
  onChange?(value?: Value): void;
  className?: string;
  error?: string;
};

export const ReverseProductsList = memo(({ value, onChange, options, error }: Props) => {
  return (
    <>
      <div className="flex flex-row flex-wrap p-4 mt-3 gap-3">
        {options.map((it, i) => (
          <div className="w-[calc(50%-0.4rem)] relative" key={i} onClick={() => onChange?.(it.value)}>
            <div className="absolute top-14px right-14px">
              {value === it.value ? <ReverseProductCheckboxCheckedSVG /> : <ReverseProductCheckboxUncheckedSVG />}
            </div>
            <div
              className={classNames(
                'pt-9 pb-4 px-3 h-250px rounded-6px border-solid border-2 flex flex-col justify-end items-center',
                it.className,
                value === it.value && 'border-primary shadow-selected-primary',
              )}>
              {it.logo}
              <span className="font-bold text-c_161616 mt-4 mb-1">Gói</span>
              <span className="font-bold text-primary mb-1 text-center">&quot;{it.label}&quot;</span>
              <span className="text-12px text-center italic text-c_515151">{it.content}</span>
              {!!it?.total_benefit && (
                <>
                  <span className="text-13px text-center italic  text-c_515151">Tổng quyền lợi lên tới</span>
                  <span className="text-13px text-center italic font-semibold text-primary">{it?.total_benefit}</span>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      {error && <span className="text-c_EB5757 text-12px absolute -bottom-20px">{error}</span>}
    </>
  );
});
