import { useEffect, useState } from 'react';
import { QueryClient } from 'react-query';

import { init } from './init';

type Init = {
  query: QueryClient;
};

export function useInit() {
  const [initResult, setInitResult] = useState<Init>({
    query: new QueryClient({
      defaultOptions: {
        queries: {
          structuralSharing: false,
          retry: false,
          refetchOnWindowFocus: false,
          staleTime: 24 * 60 * (60 * 1000),
          cacheTime: 24 * 60 * (60 * 1000),
        },
      },
    }),
  });

  useEffect(() => {
    init().then((result) => setInitResult(result));
  }, []);

  return initResult;
}
