import { Button } from '@mantine/core';
import { stringify } from 'qs';
import { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { CollapseHeaderCustomizable, LeadsLayout } from '../../../components';
import { useDialog, useInitialData, useLeads } from '../../../contexts';
import { AppRoutes, dateFormat, formatMoney, formatPhoneNumber, getMessage } from '../../../helpers';
import { vi } from '../../../i18n/vi';
import { ClipboardTextSVG } from '../../../icons';
import {
  DirectStateEnum,
  InsuranceMethodEnum,
  LeadsContractState,
  LeadsFlowEnum,
  ReferOrDirectTabEnum,
  ReferStateEnum,
  useGetLeadDirectId,
  useGetLeadReferId,
} from '../../../services';
import { leadsContractStateOptions, referStateOptions } from '../components';
import { useQueryString } from '../../../hooks';
import { LeadsFormStepEnum, LeadsScreenParams } from '../form';

import { StatusStepsVertical, getLeadsStateFromStatus } from './components';
import { LeadsRowItem } from './detail';

type Props = {
  method: InsuranceMethodEnum;
};

export const LeadsMethodDetail = memo(({ method }: Props) => {
  const { id } = useParams();
  const { type = ReferOrDirectTabEnum.PROFILE } = useQueryString<LeadsScreenParams>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const activeStep = useMemo(() => {
    return ReferOrDirectTabEnum[type as ReferOrDirectTabEnum] ?? ReferOrDirectTabEnum.PROFILE;
  }, [type]);

  const activeStepForm = useMemo(() => {
    if ([LeadsFlowEnum.REFER, ReferOrDirectTabEnum.PROFILE, ReferOrDirectTabEnum.CONTRACT].includes(type)) {
      return LeadsFormStepEnum.REFER;
    }
  }, [type]);

  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const { onUpdateLeadsCustomer, onUpdateLeadsDetail } = useLeads();
  const [isCustomerFormDirty, setIsCustomerFormDirty] = useState(false);

  const onChangeType = useCallback(
    async (it: ReferOrDirectTabEnum) => {
      onUpdateLeadsCustomer(undefined);
      if (isCustomerFormDirty) {
        const res = await showDialog({
          type: 'CONFIRM',
          message: vi.abortChanges,
        });
        if (res) {
          setIsCustomerFormDirty(false);
        }
      }
      navigate(
        {
          search: stringify({
            type: it,
          }),
        },
        {
          replace: true,
        },
      );
    },
    [isCustomerFormDirty, navigate, onUpdateLeadsCustomer, showDialog],
  );

  const { data: dataLeadRefer, isLoading: referLoading } = useGetLeadReferId(id ?? '', {
    enabled: id !== null && method === InsuranceMethodEnum.REFER,
    onError: async (error) => {
      const err = await showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
      err && navigate(-1);
    },
  });

  const { data: dataLeadDirect, isLoading: directLoading } = useGetLeadDirectId(id ?? '', {
    enabled: id !== null && method === InsuranceMethodEnum.DIRECT,
    onError: async (error) => {
      const err = await showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
      err && navigate(-1);
    },
  });

  const data = useMemo(
    () => (method === InsuranceMethodEnum.DIRECT ? dataLeadDirect : dataLeadRefer),
    [method, dataLeadDirect, dataLeadRefer],
  );

  const { occupations } = useInitialData();

  const onSubmit = useCallback(
    (method: InsuranceMethodEnum) => {
      const quotationId = data?.quotation?.id?.toString();
      onUpdateLeadsCustomer(
        data
          ? {
              ...data?.potential_customer,
              direct_id: data?.id,
            }
          : undefined,
      );
      if (method === InsuranceMethodEnum.REFER) {
        navigate(-1);
      }
      if (method === InsuranceMethodEnum.DIRECT) {
        if (data?.direct_state === DirectStateEnum.CREATE_BMHN && data?.user_combo_id) {
          navigate({
            pathname: AppRoutes.reverse_result(data?.user_combo_id.toString()),
            search: stringify({
              combo_name: data?.user_combo_name?.toLocaleUpperCase(),
            }),
          });
        }
        if (data?.direct_state === DirectStateEnum.INIT_DIRECT) {
          navigate(AppRoutes.reverse_products);
        }
        if (data?.direct_state === DirectStateEnum.CREATE_BMHX) {
          onUpdateLeadsDetail(data);
          navigate(AppRoutes.illustration(quotationId));
        }
      }
    },
    [data, navigate, onUpdateLeadsCustomer, onUpdateLeadsDetail],
  );
  const onBack = useCallback(() => {
    if (activeStepForm === LeadsFormStepEnum.REFER) {
      navigate(`${AppRoutes.leads_insurance_contract}?type=${LeadsFlowEnum.REFER}`);
    }
  }, [activeStepForm, navigate]);

  if (referLoading || directLoading) return null;

  return (
    <LeadsLayout
      title={`Chi tiết khách hàng ${method.toLowerCase()}`}
      className="bg-primary text-white"
      onLeft={onBack}
      fillColor="white">
      <div className="bg-primary">
        <div className={'overflow-x-auto whitespace-nowrap wrap-tab-lead py-3 px-4'}>
          {Object.values(ReferOrDirectTabEnum).map((tabValue) => (
            <div
              key={tabValue}
              className={classNames(['tab-referdirect', tabValue === type && 'active'])}
              onClick={() => onChangeType(tabValue)}>
              {tabValue === ReferOrDirectTabEnum.PROFILE ? 'Lead Profile' : 'Hồ sơ YCBH'}
            </div>
          ))}
        </div>
      </div>
      <div
        className={`px-16px pt-20px flex flex-col pb-4 gap-y-16px ${
          method === InsuranceMethodEnum.DIRECT && 'h-screen pb-8'
        }`}>
        {type === ReferOrDirectTabEnum.PROFILE && method === InsuranceMethodEnum.REFER && (
          <>
            <CollapseHeaderCustomizable
              icon={<ClipboardTextSVG />}
              title="Trạng thái khách hàng"
              containerClassName="p-16px rounded-md">
              <StatusStepsVertical
                steps={referStateOptions}
                statusNums={data?.lead_status_num_detail}
                stepDone={(getLeadsStateFromStatus(data?.lead_status_num) || data?.state) ?? ReferStateEnum.SENT}
              />
            </CollapseHeaderCustomizable>
            <CollapseHeaderCustomizable
              icon={<ClipboardTextSVG />}
              title="Thông tin khách hàng"
              shadowDisabled
              containerClassName="p-16px rounded-md">
              <div className="mt-20px">
                <LeadsRowItem
                  label="Mã khách hàng"
                  value={(data?.potential_customer.lead_id || data?.lead_id || '-')?.toString()}
                />
                <LeadsRowItem label="Họ và tên" value={data?.potential_customer.full_name} />
                <LeadsRowItem
                  label="Giới tính"
                  value={data?.potential_customer ? vi.gender[data.potential_customer.gender] : '...'}
                />
                <LeadsRowItem
                  label="Ngày sinh"
                  value={data?.potential_customer.dob ? dateFormat(data.potential_customer.dob) : '...'}
                />
                <LeadsRowItem
                  label="Nghề nghiệp"
                  value={occupations.find((it) => it.id === data?.potential_customer.occupation_id)?.name_vn}
                />
                <LeadsRowItem
                  label="Số điện thoại"
                  value={
                    data?.potential_customer.phone_number
                      ? formatPhoneNumber(data?.potential_customer.phone_number)
                      : '...'
                  }
                />
                <LeadsRowItem
                  label="Loại giấy tờ tuỳ thân"
                  value={
                    data?.potential_customer.identification_type
                      ? vi.identification_type[data?.potential_customer.identification_type]
                      : ''
                  }
                />
                <LeadsRowItem label="Số giấy tờ tuỳ thân" value={data?.potential_customer.identification_id} />
                <LeadsRowItem
                  label="Số tiền có thể tham gia bảo hiểm/năm"
                  value={formatMoney(data?.potential_customer.input_amount.toString())}
                />
                <LeadsRowItem label="Ghi chú" value={data?.potential_customer.note} />
              </div>
            </CollapseHeaderCustomizable>
          </>
        )}
        {type === ReferOrDirectTabEnum.PROFILE && method === InsuranceMethodEnum.DIRECT && (
          <CollapseHeaderCustomizable
            icon={<ClipboardTextSVG />}
            title="Thông tin khách hàng"
            shadowDisabled
            containerClassName="p-16px rounded-md">
            <div className="mt-20px">
              <LeadsRowItem
                label="Mã khách hàng"
                value={(data?.potential_customer.lead_id || data?.lead_id || '-')?.toString()}
              />
              <LeadsRowItem label="Họ và tên" value={data?.potential_customer.full_name} />
              <LeadsRowItem
                label="Giới tính"
                value={data?.potential_customer ? vi.gender[data.potential_customer.gender] : '...'}
              />
              <LeadsRowItem
                label="Ngày sinh"
                value={data?.potential_customer.dob ? dateFormat(data.potential_customer.dob) : '...'}
              />
              <LeadsRowItem
                label="Nghề nghiệp"
                value={occupations.find((it) => it.id === data?.potential_customer.occupation_id)?.name_vn}
              />
              <LeadsRowItem
                label="Số điện thoại"
                value={
                  data?.potential_customer.phone_number
                    ? formatPhoneNumber(data?.potential_customer.phone_number)
                    : '...'
                }
              />
              <LeadsRowItem
                label="Loại giấy tờ tuỳ thân"
                value={
                  data?.potential_customer.identification_type
                    ? vi.identification_type[data?.potential_customer.identification_type]
                    : ''
                }
              />
              <LeadsRowItem label="Số giấy tờ tuỳ thân" value={data?.potential_customer.identification_id} />
              <LeadsRowItem
                label="Số tiền có thể tham gia bảo hiểm/năm"
                value={formatMoney(data?.potential_customer.input_amount.toString())}
              />
              <LeadsRowItem label="Ghi chú" value={data?.potential_customer.note} />
            </div>
          </CollapseHeaderCustomizable>
        )}

        {type === ReferOrDirectTabEnum.CONTRACT && (
          <CollapseHeaderCustomizable
            icon={<ClipboardTextSVG />}
            title="Trạng thái hồ sơ"
            containerClassName="p-16px rounded-md">
            <StatusStepsVertical
              steps={leadsContractStateOptions}
              stepDone={data?.app_status || LeadsContractState.COMPLETED}
              stepDoneDescription={data?.contract_pending_note}
            />
          </CollapseHeaderCustomizable>
        )}
      </div>
      {method === InsuranceMethodEnum.DIRECT && (
        <div className="bg-white py-3 px-4 bottom-0 left-0 mt-8 w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
          <Button type="submit" onClick={() => onSubmit(method)} fullWidth loading={false} disabled={false}>
            {method === InsuranceMethodEnum.DIRECT && 'Xem BMH tóm tắt'}
          </Button>
        </div>
      )}
    </LeadsLayout>
  );
});
