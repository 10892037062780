import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mantine/core';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { DatePicker, FormLabel, Layout } from '../../../components';
import { useReverse } from '../../../contexts/reverse';
import { AppRoutes } from '../../../helpers';
import { CalendarSVG } from '../../../icons';
import { GenderEnum, ReverseCustomerFormData } from '../../../services';
import { genderValidation } from '../../illustrations/components';
import { MoneyInputSuggest } from '../../illustrations/components/money-suggestion';
import { NoticeMessage } from '../components';
import { dobValidation, yearlyExpenseValidation } from '../components/yup-validation';

export const ReverseCustomerScreen = () => {
  const navigate = useNavigate();

  const { onUpdateReverseCustomerForm, reverseCustomer } = useReverse();

  const validationSchema: yup.ObjectSchema<ReverseCustomerFormData> = useMemo(
    () =>
      yup.object({
        gender: genderValidation,
        dob: dobValidation,
        input_amount: yearlyExpenseValidation,
      }),
    [],
  );

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm<ReverseCustomerFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      gender: reverseCustomer?.gender ?? GenderEnum.MALE,
      dob: reverseCustomer?.dob,
      input_amount: reverseCustomer?.input_amount,
    },
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    (data: ReverseCustomerFormData) => {
      onUpdateReverseCustomerForm(data);
      navigate(AppRoutes.reverse_products);
    },
    [navigate, onUpdateReverseCustomerForm],
  );

  return (
    <Layout title="Thông tin khách hàng">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pb-120px">
          <div className="px-16px py-20px">
            <div>
              {/* Date of birth field  */}
              <FormLabel required>Ngày sinh</FormLabel>
              <Controller
                control={control}
                name="dob"
                render={({ field: { onBlur, onChange, value } }) => (
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    placeholder="DD/MM/YYYY"
                    labelFormat="MM/YYYY"
                    allowFreeInput
                    value={value != null ? dayjs(value).toDate() : undefined}
                    onChange={onChange}
                    onBlur={onBlur}
                    clearable={false}
                    rightSection={<CalendarSVG />}
                    excludeDate={(date) => date.getTime() > new Date().getTime()}
                    error={errors.dob?.message}
                  />
                )}
              />
            </div>

            {/* Yearly expense field  */}
            <div>
              <FormLabel required>Số tiền KH có thể dành cho bảo hiểm/năm</FormLabel>
              <Controller
                control={control}
                name="input_amount"
                render={({ field: { value, onChange, onBlur } }) => (
                  <>
                    <MoneyInputSuggest
                      placeholder="Nhập số tiền"
                      error={errors.input_amount?.message}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      max={100000000000}
                    />
                  </>
                )}
              />
            </div>

            {/* Notice  */}
            <NoticeMessage important textContent="Phí bảo hiểm phải là bội số của" amount="1,000,000" />
            <NoticeMessage textContent="Phí bảo hiểm tối thiểu là" amount="10,000,000" />
          </div>
        </div>

        {/* Proceed button  */}
        <div className="bg-white pt-12px pb-34px px-16px fixed bottom-0 left-0 w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
          <Button type="submit" disabled={!isValid} fullWidth>
            Tiếp tục
          </Button>
        </div>
      </form>
    </Layout>
  );
};
