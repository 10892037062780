import classNames from 'classnames';
import { memo, useCallback, useEffect, useState } from 'react';

import { SelectItemType } from '../../../../components';
import { ReverseProductCheckboxCheckedSVG, ReverseProductCheckboxUncheckedSVG } from '../../../../icons';

type Props = {
  options: SelectItemType[];
  value?: string;
  onChange?(value?: string): void;
  className?: string;
  error?: string;
  disabled?: boolean;
};

export const RadioGroupPackages = memo(({ value, onChange, className, options, error, disabled }: Props) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const onTouch = useCallback(
    (value?: string) => {
      if (disabled) return;
      setSelectedValue(value);
      onChange?.(value);
    },
    [disabled, onChange],
  );

  return (
    <div className="relative">
      <div className="flex flex-col gap-y-16px">
        {options?.map((it, i) => (
          <div className="w-full" key={i}>
            <div
              className={classNames(
                'rounded-6px border-solid border-0.5px p-16px flex justify-between items-center',
                className,
                selectedValue === it?.value ? 'border-primary bg-c_E2F5FE' : 'border-c_DDDDDD bg-white',
                !!error && 'border-red',
              )}
              onClick={() => onTouch(it?.value)}>
              <div className="flex-1 flex flex-col gap-y-4px">
                <span
                  className={classNames(
                    'font-semibold',
                    selectedValue === it?.value ? 'text-primary' : 'text-c_111111',
                  )}>
                  {it?.label}
                </span>
                <span
                  className={classNames('text-13px', selectedValue === it?.value ? 'text-primary' : 'text-c_999999')}>
                  {it?.extra}
                </span>
                {it?.note && (
                  <span
                    className={classNames('text-13px', selectedValue === it?.value ? 'text-primary' : 'text-c_999999')}>
                    {it?.note}
                  </span>
                )}
              </div>
              {selectedValue === it?.value ? (
                <ReverseProductCheckboxCheckedSVG />
              ) : (
                <ReverseProductCheckboxUncheckedSVG />
              )}
            </div>
          </div>
        ))}
      </div>
      {error && <div className="errorTextStyle mt-10px mb-0">{error}</div>}
    </div>
  );
});
