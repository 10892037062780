import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';

import { lineLeftIcon, lineRightIcon, searchEmptyIcon } from '../../../../icons';
import { AppRoutes } from '../../../../helpers';

export const EmptyLeads = () => {
  const navigate = useNavigate();
  const handleCreateLead = useCallback(() => {
    navigate(AppRoutes.leads_new);
  }, [navigate]);
  return (
    <div className="mt-28">
      <div className="flex justify-center items-center">
        <div>
          <img src={lineLeftIcon} alt="" />
        </div>
        <div>
          <img src={searchEmptyIcon} alt="" />
        </div>
        <div>
          <img className="mb-20px ml-[-11px]" src={lineRightIcon} alt="" />
        </div>
      </div>
      <div className="text-center mt-10">
        <p className="font-bold text-16px text-c_515151">Không có dữ liệu trùng khớp</p>
      </div>
      <div className="flex justify-center items-center mt-3">
        <Button onClick={handleCreateLead} className="border-none">
          Tạo khách hàng mới
        </Button>
      </div>
    </div>
  );
};
