import * as yup from 'yup';
import dayjs from 'dayjs';

import { regexp, validationMessages } from '../../../helpers';
import { GenderEnum, IdentityDocumentTypeEnum } from '../../../services';

export const nameValidation = yup
  .string()
  .trim()
  .required(validationMessages.required)
  .min(4, validationMessages.invalidName)
  .test(
    'not-empty',
    validationMessages.invalidName,
    (val) => val == null || val.length === 0 || !regexp.specialLetter.test(val),
  );

export const identityDocumentNumberValidation = yup
  .string()
  .required(validationMessages.required)
  .when(['identification_type', 'dob', 'gender'], ([identification_type, dob, gender], scheme, { value }) => {
    const isCentry21 = regexp.centery21.test(dayjs(dob).year().toString());
    const letter4 = Number(value?.toString()?.substring(3, 4));
    const isMatchYear = value?.toString()?.substring(4, 6) === dayjs(dob).format('YY');
    const female = isCentry21 && gender === GenderEnum.FEMALE ? 3 : 1;
    const male = isCentry21 && gender === GenderEnum.MALE ? 2 : 0;
    let isMatchGender = false;
    if ((gender === GenderEnum.FEMALE && female === letter4) || (gender === GenderEnum.MALE && male === letter4)) {
      isMatchGender = true;
    }
    switch (identification_type) {
      case IdentityDocumentTypeEnum.MILITARY_ID:
        // Military ID
        return scheme.trim().matches(regexp.militaryId, validationMessages.identityDocumentNumber.militaryId);
      case IdentityDocumentTypeEnum.CITIZEN_ID:
        if (regexp.citizenId.test(value) && !isMatchGender) {
          return scheme.test('invalite-format', `Số CCCD không hợp lệ (không đúng với giới tính)`, () => false);
        } else if (regexp.citizenId.test(value) && !isMatchYear) {
          return scheme.test('invalite-format', `Số CCCD không hợp lệ (không đúng với năm sinh)`, () => false);
        }
        return scheme.matches(regexp.citizenId, validationMessages.identityDocumentNumber.citizenId);
      case IdentityDocumentTypeEnum.BIRTH_CERTIFICATE:
        return scheme.matches(regexp.birthdayCertificate, validationMessages.identityDocumentNumber.birthCertificate);
      case IdentityDocumentTypeEnum.PASSPORT:
        return scheme.matches(regexp.passport, validationMessages.identityDocumentNumber.passport);
      default:
        if (!!value && value.length === 12 && regexp.nationalId.test(value) && !isMatchGender) {
          return scheme.test('invalite-format', `Số CMND không hợp lệ (không đúng với giới tính)`, () => false);
        } else if (!!value && value.length === 12 && regexp.nationalId.test(value) && !isMatchYear) {
          return scheme.test('invalite-format', `Số CMND không hợp lệ (không đúng với năm sinh)`, () => false);
        }
        return scheme.matches(regexp.nationalId, validationMessages.identityDocumentNumber.nationalId);
    }
  });

export const identityDocumentTypeValidation = yup
  .mixed<IdentityDocumentTypeEnum>()
  .oneOf(Object.values(IdentityDocumentTypeEnum))
  .required(validationMessages.required);

export const genderValidation = yup.mixed<GenderEnum>().oneOf(Object.values(GenderEnum)).required();

export const phoneValidation = yup
  .string()
  .trim()
  .required(validationMessages.required)
  .test(
    'not-empty',
    validationMessages.invalidPhone,
    (val) => val == null || val.length === 0 || regexp.vn_phone.test(val),
  );

export const phoneOptionalValidation = yup
  .string()
  .test(
    'phone-validation',
    validationMessages.invalidPhone,
    (val) => val == null || val.length === 0 || regexp.vn_phone.test(val),
  );

export const dobValidation = yup
  .string()
  .required(validationMessages.required)
  .test(
    'less-than-now',
    'Giá trị không hợp lệ',
    (val) => val === null || dayjs(val).toDate().getTime() < new Date().getTime(),
  );

export const emailValidation = yup
  .string()
  .required(validationMessages.emailRequired)
  .test(
    'not-empty',
    validationMessages.invalidEmail,
    (val) => val == null || val.length === 0 || regexp.email.test(val),
  )
  .test('no-email', validationMessages.noEmail, (val) => !!val && !val?.toLocaleLowerCase()?.match(regexp.noEmail));

export const emailOptionalValidation = yup
  .string()
  .test(
    'not-empty',
    validationMessages.invalidEmail,
    (val) => val == null || val.length === 0 || regexp.email.test(val),
  )
  .test(
    'no-email',
    validationMessages.noEmail,
    (val) => val == null || val.length === 0 || !val?.toLocaleLowerCase()?.match(regexp.noEmail),
  );
