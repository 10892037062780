import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncedValue } from '@mantine/hooks';
import dayjs from 'dayjs';
import { stringify } from 'qs';
import { Button, Checkbox, TextInput } from '@mantine/core';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';

import { DateRangePicker, LeadsNavbar } from '../../../components';
import { useDialog, useLeads } from '../../../contexts';
import { useQueryString } from '../../../hooks';
import { LeadsScreenParams } from '../form';
import { DATE_FORMAT } from '../../../constants';
import { LeadsCustomerFormData, LeadsFlowEnum, useGetLeads } from '../../../services/leads';
import { vi } from '../../../i18n/vi';
import { AppRoutes, getMessage } from '../../../helpers';
import { CloseSVG, FilterPrimarySVG, SearchSVG } from '../../../icons';
import { SpinLoader } from '../../../components/spin-loader';
import { EmptyLeads, LeadsCard } from '../components';

import { useLeadsHook } from './hook';

export const ListInsuranceContract = () => {
  const { type = LeadsFlowEnum.OVERVIEW } = useQueryString<LeadsScreenParams>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const activeStep = useMemo(() => {
    return LeadsFlowEnum[type as LeadsFlowEnum] ?? LeadsFlowEnum.REFER;
  }, [type]);

  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const [date, setDate] = useState<[Date | null, Date | null]>([null, null]);
  const [keyword, setKeyword] = useState<string>('');
  const [q] = useDebouncedValue(keyword, 400);
  const [isCustomerFormDirty, setIsCustomerFormDirty] = useState(false);

  const { onUpdateLeadsCustomer } = useLeads();

  const input = useMemo(() => {
    return {
      q,
      size: 10,
      type,
      from: date?.[0] ? dayjs(date?.[0]).format(DATE_FORMAT.server) : undefined,
      to: date?.[1] ? dayjs(date?.[1]).format(DATE_FORMAT.server) : undefined,
    };
  }, [date, q, type]);

  const {
    data: potential,
    isLoading,
    fetchNextPage,
    refetch,
  } = useGetLeads(input, {
    getNextPageParam: (lastPage) => lastPage?.page + 1,
    onError: async (error) => {
      showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
    },
  });

  const potentialItems = useMemo(() => {
    let data: LeadsCustomerFormData[] = [];
    potential?.pages?.forEach((page) => {
      data = data.concat(page?.content);
    });
    return data;
  }, [potential?.pages]);

  const { isDeleted, handleDelete, onPickAll, onPick, ids, onDelete, deleteLoading, handleRemove, onRemove } =
    useLeadsHook({
      data: potentialItems,
      refetch,
      type,
    });

  const onChangeType = useCallback(
    async (it: LeadsFlowEnum) => {
      onUpdateLeadsCustomer(undefined);
      if (isCustomerFormDirty) {
        const res = await showDialog({
          type: 'CONFIRM',
          message: vi.abortChanges,
        });
        if (res) {
          setIsCustomerFormDirty(false);
        }
      }
      setKeyword('');
      setDate([null, null]);
      navigate(
        {
          search: stringify({
            type: it,
          }),
        },
        {
          replace: true,
        },
      );
    },
    [isCustomerFormDirty, navigate, onUpdateLeadsCustomer, showDialog],
  );
  const onBack = useCallback(() => {
    return navigate(AppRoutes.home);
  }, [navigate]);
  const totalCount = useMemo(() => potential?.pages?.[0]?.total_elements || 0, [potential?.pages]);

  const onScrollFrame = useCallback(() => {
    if (potentialItems.length >= totalCount) return false;
    fetchNextPage();
  }, [fetchNextPage, potentialItems.length, totalCount]);

  return (
    <div className="h-screen w-full pb-3 bg-c_F0F4F7">
      <div>
        <LeadsNavbar
          onLeft={onBack}
          className={'bg-primary text-white'}
          title={'Theo dõi HĐBH phát hành'}
          fillColor="white"
        />
      </div>
      <div className="flex flex-col px-15px py-16px bg-primary">
        <div className="pt-10">
          <TextInput
            value={keyword}
            placeholder="Nhập tên, SĐT, Mã khách hàng"
            rightSection={<SearchSVG />}
            onChange={(e) => setKeyword(e?.target?.value)}
          />
        </div>
        <div className="bg-primary">
          <div className={'overflow-x-auto whitespace-nowrap wrap-tab-lead'}>
            {Object.values(LeadsFlowEnum)
              .filter(
                (it) => it === LeadsFlowEnum.OVERVIEW || it === LeadsFlowEnum.REFER || it === LeadsFlowEnum.DIRECT,
              )
              .map((it) => (
                <div
                  key={it}
                  className={classNames(['tab-lead', it === type && 'active'])}
                  onClick={() => onChangeType(it)}>
                  {it === LeadsFlowEnum.OVERVIEW
                    ? 'Tổng quan'
                    : it === LeadsFlowEnum.DIRECT
                    ? 'Lead KH Direct'
                    : 'Lead KH refer'}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="py-10px px-15px bg-c_F0F4F7">
        <div className="flex items-center justify-between mb-16px">
          <div className={'w-215px mr-auto'}>
            {type !== LeadsFlowEnum.OVERVIEW && (
              <DateRangePicker
                icon={<FilterPrimarySVG />}
                className={`mb-0 filter-datepicker-input`}
                onChange={(date) => setDate(date)}
                disabled={isLoading}
                value={date}
              />
            )}
          </div>

          {type !== LeadsFlowEnum.OVERVIEW && (
            <div className="flex-shrink-0">
              {isDeleted ? (
                <div className="flex items-center justify-end">
                  <Button
                    color="red"
                    variant="outline"
                    className="bg-white border-c_DDDDDD rounded-6px font-medium px-10px"
                    onClick={() => handleRemove(() => onDelete())}
                    disabled={deleteLoading || !ids?.length}
                    loading={deleteLoading}>
                    Xoá
                  </Button>
                  <div className="px-12px">
                    <Checkbox
                      checked={ids.length === potentialItems?.length}
                      onChange={(event) => onPickAll(event.currentTarget.checked)}
                    />
                  </div>
                  <div className="w-20px h-20px" onClick={handleDelete}>
                    <CloseSVG width={20} height={20} />
                  </div>
                </div>
              ) : (
                <Button
                  color="red"
                  variant="outline"
                  className="bg-white border-c_DDDDDD rounded-full font-medium px-3 py-6px border-none inline-block"
                  onClick={handleDelete}
                  disabled={isLoading || !potentialItems.length}>
                  Xoá tất cả
                </Button>
              )}
            </div>
          )}
        </div>
        <div>
          <SpinLoader spining={isLoading}>
            <>
              {type !== LeadsFlowEnum.OVERVIEW ? (
                <InfiniteScroll
                  dataLength={potentialItems.length}
                  next={onScrollFrame}
                  hasMore={true}
                  loader={potentialItems.length < totalCount && <h4 className="text-center">Đang tải...</h4>}>
                  {potentialItems?.map((potential) => (
                    <div key={potential.id} className="mb-16px">
                      <LeadsCard
                        data={potential}
                        type={input.type}
                        isDeleted={isDeleted}
                        isChecked={ids?.includes(potential?.id || 0)}
                        onPick={onPick}
                        onRemove={(id) => handleRemove(() => onRemove(id))}
                      />
                    </div>
                  ))}
                </InfiniteScroll>
              ) : (
                ''
              )}

              {!isLoading && potentialItems?.length === 0 && (
                <div className="flex-1">
                  <EmptyLeads />
                </div>
              )}
            </>
          </SpinLoader>
        </div>
      </div>
    </div>
  );
};
