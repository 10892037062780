import { Button } from '@mantine/core';
import { stringify } from 'qs';
import { memo, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { FormLabel } from '../../../components';
import { useDialog, useLeads } from '../../../contexts';
import { AppRoutes, getMessage } from '../../../helpers';
import { useQueryString } from '../../../hooks';
import { vi } from '../../../i18n/vi';
import {
  HealthCheckupEnum,
  InsuranceMethodEnum,
  LeadsInsuranceMethodFormData,
  useInitDirect,
} from '../../../services/leads';
import { LeadsMethodOptionType, MethodCardsSelectable } from '../components';
import { MedicalKitBoldSVG } from '../../../icons';

import { LeadsFormStepEnum, LeadsScreenParams } from './leads-form-screen';

export const fakeMethodOptions: LeadsMethodOptionType[] = [
  {
    value: InsuranceMethodEnum.REFER,
    label: 'Refer',
    point: 57,
    staticConditions: ['Các ĐVKD triển khai mô hình Refer', 'LLB chưa có Code bán Direct', 'Case size từ 50 triệu'],
    selectableConditions: [
      '74 ĐVKD triển khai mô hình Refer',
      'LLB đã có Code bán Direct',
      'Case size từ 20 đến 50 triệu',
      'KH phải đi khám SK theo yêu cầu MBAL',
    ],
    steps: ['Xác định khách hàng tiềm năng', 'Giới thiệu khách hàng về bảo hiểm', 'Chuyển thông tin sang IC tư vấn'],
    mbalSteps: [
      'Tư vấn/ chạy BMH',
      'Chốt deal HĐBH',
      'Hướng dẫn KH thanh toán, ký HĐBH',
      'Hỗ trợ KH đi khám sức khỏe &\n Bổ sung giấy tờ (nếu có)',
      'Chăm sóc sau bán',
    ],
  },
  {
    value: InsuranceMethodEnum.DIRECT,
    label: 'Direct',
    point: 80,
    staticConditions: ['74 ĐVKD triển khai mô hình Direct', 'LLB đã có Code bán Direct', 'Case size dưới 20 triệu'],
    selectableConditions: [
      '74 ĐVKD triển khai mô hình Direct',
      'LLB đã có Code bán Direct',
      'Case size từ 20 đến 50 triệu',
      'KH phải đi khám SK theo yêu cầu MBAL',
    ],
    steps: [
      'Xác định khách hàng tiềm năng',
      'Giới thiệu khách hàng về bảo hiểm',
      'Thiết kế bảng hình họa',
      'Tư vấn khách hàng',
      'Chốt deal, hướng dẫn KH thanh toán',
    ],
    mbalSteps: ['Hỗ trợ KH đi khám sức khỏe &\n Bổ sung giấy tờ (nếu có)', 'Chăm sóc sau bán'],
  },
];

export const LeadsMethodForm = memo(() => {
  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const { leadsId = 0, health_checkup } = useQueryString<LeadsScreenParams>();
  const { onUpdateLeadsInsuranceMethod, onUpdateLeadsCustomer, leadsCustomer } = useLeads();

  const { mutateAsync, isLoading } = useInitDirect({
    onError: (error) => {
      showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
    },
    onSuccess: (result) => {
      if (leadsCustomer) {
        onUpdateLeadsCustomer({ ...leadsCustomer, direct_id: result.direct_id, id: leadsId });
        navigate(AppRoutes.reverse_products);
      }
    },
  });

  const onSubmit = useCallback(
    (data: LeadsInsuranceMethodFormData) => {
      onUpdateLeadsInsuranceMethod(data?.insurance_method);
      if (data.insurance_method === InsuranceMethodEnum.REFER) {
        navigate({
          search: stringify({
            step: LeadsFormStepEnum.REFER,
            leadsId,
            health_checkup,
          }),
        });
      } else if (data.insurance_method === InsuranceMethodEnum.DIRECT) {
        mutateAsync({ potential_customer_id: Number(leadsId || 0) });
      }
    },
    [health_checkup, leadsId, mutateAsync, navigate, onUpdateLeadsInsuranceMethod],
  );

  const { handleSubmit, control } = useForm<LeadsInsuranceMethodFormData>({
    mode: 'onSubmit',
    defaultValues: { insurance_method: InsuranceMethodEnum.DIRECT },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="pb-120px">
        <>
          {health_checkup !== HealthCheckupEnum.NONE && (
            <div className="mb-16px rounded-8px bg-c_FFE5F3 p-16px flex gap-x-12px items-center">
              <MedicalKitBoldSVG />
              <span className="text-c_FB4390 text-14px">
                {health_checkup == HealthCheckupEnum.REQUIRED
                  ? 'Khách hàng phải đi khám sức khỏe theo yêu cầu MBAL'
                  : 'Khách hàng không phải đi khám sức khoẻ theo yêu cầu MBAL'}
              </span>
            </div>
          )}

          <FormLabel>Lực lượng bán linh hoạt lựa chọn 1 trong 2 luồng:</FormLabel>
          <Controller
            control={control}
            name="insurance_method"
            render={({ field: { onChange, value } }) => (
              <MethodCardsSelectable options={fakeMethodOptions} value={value} onChange={onChange} />
            )}
          />
        </>
      </div>

      <div className="bg-white pt-12px pb-34px px-16px fixed bottom-0 left-0 w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
        <Button type="submit" fullWidth loading={isLoading} disabled={isLoading}>
          Tiếp tục
        </Button>
      </div>
    </form>
  );
});
