import { memo } from 'react';

type Props = {
  important?: boolean;
  textContent: string;
  amount: string;
};

export const NoticeMessage = memo(({ important, textContent, amount }: Props) => (
  <p className="italic text-sm m-0">
    <span className={important ? 'text-c_FB4390' : 'opacity-0'}>(*)</span> {textContent}{' '}
    <span className="text-primary">{amount}</span> VND
  </p>
));
