import { Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  PropsWithChildren,
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';

import { ErrorSVG, RejectedSVG, SuccessSVG } from '../icons';

type DialogOption = {
  title?: ReactNode;
  message: ReactNode;
  confirmText?: ReactNode;
  textAlign?: 'center';
  isLoading?: boolean;
  icon?: ReactNode;
} & (
  | {
      type: 'CONFIRM';
      cancelText?: ReactNode;
    }
  | {
      type: 'ALERT';
      icon?: ReactNode;
    }
  | {
      type: 'SUCCESS';
      icon?: ReactNode;
      cancelText?: ReactNode;
    }
  | { type: 'TOAST' }
);

type ContextProps = {
  showDialog(option: DialogOption): Promise<boolean>;
};

const DialogContext = createContext<ContextProps>({
  showDialog() {
    throw new Error('not ready');
  },
});

export const useDialog = () => useContext(DialogContext);

export const DialogProvider = memo((props: PropsWithChildren) => {
  const [state, setState] = useState<DialogOption>();

  const dialogResolveRef = useRef<(val: boolean) => void>();

  const [opened, { open, close }] = useDisclosure(false);

  const showDialog = useCallback(
    function (dialogOption: DialogOption) {
      if (dialogResolveRef.current != null) dialogResolveRef.current = undefined;

      setState(dialogOption);
      open();

      return new Promise<boolean>((resolve) => {
        dialogResolveRef.current = resolve;
      });
    },
    [open],
  );

  const resolveDialog = useCallback(
    (val: boolean) => {
      dialogResolveRef.current?.(val);
      dialogResolveRef.current = undefined;
      close();
    },
    [close],
  );

  useEffect(() => {
    if (state?.type === 'TOAST' && opened) {
      const timeout = setTimeout(() => {
        close();
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [close, opened, state?.type]);

  const renderIcon = useMemo(() => {
    let icon = <ErrorSVG />;
    switch (state?.type) {
      case 'CONFIRM':
        icon = <RejectedSVG />;
        break;

      case 'SUCCESS':
        icon = <SuccessSVG />;
        break;

      default:
        break;
    }
    return icon;
  }, [state?.type]);

  return (
    <DialogContext.Provider value={{ showDialog }}>
      {props.children}
      <Modal
        opened={opened && state?.type !== 'TOAST'}
        onClose={() => resolveDialog(false)}
        withCloseButton={false}
        centered
        radius={16}>
        <div className="pb-10px pt-10px">
          <div className="text-center flex flex-col items-center justify-center">
            {state?.icon || renderIcon}
            <div className="mt-20px text-20px font-bold">{state?.title}</div>
            <div className="mt-10px text-15px font-medium">{state?.message}</div>
          </div>
          {state?.type === 'CONFIRM' && (
            <Button variant="light" fullWidth onClick={() => resolveDialog(false)} className="mt-30px">
              {state.cancelText ?? 'Huỷ'}
            </Button>
          )}
          <Button
            fullWidth
            onClick={() => resolveDialog(true)}
            className="mt-20px"
            loading={state?.isLoading}
            disabled={state?.isLoading ?? false}>
            {state?.confirmText ?? 'Xác nhận'}
          </Button>
          {state?.type === 'SUCCESS' && (
            <Button variant="light" fullWidth onClick={() => resolveDialog(false)} className="mt-12px">
              {state.cancelText ?? 'Huỷ'}
            </Button>
          )}
        </div>
      </Modal>
      {opened && state?.type === 'TOAST' && (
        <div
          className="fixed top-4 right-4 border-primary border border-solid z-50 bg-primary min-w-[200px] px-16px py-8px shadow-sm rounded-sm"
          onClick={() => resolveDialog(true)}>
          <span className="font-semibold text-white">{state.message}</span>
        </div>
      )}
    </DialogContext.Provider>
  );
});
