import { memo, useMemo } from 'react';
import { DateRangePickerProps, DateRangePicker as MantineDateRangePicker } from '@mantine/dates';

import { DATE_FORMAT } from '../../constants';

type Props = DateRangePickerProps;

export const DateRangePicker = memo(({ ...props }: Props) => {
  const maxDate = useMemo(() => {
    const dt = new Date();
    dt.setDate(dt.getDate());
    return dt;
  }, []);

  return (
    <MantineDateRangePicker
      placeholder="Từ ngày - đến ngày"
      inputFormat={DATE_FORMAT.dob}
      labelFormat={DATE_FORMAT.my}
      maxDate={maxDate}
      allowSingleDateInRange
      {...props}
    />
  );
});
