import { memo } from 'react';
import classNames from 'classnames';

import { AlertBlueSVG } from '../../../../icons';

interface ImplementationStepsProps {
  conditions?: string[];
  steps?: string[];
  mbalSteps?: string[];
  polygonClassName: string;
}

export const ImplementationSteps = memo(
  ({ steps, mbalSteps, conditions, polygonClassName }: ImplementationStepsProps) => {
    return (
      <>
        <div className="relative bg-white p-4 rounded-lg shadow-lead-shadow max-w-full">
          <div
            className={classNames(
              'w-0 h-0 border-solid border-transparent border-x-[8px] border-b-[14px] border-b-white',
              `${polygonClassName}`,
            )}></div>
          <div>
            <div className="flex gap-x-8px items-center">
              <AlertBlueSVG />
              <span className="font-semibold">Luồng bán áp dụng với:</span>
            </div>
            <ul className="list-disc pl-16px">
              {conditions?.map((condition, i) => (
                <li className="mb-3 text-sm" key={i}>
                  {condition}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-16px mb-12px flex gap-x-16px overflow-x-auto">
          {steps && (
            <div className="w-300px rounded-6px p-16px bg-white shrink-0 self-stretch">
              <span className="font-semibold">{steps.length} bước LLB thực hiện:</span>
              <ul className="list-none pl-0">
                {steps.map((step, i) => (
                  <li className="mb-3 text-sm flex items-center" key={i}>
                    <p className="m-0 mr-2 w-20px h-20px shrink-0 rounded-full bg-c_c5e6f5 text-primary flex-center text-12px text-center">
                      {i + 1}
                    </p>
                    <span>{step}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {mbalSteps && (
            <div className="w-300px rounded-6px p-16px bg-white shrink-0 self-stretch">
              <span className="font-semibold">IC MBAL thực hiện:</span>
              <ul className="list-none pl-0">
                {mbalSteps.map((step, i) => (
                  <li className="mb-3 text-sm flex items-center" key={i}>
                    <p className="m-0 mr-2 w-20px h-20px shrink-0 rounded-full bg-c_c5e6f5 text-primary flex-center text-12px text-center">
                      {i + 1}
                    </p>
                    <span>{step}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </>
    );
  },
);
