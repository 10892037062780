import { memo } from 'react';

import { FolderSVG } from '../../icons';

type Props = {
  title: string;
};

export const Empty = memo(({ title }: Props) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <FolderSVG />
      <div className="mt-20px">
        <span className="text-16px font-medium">{title}</span>
      </div>
    </div>
  );
});
