/* eslint-disable import/order */
import { Button } from '@mantine/core';
import { memo, useCallback, useContext, useMemo } from 'react';

import './table.less';

import { useNavigate } from 'react-router-dom';

import { CollapseHeader, insuranceTypes, ScreenParams } from '..';
import { useDialog, useLeads, usePdf } from '../../../../contexts';
import { EditSVG, TemplateSVG } from '../../../../icons';
import {
  AdditionalAssuredsEntity,
  GenderEnum,
  InsuranceTypeEnum,
  QuotationEntity,
  QuotationStateEnum,
  useConfirmQuotationMutation,
  useGetQRCodeMutation,
} from '../../../../services';

import { stringify } from 'qs';

import { FormStepEnum, MixQuestionsContext, useIllustration } from '../../form';

import dayjs from 'dayjs';
import { forEach } from 'lodash';
import { AppRoutes, getAge, getMessage } from '../../../../helpers';
import { useQueryString } from '../../../../hooks';
import { vi } from '../../../../i18n/vi';
import { SwitchFlowToReferButton } from '../../../leads/components';
import { BuyerInformation } from './buyer-information';
import { ProductInformation } from './product-information';
import { SummaryLifeInsuranceBenefits } from './summary-life-insurance-benefits';
import { SummaryMicHealthynsuranceBenefits } from './summary-mic-healthy-insurance-benefits';
import { SummaryTable } from './summary-table';

type Props = {
  insuranceType: InsuranceTypeEnum;
  quotation: QuotationEntity;
  mode?: 'edit' | 'new';
  onEdit?(): void;
};

export const InsuranceResult = memo(({ insuranceType, quotation, mode, onEdit }: Props) => {
  const { processId = 0 } = useQueryString<ScreenParams>();
  const navigate = useNavigate();
  const { onUpdateHealthPersons, onUpdateHealths, onUpdateCurrentAssured, onUpdateQuotation, customer_is_assured } =
    useIllustration();
  const { onUpdateLeadsHealths, onUpdateLeadsCurrentAssured } = useLeads();
  const { onResetMixQuestionsContext } = useContext(MixQuestionsContext);
  const selectedInsurance = useMemo(() => insuranceTypes.find((it) => it.type === insuranceType), [insuranceType]);
  const process_id = useMemo(
    () => Number(mode !== 'edit' ? processId : quotation?.process_id ?? quotation?.id ?? 0),
    [mode, processId, quotation?.id, quotation?.process_id],
  );

  const is_mic = useMemo(() => {
    let isBool = false;
    if (
      quotation?.customer?.mic_additional_product?.mic_benefit ||
      quotation?.assured?.mic_additional_product?.mic_benefit
    ) {
      isBool = true;
    }
    forEach(quotation?.additional_assureds, (it) => {
      if (it?.mic_additional_product?.mic_benefit) {
        isBool = true;
      }
    });
    return isBool;
  }, [quotation]);

  const overviews = useMemo(() => {
    return [
      {
        id: 1,
        title: 'Thông tin <br/>bên mua bảo hiểm',
        collapsed: true,
        data: () => <BuyerInformation quotation={quotation} />,
        isShow: true,
      },
      {
        id: 2,
        title: 'THÔNG TIN CƠ BẢN VỀ <br/>HỢP ĐỒNG BẢO HIỂM',
        collapsed: true,
        data: () => <ProductInformation quotation={quotation} />,
        description: () => {
          return (
            <div className="p-16px pt-0">
              <span className="text-primary text-16px font-semibold">Lưu ý</span>
              <div className="mt-12px">
                <ul className="p-start-20px text-14px text-c_767676">
                  <li>Thời hạn hợp đồng của sản phẩm chính là thời hạn bảo hiểm</li>
                  <li className="mt-16px">
                    Đối với sản phẩm chính, thời hạn đóng phí được thể hiện trong tài liệu này là Thời hạn đóng phí dự
                    kiến được khách hàng lựa chọn để thể hiện dòng tiền trong Bảng minh họa quyền lợi
                  </li>
                  <li className="mt-16px">
                    Phí bảo hiểm định kỳ của sản phẩm chính không thay đổi trong toàn bộ thời hạn hợp đồng và được đóng
                    đầy đủ trong 03 Năm Hợp đồng đầu tiên. Phí bảo hiểm đóng thêm được thể hiện tại bảng TÓM TẮT MINH
                    HỌA HỢP ĐỒNG.
                  </li>
                </ul>
              </div>
            </div>
          );
        },
        isShow: true,
      },
      {
        id: 3,
        title: 'Tóm tắt quyền lợi <br/>bảo hiểm nhân thọ',
        collapsed: true,
        description: () => <SummaryLifeInsuranceBenefits quotation={quotation} />,
        isShow: true,
      },
      {
        id: 4,
        title: 'Tóm tắt quyền lợi <br/>bảo hiểm sức khỏe',
        collapsed: true,
        description: () => <SummaryMicHealthynsuranceBenefits quotation={quotation} />,
        isShow: is_mic,
      },
      {
        id: is_mic ? 5 : 4,
        title: 'Tóm tắt minh hoạ <br/>hợp đồng bảo hiểm',
        description: () => <SummaryTable quotation={quotation} />,
        isShow: true,
      },
    ];
  }, [is_mic, quotation]);

  const { onOpenPdf } = usePdf();

  const handleDownloadPDF = useCallback(async () => {
    quotation && onOpenPdf(quotation.id);
  }, [onOpenPdf, quotation]);

  const { showDialog } = useDialog();
  const getHealthPerson = useCallback((type: string, data?: AdditionalAssuredsEntity) => {
    const age = getAge(dayjs(data?.dob ?? dayjs()));
    const isLess18LA = age < 18;
    let app_question_number = data?.app_question_number ?? 0;
    if (data?.mic_additional_product?.mic_request) {
      app_question_number = app_question_number == 3 ? 3 : app_question_number + 3;
    }
    const listAssured = {
      id: data?.uuid ?? data?.id ?? type,
      mini_assured_id: data?.uuid ?? data?.id ?? type,
      type,
      full_name: data?.full_name ?? '',
      gender: data?.gender ?? GenderEnum.MALE,
      app_question_number,
      is_display: isLess18LA,
      is_child: isLess18LA,
      age: age || 1,
      is_mic: !!data?.mic_additional_product?.mic_request,
      is_mbal: !!data?.app_question_number,
      from3_to12: false,
    };
    return listAssured;
  }, []);

  const getHealthPersons = useCallback(
    (data: QuotationEntity) => {
      const listAssured = getHealthPerson('main', data?.assured as AdditionalAssuredsEntity);
      const customer = getHealthPerson('customer', data?.customer as AdditionalAssuredsEntity);
      const additionalAssured =
        data?.additional_assureds?.map((it: AdditionalAssuredsEntity, index: number) =>
          getHealthPerson('additional_' + index, it),
        ) ?? [];

      let healths = [...[listAssured, customer], ...additionalAssured];
      if (customer_is_assured) {
        healths = [...[listAssured], ...additionalAssured];
      }
      onUpdateHealthPersons(healths);
      return healths;
    },
    [getHealthPerson, onUpdateHealthPersons, customer_is_assured],
  );

  const handleHealths = useCallback(
    (data: any) => {
      const healths = getHealthPersons(data)?.filter((it) => !!it.app_question_number);

      onUpdateHealths(healths);
      onUpdateLeadsHealths(healths);
      onUpdateLeadsCurrentAssured(healths?.[0]);
      onUpdateCurrentAssured(healths?.[0]);
      return healths?.length;
    },
    [getHealthPersons, onUpdateCurrentAssured, onUpdateHealths, onUpdateLeadsCurrentAssured, onUpdateLeadsHealths],
  );
  const { mutateAsync: getQRCodeAsync, isLoading: getQRCodeLoading } = useGetQRCodeMutation({
    onError: (err) => {
      showDialog({
        type: 'ALERT',
        message: getMessage(err),
      });
    },
    onSuccess: (res) => {
      if (mode === 'edit') {
        navigate({
          pathname: AppRoutes.qrCode,
          search: stringify({
            url: res?.url ?? '',
            date: quotation?.quotation_date,
          }),
        });
        return;
      }
      navigate({
        search: stringify({
          step: FormStepEnum.QR_CODE,
          url: res,
          date: quotation?.quotation_date,
        }),
      });
    },
  });
  const { mutateAsync: confirmQuotationAsync, isLoading: confirmLoading } = useConfirmQuotationMutation({
    onError: (error) => {
      showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
    },
    onSuccess: async (res: QuotationEntity) => {
      const newQuotation: QuotationEntity = {
        ...quotation,
        ...res,
      };
      onUpdateQuotation(newQuotation);
      handleHealths(newQuotation);
      onResetMixQuestionsContext();
      navigate({
        search: stringify({
          step: FormStepEnum.SALES,
          processId: process_id,
        }),
      });
    },
  });

  const onNext = useCallback(async () => {
    await confirmQuotationAsync(process_id);
  }, [confirmQuotationAsync, process_id]);

  const onDisplayQRCode = useCallback(async () => {
    await getQRCodeAsync({ processId: Number(process_id ?? 0) });
  }, [process_id, getQRCodeAsync]);

  const isDisabled = useMemo(() => getQRCodeLoading || confirmLoading, [getQRCodeLoading, confirmLoading]);
  return (
    <div className="p-16px pb-30px bg-white insurance-result">
      <div
        className="bg-cover relative rounded-12px overflow-hidden"
        style={{ backgroundImage: `url("${selectedInsurance?.image}")` }}>
        <div className="h-200px bg-cover relative bg-origin">
          <span className="text-c_E9F6FF text-20px font-bold absolute bottom-16px left-16px leading-[32px] uppercase block max-w-[305px]">
            {selectedInsurance?.label}
          </span>
        </div>
      </div>

      {overviews.map(
        (it) =>
          it.isShow && (
            <CollapseHeader
              key={it.id}
              icon={
                <div className="w-44px h-44px rounded-full flex-center bg-c_E2F5FE">
                  <span className="text-18px text-primary font-bold">{it.id}</span>
                </div>
              }
              title={
                <span
                  className="text-18px font-bold text-primary uppercase"
                  dangerouslySetInnerHTML={{ __html: it.title }}
                />
              }
              shadowDisabled
              defaultVisible={!it.collapsed}
              className="mt-12px -mx-16px">
              {it.data != null && (
                <div className="px-16px">
                  <div className="overflow-x-auto mt-12px mb-24px">{it.data?.()}</div>
                </div>
              )}
              <div>{it.description?.()}</div>
            </CollapseHeader>
          ),
      )}

      <div className="mt-20px">
        <div className="pt-20px flex flex-row items-center justify-between">
          <p className="text-15px font-semibold">Tổng phí bảo hiểm:</p>
          <p className="text-18px font-bold text-primary">{quotation?.total_amount_str ?? '-'}</p>
        </div>
        <div className="flex flex-col gap-y-16px">
          <SwitchFlowToReferButton disabled={isDisabled} />
          <Button fullWidth variant="light" onClick={handleDownloadPDF} disabled={isDisabled}>
            <TemplateSVG className="mr-4px" /> Xem bảng minh họa chi tiết
          </Button>
          {mode === 'edit' &&
            quotation?.type === InsuranceTypeEnum.ULRP_3_0 &&
            !!quotation?.state &&
            ![QuotationStateEnum.CREATED, QuotationStateEnum.COMPLETED, QuotationStateEnum.RE_COMPLETED].includes(
              quotation?.state,
            ) && (
              <Button fullWidth onClick={onDisplayQRCode} disabled={isDisabled}>
                Hiển thị mã QR
              </Button>
            )}
          {mode === 'edit' && (
            <Button fullWidth color="red" variant="light" onClick={onEdit}>
              <EditSVG /> Chỉnh sửa
            </Button>
          )}
          {mode !== 'edit' && insuranceType === InsuranceTypeEnum.ULRP_3_0 && (
            <Button fullWidth onClick={onNext} disabled={isDisabled} loading={getQRCodeLoading || confirmLoading}>
              Tiếp tục
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});
