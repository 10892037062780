import { PropsWithChildren, createContext, memo, useCallback, useContext, useRef, useState } from 'react';
import { Camera, CameraType } from 'react-camera-pro';

import { CameraFilledSVG, ChevronLeftSVG, ImageUploadSVG } from '../../icons';
import { dataURLtoFile } from '../../helpers';

export enum IdentificationFaceEnum {
  FRONT = 'FRONT',
  BACK = 'BACK',
}

type OCRInput = {
  face: IdentificationFaceEnum;
  person: string;
  imgUrl?: string;
  imgFile?: File;
};

type OCRContextProps = {
  person?: string;
  face?: IdentificationFaceEnum;
  onOpenOCR: (person?: string, face?: IdentificationFaceEnum) => void;
  capturedOCRs?: OCRInput[];
  onClearCapturedOCRs: (person?: string, face?: IdentificationFaceEnum) => void;
};

const OCRContext = createContext<OCRContextProps>({
  person: '',
  onOpenOCR() {
    throw new Error('not ready');
  },
  onClearCapturedOCRs() {
    throw new Error('not ready');
  },
});

export const OCRContextProvider = memo(({ children }: PropsWithChildren) => {
  // const webcamRef = useRef<Webcam>(null);
  const webcamRef = useRef<CameraType>(null);
  const inputFileRef = useRef(null);

  const [person, setPerson] = useState<string>();
  const [face, setFace] = useState<IdentificationFaceEnum>();
  const [capturedOCRs, setCapturedOCRs] = useState<OCRInput[]>();

  const handleOpenOCR = useCallback((person?: string, face?: IdentificationFaceEnum) => {
    setPerson(person);
    setFace(face);
  }, []);

  const updateCapturedOCRs = useCallback(
    (imgFile: File, imgUrl: string) => {
      if (face && person) {
        if (capturedOCRs) {
          if (!capturedOCRs.find((capturedOCR) => capturedOCR.face === face && capturedOCR.person === person)) {
            setCapturedOCRs([
              ...capturedOCRs,
              {
                face: face,
                person: person,
                imgUrl: imgUrl,
                imgFile: imgFile,
              },
            ]);
          } else {
            const updatedOCRs = capturedOCRs.map((capturedOCR) => {
              if (capturedOCR.face === face && capturedOCR.person === person) {
                return {
                  face: face,
                  person: person,
                  imgUrl: imgUrl,
                  imgFile: imgFile,
                };
              } else {
                return capturedOCR;
              }
            });
            setCapturedOCRs(updatedOCRs);
          }
        } else {
          setCapturedOCRs([
            {
              face: face,
              person: person,
              imgUrl: imgUrl,
              imgFile: imgFile,
            },
          ]);
        }
      }
    },
    [capturedOCRs, face, person],
  );

  const capture = useCallback(() => {
    // const imageSrc = webcamRef.current?.getScreenshot();
    const imageSrc = webcamRef.current?.takePhoto();
    if (imageSrc) {
      updateCapturedOCRs(dataURLtoFile(imageSrc, `${person}_${face}`), imageSrc);
      handleOpenOCR(undefined);
    }
  }, [face, handleOpenOCR, person, updateCapturedOCRs]);

  const handleFileUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        updateCapturedOCRs(file, URL.createObjectURL(file));
        handleOpenOCR(undefined);
      }
    },
    [handleOpenOCR, updateCapturedOCRs],
  );

  const onClearCapturedOCRs = useCallback(
    (person?: string, face?: IdentificationFaceEnum) => {
      if (person && face) {
        setCapturedOCRs(
          capturedOCRs?.filter((capturedOCR) => capturedOCR.face !== face || capturedOCR.person !== person),
        );
      } else {
        setCapturedOCRs(undefined);
      }
    },
    [capturedOCRs],
  );

  return (
    <OCRContext.Provider value={{ person, face, onOpenOCR: handleOpenOCR, capturedOCRs, onClearCapturedOCRs }}>
      {children}
      {person != null && (
        <div className="fixed top-0 left-0 z-10 w-screen min-h-screen bg-[#0A1A32]">
          <div className=" flex items-center px-20px py-8px fixed top-0 w-full z-10 text-white">
            <div
              className="h-24px flex-[0_1_58px]"
              onClick={() => {
                handleOpenOCR(undefined);
              }}>
              <ChevronLeftSVG className="h-12px" fill="white" />
            </div>
            <span className="font-bold flex-1 text-center">Chụp ảnh</span>
            <div className="text-sm flex-[0_1_58px] opacity-0">Lưu ảnh</div>
          </div>
          <div className="w-full h-full relative pt-40px text-white flex flex-col items-center">
            <input
              type="file"
              id="ocr-input-file"
              ref={inputFileRef}
              accept=".jpg, .jpeg, .png"
              className="hidden"
              onChange={handleFileUpload}
            />
            <span className="text-xs text-center mx-11 mt-16">
              Vui lòng đặt CMND, CCCD của bạn vào đúng khung hình và có ánh sáng rõ nét
            </span>
            {/* <Webcam
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              audio={false}
              videoConstraints={{ facingMode: 'environment' }}
              className="w-full h-[220px] my-5"
            /> */}
            <div className="w-[335px] h-[220px] my-5">
              <Camera
                aspectRatio={335 / 220}
                ref={webcamRef}
                facingMode="environment"
                errorMessages={{
                  noCameraAccessible: 'Không tìm thấy camera.',
                  permissionDenied: 'Chưa được cấp quyền camera.',
                  canvas: 'Canvas không được hỗ trợ.',
                }}
              />
            </div>

            <span className="font-semibold">{face === IdentificationFaceEnum.FRONT ? 'Mặt trước' : 'Mặt sau'}</span>
            <div
              className="py-2 px-5 border-solid border border-[#0033AB] rounded-lg flex items-center gap-x-[10px] h-9 w-fit text-sm font-se mt-10 mb-[73px]"
              onClick={() => (inputFileRef as any)?.current?.click()}>
              <ImageUploadSVG />
              Tải ảnh lên
            </div>
            <div
              onClick={capture}
              className="bg-gray-800 w-[72px] aspect-square rounded-full flex items-center justify-center">
              <CameraFilledSVG />
            </div>
          </div>
        </div>
      )}
    </OCRContext.Provider>
  );
});

export const useOCR = () => useContext(OCRContext);
