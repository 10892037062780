import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useQueryString } from '../../hooks';
import { FormStepEnum } from '../../screens/illustrations/form';

export function ScrollToTop() {
  const { pathname } = useLocation();

  const { step } = useQueryString<{ step: FormStepEnum }>();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (Object.values(FormStepEnum).includes(step)) {
      window.scrollTo(0, 0);
    }
  }, [pathname, step]);

  return <Outlet />;
}
