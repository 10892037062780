import { Button } from '@mantine/core';
import { stringify } from 'qs';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { FormLabel, FullscreenLoader, LeadsNavbar } from '../../../components';
import { useDialog, useInitialData, useLeads } from '../../../contexts';
import { AppRoutes, dateFormat, formatPhoneNumber, getMessage } from '../../../helpers';
import { vi } from '../../../i18n/vi';
import { AdditionalAssuredProductEnum, AdditionalMicProductEnum, InsuranceTypeEnum } from '../../../services';
import { LeadsCustomerFormData, LeadsFlowEnum, useCheckReferOrDirect, useGetLeadsId } from '../../../services/leads';
import { MoneyInputSuggest } from '../../illustrations/components/money-suggestion';
import { LeadsFormStepEnum } from '../form';

export const LeadsRowItem = memo<{ label: string; value?: string }>(({ label, value = '-' }) => {
  return (
    <div className="overflow-hidden">
      <span className="text-c_515151 text-sm font-normal break-all">{label}</span>
      <p className="text-base font-semibold text-c_111111 mt-1">{value}</p>
    </div>
  );
});
export const LeadDetail = memo(() => {
  const { id } = useParams();
  const { onUpdateLeadsCustomer } = useLeads();
  const navigate = useNavigate();
  const { showDialog } = useDialog();

  const { data, isLoading } = useGetLeadsId(id ?? '', {
    enabled: id !== null,
    onError: async (error) => {
      const err = await showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
      err && navigate(-1);
    },
    onSuccess: (res) => {
      onUpdateLeadsCustomer({
        ...res,
        id: Number(id),
      });
    },
  });

  const mbalData = useMemo(() => {
    return data?.cared_product?.sup_mbal?.map((item) => item.toString()) || [];
  }, [data?.cared_product?.sup_mbal]);

  const micData = useMemo(() => {
    return data?.cared_product?.mic?.map((item) => item.toString()) || [];
  }, [data?.cared_product?.mic]);

  const { control, setValue } = useForm<LeadsCustomerFormData>({
    defaultValues: useMemo(() => {
      return {
        input_amount: data?.input_amount,
        cared_product: {
          mbal: [InsuranceTypeEnum.ULRP_3_0],
        },
      };
    }, [data?.input_amount]),
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('cared_product.sup_mbal', mbalData as AdditionalAssuredProductEnum[]);
    setValue('cared_product.mic', micData as AdditionalMicProductEnum[]);
  }, [mbalData, micData, setValue]);

  const { mutateAsync: checkReferOrDirect, isLoading: isLoadingCheck } = useCheckReferOrDirect({
    onError: (error) => {
      showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
    },
    onSuccess: (result, input) => {
      navigate({
        pathname: AppRoutes.leads_new,
        search: stringify({
          step: LeadsFormStepEnum.LOAN_INFO,
          flow: result?.flow == LeadsFlowEnum.ALL ? LeadsFormStepEnum.METHOD : result?.flow || LeadsFormStepEnum.DIRECT,
          leadsId: input?.id,
          health_checkup: result?.health_checkup,
        }),
      });
    },
  });

  const onNext = useCallback(() => {
    if (!id) return;
    checkReferOrDirect({ id, req: { amount: data?.input_amount || 0 } });
  }, [checkReferOrDirect, data?.input_amount, id]);

  const { getOccupation } = useInitialData();

  if (isLoading) {
    return <FullscreenLoader />;
  }

  if (data == null) return null;

  return (
    <div>
      <div>
        <LeadsNavbar title="Chi tiết thông tin khách hàng" />
      </div>
      <>
        <div className="bg-c_F0F4F7 pb-20px">
          <div className="bg-white px-16px pb-24px pt-60px">
            <div>
              <p className="text-base text-primary font-bold uppercase">Thông tin khách hàng</p>
            </div>
            <LeadsRowItem label="Họ và tên" value={data?.full_name} />
            <LeadsRowItem label="Ngày sinh" value={dateFormat(data?.dob)} />
            <LeadsRowItem label="Giới tính" value={vi.gender[data?.gender]} />
            <LeadsRowItem label="Nghề nghiệp" value={getOccupation(data?.occupation_id)?.name_vn} />
            <LeadsRowItem label="Số điện thoại" value={formatPhoneNumber(data?.phone_number)} />
            <LeadsRowItem label="Loại giấy tờ tùy thân" value={vi.identification_type[data?.identification_type]} />
            <LeadsRowItem label="Số giấy tờ tùy thân" value={data?.identification_id || '...'} />
            <LeadsRowItem label="Ghi chú" value={data?.note || '...'} />
          </div>
          <div className="bg-white px-16px pb-24px mt-4 ">
            <div className="py-4">
              <div className="pointer-events-none">
                <div>
                  <p className="text-base text-primary font-bold uppercase">Thông tin bổ sung</p>
                </div>

                <div>
                  <>
                    <FormLabel required>Số tiền có thể tham gia bảo hiểm/năm</FormLabel>
                    <Controller
                      control={control}
                      name="input_amount"
                      render={({ field: { onChange, onBlur } }) => (
                        <MoneyInputSuggest
                          placeholder="Nhập số tiền có thể chi trả"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={data?.input_amount}
                        />
                      )}
                    />
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white pt-12px pb-9 px-16px bottom-0 left-0 w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
          <div>
            <Button
              onClick={() => navigate(AppRoutes.leads_edit(id))}
              className="w-full border-none py-[9px] bg-c_E2F5FE text-sm text-primary font-semibold rounded-md mb-2">
              Chỉnh sửa
            </Button>
          </div>
          <div>
            <Button fullWidth loading={isLoadingCheck} disabled={isLoadingCheck} onClick={onNext}>
              Tiếp tục
            </Button>
          </div>
        </div>
      </>
    </div>
  );
});
