import { SelectItem } from '@mantine/core';

import { LeadsContractState, DirectStateEnum, ReferStateEnum, ReferStatusColor } from '../../../services';

export const leadsContractStateOptions: SelectItem[] = [
  {
    label: 'Chưa hoàn thành',
    value: LeadsContractState.DRAFT,
    order: 1,
    bgColor: 'bg-c_ffecf4',
    fillSVG: '#FB4390',
    text: 'text-c_FB4390',
  },
  {
    label: 'Đã nộp HSYCBH',
    value: LeadsContractState.SUBMITTED,
    order: 2,
    fillSVG: '#2B33D8',
    text: 'text-primary',
  },
  {
    label: 'Chờ phát hành',
    value: LeadsContractState.PENDING,
    order: 3,
  },
  {
    label: 'Phát hành thành công',
    value: LeadsContractState.COMPLETED,
    order: 4,
    bgColor: 'bg-c_e5f8eb',
    fillSVG: '#00BC3C',
    text: 'text-c_00BC3C',
  },
  {
    label: 'Từ chối',
    value: LeadsContractState.REJECTED,
    order: 5,
  },
];
export const directStateOptions: SelectItem[] = [
  {
    value: DirectStateEnum.DRAFT,
    label: 'Đã nộp',
  },
  {
    value: DirectStateEnum.PENDING,
    label: 'Chờ phát hành',
  },
  {
    value: DirectStateEnum.CREATE_BMHN,
    label: 'CREATE_BMHN',
  },
  {
    value: DirectStateEnum.CREATE_BMHX,
    label: 'CREATE_BMHX',
  },
  {
    value: DirectStateEnum.INIT_DIRECT,
    label: 'INIT_DIRECT',
  },
];

export const referStateOptions: SelectItem[] = [
  {
    label: 'Đang chuyển thông tin cho IC',
    description: 'Tạo mới, chờ xác nhận',
    value: ReferStateEnum.SENT,
    order: 1,
    bgColor: 'bg-c_ffecf4',
    fillSVG: '#FB4390',
    colorStatus: ReferStatusColor.S_1,
    text: 'text-c_FB4390',
  },
  {
    label: 'IC đã nhận',
    description: 'Đã nhận',
    value: ReferStateEnum.RECEIVED,
    order: 2,
    bgColor: 'bg-c_eaebfb',
    fillSVG: '#2B33D8',
    colorStatus: ReferStatusColor.S_3,
    text: 'text-primary',
  },
  {
    label: 'Liên hệ KH',
    description: 'Gọi điện/email, Đã gặp',
    value: ReferStateEnum.CONTACTED,
    order: 3,
    bgColor: 'bg-c_fffae8',
    fillSVG: '#FFCC18',
    colorStatus: ReferStatusColor.S_4,
    text: 'text-c_FFCC18',
  },
  {
    label: 'Chờ phát hành HĐBH',
    description: 'Đã nộp HSYCBH',
    value: ReferStateEnum.WAITTING,
    order: 4,
    bgColor: 'bg-c_ffecf4',
    fillSVG: '#FB4390',
    colorStatus: ReferStatusColor.S_7,
    text: 'text-c_FB4390',
  },
  {
    label: 'Phát hành HĐBH',
    description: 'Đã phát hành, Có số HĐBH',
    value: ReferStateEnum.COMPLETED,
    order: 5,
    bgColor: 'bg-c_e5f8eb',
    fillSVG: '#00BC3C',
    colorStatus: ReferStatusColor.S_8,
    text: 'text-c_00BC3C',
  },
  {
    label: 'IC nhập lỗi',
    description: 'Chờ phân giao RM, RM trả lại, Trả lại',
    value: ReferStateEnum.ERROR,
    order: 6,
    bgColor: 'bg-c_fffae8',
    fillSVG: '#FFCC18',
    colorStatus: ReferStatusColor.S_10,
    text: 'text-c_FFCC18',
  },
  {
    label: 'KH từ chối',
    value: ReferStateEnum.REJECT,
    order: 7,
    bgColor: 'bg-c_E5E4E2',
    fillSVG: '#818589',
    colorStatus: ReferStatusColor.S_11,
    text: 'text-c_818589',
  },
];
export enum LoanTypeEnum {
  YES = 'YES',
  NO = 'NO',
  NONE = 'NONE',
}
export type LoanInformationFormData = {
  loan?: LoanTypeEnum;
  packageType?: string;
};
