import { useCallback, useState } from 'react';

import { LeadsCustomerFormData, LeadsFlowEnum, ReferOrDirectTabEnum, useDeleteLeadsMutation } from '../../../services';
import { useDialog } from '../../../contexts';
import { vi } from '../../../i18n/vi';
import { getMessage } from '../../../helpers';

type Props = {
  type: LeadsFlowEnum | ReferOrDirectTabEnum;
  data?: LeadsCustomerFormData[];
  refetch?: () => void;
};

export const useLeadsHook = ({ type, data, refetch }: Props) => {
  const [ids, setIds] = useState<number[]>([]);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const { showDialog } = useDialog();

  const { mutate: deleteLeadsAsync, isLoading } = useDeleteLeadsMutation({
    onError: (error) => {
      showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
    },
    onSuccess: () => {
      refetch?.();
      handleDelete();
      setIsDeleted(false);
    },
  });

  const onPickAll = useCallback(
    (value: any) => {
      const newIds: any[] = data?.map((el) => el.id) || [];
      if (value && newIds?.length) {
        setIds(newIds);
      } else {
        setIds([]);
      }
    },
    [data],
  );

  const onPick = useCallback(
    (id: number) => {
      let newIds: number[] = [...ids];
      const findIndex = newIds?.findIndex((el) => el === id);
      if (findIndex > -1) {
        newIds = newIds.filter((el) => el !== id);
      } else {
        newIds = [...newIds, id];
      }
      setIds(newIds);
    },
    [ids],
  );

  const handleDelete = useCallback(() => {
    setIsDeleted((prev) => !prev);
    onPickAll(false);
    setIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPickAll, isDeleted, ids]);

  const onDelete = useCallback(() => {
    deleteLeadsAsync({ ids, type });
  }, [deleteLeadsAsync, ids, type]);

  const handleRemove = useCallback(
    async (resolve: () => void) => {
      const res = await showDialog({
        message: 'Nếu bạn xác nhận xoá, dữ liệu của bản ghi sẽ không thể khôi phục.',
        type: 'CONFIRM',
      });

      res && resolve();
    },
    [showDialog],
  );

  const onRemove = (id: number) => {
    deleteLeadsAsync({
      ids: [id],
      type,
    });
  };

  return {
    handleDelete,
    onPickAll,
    onPick,
    ids,
    onDelete,
    deleteLoading: isLoading,
    handleRemove,
    onRemove,
    isDeleted,
  };
};
