import classNames from 'classnames';
import React, { useMemo } from 'react';

import { ShieldUserSVG } from '../../../../icons';
import { useIllustration } from '../../form';

export const UserComponent = React.memo(() => {
  const { currentAssured } = useIllustration();
  const assuredType = useMemo(() => {
    let t = 'Người được Bảo hiểm bổ sung';
    switch (currentAssured?.type) {
      case 'main':
        t = 'Người được Bảo hiểm chính';
        break;
      case 'customer':
        t = 'Người mua';
        break;
      default:
        break;
    }
    return t;
  }, [currentAssured]);
  return (
    <div className="flex items-center bg-c_E2F5FE rounded-8px p-16px my-20px">
      <div className={classNames(['rounded-full flex-center bg-white mr-12px flex-shrink-0', 'w-44px h-44px'])}>
        <ShieldUserSVG />
      </div>

      <div className="flex-1 pl-12px">
        <p className="font-medium text-c_161616 opacity-70 mt-0 mb-4px">{assuredType}</p>
        <h4 className="font-semibold text-16px text-c_161616 m-0">{currentAssured?.full_name}</h4>
      </div>
    </div>
  );
});
