import { PropsWithChildren, memo } from 'react';

import { PdfViewNavbar } from '../navbar';

type Props = PropsWithChildren<{
  title: string;
  onLeft?: () => void;
  onRight?: () => void;
}>;

export const PdfViewLayout = memo(({ title, children, onLeft, onRight }: Props) => {
  return (
    <div className="w-screen min-h-screen bg-c_61686B">
      <PdfViewNavbar title={title} onLeft={onLeft} onRight={onRight} />
      <div className="w-full h-full relative pt-48px">{children}</div>
    </div>
  );
});
