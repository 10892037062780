import { Checkbox, SelectItem } from '@mantine/core';
import dayjs from 'dayjs';
import { stringify } from 'qs';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes, noop } from '../../helpers';
import { TimerSVG, TrashSVG } from '../../icons';
import { QuotationSearchContentEntity, QuotationStateEnum } from '../../services';

type Props = QuotationSearchContentEntity & {
  isDeleted?: boolean;
  isChecked?: boolean;
  onPick?: (id: number) => void;
  onRemove: (id: number) => void;
};

const quotationState: SelectItem[] = [
  {
    value: QuotationStateEnum.CREATED,
    label: 'Tạo BMH thành công',
  },
  {
    value: QuotationStateEnum.SUBMITTED,
    label: 'Đã nộp HSYCBH',
  },
  {
    value: QuotationStateEnum.CONFIRMED,
    label: 'Đã xác nhận BMH',
  },
  {
    value: QuotationStateEnum.COMPLETED,
    label: 'Đã thanh toán thành công',
  },
  {
    value: QuotationStateEnum.UN_MATCH,
    label: 'Thông tin không trùng khớp',
  },
  {
    value: QuotationStateEnum.RE_CREATED,
    label: 'Tạo BMH thành công',
  },
  {
    value: QuotationStateEnum.RE_SUBMITTED,
    label: 'Đã nộp HSYCBH',
  },
  {
    value: QuotationStateEnum.RE_CONFIRMED,
    label: 'Đã xác nhận BMH',
  },
  {
    value: QuotationStateEnum.RE_COMPLETED,
    label: 'Đã thanh toán thành công',
  },
];

export const CustomerCard = memo<Props>(
  ({
    id,
    isDeleted,
    isChecked,
    name,
    phone_number,
    email,
    created_date,
    quotation_state,
    onPick,
    onRemove,
    combo_name,
  }) => {
    const navigate = useNavigate();

    const onDirect = () => {
      if (isDeleted) {
        onPick?.(id);
      } else if (combo_name) {
        navigate({
          pathname: AppRoutes.reverse_result(id.toString()),
          search: stringify({
            combo_name: combo_name?.toLocaleUpperCase(),
          }),
        });
      } else {
        navigate(AppRoutes.illustration(id.toString()));
      }
    };

    return (
      <div className="rounded-6px bg-white border-0.5px border-solid border-c_DDDDDD p-16px flex" onClick={onDirect}>
        <div>
          <span className="text-16px font-semibold block">
            {name ?? combo_name}
            {!!phone_number && <span className="font-normal"> - {phone_number}</span>}
          </span>
          <span className="font-medium text-c_161616 block opacity-50">{email}</span>
          {quotation_state && (
            <div className="mt-4px">
              <span className="text-sm font-medium">
                Trạng thái:{' '}
                <span className="text-primary">
                  {quotationState.find((item) => item.value === quotation_state)?.label}
                </span>
              </span>
            </div>
          )}
          <span className="block mt-8px">
            <TimerSVG className="relative top-2px" /> {dayjs(created_date).format('DD/MM/YYYY')}
          </span>
        </div>
        {isDeleted ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onDirect();
            }}
            className="ml-auto flex-center">
            <Checkbox checked={isChecked} onChange={noop} />
          </div>
        ) : (
          <div className="ml-auto flex-center">
            <TrashSVG
              onClick={(e) => {
                e.stopPropagation();
                onRemove(id);
              }}
            />
          </div>
        )}
      </div>
    );
  },
);
