import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextInput } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { FormLabel } from '../../../components';
import { SpinLoader } from '../../../components/spin-loader';
import { useDialog, useLeads } from '../../../contexts';
import { AppRoutes, getMessage, validationMessages } from '../../../helpers';
import { useCurrentUser, useQueryString } from '../../../hooks';
import { vi } from '../../../i18n/vi';
import { PlusSVG, SearchSVG } from '../../../icons';
import {
  IC,
  InsuranceMethodEnum,
  LeadsReferFormData,
  useGetAllICs,
  useReferCustomerToSale,
  useSearchICcode,
} from '../../../services/leads';
import { MethodCardsSelectable, RadioGroupICs } from '../components';
import { ModalButton } from '../components/modal-button';

import { LeadsScreenParams } from './leads-form-screen';
import { fakeMethodOptions } from './leads-method-form';

export const LeadsReferForm = memo(() => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const { data: dataICs, isLoading: isLoadingICs } = useGetAllICs();

  const [dataICsDisplayed, setDataICDisplayed] = useState<IC[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [selectedIC, setSelectedIC] = useState<string | number | boolean | undefined>(undefined);

  useEffect(() => {
    setDataICDisplayed(dataICs as IC[]);
  }, [dataICs]);

  const { leadsId = 0, reason } = useQueryString<LeadsScreenParams>();
  const user = useCurrentUser();
  const { leadsCustomer } = useLeads();

  const id = useMemo(() => Number(leadsId || leadsCustomer?.id || 0), [leadsId, leadsCustomer]);

  const [keywordDebounce] = useDebouncedValue(keyword, 1000);
  const validationSchema = yup.object({
    ic_code: yup.string().required(validationMessages.required),
  });
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const { data: searchICData, isLoading: isLoadingICSearch } = useSearchICcode(
    {
      icCode: keywordDebounce,
    },
    {
      enabled: keywordDebounce.length === 10,
      onError: () => {
        showDialog({
          type: 'ALERT',
          title: vi.notification,
          message: 'Thông tin nhân viên tư vấn bảo hiểm không hợp lệ. Bạn vui lòng nhập lại thông tin hợp lệ',
        });
      },
    },
  );

  const { mutateAsync: handleReferCustomerToSale, isLoading: isLoadingSubmit } = useReferCustomerToSale({
    onError: (error) => {
      showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
    },
    onSuccess: async (response) => {
      const res = await showDialog({
        type: 'SUCCESS',
        message: vi.referCustomerToSale_success,
        confirmText: 'Xem chi tiết',
        cancelText: 'Đóng',
      });
      res && navigate(AppRoutes.leads_refer_detail(response?.id?.toString()));
      !res && navigate(AppRoutes.home);
    },
  });

  const onSubmit = useCallback(
    (data: LeadsReferFormData) => {
      const chosenIC = dataICsDisplayed?.find((ic) => {
        return ic.code === data.ic_code;
      });
      if (chosenIC?.code && chosenIC?.full_name) {
        handleReferCustomerToSale({
          id,
          req: {
            ic_code: chosenIC.code,
            name: chosenIC.full_name,
            phone_number: chosenIC.phone_number,
            reason,
          },
        });
      }
    },
    [dataICsDisplayed, handleReferCustomerToSale, id, reason],
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LeadsReferFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const handleSubmitError = useCallback(() => {
    showDialog({
      type: 'ALERT',
      message: 'Vui lòng kiểm tra lại thông tin đã nhập',
    });
  }, [showDialog]);

  const addRadioGroup = useCallback(() => {
    if (searchICData) {
      const newIC = searchICData?.[0];
      if (newIC?.code) {
        if ((dataICsDisplayed ?? [])?.some((ic) => ic.code === newIC.code)) {
          showDialog({
            message: 'Mã IC này đã tồn tại trong danh sách!',
            type: 'ALERT',
          });
        } else {
          setDataICDisplayed([newIC, ...(dataICsDisplayed ?? [])]);
          setSelectedIC(newIC?.code);
        }
      }
    }
    setIsModalOpen(false);
    setKeyword('');
  }, [dataICsDisplayed, searchICData, showDialog]);

  return (
    <div className="pb-120px">
      <div className="pb-16px px-16px">
        <MethodCardsSelectable options={fakeMethodOptions} value={InsuranceMethodEnum.REFER} disabled />
      </div>
      <form onSubmit={handleSubmit(onSubmit, handleSubmitError)}>
        <div className="mt-12px -mx-4 py-24px px-16px bg-white flex flex-col gap-y-16px">
          <div className="flex justify-between items-center">
            <span className="text-base font-semibold uppercase">Chọn IC:</span>
            <Button
              onClick={openModal}
              className="flex items-center justify-center rounded-3xl h-10 text-primary font-normal text-sm bg-c_eaebfb pl-2 pr-3 py-5px"
              leftIcon={<PlusSVG width={20} height={20} />}>
              Thêm mới
            </Button>
          </div>
          <ModalButton
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            title="Thêm mới IC"
            addRadioGroup={addRadioGroup}
            isLoading={isLoadingICSearch}>
            <div className="mb-4">
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    rightSection={<SearchSVG />}
                    placeholder="Nhập mã IC"
                    className="w-full p-2 rounded-md px-0"
                    onBlur={() => {
                      const val = value?.trim();
                      onChange(val);
                    }}
                    onChange={(e) => {
                      const value = e.target.value.toUpperCase();
                      setKeyword(value);
                    }}
                    value={keyword}
                    maxLength={10}
                  />
                )}
                name="ic_search"
              />
            </div>
            <div>
              <SpinLoader spining={isLoadingICSearch}>
                {searchICData && <RadioGroupICs options={searchICData} />}
              </SpinLoader>
            </div>
          </ModalButton>
          <SpinLoader spining={isLoadingICs}>
            {dataICsDisplayed?.length > 0 && (
              <Controller
                control={control}
                name="ic_code"
                render={({ field: { onChange } }) => (
                  <RadioGroupICs
                    options={dataICsDisplayed}
                    value={selectedIC}
                    onChange={(newValue) => {
                      onChange(newValue);
                      setSelectedIC(newValue);
                    }}
                    error={errors?.ic_code?.message}
                  />
                )}
              />
            )}
          </SpinLoader>
        </div>

        <div className="mt-12px -mx-4 py-24px px-16px flex flex-col gap-y-16px bg-white">
          <span className="text-base font-semibold uppercase">Thông tin lực lượng bán</span>
          <div>
            <FormLabel>Nguời giới thiệu</FormLabel>
            <TextInput placeholder="Lực lượng bán hàng MBXXX" disabled value={user?.rm_info?.code} />
            <TextInput
              placeholder="Tên người giới thiệu"
              disabled
              value={user?.rm_info?.full_name || user?.rm_info?.name}
            />
            <TextInput placeholder="Số điện thoại" disabled value={user?.rm_info?.phone_number} />
            <TextInput placeholder="Nhập email" disabled value={user?.rm_info?.email} />
          </div>
          <div className="-mt-12px">
            <FormLabel>Đơn vị kinh doanh</FormLabel>
            <TextInput
              placeholder="Đơn vị kinh doanh"
              disabled
              value={user?.rm_info?.branch_name || user?.rm_info?.branch_code}
            />
          </div>
        </div>

        <div className="bg-white pt-12px pb-34px px-16px fixed bottom-0 left-0 w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
          <Button type="submit" fullWidth loading={isLoadingICs || isLoadingSubmit}>
            Chuyển sang IC tư vấn
          </Button>
        </div>
      </form>
    </div>
  );
});
