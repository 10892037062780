import { PropsWithChildren, createContext, memo, useCallback, useContext, useMemo } from 'react';

import { Occupation, useGetAllOccupationQuery } from '../services';
import { ProgressLoading } from '../components';

type ContextProps = {
  defaultOccupation?: Occupation;
  studentOccupation?: Occupation;
  occupations: Occupation[];
  getOccupation(id: number): Occupation | undefined;
};

const InitialDataContext = createContext<ContextProps>({
  occupations: [],
  getOccupation() {
    throw new Error('not-ready');
  },
});
export const DEFAULT_JOB = 'Nhân viên văn phòng';

export const DEFAULT_NATIONALITY = 'VN';

type Props = PropsWithChildren;

export const InitialDataProvider = memo(({ children }: Props) => {
  const { data: occupations = [], isLoading } = useGetAllOccupationQuery();

  const getOccupation = useCallback((id: number) => occupations.find((it) => it.id === id), [occupations]);
  const defaultOccupation = useMemo(() => occupations?.find((it) => it?.name_vn === DEFAULT_JOB), [occupations]);
  const studentOccupation = occupations.find((it) => it.name_vn === 'Sinh viên, học sinh');

  const contextValue = useMemo(
    () => ({ occupations, getOccupation, defaultOccupation, studentOccupation }),
    [occupations, getOccupation, defaultOccupation, studentOccupation],
  );

  return (
    <InitialDataContext.Provider value={contextValue}>
      {isLoading ? <ProgressLoading /> : children}
    </InitialDataContext.Provider>
  );
});

export const useInitialData = () => useContext(InitialDataContext);
