import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';

import { request } from '../helpers';
import { ApiUrl } from '../helpers/api-url';

import { GenderEnum } from './quotation';
import { APIError } from './types';

const QUERY_KEY = 'COMBO';

export enum ComboEnum {
  SIGNATURE = 'SIGNATURE',
  MUST_TRY = 'MUST_TRY',
  SPECIAL_OFFER = 'SPECIAL_OFFER',
  FLEXIBLE = 'FLEXIBLE',
}

export type ReverseCustomerFormData = {
  gender: GenderEnum;
  input_amount: number;
  dob: string;
};

type ComboData = {
  combo_code: string;
  dob: string;
  gender: string;
  input_amount: number;
};

export const useComboDownloadPDFQuery = (id: string | number, options?: UseQueryOptions<Blob, APIError>) => {
  return useQuery(
    [QUERY_KEY, id, 'pdf'] as QueryKey,
    () =>
      request
        .get(ApiUrl.combo + '/' + id + '/download', {
          responseType: 'blob',
        })
        .then((res) => res.data),
    options,
  );
};

export const useComboSuggestionMutation = (options?: UseMutationOptions<string, APIError, ComboData>) => {
  return useMutation(
    (data: ComboData) =>
      request.post(ApiUrl.combo + '/suggestion', data).then((res) => {
        return res?.data?.id;
      }),
    options,
  );
};
