import { memo, useMemo, useState } from 'react';
import classNames from 'classnames';

import { GenderEnum, QuotationEntity } from '../../../../services';
import { ManSVG, StarSVG, WomanSVG } from '../../../../icons';
import { numberWithCommas } from '../../../../helpers';
import { additionalAssuredTypes, insuranceTypes } from '../insurance-types';
import { supportingInsuranceOptions } from '../../form/insurance-detail-form';

type Props = {
  quotation: QuotationEntity;
};

export const SummaryLifeInsuranceBenefits = memo(({ quotation }: Props) => {
  const [activeUser, setActiveUser] = useState<string>('-1');

  const benefits = insuranceTypes.find((it) => it.type === quotation.type)?.benefits;

  const data = useMemo(
    () => [
      {
        ...quotation.assured,
        id: '-1',
        type: 'main',
        content: (
          <div className="mb-16px">
            <div className="mb-16px">
              <div className="flex items-center">
                <StarSVG className="mr-4px flex-shrink-0" />
                <span className="leading-none text-18px font-semibold relative top-2px">
                  {' '}
                  Quyền lợi bảo hiểm cơ bản
                </span>
              </div>
              <div className="text-14px p-16px bg-c_F5F6F7 rounded-8px mt-16px">
                <div className="font-semibold">Quyền lợi bảo hiểm Tử vong/ TTTBVV (*)</div>
                <div className="font-semibold text-primary mt-8px">
                  Số tiền bảo hiểm: {numberWithCommas(quotation.package_sum_assured)}
                </div>
              </div>
              {benefits?.slice(1, 4).map((it: any) => (
                <div className="text-14px p-16px bg-c_F5F6F7 rounded-8px mt-16px" key={it.title}>
                  <div className="font-semibold">{it.title}</div>
                  <div className="mt-8px text-c_4C5466">{it.content}</div>
                </div>
              ))}
            </div>
            {quotation.assured.additional_products.map((it, index) => (
              <div key={it.type + index + 'main'} className="mb-16px">
                <div className="flex items-center">
                  <StarSVG className="mr-4px flex-shrink-0" />
                  <span className="leading-none text-18px font-semibold relative top-2px">
                    {supportingInsuranceOptions.find((si) => si.value === it.type)?.label}
                  </span>
                </div>
                {additionalAssuredTypes
                  .find((at) => at.type === it.type)
                  ?.benefits.map((it) => (
                    <div className="text-14px p-16px bg-c_F5F6F7 rounded-8px mt-16px" key={it.title}>
                      <div className="font-semibold">{it.title}</div>
                      <div className="mt-8px text-c_4C5466">{it.content}</div>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        ),
      },
      ...[
        ...(quotation?.customer?.additional_products?.length && !quotation?.customer_is_assured
          ? [quotation.customer]
          : []),
        ...(quotation?.additional_assureds ?? []),
      ].map((it, index) => ({
        id: index.toString(),
        ...it,
        type: 'additional',
        content: it.additional_products.map((p, i) => (
          <div key={p.type + index + ' ' + i} className="mt-16px">
            <div className="flex items-center">
              <StarSVG className="mr-4px flex-shrink-0" />
              <span className="leading-none text-18px font-semibold relative top-2px">
                {supportingInsuranceOptions.find((si) => si.value === p.type)?.label}
              </span>
            </div>
            {additionalAssuredTypes
              .find((at) => at.type === p.type)
              ?.benefits.map((it) => (
                <div className="text-14px p-16px bg-c_F5F6F7 rounded-8px mt-16px" key={it.title}>
                  <div className="font-semibold">{it.title}</div>
                  <div className="mt-8px text-c_4C5466">{it.content}</div>
                </div>
              ))}
          </div>
        )),
      })),
    ],
    [benefits, quotation],
  );

  const newData = useMemo(
    () =>
      data.filter((it) => {
        if (it.type !== 'main' && !it?.additional_products?.length) {
          return null;
        }
        return it;
      }),
    [data],
  );

  return (
    <div className="px-20px">
      <div className="flex -m-4px pb-10px flex-nowrap overflow-x-auto">
        {newData?.map((it) => (
          <div className="flex-shrink-0 p-4px" key={it.id}>
            <div
              className={classNames(
                'py-12px px-16px flex border border-solid rounded-4px',
                activeUser === it.id ? 'border-primary' : 'border-c_EEEEEE',
              )}
              onClick={() => setActiveUser(it.id)}>
              <div className="flex-shrink-0 mr-16px">{it.gender === GenderEnum.FEMALE ? <WomanSVG /> : <ManSVG />}</div>
              <div className="flex-shrink-0">
                <div className="text-primary font-semibold">{it.full_name}</div>
                <div className="text-12px">{it.id === '-1' ? 'NĐBH chính' : 'NĐBH bổ sung'}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-20px">{newData.find((it) => it.id === activeUser)?.content}</div>
    </div>
  );
});
