import { compact, forEach } from 'lodash';
import { memo, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { FullscreenLoader } from '../../../components';
import { useDialog } from '../../../contexts';
import { vi } from '../../../i18n/vi';
import { HealthInfoRequest, MicRequestDTO, QuotationEntity, useGetQuotationByIdQuery } from '../../../services';
import { IllustrationFormScreen, MixQuestionsContext } from '../form';
import { useCurrentUser } from '../../../hooks';

export const defaultMicBenefits = ['benefits_1', 'benefits_2', 'benefits_3'];
export const IllustrationEdit = memo(() => {
  const { id } = useParams<{ id: string }>();
  const user = useCurrentUser();

  const { showDialog } = useDialog();
  const { handleDataDefine } = useContext(MixQuestionsContext);

  const { isLoading, data } = useGetQuotationByIdQuery(id ?? '', {
    enabled: id != null,
    onError: (error) => {
      showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: error.response?.data?.message ?? error.message,
      });
    },
    onSuccess: (res: QuotationEntity) => {
      const dataDefine: any = res?.healths?.map((it: HealthInfoRequest) => ({
        [it?.assured_id]: {
          ...it?.combo_big_question,
        },
      }));
      handleDataDefine(dataDefine);
    },
  });
  const micData: any[] = useMemo(() => {
    let mic_additional_products = data?.assured?.mic_additional_product?.mic_request
      ? [
          {
            person: data?.customer_is_assured === false ? '0' : '-1',
            mic_name: data?.assured?.mic_additional_product?.mic_benefit?.id,
            mic_package_selected: {
              ...data?.assured?.mic_additional_product?.mic_benefit,
              phi: data?.assured?.mic_additional_product?.mic_result?.phi,
            },
            mic_benefits: data?.assured?.mic_additional_product?.mic_request
              ? defaultMicBenefits.concat(
                  (Object.keys(data?.assured?.mic_additional_product?.mic_request) as (keyof MicRequestDTO)[]).filter(
                    (key) =>
                      data?.assured?.mic_additional_product?.mic_request &&
                      data?.assured?.mic_additional_product?.mic_request[key] === 'C',
                  ) as string[],
                )
              : [],
            contract_num: data?.assured?.mic_additional_product?.mic_request?.parent_contract?.so_hd_bm,
            contract_name: data?.assured?.mic_additional_product?.mic_request?.parent_contract?.ten_bm,
            contract_dob: data?.assured?.mic_additional_product?.mic_request?.parent_contract?.ng_sinh_bm,
            contract_card_id: data?.assured?.mic_additional_product?.mic_request?.parent_contract?.cmt_bm,
            contract_phone_number: data?.assured?.mic_additional_product?.mic_request?.parent_contract?.mobi_bm,
          },
        ]
      : [];
    const mic_additional_product_of_customer = data?.customer?.mic_additional_product;
    if (data?.customer_is_assured === false) {
      mic_additional_products = mic_additional_product_of_customer?.mic_request
        ? mic_additional_products.concat([
            {
              person: '-1',
              mic_name: mic_additional_product_of_customer?.mic_benefit?.id,
              mic_package_selected: {
                ...mic_additional_product_of_customer?.mic_benefit,
                phi: mic_additional_product_of_customer?.mic_result?.phi,
              },
              mic_benefits: mic_additional_product_of_customer?.mic_request
                ? defaultMicBenefits.concat(
                    (Object.keys(mic_additional_product_of_customer?.mic_request) as (keyof MicRequestDTO)[]).filter(
                      (key) =>
                        mic_additional_product_of_customer?.mic_request &&
                        mic_additional_product_of_customer?.mic_request[key] === 'C',
                    ) as string[],
                  )
                : [],
              contract_num: mic_additional_product_of_customer?.mic_request?.parent_contract?.so_hd_bm,
              contract_name: mic_additional_product_of_customer?.mic_request?.parent_contract?.ten_bm,
              contract_dob: mic_additional_product_of_customer?.mic_request?.parent_contract?.ng_sinh_bm,
              contract_card_id: mic_additional_product_of_customer?.mic_request?.parent_contract?.cmt_bm,
              contract_phone_number: mic_additional_product_of_customer?.mic_request?.parent_contract?.mobi_bm,
            },
          ])
        : [];
    }
    forEach(data?.additional_assureds, (it, index) => {
      if (it.mic_additional_product?.mic_request) {
        const additionalMicProducts = {
          person: (index + 1)?.toString(),
          mic_name: it?.mic_additional_product?.mic_benefit?.id,
          mic_package_selected: {
            ...it.mic_additional_product?.mic_benefit,
            phi: it.mic_additional_product?.mic_result?.phi,
          },
          mic_benefits: it.mic_additional_product?.mic_request
            ? defaultMicBenefits.concat(
                (Object.keys(it.mic_additional_product?.mic_request) as (keyof MicRequestDTO)[]).filter(
                  (key) =>
                    it.mic_additional_product?.mic_request && it.mic_additional_product?.mic_request[key] === 'C',
                ) as string[],
              )
            : [],
          contract_num: it?.mic_additional_product?.mic_request?.parent_contract?.so_hd_bm,
          contract_name: it?.mic_additional_product?.mic_request?.parent_contract?.ten_bm,
          contract_dob: it?.mic_additional_product?.mic_request?.parent_contract?.ng_sinh_bm,
          contract_card_id: it?.mic_additional_product?.mic_request?.parent_contract?.cmt_bm,
          contract_phone_number: it?.mic_additional_product?.mic_request?.parent_contract?.mobi_bm,
        };
        mic_additional_products = mic_additional_products.concat(additionalMicProducts);
      }
    });
    return mic_additional_products;
  }, [data]);

  const additional_assureds = useMemo(() => {
    return data?.additional_assureds?.map((it) => ({
      ...it,
      province_name: it?.address?.province_name,
      district_name: it?.address?.district_name,
      ward_name: it?.address?.ward_name,
      address: it?.address?.line1,
      annual_income: (it?.annual_income ?? 0) / 12,
      relationship: it?.relationship_with_policy_holder,
    }));
  }, [data]);

  const additional_products = useMemo(() => {
    if (!data) return [];
    return compact([
      ...(data.customer?.additional_products ?? []).map((it) => ({
        name: it.type,
        person: '-1',
        policy_term: it.policy_term,
        premium_term: it.premium_term,
        sum_assured: it.sum_assured,
      })),
      ...data.assured.additional_products.map(
        (it) =>
          !data.customer_is_assured && {
            name: it.type,
            person: '0',
            policy_term: it.policy_term,
            premium_term: it.premium_term,
            sum_assured: it.sum_assured,
          },
      ),
      ...(data.additional_assureds ?? []).reduce<any>(
        (prev, next, index) => [
          ...prev,
          ...next.additional_products.map((it) => ({
            name: it.type,
            person: `${index + 1}`, // Phải dùng index as key bởi vì full_name có thể trùng
            policy_term: it.policy_term,
            premium_term: it.premium_term,
            sum_assured: it.sum_assured,
          })),
        ],
        [],
      ),
    ]);
  }, [data]);

  if (isLoading) {
    return <FullscreenLoader />;
  }

  if (data == null) return null;

  return (
    <IllustrationFormScreen
      quotation={data}
      customer_is_assured={data.customer_is_assured}
      insuranceType={data.type}
      insuredPerson={{
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        customer: {
          ...data?.customer,
          province_name: data?.customer?.address?.province_name,
          district_name: data?.customer?.address?.district_name,
          ward_name: data?.customer?.address?.ward_name,
          annual_income: (data?.customer?.annual_income ?? 0) / 12,
          address: data?.customer?.address?.line1,
        },
        assured: {
          ...data?.assured,
          province_name: data?.assured?.address?.province_name,
          district_name: data?.assured?.address?.district_name,
          ward_name: data?.assured?.address?.ward_name,
          annual_income: (data?.assured?.annual_income ?? 0) / 12,
          address: data?.assured?.address?.line1,
          relationship: data?.assured?.relationship_with_policy_holder,
        },
        additional_assureds,
      }}
      insuranceDetail={{
        prime: {
          package_sum_assured: data.package_sum_assured,
          package_benefit_type: data.package_benefit_type,
          package_payment_period: data.package_payment_period,
          package_periodic_premium: data.package_periodic_premium,
          package_policy_term: data.package_policy_term,
          package_premium_term: data.package_premium_term,
        },
        amount: data.amount,
        additional_products,
        mic_additional_products: micData || [],
        raider_deduct_fund: data.raider_deduct_fund,
      }}
      referrerData={{
        referrer: data?.referrer
          ? { ...data?.referrer }
          : {
              code: user?.rm_info?.code,
              name: user?.rm_info?.full_name || user?.rm_info?.name,
              phone_number: user?.rm_info?.phone_number,
              email: user?.rm_info?.email,
              branch_code: user?.rm_info?.branch_code,
              branch_name: user?.rm_info?.branch_name,
            },
        sale: data?.sale
          ? { ...data?.sale }
          : {
              code: user?.ic_info?.code,
              name: user?.ic_info?.full_name || user?.ic_info?.name,
            },
        supporter: { ...data?.supporter },
      }}
    />
  );
});
