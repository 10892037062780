import { Loader } from '@mantine/core';
import { memo } from 'react';

export const FullscreenLoader = memo(() => {
  return (
    <div className="w-screen h-screen flex justify-center pt-100px">
      <Loader size="lg" variant="dots" />
    </div>
  );
});
