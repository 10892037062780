export const ApiUrl = {
  verify: '/v1/crm/verify',
  refresh: (token: string) => `/v1/auth/refresh?token=${token}`,
  quotation: '/v1/quotation',
  occupations: '/v1/occupations',
  searchRMCode: '/v1/backoffice/search-crm',
  searchICCode: '/v1/backoffice/search-ic',
  ocrProcess: '/v1/backoffice/process-ocr',
  createProcess: '/v1/quotation/create-process',
  confirmQuotation: '/v1/quotation/confirm',
  genQR: '/v1/quotation/update-and-gen-qrcode',
  combo: '/combo',
  province: '/v1/backoffice/provinces',
  district: (provinceCode?: string) => `/v1/backoffice/districts?provinceCode=${provinceCode}`,
  Ward: (districtCode?: string) => `/v1/backoffice/wards?districtCode=${districtCode}`,
  getMic: '/v1/insurance/mic-packages',
  leads: '/v1/potential-customer',
  leadsid: (id?: number | string) => `/v1/potential-customer/${id}`,
  refer: '/v1/potential-customer/referred',
  leadReferSearch: '/v1/potential-customer/referred/search',
  leadReferId: (id?: number | string) => `/v1/potential-customer/referred/${id}`,
  leadsDirectId: (id?: number | string) => `/v1/potential-customer/direct/${id}`,
  checkReferOrDirect: (id: string) => `/v1/potential-customer/${id}/flow`,
  referCustomerToSale: (id: number) => `/v1/potential-customer/${id}/refer`,
  getICslist: '/v1/backoffice/branch/ics',
  direct: '/v1/potential-customer/direct',
  micContract: '/v1/insurance/mic/contract',
  searchIC: '/v1/backoffice/ic/search',
  getQR: (id: number) => `/v1/quotation/gen-qrcode/${id}`,
};
