import { yupResolver } from '@hookform/resolvers/yup';
import { Button, SelectItem, Textarea } from '@mantine/core';
import { stringify } from 'qs';
import { memo, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { useLeads } from '../../../contexts';
import { validationMessages } from '../../../helpers';
import { useQueryString } from '../../../hooks';
import { LeadsReferReasonFormData } from '../../../services/leads';
import { RadioGroupReason } from '../components';

import { LeadsFormStepEnum, LeadsScreenParams } from './leads-form-screen';

export const reasonOptions: SelectItem[] = [
  {
    value: 'REASON_1',
    label: 'Khách hàng có vấn đề sức khỏe nên không mua được trên Digital',
  },
  {
    value: 'REASON_2',
    label: 'Khách hàng cần IC tư vấn kỹ hơn',
  },
  {
    value: 'REASON_3',
    label: 'Khách hàng đăng ký sản phẩm khác (Kiến tạo tương lai/ Món quà phú quý)',
  },
  {
    value: 'REASON_4',
    label: 'Khách hàng không có app để thực hiện thanh toán',
  },
  {
    value: 'OTHER',
    label: 'Lý do khác',
  },
];

export const LeadsReferReasonForm = memo(() => {
  const navigate = useNavigate();
  const { leadsId = 0 } = useQueryString<LeadsScreenParams>();
  const { leadsCustomer } = useLeads();
  const id = useMemo(() => Number(leadsId || leadsCustomer?.id || 0), [leadsId, leadsCustomer]);

  const validationSchema = yup.object({
    reason_id: yup.string().required(validationMessages.required),
    reason_content: yup.string().required(validationMessages.required),
  });

  const onSubmit = useCallback(
    (data: LeadsReferReasonFormData) => {
      navigate({
        search: stringify({
          step: LeadsFormStepEnum.REFER,
          leadsId: id,
          reason: data?.reason_content,
        }),
      });
    },
    [id, navigate],
  );

  const {
    watch,
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<LeadsReferReasonFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  return (
    <div className="pb-120px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="-mx-4 -my-24px py-24px px-16px bg-white flex flex-col">
          <p className="font-bold text-16px mt-0 mb-15px">Lý do chuyển từ Direct sang Refer:</p>
          <Controller
            control={control}
            name="reason_id"
            render={({ field: { onChange, value } }) => (
              <RadioGroupReason
                options={reasonOptions}
                value={value}
                onChange={(val) => {
                  onChange(val);
                  setValue(
                    'reason_content',
                    val !== 'OTHER' ? reasonOptions?.find((it) => it.value === val)?.label || '' : '',
                  );
                }}
                error={errors?.reason_id?.message}
              />
            )}
          />
          {watch('reason_id') === 'OTHER' && (
            <div className="mt-4">
              <p className="font-semibold text-14px mt-0 mb-10px opacity-50">Nhập nội dung lý do khác</p>
              <Textarea
                placeholder="Nhập chi tiết lý do khác"
                maxLength={1000}
                {...register('reason_content')}
                error={errors?.reason_content?.message}
              />
            </div>
          )}
        </div>
        <div className="bg-white pt-12px pb-34px px-16px fixed bottom-0 left-0 w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
          <Button type="submit" fullWidth>
            Xác nhận
          </Button>
        </div>
      </form>
    </div>
  );
});
