import { Button } from '@mantine/core';
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { memo, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FullscreenLoader, PdfViewLayout } from '../../../components';
import { useDialog, useLeads, useReverse } from '../../../contexts';
import { AppRoutes, openPDFFromBlob } from '../../../helpers';
import { ApiUrl } from '../../../helpers/api-url';
import { useQueryString } from '../../../hooks';
import { vi } from '../../../i18n/vi';
import { useComboDownloadPDFQuery } from '../../../services';
import { reverseComboOptions } from '../products';

export const ReverseResultScreen = memo(() => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { showDialog } = useDialog();

  const { combo_name } = useQueryString<{ combo_name?: string }>();
  const { selectedCombo } = useReverse();
  const { leadsCustomer, leadsDetail } = useLeads();
  const selectedComboLabel = reverseComboOptions.find((item) => item.value === selectedCombo)?.label.toUpperCase();

  const { data, isLoading } = useComboDownloadPDFQuery(id ?? '', {
    enabled: id != null,
    onError: async (error) => {
      const dialogRes = await showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: error.response?.data?.message ?? error.message,
      });
      dialogRes && navigate(-1);
    },
  });

  const onRight = useCallback(() => {
    const data = {
      type: 'DOWNLOAD_PDF',
      url: process.env.REACT_APP_API_BASE_URL + ApiUrl.combo + '/' + id + '/crm-download',
      data: {
        url: process.env.REACT_APP_API_BASE_URL + ApiUrl.combo + '/' + id + '/crm-download',
      },
    };
    window.parent.postMessage(JSON.stringify(data));
    (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify(data));
  }, [id]);

  const onNext = useCallback(async () => {
    const res = await showDialog({
      type: 'ALERT',
      message: vi.directLeadsReverseComboProceed,
      confirmText: 'Đồng ý',
    });
    res &&
      navigate({
        pathname: AppRoutes.illustration_new,
      });
  }, [navigate, showDialog]);

  const url = useMemo(() => (data ? openPDFFromBlob(data) : undefined), [data]);

  return (
    <PdfViewLayout onRight={onRight} title={combo_name ?? `GÓI '${selectedComboLabel}'`}>
      {/* <ReversePdf /> */}
      {isLoading ? (
        <FullscreenLoader />
      ) : (
        url != null && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.js">
            <Viewer fileUrl={url} defaultScale={SpecialZoomLevel.PageWidth} renderLoader={() => <FullscreenLoader />} />
          </Worker>
        )
      )}
      {(leadsCustomer || leadsDetail) && (
        <div className="bg-white pt-12px pb-34px px-16px fixed bottom-0 left-0 w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
          <Button type="submit" onClick={onNext} fullWidth disabled={isLoading}>
            Tiếp tục
          </Button>
        </div>
      )}
    </PdfViewLayout>
  );
});
