import { Table } from '@mantine/core';
import { forEach } from 'lodash';
import { memo, useMemo } from 'react';

import { numberWithCommas } from '../../../../helpers';
import {
  AdditionalAssuredProductEnum,
  AdditionalProductEntity,
  MicResultType,
  QuotationEntity,
} from '../../../../services';
import { supportingInsuranceOptions } from '../../form/insurance-detail-form';
import { insuranceTypes } from '../insurance-types';

type Props = {
  quotation: QuotationEntity;
};

export const ProductInformation = memo(({ quotation }: Props) => {
  const additionalAssuredMics = useMemo(() => {
    let mics: MicResultType[] = [];
    if (quotation?.assured?.mic_additional_product?.mic_request) {
      mics = mics?.concat([
        {
          ...quotation?.assured?.mic_additional_product?.mic_benefit,
          ...quotation?.assured?.mic_additional_product?.mic_result,
          full_name: quotation?.assured.full_name,
        },
      ]);
    }
    if (quotation?.customer?.mic_additional_product?.mic_request && !quotation.customer_is_assured) {
      mics = mics?.concat([
        {
          ...quotation?.customer?.mic_additional_product?.mic_benefit,
          ...quotation?.customer?.mic_additional_product?.mic_result,
          full_name: quotation?.customer?.full_name,
        },
      ]);
    }
    forEach(quotation?.additional_assureds, (it) => {
      if (it?.mic_additional_product?.mic_request) {
        mics = mics?.concat([
          {
            ...it?.mic_additional_product?.mic_benefit,
            ...it?.mic_additional_product?.mic_result,
            full_name: it?.full_name,
          },
        ]);
      }
    });
    return mics;
  }, [quotation]);

  const rows = useMemo(
    () => [
      <tr key={1}>
        <td>Sản phẩm chính</td>
        <td>{insuranceTypes.find((insurance) => insurance.type === quotation?.type)?.label}</td>
        <td>{quotation?.assured.full_name}</td>
        <td>{quotation?.package_policy_term}</td>
        <td>{quotation?.package_premium_term}</td>
        <td>{quotation?.package_sum_assured ? numberWithCommas(quotation?.package_sum_assured) : '-'}</td>
        <td>{quotation?.package_periodic_premium ? numberWithCommas(quotation?.package_periodic_premium) : '-'}</td>
      </tr>,
      ...(quotation?.assured.additional_products ?? [])?.map((it, index) => (
        <tr key={index + 'prime'}>
          <td>Sản phẩm bổ trợ</td>
          <td>
            <div className="w-200px whitespace-pre-wrap">
              {supportingInsuranceOptions?.find((insurance) => insurance?.value === it?.type)?.label}
            </div>
          </td>
          <td>{quotation?.assured.full_name}</td>
          <td>{it?.policy_term}</td>
          <td>{it?.premium_term}</td>
          <td>{it?.sum_assured ? numberWithCommas(it?.sum_assured) : '-'}</td>
          <td>
            {it?.base_premium && it?.type !== AdditionalAssuredProductEnum.COI_RIDER
              ? numberWithCommas(it?.base_premium)
              : '-'}
          </td>
        </tr>
      )),
      ...(quotation?.customer?.additional_products ?? [])?.map(
        (it: AdditionalProductEntity, index: number) =>
          !quotation?.customer_is_assured && (
            <tr key={index + 'customer'}>
              <td>Sản phẩm bổ trợ</td>
              <td>
                <div className="w-200px whitespace-pre-wrap">
                  {supportingInsuranceOptions?.find((insurance) => insurance?.value === it?.type)?.label}
                </div>
              </td>
              <td>{quotation?.customer?.full_name}</td>
              <td>{it?.policy_term}</td>
              <td>{it?.premium_term}</td>
              <td>{it?.sum_assured ? numberWithCommas(it?.sum_assured) : '-'}</td>
              <td>
                {it?.base_premium && it?.type !== AdditionalAssuredProductEnum.COI_RIDER
                  ? numberWithCommas(it?.base_premium)
                  : '-'}
              </td>
            </tr>
          ),
      ),
      ...(quotation?.additional_assureds ?? [])
        ?.reduce<any>(
          (prev, next) => [
            ...prev,
            ...next?.additional_products?.map((it: AdditionalProductEntity) => ({ ...it, ...next })),
          ],
          [],
        )
        ?.map((it: any, index: number) => (
          <tr key={index + 'support'}>
            <td>Sản phẩm bổ trợ</td>
            <td>
              <div className="w-200px whitespace-pre-wrap">
                {supportingInsuranceOptions?.find((insurance) => insurance?.value === it?.product_type)?.label}
              </div>
            </td>
            <td>{it.full_name}</td>
            <td>{it?.policy_term}</td>
            <td>{it?.premium_term}</td>
            <td>{it?.sum_assured ? numberWithCommas(it?.sum_assured) : '-'}</td>
            <td>
              {it?.base_premium && it?.product_type !== AdditionalAssuredProductEnum.COI_RIDER
                ? numberWithCommas(it?.base_premium)
                : '-'}
            </td>
          </tr>
        )),
      ...(additionalAssuredMics ?? []).map((it) => (
        <tr key={'mic' + it?.name + it?.full_name}>
          <td>Sản phẩm MIC</td>
          <td>{it?.name}</td>
          <td>{it?.full_name}</td>
          <td>1</td>
          <td>1</td>
          <td>{it?.mic_sum_benefit ? numberWithCommas(it?.mic_sum_benefit) : '-'}</td>
          <td>{it?.phi ? numberWithCommas(it?.phi) : '-'}</td>
        </tr>
      )),
    ],
    [
      additionalAssuredMics,
      quotation?.additional_assureds,
      quotation?.assured.additional_products,
      quotation?.assured.full_name,
      quotation?.customer?.additional_products,
      quotation?.customer?.full_name,
      quotation?.customer_is_assured,
      quotation?.package_periodic_premium,
      quotation?.package_policy_term,
      quotation?.package_premium_term,
      quotation?.package_sum_assured,
      quotation?.type,
    ],
  );

  const totalPeriodicPremium = useMemo(() => quotation?.total_amount ?? 0, [quotation?.total_amount]);

  return (
    <div className="pb-16px">
      <Table className="mx-0">
        <thead>
          <tr>
            <th></th>
            <th>Tên sản phẩm</th>
            <th>Người được bảo hiểm</th>
            <th>Thời hạn HĐ/Thời hạn BV (năm)</th>
            <th>Thời hạn đóng phí (năm)</th>
            <th>Số tiền bảo hiểm (đồng)</th>
            <th>Phí bảo hiểm định kỳ (đồng)</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
        <tfoot>
          <tr>
            <td colSpan={6}>
              Tổng Phí bảo hiểm định kỳ của SPC và SPBS (nếu có): {numberWithCommas(totalPeriodicPremium)}
            </td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
});
