import { PropsWithChildren, memo } from 'react';

import { LeadsNavbar, LeadsNavbarProps } from '../navbar';

type Props = PropsWithChildren<LeadsNavbarProps>;

export const LeadsLayout = memo(({ title, children, onLeft, onRight, rightButton, ...props }: Props) => {
  return (
    <div className="w-screen min-h-screen bg-c_F1F6F8">
      <LeadsNavbar title={title} onLeft={onLeft} onRight={onRight} rightButton={rightButton} {...props} />
      <div className="w-full h-full relative pt-48px">{children}</div>
    </div>
  );
});
