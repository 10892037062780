export const openPDFFromBlob = (data: Blob) => {
  const blob = new Blob([data], {
    type: 'application/pdf',
  });

  return window.URL.createObjectURL ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
};

export const downloadPDFFromBlob = (data: Blob, filename?: string) => {
  filename = filename ?? `${new Date().toISOString()}.pdf`;

  const blob = new Blob([data], {
    type: 'application/pdf',
  });

  const href = window.URL.createObjectURL ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();

  // Fixes "webkit blob resource error 1"
  setTimeout(function () {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(href);
  }, 200);
};
