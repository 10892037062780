/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@mantine/core';
import classNames from 'classnames';
import { omit, pick } from 'lodash';
import { stringify } from 'qs';
import React, { useCallback, useContext, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Question } from '../../../../../components';
import { useDialog } from '../../../../../contexts';
import { getMessage } from '../../../../../helpers';
import { useQueryString } from '../../../../../hooks';
import { vi } from '../../../../../i18n/vi';
import { UserRoundWhiteSVG } from '../../../../../icons';
import { HealthsInput, MicHealthInfoRequest, QRCodeInput, useGenQRCodeMutation } from '../../../../../services';
import { FormStepEnum, useIllustration } from '../../../form';
import { MixQuestionsContext } from '../../../form/insurance-request';
import { dataOtherQuestions } from '../../../form/insurance-request/mock';
import { CollapseHeader } from '../../collapse-header';
import { ScreenParams } from '../../types';
import { SwitchFlowToReferButton } from '../../../../leads/components';

interface FormType {
  otherQuestions: string[];
  checked: boolean;
  files: any[];
}
export const LastQuizzComponent = React.memo(() => {
  const { processId = 0 } = useQueryString<ScreenParams>();
  const navigate = useNavigate();
  const { dataDefine } = useContext(MixQuestionsContext);
  const { referrerData, quotation } = useIllustration();

  const { handleSubmit, control, watch } = useForm<FormType>({
    defaultValues: useMemo(() => {
      return {
        otherQuestions: ['K'],
        checked: false,
      };
    }, []),
  });

  const { showDialog } = useDialog();
  const { mutateAsync: submitAppAsync, isLoading: submitAppLoading } = useGenQRCodeMutation({
    onError: (err) => {
      showDialog({
        type: 'ALERT',
        message: getMessage(err),
      });
    },
    onSuccess: (res) => {
      navigate({
        search: stringify({
          step: FormStepEnum.QR_CODE,
          url: res?.url ?? '',
          date: quotation?.quotation_date,
        }),
      });
    },
  });

  const getComboQuestion = useCallback(
    (data: any) => {
      const otherQuestions = watch('otherQuestions');
      const quizzes = data.body;
      const combo_big_question = {
        ...quizzes,
        files: data?.files?.map((el: any) => el.url),
      };
      const omitC7Body = [
        'c7a',
        'c7b',
        'c7c',
        'c7d',
        'c7e',
        'c7f',
        'c7g',
        'c7h',
        'c7i',
        'c7j',
        'c7k',
        'c7l',
        'c7m',
        'c7n',
      ];
      const omitC9Body = ['c9a', 'c9b', 'c9c'];
      const combo_big_questionForMiniIllustration = {
        ...omit(combo_big_question, [...omitC7Body, ...omitC9Body]),
        c7: {
          response: combo_big_question?.c7?.response,
          ...pick(combo_big_question, omitC7Body),
        },
        c9: {
          response: combo_big_question?.c9?.response,
          ...pick(combo_big_question, omitC9Body),
        },
        oqs: otherQuestions?.[0],
      };

      if ([12, 15].includes(data?.assured?.app_question_number)) {
        return {
          combo_big_question: combo_big_questionForMiniIllustration,
        };
      }
      return {
        combo_small_question: { ...data?.combo_small_question, oqs: otherQuestions?.[0] },
      };
    },
    [watch],
  );

  const onSubmit = useCallback(
    async (data: FormType) => {
      if (data?.otherQuestions?.[0] === 'C') {
        showDialog({
          type: 'ALERT',
          message: vi.MSG05,
        });
        return;
      }

      const resultDatas = Object.values(dataDefine);
      const healths: HealthsInput[] = resultDatas
        ?.filter((it: any) => it?.assured?.is_mbal)
        ?.map((it: any) => {
          return {
            assured_id: it?.assured?.id,
            from3_to12: it?.from3_to12 || it?.assured?.from3_to12,
            ...getComboQuestion(it),
          };
        });
      const mic_healths: MicHealthInfoRequest[] = resultDatas
        ?.filter((it: any) => it?.assured?.is_mic)
        ?.map((it: any) => {
          return {
            mini_assured_id: it?.assured?.mini_assured_id || it?.assured?.id,
            mic_question: { ...it?.combo_small_question },
          };
        });

      const body: QRCodeInput = {
        healths,
        mic_healths,
        process_id: processId,
        ...referrerData,
      };
      await submitAppAsync(body);
    },
    [dataDefine, getComboQuestion, processId, referrerData, showDialog, submitAppAsync],
  );

  return (
    <>
      <div className="flex-1">
        <CollapseHeader icon={<UserRoundWhiteSVG />} title="Thông tin khác" className="custom-collapse-header">
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                {dataOtherQuestions?.map((el, index) => (
                  <div
                    className={classNames(['questions', index === 0 && 'borderTopNone'])}
                    key={'otherQuestions' + el?.id}>
                    <div className="questionViewTitle">
                      <div className="questionIndex">
                        <p className="questionIndexText">{index + 1}</p>
                      </div>
                      <p className="questionTxtTitle">{'Câu hỏi ' + (index + 1)}</p>
                    </div>
                    <Question
                      onChange={(newVal) => {
                        if (value !== undefined) {
                          value[index] = newVal;
                          onChange(value);
                        }
                      }}
                      value={value?.[index] || ''}
                      desc={el.name || ''}
                    />
                  </div>
                ))}
              </>
            )}
            name="otherQuestions"
          />
        </CollapseHeader>
      </div>
      <div className="bg-white p-16px flex flex-col gap-y-16px">
        <SwitchFlowToReferButton disabled={submitAppLoading} />
        <Button fullWidth onClick={handleSubmit(onSubmit)} loading={submitAppLoading} disabled={submitAppLoading}>
          Tiếp theo
        </Button>
      </div>
    </>
  );
});
