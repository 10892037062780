import classNames from 'classnames';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Collapse } from '../../../../components';
import { ManSVG, WomanSVG } from '../../../../icons';
import {
  AdditionalAssuredsEntity,
  AssuredEntityRes,
  GenderEnum,
  MicAdditionalProductDT0,
  QuotationEntity,
} from '../../../../services';
import { User } from '../../form';
import { packageAdditionalBenefits, packageHealthBenefits } from '../choose-package/utils';

import './summary-mic-healthy-insurance-benefits.less';

type Props = {
  quotation: QuotationEntity;
};

export const SummaryMicHealthynsuranceBenefits = memo(({ quotation }: Props) => {
  const [activeUser, setActiveUser] = useState<number>(-1);

  const renderMicBenefits = (data: AssuredEntityRes) => {
    const micBenefits = data?.additional_product?.mic_request;
    const micInsuranceBenefit = data?.additional_product?.mic_benefit;
    const additionalBenefits: string[] = [];
    if (micBenefits) {
      for (const [key, value] of Object.entries(micBenefits)) {
        if (value === 'C') {
          additionalBenefits.push(key);
        }
      }
    }

    return (
      <>
        {packageHealthBenefits(micInsuranceBenefit).map((el) => (
          <Collapse
            key={'health' + el.label + el.value}
            label={el.label}
            text={el.value}
            style="mic-elmItem"
            hasToggle={el?.content && el?.content?.length > 0}>
            {el?.content && el?.content?.length > 0 && (
              <div className={'mic-boxContent'}>
                {el.content.map((i) => (
                  <div key={'death-content' + i.label + i.value} className={'mic-viewLine'}>
                    <div className={'mic-dot'} />
                    <div className={'mic-contentText'}>
                      {i.label} <span className={'mic-highlight'}>{i.value}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Collapse>
        ))}

        {packageAdditionalBenefits(micInsuranceBenefit).map(
          (el) =>
            !!el.value &&
            additionalBenefits?.includes(el?.id) && (
              <Collapse
                key={'health' + el?.label + el?.value}
                label={el?.label}
                text={el?.value}
                style={'mic-elmItem'}
                hasToggle={el?.content && el?.content?.length > 0}>
                {el?.content && el?.content?.length > 0 && (
                  <div className={'mic-boxContent'}>
                    {el.content.map(
                      (i) =>
                        !!i.value && (
                          <div key={'death-content' + i.label + i.value} className={'mic-viewLine'}>
                            {!!i.value && <div className={'mic-dot'} />}
                            <div className={'mic-contentText'}>
                              {i.label} <span className={'mic-highlight'}>{i.value || 0}</span>
                            </div>
                          </div>
                        ),
                    )}
                  </div>
                )}
              </Collapse>
            ),
        )}
      </>
    );
  };

  const additional_assureds = useMemo(() => {
    const arr: AdditionalAssuredsEntity[] = quotation?.additional_assureds ?? [];
    if (!quotation?.customer_is_assured && !!quotation?.customer) {
      return arr.concat([quotation?.customer]);
    }
    return arr;
  }, [quotation]);

  const data = useMemo(
    () => [
      {
        ...quotation?.assured,
        mic_benefit: quotation?.assured?.mic_additional_product?.mic_benefit,
        id: -1,
        type: 'main',
        content: (
          <div className="mb-16px">
            {renderMicBenefits({
              assured: quotation?.assured as User,
              additional_product: quotation?.assured?.mic_additional_product as MicAdditionalProductDT0,
            })}
          </div>
        ),
      },
      ...additional_assureds?.map((it, index) => ({
        ...it,
        mic_benefit: it?.mic_additional_product?.mic_benefit,
        id: index,
        type: 'additional',
        content: (
          <div className="mb-16px">
            {renderMicBenefits({
              assured: it as any,
              additional_product: it?.mic_additional_product as MicAdditionalProductDT0,
            })}
          </div>
        ),
      })),
    ],
    [additional_assureds, quotation?.assured],
  );

  const newData = useMemo(() => data.filter((it: any) => !!it?.mic_benefit), [data]);
  const onActiveUser = useCallback(() => setActiveUser(newData?.[0]?.id), [newData]);

  useEffect(() => {
    onActiveUser();
  }, [onActiveUser]);

  return (
    <div className="px-20px">
      <div className={'mic-element mb-30px'}>
        <div className="flex -m-4px pb-10px flex-nowrap overflow-x-auto">
          {newData.map((it) => (
            <div className="flex-shrink-0 p-4px" key={it.id}>
              <div
                className={classNames(
                  'py-12px px-16px flex border border-solid rounded-4px',
                  activeUser === it.id ? 'border-primary' : 'border-c_EEEEEE',
                )}
                onClick={() => setActiveUser(it.id)}>
                <div className="flex-shrink-0 mr-16px">
                  {it?.gender === GenderEnum.FEMALE ? <WomanSVG /> : <ManSVG />}
                </div>
                <div className="flex-shrink-0">
                  <div className="text-primary font-semibold">{it?.full_name}</div>
                  <div className="text-12px">{it.id === -1 ? 'NĐBH chính' : 'NĐBH bổ sung'}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-20px">{newData.find((it) => it.id === activeUser)?.content}</div>

        <div className={classNames(['mic-wrapNote', 'mt-10px'])}>
          <div className={'mic-noteTitle'}>*Lưu ý: </div>
          <div className={classNames(['mic-viewLine', 'mic-viewLineNote'])}>
            <div className={classNames(['mic-dot', 'mic-miniDot'])} />
            <div className={'mic-textNote'}>Không áp dụng hủy trong 21 ngày</div>
          </div>
          <div className={classNames(['mic-viewLine', 'mic-viewLineNote'])}>
            <div className={classNames(['mic-dot', 'mic-miniDot'])} />
            <div className={'mic-textNote'}>
              Phí bảo hiểm MIC Care có thể sẽ thay đổi theo độ tuổi và tỷ lệ bồi thường năm liền trước của Người được
              bảo hiểm
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
