import dayjs from 'dayjs';
import { compact } from 'lodash';
import { memo, useContext, useMemo } from 'react';

import { FullscreenLoader } from '../../../components';
import { DATE_FORMAT } from '../../../constants';
import { useDialog, useLeads, useReverse } from '../../../contexts';
import { getMessage } from '../../../helpers';
import { vi } from '../../../i18n/vi';
import { ComboEnum, HealthInfoRequest, useGetLeadsQuotationExample, useGetMicPackagesQuery } from '../../../services';
import { IllustrationFormScreen, MixQuestionsContext } from '../form';

export const IllustrationNewScreen = memo(() => {
  const {
    leadsCustomer,
    leadsDetail,
    leadsInsuredPersonForm,
    leadsInsuranceDetailForm,
    leadsHealths,
    leadsCurrentAssured,
    leadsQuotation,
  } = useLeads();
  const { selectedCombo } = useReverse();
  const { showDialog } = useDialog();
  const { handleDataDefine } = useContext(MixQuestionsContext);

  const { isLoading: isLoadingLeadsQuotationExample, data: leadsQuotationExample } = useGetLeadsQuotationExample(
    leadsCustomer?.direct_id ?? 0,
    selectedCombo === ComboEnum.FLEXIBLE || undefined,
    {
      enabled: leadsCustomer?.direct_id != null && !!leadsCustomer,
      onError: (error) => {
        showDialog({
          type: 'ALERT',
          title: vi.notification,
          message: error.response?.data?.message ?? error.message,
        });
      },
      onSuccess: (res) => {
        const dataDefine: any = res?.healths?.map((it: HealthInfoRequest) => ({
          [it?.assured_id]: {
            ...it?.combo_big_question,
          },
        }));
        handleDataDefine(dataDefine);
      },
    },
  );

  const leadsQuotationData = useMemo(() => {
    if (leadsQuotation) {
      return leadsQuotation;
    }
    if (leadsDetail) {
      return leadsDetail.quotation;
    }
    if (leadsCustomer) {
      return leadsQuotationExample;
    }
  }, [leadsCustomer, leadsDetail, leadsQuotation, leadsQuotationExample]);

  const { isLoading: isLoadingMicData, data: micPackagesData } = useGetMicPackagesQuery(
    { dob: dayjs(leadsQuotationData?.customer.dob ?? dayjs()).format(DATE_FORMAT.server) },
    {
      enabled: !!leadsQuotationData?.customer.dob && !!leadsQuotationData.customer.mic_request,
      onError: (error) =>
        showDialog({
          type: 'ALERT',
          title: vi.notification,
          message: getMessage(error),
        }),
    },
  );

  const micData: any[] = useMemo(() => {
    const mic_additional_products = leadsQuotationData?.customer.mic_request
      ? [
          {
            person: '-1',
            mic_name: leadsQuotationData.customer.mic_request.nhom,
            mic_package_selected: micPackagesData?.find(
              (micPackage) => micPackage.id == leadsQuotationData?.customer?.mic_request?.nhom,
            ),
            mic_benefits: ['benefits_1', 'benefits_2', 'benefits_3'],
          },
        ]
      : [];
    return mic_additional_products;
  }, [leadsQuotationData?.customer.mic_request, micPackagesData]);

  const additional_products = useMemo(() => {
    if (!leadsQuotationData) return [];
    return compact([
      ...(leadsQuotationData.customer?.additional_products ?? []).map((it) => ({
        name: it.type,
        person: '-1',
      })),
      ...(leadsQuotationData.additional_assureds ?? []).reduce<any>(
        (prev, next, index) => [
          ...prev,
          ...(next.additional_products ?? []).map((it) => ({
            name: it.type,
            person: `${index + 1}`, // Phải dùng index as key bởi vì full_name có thể trùng
            policy_term: it.policy_term,
            premium_term: it.premium_term,
            sum_assured: it.sum_assured,
          })),
        ],
        [],
      ),
    ]);
  }, [leadsQuotationData]);

  if (isLoadingLeadsQuotationExample || isLoadingMicData) {
    return <FullscreenLoader />;
  }
  if (leadsQuotationData == null) return <IllustrationFormScreen />;

  return (
    <IllustrationFormScreen
      quotation={leadsQuotationData}
      customer_is_assured={leadsQuotationData.customer_is_assured}
      insuranceType={leadsQuotationData.type}
      insuredPerson={
        leadsInsuredPersonForm
          ? leadsInsuredPersonForm
          : {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              customer: {
                ...leadsQuotationData?.customer,
                province_name: leadsQuotationData?.customer?.address?.province_name,
                district_name: leadsQuotationData?.customer?.address?.district_name,
                ward_name: leadsQuotationData?.customer?.address?.ward_name,
                annual_income: (leadsQuotationData?.customer?.annual_income ?? 0) / 12 || undefined,
                address: leadsQuotationData?.customer?.address?.line1,
                married: false,
              },
              assured: {
                ...leadsQuotationData?.customer,
                province_name: leadsQuotationData?.customer?.address?.province_name,
                district_name: leadsQuotationData?.customer?.address?.district_name,
                ward_name: leadsQuotationData?.customer?.address?.ward_name,
                annual_income: (leadsQuotationData?.customer?.annual_income ?? 0) / 12 || undefined,
                address: leadsQuotationData?.customer?.address?.line1,
                married: false,
              },
            }
      }
      insuranceDetail={
        leadsInsuranceDetailForm
          ? leadsInsuranceDetailForm
          : {
              prime: {
                package_sum_assured: leadsQuotationData.package_sum_assured ?? 500000000,
                package_benefit_type: leadsQuotationData.package_benefit_type,
                package_payment_period: leadsQuotationData.package_payment_period,
                package_periodic_premium: leadsQuotationData.package_periodic_premium,
                package_policy_term: leadsQuotationData.package_policy_term,
                package_premium_term: leadsQuotationData.package_premium_term,
              },
              amount: leadsQuotationData.amount,
              additional_products,
              mic_additional_products: micData || [],
              raider_deduct_fund: leadsQuotationData.raider_deduct_fund,
            }
      }
      healths={leadsHealths}
      currentAssured={leadsCurrentAssured}
    />
  );
});
