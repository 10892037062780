import { Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { memo, PropsWithChildren } from 'react';

import { InfoCircleSVG, WarningSVG } from '../../../../icons';

export const GuideModal = memo(({ children }: PropsWithChildren) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
      <div onClick={open} className="ml-8px">
        <InfoCircleSVG width={20} height={20} />
      </div>
      <Modal opened={opened} onClose={close} centered withCloseButton={false}>
        <div className="flex flex-col items-center justify-center">
          <WarningSVG />
          <h3 className="text-center mt-20px mb-10px text-24px font-bold">Thông báo</h3>
        </div>
        <p className="mt-0 mb-15px text-13px">
          1. Trẻ em 15 ngày tuổi đến 6 tuổi tham gia độc lập: phí bảo hiểm của trẻ tăng 30%.
        </p>
        <p className="mt-0 mb-15px text-13px">
          2. Trường hợp trẻ tham gia cùng bố/mẹ hoặc bố/mẹ đã tham gia ít nhất 01 chương trình bảo hiểm MIC Care còn
          hiệu lực: phí bảo hiểm của trẻ giữ nguyên (không tăng phí).
        </p>
        <p className="mt-0 mb-15px text-primary text-13px">
          <i>
            Lưu ý: Gói bảo hiểm của con chỉ được áp dụng bằng hoặc thấp hơn gói bảo hiểm của bố/mẹ (bao gồm cả điều
            khoản chính và điều khoản bổ sung)
          </i>
        </p>

        {children}
        <Button fullWidth onClick={close} className="mt-12px">
          Đóng
        </Button>
      </Modal>
    </>
  );
});
