import { PropsWithChildren, createContext, memo, useCallback, useContext, useState } from 'react';

import { IllustrationPdf } from '../screens';

type PdfContextProps = {
  id?: string | number;
  onOpenPdf: (id?: number | string) => void;
};

const PdfContext = createContext<PdfContextProps>({
  id: '',
  onOpenPdf() {
    throw new Error('not ready');
  },
});

export const PdfContextProvider = memo(({ children }: PropsWithChildren) => {
  const [id, setId] = useState<string | number>();

  const handleOpenPdf = useCallback((id?: number | string) => setId(id), []);

  return (
    <PdfContext.Provider value={{ id, onOpenPdf: handleOpenPdf }}>
      {children}
      {id != null && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-white z-50">
          <IllustrationPdf id={id} />
        </div>
      )}
    </PdfContext.Provider>
  );
});

export const usePdf = () => useContext(PdfContext);
