import { memo, useEffect, useState } from 'react';
import classNames from 'classnames';

import { FemaleSVG, MaleSVG } from '../../../../icons';
import { Colors } from '../../../../constants';
import { GenderEnum } from '../../../../services';

type Props = {
  value: GenderEnum;
  onChange(value: GenderEnum): void;
  error?: string;
};

export const GenderRadioGroup = memo(({ value, onChange, error }: Props) => {
  const [selectedValue, setSelectedValue] = useState<GenderEnum>(value);

  useEffect(() => {
    onChange(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    if (value !== selectedValue) {
      setSelectedValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="mb-16px">
      <div className="-mx-4px flex flex-row">
        <div className="w-1/2 px-4px">
          <div
            className={classNames(
              'h-48px rounded-6px border-solid border-0.5px px-16px flex justify-between items-center',
              selectedValue === GenderEnum.MALE ? 'border-primary bg-primary' : 'border-c_DDDDDD bg-white',
              !!error && 'border-red',
            )}
            onClick={() => setSelectedValue(GenderEnum.MALE)}>
            <span
              className={classNames(
                'text-16px font-semibold',
                selectedValue === GenderEnum.MALE ? 'text-white' : 'text-c_161616',
              )}>
              Nam
            </span>
            <MaleSVG fill={selectedValue === 'MALE' ? Colors.white : Colors.c_161616} />
          </div>
        </div>
        <div className="w-1/2 px-4px">
          <div
            className={classNames(
              'h-48px rounded-6px border-solid border-0.5px px-16px flex justify-between items-center',
              selectedValue === GenderEnum.FEMALE ? 'border-primary bg-primary' : 'border-c_DDDDDD bg-white',
            )}
            onClick={() => setSelectedValue(GenderEnum.FEMALE)}>
            <span
              className={classNames(
                'text-16px font-semibold',
                selectedValue === GenderEnum.FEMALE ? 'text-white' : 'text-c_161616',
                !!error && 'text-red',
              )}>
              Nữ
            </span>
            <FemaleSVG fill={selectedValue === 'FEMALE' ? Colors.white : Colors.c_161616} />
          </div>
        </div>
      </div>
      {!!error && <div className="text-red text-14px mt-8px">{error}</div>}
    </div>
  );
});
