import classNames from 'classnames';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { Colors } from '../../constants';
import { CheckedSVG } from '../../icons';

import './index.less';
import { CheckBoxStyle } from './type.interface';

type Props = PropsWithChildren<{
  value: boolean;
  onPress: (val: boolean) => void;
  type?: CheckBoxStyle;
  isReverse?: boolean;
  style?: string;
  checkStyle?: string;
  activeStyle?: string;
  primary?: boolean;
  disabled?: boolean;
}>;

export const CheckBox = React.memo(
  ({
    value,
    onPress,
    children,
    type = 'checkbox',
    isReverse = false,
    checkStyle,
    primary = false,
    disabled,
    ...props
  }: Props) => {
    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
      setChecked(value);
    }, [value]);

    const onTouch = useCallback(() => {
      if (disabled) return;
      setChecked((prev) => !prev);
      onPress(!checked);
    }, [checked, disabled, onPress]);

    const isBox = useMemo(() => type === 'box', [type]);
    return (
      <div
        onClick={onTouch}
        className={classNames([
          'wrapCheckbox',
          isReverse && 'reverse',
          isBox && value && 'activeBoxSelect',
          props.style,
        ])}>
        {!isBox && (
          <>
            {type === 'radio' ? (
              <div
                className={classNames([
                  'radio',
                  primary && { borderColor: Colors.primary },
                  checkStyle,
                  value && 'activeRadio',
                ])}>
                <div className={classNames(['dot', value && 'activeDot'])} />
              </div>
            ) : (
              <div className={classNames(['checkbox', checkStyle, value && 'active'])}>
                <CheckedSVG />
              </div>
            )}
          </>
        )}
        {!!children && (
          <div className={classNames(['label', isReverse && 'reverseLabel', isBox && 'boxSelect'])}>{children}</div>
        )}
      </div>
    );
  },
);
