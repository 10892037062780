export const validationMessages = {
  required: 'Đây là trường bắt buộc nhập',
  invalidPhone: 'Số điện thoại không hợp lệ',
  identityDocumentNumber: {
    nationalId: 'Chứng minh nhân dân không hợp lệ',
    citizenId: 'Căn cước công dân không hợp lệ',
    birthCertificate: 'Giấy khai sinh chỉ không hợp lệ',
    passport: 'Hộ chiếu không hợp lệ',
    militaryId: 'Chứng minh thư quân đội không hợp lệ',
  },
  invalidEmail: 'Email không đúng định dạng',
  emailRequired: 'Email là trường bắt buộc nhập',
  provinceNameRequired: 'Tỉnh/Thành phố bắt buộc chọn',
  districtNameRequired: 'Quận/Huyện bắt buộc chọn',
  wardNameRequired: 'Xã/Phường bắt buộc chọn',
  addressRequired: 'Địa chỉ chi tiết bắt buộc nhập',
  nationalityRequired: 'Quốc tịch bắt buộc chọn',
  jobRequired: 'Nghề nghiệp bắt buộc chọn',
  nameRequired: 'Họ và tên bắt buộc nhập',
  invalidName: 'Họ tên không hợp lệ (ít nhất 4 kí tự trở lên và không chứa kí tự đặc biệt)',
  annualIncome: 'Thu nhập bắt buộc nhập',
  noEmail: 'Email không có giá trị sử dụng',
  invalidRM: 'Thông tin Người giới thiệu không đúng định dạng (MBxxxxxxxx)',
  invalidIC: 'Nhân viên tư vấn bảo hiểm không đúng định dạng',
  invalidSupporter: 'Người hỗ trợ không đúng định dạng (chỉ 10 ký tự số)',
  maxSumAssured:
    'Số tiền bảo hiểm đang vượt quá hạn mức tối  đa so với thu nhập của Qúy khách. Vui lòng chọn Số tiền bảo hiểm phù hợp',
  invalidCardNumber:
    'Chữ số thứ 4 là mã giới tính (số 0,1 cho 1900 - 1999; số 2,3 cho 2000-2099), 2 chữ số tiếp theo là hai chữ số cuối năm sinh',
  packagePolicyTermMax: 'Thời hạn hợp đồng không được lớn hơn 100 năm',
  packagePremiumTermMin: 'Thời hạn đóng phí phải lớn hơn hoặc bằng 3 năm',
  packagePremiumTermMax: 'Thời hạn đóng phí không được lớn hơn 100 năm',
  packagePremiumTermMaxPolicyTerm: 'Thời hạn đóng phí không được lớn hơn Thời hạn hợp đồng',
  multiplesOfOneMillion: 'Số tiền phải là bội số của 1.000.000',
  multiplesOfHundredThousands: 'Số tiền phải là bội số của 100.000',
  multiplesOfOneThousand: 'Phí bảo hiểm phải là bội số của 1.000 VND',
  minValueQuarterly: 'Phí bảo hiểm tối thiểu là 2.000.000 VND/quý',
  minValueSemiAnnual: 'Phí bảo hiểm tối thiểu là 3.000.000 VND/nửa năm',
  minValueAnnual: 'Phí bảo hiểm tối thiểu là 6.000.000 VND/năm',
  minValueTenMillions: 'Số tiền >= 10.000.000 VND và phải là bội số của 1.000.000',
  maxValue: 'Phí bảo hiểm đang vượt quá 30% thu nhập năm. Vui lòng nhập lại',
  additionalProductDuplicated:
    'Sản phẩm bổ trợ đã được chọn cho Người được bảo hiểm này. Vui lòng chọn sản phẩm bổ trợ hoặc Người được bảo hiểm khác.',
  micProductDuplicated:
    'Sản phẩm Mic đã được chọn cho Người được bảo hiểm này. Vui lòng chọn sản phẩm Mic với Người được bảo hiểm khác.',
  micNameRequired: 'Vui lòng chọn sản phẩm Mic hoặc sản phẩm bổ trợ',
  invalidTrmrName: 'NĐBH chính không được quyền mua BH bổ sung TRMR',
  ageInvalid30to65: 'Tuổi tham gia BH phải từ 30 ngày tuổi đến 65 tuổi',
  ageInvalid30to60: 'Tuổi tham gia BH phải từ 30 ngày tuổi đến 60 tuổi',
  ageInvalid30to70: 'Tuổi tham gia BH phải từ 30 ngày tuổi đến 70 tuổi',
  ageInvalid18to60: 'Tuổi tham gia BH phải từ 18 đến 60 tuổi',
  ageInvalid18: 'Người mua bảo hiểm phải đủ từ 18 tuổi trở lên',
  minYear: 'Số năm phải lớn hơn 0',
  maxYear: 'Số năm phải nhỏ hơn 100',
  hintAddress: '*Vui lòng nhập đầy đủ thông tin địa chỉ số nhà/ngõ, Xã/Phường, Quận/Huyện, Tỉnh/Thành phố',
  selectGender: 'Chọn lại giới tính do trùng với Người mua bảo hiểm',
};

export const reverseCustomerDobValidationMessages = {
  required: 'Ngày sinh bắt buộc nhập',
  minDays: 'Tuổi của người mua bảo hiểm phải từ đủ 15 ngày tuổi trở lên',
  maxAge: 'Độ tuổi tham gia bảo hiểm phải từ 15 ngày tuổi đến 65 tuổi',
};

export const reverseCustomerYearlyExpenseValidationMessages = {
  multiples: 'Số tiền phải là bội số của 1.000.000',
  minValue: 'Số tiền bảo hiểm >= 10.000.000 VND',
};
