import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';

import { dataStepsQuestion, isCheckFile, MixQuestionsContext, useIllustration } from '../../../form';
type Props = {
  step: number;
  handleChangeStep?: (val: number) => void;
};

export const StepLine = React.memo<Props>(({ step }) => {
  const { dataDefine } = useContext(MixQuestionsContext);
  const { currentAssured } = useIllustration();
  const isMale = useMemo(() => currentAssured?.gender === 'MALE', [currentAssured]);
  const assuredAge = useMemo(() => currentAssured?.age ?? 18, [currentAssured?.age]);
  return (
    <div className="pagination-line">
      {dataStepsQuestion?.map((el: { id: string }, index: number) => {
        if (isMale && el.id === '10' && assuredAge > 12) return;
        return (
          <span key={el?.id} className={classNames([step === index && 'active', index < step && 'done'])}>
            {(isMale && el.id === '11') || assuredAge <= 12 ? index : index + 1}
          </span>
        );
      })}
      {isCheckFile(dataDefine) && (
        <span className={classNames([step === dataStepsQuestion.length && 'active'])}>
          {isMale ? dataStepsQuestion.length : dataStepsQuestion.length + 1}
        </span>
      )}
    </div>
  );
});
