import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Select, SelectItem } from '@mantine/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { sumBy } from 'lodash';
import { stringify } from 'qs';
import { memo, useCallback, useEffect, useMemo, useRef, useState, ReactNode } from 'react';
import { Controller, FieldErrors, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { defaultMicBenefits } from '..';
import { Empty, FormLabel, NumberInput, RadioGroup } from '../../../components';
import { DATE_FORMAT } from '../../../constants';
import { useDialog, useLeads } from '../../../contexts';
import { getAge, getBS, getIndexSumAssured, getMessage, getMicBenefits, validationMessages } from '../../../helpers';
import { useQueryString } from '../../../hooks';
import { vi } from '../../../i18n/vi';
import { BookmarkSVG, ChevronDownSVG, GiftSVG, PlusSVG, TrashSVG, WarningSVG } from '../../../icons';
import {
  AdditionalAssuredProductEnum,
  CreateQuotationMutationVariables,
  GenderEnum,
  IdentityDocumentTypeEnum,
  InsuranceBenefitEnum,
  InsuranceDiscountCodeEnum,
  InsuranceTypeEnum,
  PackagePaymentPeriodEnum,
  ParentInfoMicType,
  RelationshipTypeEnum,
  useCreateQuotationMutation,
} from '../../../services';
import {
  AssuredTypeEnum,
  ChooseMicPackage,
  CollapseHeader,
  CollapseHeaderType,
  getMICMessage,
  insuranceTypes,
  MAX_LENGTH_AMOUNT,
  MAX_SUM_ASSURED,
  MicMessageTypeEnum,
  ScreenParams,
  useInsuranceDetailValidationSchema,
} from '../components';
import { MicInsuranceBenefitPackageType } from '../components/choose-package/utils';
import { MoneyInputSuggest } from '../components/money-suggestion/money-suggestion';

import {
  AdditionalProductEntity,
  FormStepEnum,
  InsuranceDetailFormData,
  useIllustration,
  User,
} from './illustration-form-screen';
import { SearchInsuranceHealth } from './search-insurance-health';

const insuranceBenefitsOptions: SelectItem[] = [
  {
    value: InsuranceBenefitEnum.BASIC,
    label: 'Quyền lợi bảo hiểm cơ bản',
  },
  {
    value: InsuranceBenefitEnum.ADVANCED,
    label: 'Quyền lợi bảo hiểm nâng cao',
  },
];

export const supportingInsuranceOptions: SelectItem[] = [
  {
    value: AdditionalAssuredProductEnum.COI_RIDER,
    label: 'Bảo hiểm tử vong & thương tật toàn bộ vĩnh viễn do tai nạn',
  },
  {
    value: AdditionalAssuredProductEnum.HSCR,
    label: 'Bảo hiểm hỗ trợ viện phí & chi phí phẫu thuật',
  },
  {
    value: AdditionalAssuredProductEnum.CIR,
    label: 'Bảo hiểm bệnh hiểm nghèo',
  },
  {
    value: AdditionalAssuredProductEnum.ADDR,
    label: 'Bảo hiểm tai nạn',
  },
  {
    value: AdditionalAssuredProductEnum.PWR,
    label: 'Bảo hiểm miễn thu phí bảo hiểm',
  },
  {
    value: AdditionalAssuredProductEnum.TRMR,
    label: 'Bảo hiểm tử vong & thương tật toàn bộ vĩnh viễn',
  },
];

const insurancePeriodOptions: SelectItem[] = [
  {
    value: PackagePaymentPeriodEnum.ANNUAL,
    label: 'Hàng năm',
  },
  {
    value: PackagePaymentPeriodEnum.SEMI_ANNUAL,
    label: 'Nửa năm',
  },
  {
    value: PackagePaymentPeriodEnum.QUARTERLY,
    label: 'Hàng quý',
  },
];

// const discountCodeOptions: SelectItem[] = [
//   {
//     value: InsuranceDiscountCodeEnum.NOT_APPLY,
//     label: 'Không giảm giá',
//   },
//   {
//     value: InsuranceDiscountCodeEnum.DISCOUNT_FOR_MBAL_STAFF,
//     label: 'Giảm giá cho nhân viên MBAL',
//   },
// ];

const raiderDeductFundOptions = [
  {
    label: 'Có',
    value: true,
  },
  {
    label: 'Không',
    value: false,
  },
];

export type AssuredOptionType = SelectItem & {
  assuredAge: number;
  assuredDays: number;
  dob: string;
  type?: AssuredTypeEnum;
  maxSumAssured?: number;
  is_customer?: boolean;
  relationship?: RelationshipTypeEnum;
};

export const InsuranceDetailForm = memo(() => {
  const { processId = 0 } = useQueryString<ScreenParams>();

  const { showDialog } = useDialog();

  const {
    insuranceType,
    insuranceDetail,
    insuredPerson,
    customer_is_assured,
    onUpdateQuotation,
    quotation,
    onUpdateInsuranceDetail,
    beneficiariesPerson,
    persons,
  } = useIllustration();

  const { leadsCustomer, onUpdateLeadsInsuranceDetailForm, onUpdateLeadsQuotation } = useLeads();

  // Người được bảo hiểm options
  const insuredPersonOptions: AssuredOptionType[] = useMemo(() => {
    return [
      ...(persons ?? [])?.map((it) => ({
        value: it.id ?? '-1',
        label: it.full_name,
        assuredAge: getAge(it.dob),
        assuredDays: dayjs().diff(dayjs(it.dob), 'day'),
        dob: dayjs(it?.dob).format(DATE_FORMAT.server),
        maxSumAssured: it?.annual_income ? it?.annual_income * 12 * getIndexSumAssured(it?.dob) : MAX_SUM_ASSURED,
        type: it?.type,
        gender: it?.gender,
        is_customer: it?.is_customer ?? false,
        relationship: it?.relationship,
      })),
    ];
  }, [persons]);

  const selectedInsurance = useMemo(() => insuranceTypes.find((it) => it.type === insuranceType), [insuranceType]);

  const annualIncomeBuyer = useMemo(() => insuredPerson?.customer?.annual_income ?? 0, [insuredPerson?.customer]);
  const validationSchema = useInsuranceDetailValidationSchema(
    insuredPersonOptions,
    selectedInsurance?.type,
    annualIncomeBuyer,
  );

  const defaultPackagePremiumTerm = useMemo(() => {
    if (insuranceType === InsuranceTypeEnum.ULRP_3_0) return 5;

    return insuranceType === InsuranceTypeEnum.ULRP ? 10 : undefined;
  }, [insuranceType]);

  const insuranceAge = useMemo(
    () => (insuredPerson?.assured.dob ? getAge(insuredPerson?.assured.dob) : 0),
    [insuredPerson?.assured.dob],
  );

  const {
    trigger,
    watch,
    setValue,
    handleSubmit: onSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<InsuranceDetailFormData>({
    defaultValues: useMemo(() => {
      return insuranceDetail
        ? {
            ...insuranceDetail,
            prime: {
              package_benefit_type: insuranceDetail.prime.package_benefit_type ?? InsuranceBenefitEnum.BASIC,
              package_premium_term: insuranceDetail.prime.package_premium_term ?? defaultPackagePremiumTerm,
              package_payment_period: insuranceDetail.prime.package_payment_period ?? PackagePaymentPeriodEnum.ANNUAL,
              package_sum_assured: insuranceDetail.prime.package_sum_assured ?? 500000000,
              package_periodic_premium: insuranceDetail.prime.package_periodic_premium ?? leadsCustomer?.input_amount,
              package_policy_term:
                insuranceType === InsuranceTypeEnum.ULRP
                  ? 100 - insuranceAge
                  : [11, 15, 20, 25, 75 - insuranceAge, 100 - insuranceAge].includes(
                      insuranceDetail.prime.package_policy_term,
                    )
                  ? insuranceDetail.prime.package_policy_term
                  : 100 - insuranceAge,
            },
            additional_products:
              insuranceDetail?.additional_products?.length > 0 ? insuranceDetail?.additional_products : undefined,
            mic_additional_products:
              insuranceDetail?.mic_additional_products?.length > 0
                ? insuranceDetail?.mic_additional_products
                : undefined,
          }
        : {
            prime: {
              package_benefit_type: InsuranceBenefitEnum.BASIC,
              package_premium_term: defaultPackagePremiumTerm,
              package_payment_period: PackagePaymentPeriodEnum.ANNUAL,
              package_sum_assured: 500000000,
              package_policy_term: 100 - insuranceAge,
            },
            additional_products: [],
            mic_additional_products: [],
            raider_deduct_fund: true,
          };
    }, [defaultPackagePremiumTerm, insuranceAge, insuranceDetail, insuranceType, leadsCustomer?.input_amount]),
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'additional_products',
    shouldUnregister: true,
  });

  const {
    fields: micFields,
    append: micAppend,
    remove: micRemove,
  } = useFieldArray({
    control,
    name: 'mic_additional_products',
    shouldUnregister: true,
  });

  const navigate = useNavigate();

  const { mutateAsync: createQuotation, isLoading } = useCreateQuotationMutation({
    onError: async (error) => {
      await showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
    },
    onSuccess: (res) => {
      onUpdateQuotation(res);
      if (leadsCustomer) {
        onUpdateLeadsQuotation(res);
      }
      navigate({
        search: stringify({
          step: FormStepEnum.RESULT,
          processId,
        }),
      });
    },
  });

  const triggerDuplicateSupportingProductError = useCallback(() => {
    const duplicateError =
      Object.values(errors.additional_products ?? {})?.filter?.(
        (it: any) =>
          it?.name?.type === 'check-duplicate-name' ||
          it?.person?.type === 'check-duplicate-person' ||
          it?.person?.type === 'check-trmr-person' ||
          it?.name?.type === 'check-trmr-name' ||
          it?.person?.type === 'check-twr-person' ||
          it?.person?.type === 'valid-age' ||
          it?.policy_term?.type === 'valid-term',
      ) ?? [];

    if (duplicateError.length !== 0) {
      duplicateError.forEach((it: any) => {
        it?.name?.ref?.name && trigger(it?.name?.ref?.name);
        it?.person?.ref?.name && trigger(it?.person?.ref?.name);
      });
    }
  }, [errors, trigger]);

  const triggerAmountRequiredError = useCallback(() => {
    const errs = Object.values(errors.amount ?? {}).filter((it: any) => it?.type === 'amount-required');

    errs.forEach((it: any) => it?.ref?.name && trigger(it.ref?.name));
  }, [errors.amount, trigger]);

  const primeFormRef = useRef<CollapseHeaderType>(null);
  const supportFormRef = useRef<CollapseHeaderType>(null);
  const amountFormRef = useRef<CollapseHeaderType>(null);
  const refererFormRef = useRef<CollapseHeaderType>(null);

  const handleSubmitError = useCallback((error: FieldErrors<InsuranceDetailFormData>) => {
    showDialog({
      type: 'TOAST',
      message: 'Vui lòng kiểm tra lại thông tin đã nhập',
    });
    if (error.prime) {
      primeFormRef.current?.toggle(true);
    }
    if (error.additional_products != null) {
      supportFormRef.current?.toggle(true);
    }
    if (error.amount != null) {
      amountFormRef.current?.toggle(true);
    }

    if (error.referrer != null) {
      refererFormRef.current?.toggle(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fillPolicyTermData = useCallback(
    (index: number) => {
      const package_policy_term = watch('prime.package_policy_term');

      if (package_policy_term == null) return;

      const it = watch(`additional_products.${index}`);

      const person = insuredPersonOptions.find((p) => p.value === it.person);

      if (it.person == null || person == null) return;

      let value: number | null = null;

      const package_premium_term = Number(watch('prime.package_premium_term') ?? 0);
      switch (it.name) {
        case AdditionalAssuredProductEnum.COI_RIDER:
          value = Math.min(66 - person.assuredAge, package_policy_term);
          break;
        case AdditionalAssuredProductEnum.ADDR:
          value = Math.min(65 - person.assuredAge, package_policy_term, package_premium_term);
          break;
        case AdditionalAssuredProductEnum.CIR:
          value = Math.min(65 - person.assuredAge, package_policy_term, package_premium_term);
          break;
        case AdditionalAssuredProductEnum.PWR:
        case AdditionalAssuredProductEnum.TRMR:
          value = Math.min(25, package_policy_term);
          break;
        case AdditionalAssuredProductEnum.HSCR:
          value = Math.min(80 - person.assuredAge, package_policy_term);
          break;
        default:
          return value;
      }
      const number = package_premium_term > 25 ? 25 : package_premium_term;
      const minValue = value > 25 ? 25 : value;
      if (insuranceType === InsuranceTypeEnum.ULRP_3_0) {
        if (it.name === AdditionalAssuredProductEnum.PWR) {
          watch('prime.package_premium_term') != null && setValue(`additional_products.${index}.sum_assured`, 0);
        } else {
          watch('prime.package_premium_term') != null &&
            setValue(`additional_products.${index}.sum_assured`, 200000000);
        }
        if (value != null) {
          setValue(`additional_products.${index}.policy_term`, value, { shouldValidate: true });
          if (it.name === AdditionalAssuredProductEnum.HSCR) {
            setValue(`additional_products.${index}.premium_term`, value, { shouldValidate: true });
          } else if (it.name === AdditionalAssuredProductEnum.COI_RIDER) {
            setValue(`additional_products.${index}.premium_term`, 0, { shouldValidate: true });
          } else if ([AdditionalAssuredProductEnum.PWR].includes(it.name)) {
            setValue(`additional_products.${index}.policy_term`, number);
            setValue(`additional_products.${index}.premium_term`, number);
          } else if ([AdditionalAssuredProductEnum.ADDR, AdditionalAssuredProductEnum.CIR].includes(it.name)) {
            setValue(`additional_products.${index}.policy_term`, minValue);
            setValue(`additional_products.${index}.premium_term`, minValue);
          } else {
            setValue(`additional_products.${index}.premium_term`, number);
          }
        }
      } else if (value != null) setValue(`additional_products.${index}.policy_term`, value, { shouldValidate: true });
    },
    [insuranceType, insuredPersonOptions, setValue, watch],
  );

  useEffect(() => {
    if (leadsCustomer) {
      fields.forEach((field, index) => {
        fillPolicyTermData(index);
      });
    }
  }, [fields, fillPolicyTermData, leadsCustomer]);

  const triggerMainSumAssured = useCallback(async () => {
    clearErrors('prime.package_sum_assured');
    const additional_products = watch('additional_products');
    const amount = watch('prime.package_sum_assured');
    const person = insuredPersonOptions?.find((it) => it.type === AssuredTypeEnum.LIFE_ASSURED);
    const findPerson = additional_products?.map((it) => it?.person === person?.value);
    const totalSumAmount = sumBy(findPerson, 'sum_assured') ?? 0;
    const maxAmount = (person?.maxSumAssured ?? MAX_SUM_ASSURED) - totalSumAmount;

    if (amount > maxAmount) {
      const res = await showDialog({
        type: 'ALERT',
        message: validationMessages.maxSumAssured,
      });
      res &&
        setError('prime.package_sum_assured', {
          type: 'custom',
          message: `Tối đa gấp ${getIndexSumAssured(insuredPerson?.assured?.dob)} lần thu nhập/năm`,
        });
    }
  }, [clearErrors, watch, insuredPersonOptions, insuredPerson?.assured?.dob, showDialog, setError]);

  const triggerSumAssured = useCallback(
    async (index: number) => {
      const additional_products = watch('additional_products');
      const it = watch(`additional_products.${index}`);

      const person = insuredPersonOptions?.find((p) => p.value === it.person);

      if (it.person == null || person == null) return;

      const findPerson = additional_products?.map((e) => e?.person === it.person);
      const totalSumAmount = sumBy(findPerson, 'sum_assured') ?? 0;

      const amount = it?.sum_assured;
      const maxAmount = (person?.maxSumAssured ?? MAX_SUM_ASSURED) - totalSumAmount;
      if (amount > maxAmount) {
        const res = await showDialog({
          type: 'ALERT',
          message: validationMessages.maxSumAssured,
        });
        res &&
          setError(`additional_products.${index}.sum_assured`, {
            type: 'custom',
            message: `Tối đa gấp ${getIndexSumAssured(person?.dob)} lần thu nhập/năm`,
          });
      }
    },
    [insuredPersonOptions, setError, showDialog, watch],
  );

  const availableAssuredPersonOptions = useCallback(() => {
    return insuredPersonOptions;
  }, [insuredPersonOptions]);

  const isSpecial = useCallback(
    (index: number) => {
      if (insuranceType === InsuranceTypeEnum.ULRP_3_0) {
        return [
          AdditionalAssuredProductEnum.COI_RIDER,
          AdditionalAssuredProductEnum.PWR,
          AdditionalAssuredProductEnum.HSCR,
        ].includes(watch(`additional_products.${index}.name`));
      }
      return [AdditionalAssuredProductEnum.PWR].includes(watch(`additional_products.${index}.name`));
    },
    [watch, insuranceType],
  );

  const getRemarkAdditionalProduct = useCallback(
    (index: number) => {
      const it = watch(`additional_products.${index}`);
      const name = watch(`additional_products.${index}.name`);
      const policy_term = watch(`additional_products.${index}.policy_term`);
      const person = insuredPersonOptions.find((p) => p.value === it.person);
      const age = person?.assuredAge ?? 0;
      return insuranceType === InsuranceTypeEnum.ULRP_3_0 && [AdditionalAssuredProductEnum.HSCR].includes(name) ? (
        <div className={classNames(['text-13px italic'])}>
          <span className="font-semibold">Tái tục hàng năm</span> đến hết tuổi bảo hiểm {Number(policy_term) + age - 1}
        </div>
      ) : (
        ''
      );
    },
    [insuredPersonOptions, watch, insuranceType],
  );

  const dataSupportingInsuranceOptions = useCallback(
    (person: string) => {
      const customerPerson = insuredPersonOptions?.find((it) => it.value === person);
      if (customerPerson?.value === '-1' && customer_is_assured === false) {
        return supportingInsuranceOptions;
      }
      return supportingInsuranceOptions.filter(
        (it) =>
          ![AdditionalAssuredProductEnum.PWR, AdditionalAssuredProductEnum.TRMR].includes(
            it.value as AdditionalAssuredProductEnum,
          ),
      );
    },
    [customer_is_assured, insuredPersonOptions],
  );

  const appendAdditionalProducts = useMemo(() => {
    return {
      person: customer_is_assured && insuredPersonOptions?.length === 1 ? insuredPersonOptions?.[0]?.value : undefined,
      sum_assured: 200000000,
    } as any;
  }, [insuredPersonOptions, customer_is_assured]);

  const micAppendAdditionalProducts = useMemo(() => {
    return {
      person: customer_is_assured && insuredPersonOptions?.length === 1 ? insuredPersonOptions?.[0]?.value : undefined,
    } as any;
  }, [customer_is_assured, insuredPersonOptions]);

  // MIC ZONE

  const checkSubset = useCallback((parentArray?: Array<string>, subsetArray?: Array<string>) => {
    return subsetArray?.every((el) => {
      return parentArray?.includes(el);
    });
  }, []);

  const dataMicAssured = useCallback(
    (value: string) => insuredPersonOptions?.find((it) => it.value === value),
    [insuredPersonOptions],
  );

  const [isLoadingSearchMIC, setIsLoadingSearchMIC] = useState<boolean>(false);
  const [isLoadingMICPackages, setIsLoadingMICPackages] = useState<boolean>(false);
  const [parentInfo, setParentInfo] = useState<ParentInfoMicType | undefined>(undefined);

  const isShowMicContract = useCallback(
    (index: number) => {
      const person = watch(`mic_additional_products.${index}.person`);
      const obj = insuredPersonOptions?.find((it) => it.value === person);
      return !!obj?.dob && getAge(obj?.dob) <= 6;
    },
    [insuredPersonOptions, watch],
  );

  const isChildren = useCallback((relationship?: RelationshipTypeEnum, age?: any) => {
    return relationship == RelationshipTypeEnum.CHILDREN && getAge(age) <= 6;
  }, []);

  const handleAlert = useCallback(
    async (type: MicMessageTypeEnum, name?: string, onOk?: () => void, onCancel?: () => void, icon?: ReactNode) => {
      const res = await showDialog({
        type: 'CONFIRM',
        icon: icon || <WarningSVG />,
        message: getMICMessage(type, name),
        confirmText: 'Đồng ý',
        cancelText: 'Từ chối',
      });
      res && onOk?.();
      !res && onCancel?.();
    },
    [showDialog],
  );

  const handleDataMics = useCallback(
    (data: AdditionalProductEntity[]) => {
      const micAdditionalProducts = data?.map((it) => {
        const assured = persons?.find((p) => p.id === it.person);
        return {
          ...it,
          assured,
        };
      });
      const parents = micAdditionalProducts
        ?.filter(
          (it) =>
            (!!it?.assured?.is_customer || it.assured?.relationship === RelationshipTypeEnum.COUPLE) && !!it?.mic_name,
        )
        .sort((a, b) => Number(b?.mic_name) - Number(a?.mic_name));

      const allChilds = micAdditionalProducts?.filter(
        (it) => isChildren(it.assured?.relationship, it?.assured?.dob) && !!it?.mic_name,
      );

      const childs = allChilds
        ?.filter((it) => !it?.contract_name)
        .sort((a, b) => Number(b?.mic_name) - Number(a?.mic_name));

      const parentContactChildIds = allChilds?.filter((it) => !!it?.contract_name)?.map((it) => it?.assured?.id);

      const childPersons = persons?.filter(
        (it) => isChildren(it?.relationship, it?.dob) && !parentContactChildIds?.includes(it?.id),
      );
      return {
        micAdditionalProducts,
        isHasMic: !!micAdditionalProducts?.length,
        parents,
        parent: parents?.[0],
        parentMicBenefits: parents?.[0]?.mic_benefits?.filter((b) => !['bs2', 'bs3'].includes(b)),
        allChilds,
        childs,
        child: childs?.[0],
        name_childs: childs?.map((it) => it?.assured?.full_name)?.join(', '),
        childPersons,
      };
    },
    [isChildren, persons],
  );

  const onSetMicAdditionalProducts = useCallback(
    (index: number, data?: AdditionalProductEntity) => {
      setValue(`mic_additional_products.${index}.mic_name`, data?.mic_name);
      setValue(`mic_additional_products.${index}.mic_package_selected`, data?.mic_package_selected);
      setValue(`mic_additional_products.${index}.mic_benefits`, data?.mic_benefits);
    },
    [setValue],
  );

  const onSetParentInfo = useCallback((assured?: User, nhom?: string, benefits?: string[]) => {
    setParentInfo(
      nhom
        ? {
            full_name: assured?.full_name,
            identification_number: assured?.identification_id,
            phone_number: assured?.phone_number,
            mic_request: {
              ...getMicBenefits(benefits),
              nhom,
            },
          }
        : undefined,
    );
  }, []);

  const onChangeMicPerson = useCallback(
    async (index: number, person: string) => {
      const assured = persons?.find((p) => p.id === person);
      const isParent = assured?.relationship === RelationshipTypeEnum.COUPLE || assured?.is_customer;
      const { parent, child, parentMicBenefits } = handleDataMics(watch('mic_additional_products'));
      if (isChildren(assured?.relationship, assured?.dob)) {
        onSetMicAdditionalProducts(index, {
          ...parent,
          mic_benefits: parentMicBenefits,
        });
      }
      if (isParent && child?.mic_name) {
        onSetMicAdditionalProducts(index, child);
        onSetParentInfo(assured, child?.mic_name, child?.mic_benefits);
      }
    },
    [persons, handleDataMics, watch, isChildren, onSetMicAdditionalProducts, onSetParentInfo],
  );

  const onChangeMicPackage = useCallback(
    async (index: number, selected?: MicInsuranceBenefitPackageType) => {
      const assured = persons?.find((p) => p.id === watch(`mic_additional_products.${index}.person`));
      const isParent = assured?.relationship === RelationshipTypeEnum.COUPLE || assured?.is_customer;
      const { name_childs } = handleDataMics(watch('mic_additional_products'));
      //Set Value
      const benefits: string[] = watch(`mic_additional_products.${index}.mic_benefits`) || [];
      setValue(`mic_additional_products.${index}.mic_benefits`, benefits.length ? benefits : defaultMicBenefits);
      setValue(`mic_additional_products.${index}.mic_package_selected`, selected);

      clearErrors(`mic_additional_products.${index}`);
      isParent &&
        (!parentInfo || !name_childs?.length || Number(selected?.id) >= Number(parentInfo?.mic_request?.nhom)) &&
        onSetParentInfo(assured, selected?.id, defaultMicBenefits);
    },
    [persons, handleDataMics, watch, setValue, clearErrors, parentInfo, onSetParentInfo],
  );

  const onSelectMicPackage = useCallback(
    async (index: number, selected?: MicInsuranceBenefitPackageType) => {
      const id = Number(selected?.id);
      const assured = persons?.find((p) => p.id === watch(`mic_additional_products.${index}.person`));
      const isParent = assured?.relationship === RelationshipTypeEnum.COUPLE || assured?.is_customer;
      const { parent, child, name_childs, parentMicBenefits } = handleDataMics(watch('mic_additional_products'));
      //Validation
      if (Number(id) > Number(parent?.mic_package_selected?.id) && isChildren(assured?.relationship, assured?.dob)) {
        handleAlert(MicMessageTypeEnum.NO_MATCH_PACKAGE, assured?.full_name, undefined, () =>
          onSetMicAdditionalProducts(index, {
            ...parent,
            mic_benefits: parentMicBenefits,
          }),
        );
        return;
      }
      if (Number(id) < Number(child?.mic_package_selected?.id) && isParent && !parent) {
        handleAlert(
          MicMessageTypeEnum.NO_MATCH_PACKAGE,
          child?.assured?.full_name,
          () => onSetParentInfo(assured, selected?.id, defaultMicBenefits),
          () => onSetMicAdditionalProducts(index, child),
        );
        return;
      }
      if (
        Number(selected?.id) < Number(parent?.mic_name) &&
        assured?.id === parent?.assured?.id &&
        isParent &&
        !!name_childs?.length
      ) {
        const newParent = handleDataMics(watch('mic_additional_products'))?.parents?.find(
          (it) => it?.person !== assured?.id,
        );
        handleAlert(
          MicMessageTypeEnum.NO_MATCH_PACKAGE,
          name_childs,
          () =>
            onSetParentInfo(assured, newParent?.mic_name?.toString(), newParent?.mic_benefits || defaultMicBenefits),
          () => onSetMicAdditionalProducts(index, parent),
        );
        return;
      }
    },
    [persons, handleDataMics, watch, isChildren, handleAlert, onSetMicAdditionalProducts, onSetParentInfo],
  );

  const onChangeMicBenefits = useCallback(
    async (index: number, data?: string[]) => {
      const selected = watch(`mic_additional_products.${index}.mic_name`) || '';
      const assured = persons?.find((p) => p.id === watch(`mic_additional_products.${index}.person`));
      const isParent = assured?.relationship === RelationshipTypeEnum.COUPLE || assured?.is_customer;

      const { parents, childs, name_childs, parentMicBenefits } = handleDataMics(watch(`mic_additional_products`));
      const parent = parents?.find((it) => Number(it?.mic_name) >= Number(selected));

      isParent &&
        (Number(selected) > Number(parent?.mic_name) || !parentInfo) &&
        onSetParentInfo(assured, selected, data);

      if (
        isChildren(assured?.relationship, assured?.dob) &&
        !!data &&
        parent?.mic_benefits &&
        (data?.length > parent?.mic_benefits?.length ||
          !(data?.length <= parent?.mic_benefits?.length && checkSubset(parent?.mic_benefits, data)))
      ) {
        handleAlert(MicMessageTypeEnum.NO_MATCH_PACKAGE, assured?.full_name, undefined, () =>
          setValue(`mic_additional_products.${index}.mic_benefits`, parentMicBenefits),
        );
      }
      if (
        isParent &&
        Number(parent?.mic_name) === Number(selected) &&
        !!data &&
        childs?.[0]?.mic_benefits &&
        data?.length <= childs?.[0]?.mic_benefits?.length &&
        !checkSubset(data, childs?.[0]?.mic_benefits)
      ) {
        const newParent = handleDataMics(watch('mic_additional_products'))?.parents?.find(
          (it) => it?.person !== assured?.id,
        );
        handleAlert(
          MicMessageTypeEnum.NO_MATCH_PACKAGE,
          name_childs,
          () =>
            onSetParentInfo(assured, newParent?.mic_name?.toString(), newParent?.mic_benefits || defaultMicBenefits),
          () => setValue(`mic_additional_products.${index}.mic_benefits`, parent?.mic_benefits),
        );
        return;
      }
      setValue(`mic_additional_products.${index}.mic_benefits`, data);
    },
    [watch, persons, handleDataMics, parentInfo, onSetParentInfo, isChildren, checkSubset, setValue, handleAlert],
  );

  const handleRemoveMicAdditionalProducts = useCallback(
    async (index: number) => {
      const assured = persons?.find((p) => p.id === watch(`mic_additional_products.${index}.person`));
      const { parent } = handleDataMics(watch('mic_additional_products'));
      if (assured?.id !== parent?.person) return;
      onSetParentInfo(parent?.assured, parent?.mic_name, parent?.mic_benefits);
    },
    [handleDataMics, onSetParentInfo, persons, watch],
  );

  const onGetDataParentInfo = useCallback(
    (index: number) => {
      const assured = persons?.find((p) => p.id === watch(`mic_additional_products.${index}.person`));
      return isChildren(assured?.relationship, assured?.dob) && !!parentInfo?.full_name ? parentInfo : undefined;
    },
    [persons, isChildren, parentInfo, watch],
  );

  const assured = useMemo(
    () => (customer_is_assured ? insuredPerson?.customer : insuredPerson?.assured),
    [customer_is_assured, insuredPerson?.assured, insuredPerson?.customer],
  );

  const onHandleSubmit = useCallback(
    async (data: InsuranceDetailFormData) => {
      if (assured && insuredPerson && insuranceType) {
        const customerMicRequest = data?.mic_additional_products?.find((it) => it.person === '-1');
        const assuredMicRequest = data?.mic_additional_products?.find((it) =>
          customer_is_assured ? it.person === '-1' : it.person === '0',
        );
        const products = data?.additional_products?.concat(data?.mic_additional_products ?? []);
        const input: CreateQuotationMutationVariables = {
          id: quotation?.id,
          customer: {
            additional_products: data.additional_products
              .filter((it) => it.person === '-1')
              .map((it) => ({
                policy_term: it.policy_term,
                premium_term: it.premium_term,
                sum_assured: it.sum_assured,
                type: it.name,
              })),
            dob: dayjs(insuredPerson.customer.dob).format('YYYY-MM-DD'),
            full_name: insuredPerson.customer.full_name?.toLocaleUpperCase(),
            gender: insuredPerson.customer.gender,
            identification_id: insuredPerson.customer.identification_id,
            identification_type: insuredPerson.customer.identification_type,
            id_issued_place: insuredPerson.customer?.id_issued_place,
            identification_date: dayjs(insuredPerson.customer?.identification_date ?? dayjs()).format('YYYY-MM-DD'),
            married: insuredPerson.customer.married,
            occupation_id: +insuredPerson.customer.occupation_id,
            phone_number: insuredPerson.customer.phone_number,
            email: insuredPerson.customer.email,
            address: {
              province_name: insuredPerson?.customer?.province_name,
              district_name: insuredPerson?.customer?.district_name,
              ward_name: insuredPerson?.customer?.ward_name,
              line1: insuredPerson.customer.address,
            },
            nationality_code: insuredPerson?.customer?.nationality_code,
            annual_income: insuredPerson?.customer?.annual_income ? insuredPerson.customer.annual_income * 12 : 0,
            mic_request: customerMicRequest
              ? {
                  bs1: getBS(customerMicRequest?.mic_benefits, 'bs1'),
                  bs2: getBS(customerMicRequest?.mic_benefits, 'bs2'),
                  bs3: getBS(customerMicRequest?.mic_benefits, 'bs3'),
                  bs4: getBS(customerMicRequest?.mic_benefits, 'bs4'),
                  nhom: customerMicRequest?.mic_package_selected?.id,
                  num_of_plus_day: 8,
                }
              : undefined,
          },
          assured: {
            dob: dayjs(assured?.dob).format('YYYY-MM-DD'),
            full_name: assured?.full_name?.toLocaleUpperCase(),
            married: assured?.married,
            phone_number: assured?.phone_number,
            gender: assured?.gender,
            identification_id: assured?.identification_id,
            identification_type: assured?.identification_type,
            occupation_id: +(assured?.occupation_id ?? 0),
            email: assured?.email,
            address: {
              province_name: assured?.province_name,
              district_name: assured?.district_name,
              ward_name: assured?.ward_name,
              line1: assured?.address,
            },
            nationality_code: assured?.nationality_code,
            relationship_with_policy_holder: assured?.relationship ?? RelationshipTypeEnum.INSURED_PERSON,
            annual_income: assured.annual_income ? assured.annual_income * 12 : 0,
            additional_products: data.additional_products
              .filter((it) => (customer_is_assured ? it.person === '-1' : it.person === '0'))
              .map((it) => ({
                policy_term: it.policy_term,
                premium_term: it.premium_term,
                sum_assured: it.sum_assured,
                type: it.name,
              })),
            mic_request: assuredMicRequest
              ? {
                  bs1: getBS(assuredMicRequest?.mic_benefits, 'bs1'),
                  bs2: getBS(assuredMicRequest?.mic_benefits, 'bs2'),
                  bs3: getBS(assuredMicRequest?.mic_benefits, 'bs3'),
                  bs4: getBS(assuredMicRequest?.mic_benefits, 'bs4'),
                  nhom: assuredMicRequest?.mic_package_selected?.id,
                  num_of_plus_day: 8,
                  parent_contract: assuredMicRequest?.contract_name
                    ? {
                        cmt_bm: assuredMicRequest?.contract_card_id,
                        mobi_bm: assuredMicRequest?.contract_phone_number,
                        so_hd_bm: assuredMicRequest?.contract_num,
                        ten_bm: assuredMicRequest?.contract_name,
                      }
                    : undefined,
                }
              : undefined,
          },
          additional_assureds:
            (insuredPerson.additional_assureds ?? [])?.map((it, index) => {
              const additionalProducts = products.filter((a) => a.person === `${index + 1}` && !!a?.name);
              const micAdditionalProducts = products.find((a) => a.person === `${index + 1}` && !!a?.mic_name);

              return {
                dob: dayjs(it.dob).format('YYYY-MM-DD'),
                full_name: it.full_name?.toLocaleUpperCase(),
                gender: it.gender,
                identification_type: it.identification_type ?? IdentityDocumentTypeEnum.NATIONAL_ID,
                identification_id: it.identification_id ?? '',
                married: it.married,
                occupation_id: it.occupation_id,
                phone_number: it.phone_number,
                additional_products: additionalProducts.map((it) => ({
                  policy_term: it.policy_term,
                  premium_term: it.premium_term,
                  sum_assured: it.sum_assured,
                  type: it.name,
                })),
                email: it.email,
                address: {
                  province_name: it?.province_name,
                  district_name: it?.district_name,
                  ward_name: it?.ward_name,
                  line1: it.address,
                },
                nationality_code: it?.nationality_code,
                relationship_with_policy_holder: it.relationship ?? RelationshipTypeEnum.INSURED_PERSON,
                annual_income: it.annual_income ? it.annual_income * 12 : 0,
                mic_request: micAdditionalProducts
                  ? {
                      bs1: getBS(micAdditionalProducts?.mic_benefits, 'bs1'),
                      bs2: getBS(micAdditionalProducts?.mic_benefits, 'bs2'),
                      bs3: getBS(micAdditionalProducts?.mic_benefits, 'bs3'),
                      bs4: getBS(micAdditionalProducts?.mic_benefits, 'bs4'),
                      nhom: micAdditionalProducts?.mic_package_selected?.id,
                      num_of_plus_day: 8,
                      parent_contract: micAdditionalProducts?.contract_name
                        ? {
                            cmt_bm: micAdditionalProducts?.contract_card_id,
                            mobi_bm: micAdditionalProducts?.contract_phone_number,
                            so_hd_bm: micAdditionalProducts?.contract_num,
                            ten_bm: micAdditionalProducts?.contract_name,
                          }
                        : undefined,
                    }
                  : undefined,
              };
            }) ?? [],
          amount: data.amount?.end_year
            ? {
                end_year: data.amount?.end_year,
                start_year: data.amount?.start_year,
                value: data.amount?.value,
              }
            : undefined,

          customer_is_assured: customer_is_assured ?? false,
          package_benefit_type: data.prime.package_benefit_type,
          package_payment_period: data.prime.package_payment_period,
          package_sum_assured: data.prime.package_sum_assured,
          package_periodic_premium: data.prime.package_periodic_premium,
          package_policy_term: data.prime.package_policy_term,
          package_premium_term: data.prime.package_premium_term,
          discount_code: InsuranceDiscountCodeEnum.NOT_APPLY,
          raider_deduct_fund: data.raider_deduct_fund ?? false,
          type: insuranceType,
          beneficiary: beneficiariesPerson
            ? {
                dob: dayjs(beneficiariesPerson?.dob ?? dayjs()).format('YYYY-MM-DD'),
                full_name: beneficiariesPerson?.full_name ?? '',
                phone_number: beneficiariesPerson?.phone_number,
                gender: beneficiariesPerson?.gender ?? GenderEnum.MALE,
                identification_id: beneficiariesPerson?.identification_id,
                identification_type: beneficiariesPerson?.identification_type,
                occupation_id: +(beneficiariesPerson?.occupation_id ?? 0),
                email: beneficiariesPerson?.email,
                address: {
                  province_name: beneficiariesPerson?.province_name,
                  district_name: beneficiariesPerson?.district_name,
                  ward_name: beneficiariesPerson?.ward_name,
                  line1: beneficiariesPerson?.address,
                },
                nationality_code: beneficiariesPerson?.nationality_code,
                relationship_with_main_assured: beneficiariesPerson.relationship ?? RelationshipTypeEnum.OTHER,
                benefit_rate: beneficiariesPerson?.benefit_rate ?? 100,
              }
            : undefined,
          referrer: insuranceDetail?.referrer ?? undefined,
          sale: insuranceDetail?.sale ?? undefined,
          supporter: insuranceDetail?.supporter ?? undefined,
          process_id: processId,
        };

        onUpdateInsuranceDetail(data);
        if (leadsCustomer) {
          onUpdateLeadsInsuranceDetailForm(data);
        }
        await createQuotation(input);
      }
    },
    [
      assured,
      beneficiariesPerson,
      createQuotation,
      customer_is_assured,
      insuranceDetail?.referrer,
      insuranceDetail?.sale,
      insuranceDetail?.supporter,
      insuranceType,
      insuredPerson,
      leadsCustomer,
      onUpdateInsuranceDetail,
      onUpdateLeadsInsuranceDetailForm,
      processId,
      quotation?.id,
    ],
  );

  useEffect(() => {
    const { parent } = handleDataMics(watch('mic_additional_products'));
    !!parent && onSetParentInfo(parent?.assured, parent?.mic_name, parent?.mic_benefits);
  }, [handleDataMics, onSetParentInfo, watch]);

  const handleBeforeSubmit = useCallback(
    async (data: InsuranceDetailFormData) => {
      const { parents, parent, parentMicBenefits, isHasMic, childs, child, name_childs, childPersons } = handleDataMics(
        data?.mic_additional_products,
      );
      if (!isHasMic) return onHandleSubmit(data);
      if (!childs.length && !!childPersons?.length) {
        handleAlert(
          MicMessageTypeEnum.ONLY_PARENT,
          name_childs,
          () => {
            const a =
              persons
                ?.filter((it) => isChildren(it?.relationship, it?.dob))
                ?.map((it) => {
                  return {
                    ...parent,
                    person: it?.id || '',
                    mic_benefits: parentMicBenefits,
                  };
                }) ?? [];
            setValue('mic_additional_products', data?.mic_additional_products?.concat(a));
          },
          () => onHandleSubmit(data),
          <GiftSVG />,
        );
        return;
      }
      if (!parents?.length && !!childs.length && !!childPersons?.length) {
        handleAlert(
          MicMessageTypeEnum.ONLY_CHILD,
          name_childs,
          () => {
            const a =
              persons?.find((it) => it?.relationship === RelationshipTypeEnum.COUPLE) ||
              persons?.find((it) => it?.is_customer);

            onSetParentInfo(a, child?.mic_name, child?.mic_benefits);
            setValue(
              'mic_additional_products',
              data?.mic_additional_products?.concat([
                {
                  ...child,
                  person: a?.id || '',
                  mic_benefits: child?.mic_benefits,
                },
              ]),
            );
          },
          () => onHandleSubmit(data),
          <GiftSVG />,
        );
        return;
      }
      onHandleSubmit(data);
    },
    [handleAlert, handleDataMics, isChildren, onHandleSubmit, onSetParentInfo, persons, setValue],
  );

  const handleRemoveSupportContract = useCallback(
    async (onFullFill: () => void) => {
      const res = await showDialog({
        type: 'CONFIRM',
        message: (
          <div className="text-center text-c_EB5757 my-20px font-medium">
            Nếu bạn xác nhận xoá, dữ liệu của bản ghi sẽ không thể khôi phục.
          </div>
        ),
      });

      res && onFullFill();
    },
    [showDialog],
  );

  return (
    <div className="pb-110px">
      <div className="h-240px bg-cover relative" style={{ backgroundImage: `url("${selectedInsurance?.image}")` }}>
        <span className="text-c_E9F6FF text-25px font-bold absolute bottom-20px left-20px leading-[32px] uppercase">
          {selectedInsurance?.label}
        </span>
      </div>
      <div className="px-16px py-12px bg-primary text-18px text-white font-bold uppercase">Thông tin sản phẩm</div>

      <form onSubmit={onSubmit(handleBeforeSubmit, handleSubmitError)}>
        <div>
          <CollapseHeader ref={primeFormRef} title="Sản phẩm chính" icon={<BookmarkSVG />}>
            <div className="p-16px">
              <div>
                <FormLabel required>Quyền lợi bảo hiểm</FormLabel>
                <Controller
                  control={control}
                  name="prime.package_benefit_type"
                  render={({ field: { value, onBlur, onChange } }) => (
                    <Select
                      data={insuranceBenefitsOptions}
                      maxDropdownHeight={300}
                      rightSection={<ChevronDownSVG />}
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      error={errors.prime?.package_benefit_type?.message}
                    />
                  )}
                />
              </div>

              <div>
                <FormLabel required>Thời hạn hợp đồng</FormLabel>
                <Controller
                  control={control}
                  name="prime.package_policy_term"
                  render={({ field: { onBlur, onChange, value } }) => (
                    <Select
                      data={
                        insuranceType === InsuranceTypeEnum.ULRP_3_0
                          ? [
                              '11',
                              '15',
                              '20',
                              '25',
                              { label: 'Đến 75 tuổi', value: `${75 - insuranceAge}` },
                              { label: 'Đến 100 tuổi', value: `${100 - insuranceAge}` },
                            ]
                          : [
                              {
                                label: 'Đến 100 tuổi',
                                value: `${100 - insuranceAge}`,
                              },
                            ]
                      }
                      placeholder="Chọn thời hạn hợp đồng"
                      maxDropdownHeight={300}
                      value={value?.toString()}
                      onBlur={onBlur}
                      onChange={(val) => {
                        if (val != null) {
                          const value = +val;
                          onChange(value);
                          if (
                            errors.prime?.package_premium_term?.type === 'max-policy-term' ||
                            watch('prime.package_premium_term') != null
                          ) {
                            trigger('prime.package_premium_term');
                          }

                          watch('additional_products').forEach((it, index) => {
                            if (it.name === AdditionalAssuredProductEnum.PWR && value != null) {
                              setValue(`additional_products.${index}.policy_term`, value >= 25 ? 25 : value);
                            }
                          });
                        }
                      }}
                      error={errors.prime?.package_policy_term?.message}
                      rightSection={<span className="text-16px font-semibold">Năm</span>}
                      disabled={insuranceType === InsuranceTypeEnum.ULRP}
                    />
                  )}
                />
              </div>

              <div>
                <FormLabel required>Thời hạn đóng phí</FormLabel>
                <Controller
                  control={control}
                  name="prime.package_premium_term"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <NumberInput
                      placeholder="Nhập thời hạn đóng phí"
                      rightSection={<span className="text-16px font-semibold">Năm</span>}
                      error={errors.prime?.package_premium_term?.message}
                      onBlur={onBlur}
                      onChange={(value) => {
                        onChange(value);

                        watch('additional_products').forEach((it, index) => {
                          if (it.name === AdditionalAssuredProductEnum.PWR && value != null) {
                            setValue(`additional_products.${index}.premium_term`, value >= 25 ? 25 : value);
                          }
                        });

                        errors.additional_products
                          ?.filter?.((it) => it?.premium_term?.type === 'max-of_package_premium_term')
                          .forEach((it) => it?.premium_term?.ref?.name && trigger(it?.premium_term?.ref?.name as any));

                        if (errors.amount?.end_year?.type === 'less-than-package_premium_term') {
                          trigger('amount.end_year');
                        }
                      }}
                      value={value}
                    />
                  )}
                />
              </div>

              <div>
                <FormLabel required>Định kỳ đóng phí</FormLabel>
                <Controller
                  control={control}
                  name="prime.package_payment_period"
                  render={({ field: { value, onBlur, onChange } }) => (
                    <Select
                      data={insurancePeriodOptions}
                      maxDropdownHeight={300}
                      rightSection={<ChevronDownSVG />}
                      value={value}
                      onBlur={onBlur}
                      onChange={(val) => {
                        onChange(val);
                        trigger('prime.package_periodic_premium');
                        trigger('prime.package_sum_assured');
                      }}
                      error={errors.prime?.package_payment_period?.message}
                    />
                  )}
                />
              </div>

              <div>
                <FormLabel required>Số tiền bảo hiểm</FormLabel>
                <Controller
                  control={control}
                  name="prime.package_sum_assured"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <MoneyInputSuggest
                      placeholder="Nhập số tiền"
                      error={errors.prime?.package_sum_assured?.message}
                      onBlur={onBlur}
                      onChange={(val) => {
                        onChange(val);
                        triggerMainSumAssured();
                      }}
                      value={value}
                    />
                  )}
                />
              </div>

              <div>
                <FormLabel required>Phí bảo hiểm định kỳ</FormLabel>
                <Controller
                  control={control}
                  name="prime.package_periodic_premium"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <MoneyInputSuggest
                      placeholder="Nhập số tiền"
                      onBlur={onBlur}
                      onChange={(val: any) => {
                        onChange(val);
                        trigger('prime.package_sum_assured');
                      }}
                      value={value}
                      error={errors.prime?.package_periodic_premium?.message}
                    />
                  )}
                />
              </div>
            </div>
          </CollapseHeader>
        </div>

        <div className="mt-16px">
          <CollapseHeader
            ref={supportFormRef}
            title="Sản phẩm bổ trợ"
            defaultVisible={(watch('additional_products') ?? []).length === 0 ? false : true}
            renderCollapseIcon={(visible) =>
              !visible && (watch('additional_products') ?? []).length === 0 ? <PlusSVG /> : undefined
            }
            onCollapseToggle={(visible) =>
              visible &&
              (watch('additional_products') ?? []).length === 0 &&
              append(appendAdditionalProducts, {
                shouldFocus: true,
              })
            }
            icon={<BookmarkSVG />}>
            <div className="p-16px">
              {fields.map((field, index) => (
                <div key={field.id} className="p-16px border-0.5px border-solid border-c_DDDDDD rounded-6px mb-16px">
                  <div>
                    <FormLabel required>Người được bảo hiểm</FormLabel>
                    <Controller
                      control={control}
                      name={`additional_products.${index}.person`}
                      render={({ field: { value, onBlur, onChange } }) => (
                        <Select
                          placeholder="Chọn người được bảo hiểm"
                          data={insuredPersonOptions}
                          rightSection={<ChevronDownSVG />}
                          value={value}
                          onBlur={onBlur}
                          onChange={(val) => {
                            onChange(val);
                            triggerDuplicateSupportingProductError();
                            if (errors.additional_products?.[index]?.policy_term?.type === 'valid-term') {
                              trigger(`additional_products.${index}.policy_term`);
                            }
                            if (val !== watch(`additional_products.${index}.person`)) {
                              setValue(`additional_products.${index}.name`, '' as AdditionalAssuredProductEnum);
                            }
                          }}
                          error={errors.additional_products?.[index]?.person?.message}
                          nothingFound={<Empty title="Không có người phù hợp với sản phẩm" />}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <FormLabel required>Tên sản phẩm bổ trợ</FormLabel>
                    <Controller
                      control={control}
                      name={`additional_products.${index}.name`}
                      render={({ field: { value, onBlur, onChange } }) => (
                        <Select
                          data={dataSupportingInsuranceOptions(watch(`additional_products.${index}.person`))}
                          placeholder="Chọn sản phẩm bổ trợ"
                          rightSection={<ChevronDownSVG />}
                          value={value}
                          onBlur={onBlur}
                          onChange={(val: AdditionalAssuredProductEnum) => {
                            onChange(val);
                            triggerDuplicateSupportingProductError();
                            setTimeout(() => {
                              fillPolicyTermData(index);
                            }, 100);
                          }}
                          error={errors.additional_products?.[index]?.name?.message}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <FormLabel
                      required={
                        [
                          AdditionalAssuredProductEnum.COI_RIDER,
                          AdditionalAssuredProductEnum.HSCR,
                          AdditionalAssuredProductEnum.PWR,
                        ].includes(watch(`additional_products.${index}.name`)) &&
                        insuranceType === InsuranceTypeEnum.ULRP_3_0
                          ? false
                          : true
                      }>
                      Thời hạn hợp đồng
                    </FormLabel>
                    {getRemarkAdditionalProduct(index)}
                    <Controller
                      control={control}
                      name={`additional_products.${index}.policy_term`}
                      render={({ field: { value, onChange, onBlur } }) =>
                        [AdditionalAssuredProductEnum.HSCR].includes(watch(`additional_products.${index}.name`)) &&
                        insuranceType === InsuranceTypeEnum.ULRP_3_0 ? (
                          <div />
                        ) : (
                          <NumberInput
                            placeholder="Nhập thời hạn hợp đồng"
                            disabled={isSpecial(index)}
                            rightSection={<span className="text-16px font-semibold">Năm</span>}
                            error={errors.additional_products?.[index]?.policy_term?.message}
                            onBlur={onBlur}
                            onChange={(val) => {
                              onChange(val);
                              if (watch(`additional_products.${index}.name`) === AdditionalAssuredProductEnum.HSCR) {
                                setValue(`additional_products.${index}.premium_term`, val ?? 0);
                              }
                              if (errors.additional_products?.[index]?.premium_term?.type === 'valid_premium_term') {
                                trigger(`additional_products.${index}.premium_term`);
                              }
                            }}
                            value={value}
                          />
                        )
                      }
                    />
                  </div>

                  <div>
                    <FormLabel
                      required={
                        [
                          AdditionalAssuredProductEnum.COI_RIDER,
                          AdditionalAssuredProductEnum.HSCR,
                          AdditionalAssuredProductEnum.PWR,
                        ].includes(watch(`additional_products.${index}.name`)) &&
                        insuranceType === InsuranceTypeEnum.ULRP_3_0
                          ? false
                          : true
                      }>
                      Thời gian đóng phí
                    </FormLabel>
                    <Controller
                      control={control}
                      name={`additional_products.${index}.premium_term`}
                      render={({ field: { value, onChange, onBlur } }) =>
                        [AdditionalAssuredProductEnum.HSCR].includes(watch(`additional_products.${index}.name`)) &&
                        insuranceType === InsuranceTypeEnum.ULRP_3_0 ? (
                          <div className="tw-text-13px -tw-mt-7px">
                            <i>Bằng thời hạn bảo hiểm</i>
                          </div>
                        ) : (
                          <NumberInput
                            placeholder="Nhập thời gian đóng phí"
                            disabled={isSpecial(index)}
                            rightSection={<span className="text-16px font-semibold">Năm</span>}
                            error={errors.additional_products?.[index]?.premium_term?.message}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                          />
                        )
                      }
                    />
                    {![
                      AdditionalAssuredProductEnum.COI_RIDER,
                      AdditionalAssuredProductEnum.HSCR,
                      AdditionalAssuredProductEnum.PWR,
                    ].includes(watch(`additional_products.${index}.name`) ?? '') && (
                      <div className="text-12px">
                        <i>Thời hạn đóng phí phải nhỏ hơn hoặc bằng thời hạn hợp đồng và không được vượt quá 25 năm</i>
                      </div>
                    )}
                  </div>

                  <div className="mt-16px">
                    <FormLabel
                      required={
                        [AdditionalAssuredProductEnum.HSCR].includes(watch(`additional_products.${index}.name`)) &&
                        insuranceType === InsuranceTypeEnum.ULRP_3_0
                          ? false
                          : true
                      }>
                      Số tiền bảo hiểm
                    </FormLabel>
                    <Controller
                      control={control}
                      name={`additional_products.${index}.sum_assured`}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <MoneyInputSuggest
                          placeholder="Nhập số tiền"
                          error={errors.additional_products?.[index]?.sum_assured?.message}
                          onBlur={onBlur}
                          onChange={(val) => {
                            onChange(val as any);
                            triggerSumAssured(index);
                          }}
                          value={value}
                          disabled={
                            watch(`additional_products.${index}.name`) === AdditionalAssuredProductEnum.PWR &&
                            insuranceType === InsuranceTypeEnum.ULRP_3_0
                          }
                          maxLength={MAX_LENGTH_AMOUNT}
                        />
                      )}
                    />
                  </div>

                  <div className="flex-center">
                    <div className="flex-center">
                      <Button
                        variant="subtle"
                        color="red"
                        onClick={() =>
                          handleRemoveSupportContract(() => {
                            remove(index);
                            triggerDuplicateSupportingProductError();
                          })
                        }>
                        <TrashSVG className="mr-10px" /> Xoá
                      </Button>
                    </div>
                  </div>
                </div>
              ))}

              <div>
                <Button
                  variant="light"
                  fullWidth
                  onClick={() =>
                    append(appendAdditionalProducts, {
                      shouldFocus: true,
                    })
                  }>
                  <PlusSVG fill="currentColor" /> Thêm
                </Button>
              </div>

              {fields.length != 0 && (
                <div className="mt-16px p-16px bg-c_E2F5FE rounded-6px">
                  <FormLabel>
                    Quý khách có đồng ý khấu trừ/rút tiền từ Giá trị tài khoản/Giá trị tài khoản quỹ để cho MB Ageas
                    Life khấu trừ nợ Phí bảo hiểm đến hạn của (các) sản phẩm bổ trợ nêu trên (Phí đến hạn) nếu đến ngày
                    cuối cùng của thời gian gia hạn đóng phí Qúy khách chưa nộp Phí đến hạn không?
                  </FormLabel>

                  <Controller
                    control={control}
                    name="raider_deduct_fund"
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        options={raiderDeductFundOptions}
                        value={value}
                        onChange={onChange}
                        error={errors.raider_deduct_fund?.message}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </CollapseHeader>
        </div>

        <div className="mt-16px">
          <CollapseHeader
            ref={supportFormRef}
            title="Thẻ bảo lãnh viện phí MIC Care"
            defaultVisible={(watch('mic_additional_products') ?? []).length === 0 ? false : true}
            renderCollapseIcon={(visible) =>
              !visible && (watch('mic_additional_products') ?? []).length === 0 ? <PlusSVG /> : undefined
            }
            onCollapseToggle={(visible) =>
              visible &&
              (watch('mic_additional_products') ?? []).length === 0 &&
              micAppend(micAppendAdditionalProducts, {
                shouldFocus: true,
              })
            }
            icon={<BookmarkSVG />}>
            <div className="p-16px">
              {micFields.map((field, index) => (
                <div key={field.id} className="p-16px border-0.5px border-solid border-c_DDDDDD rounded-6px mb-16px">
                  <div>
                    <FormLabel required>Người được bảo hiểm</FormLabel>
                    <Controller
                      control={control}
                      name={`mic_additional_products.${index}.person`}
                      render={({ field: { value, onBlur, onChange } }) => (
                        <Select
                          placeholder="Chọn người được bảo hiểm"
                          data={availableAssuredPersonOptions()}
                          rightSection={<ChevronDownSVG />}
                          value={value}
                          onBlur={onBlur}
                          onChange={(val) => {
                            onChange(val);
                            onChangeMicPerson(index, val || '');
                            triggerDuplicateSupportingProductError();
                            clearErrors(`mic_additional_products.${index}.mic_name`);
                          }}
                          error={errors?.mic_additional_products?.[index]?.person?.message}
                          nothingFound={<Empty title="Không có người phù hợp với sản phẩm" />}
                        />
                      )}
                    />
                  </div>

                  {isShowMicContract(index) && (
                    <div className="pt-10px">
                      <SearchInsuranceHealth
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        contractNum={`mic_additional_products.${index}.contract_num`}
                        name={`mic_additional_products.${index}.contract_name`}
                        dob={`mic_additional_products.${index}.contract_dob`}
                        identificationNumber={`mic_additional_products.${index}.contract_card_id`}
                        phoneNumber={`mic_additional_products.${index}.contract_phone_number`}
                        errorText={errors?.mic_additional_products?.[index]?.contract_num?.message}
                        onResetMicSelected={() => {
                          setValue(`mic_additional_products.${index}.mic_name`, '');
                          setValue(`mic_additional_products.${index}.mic_benefits`, undefined);
                          setValue(`mic_additional_products.${index}.mic_package_selected`, undefined);
                        }}
                        onLoading={(loading) => setIsLoadingSearchMIC(loading)}
                      />
                    </div>
                  )}

                  <div>
                    <Controller
                      control={control}
                      name={`mic_additional_products.${index}.mic_name`}
                      render={({ field: { onChange } }) => (
                        <ChooseMicPackage
                          value={watch(`mic_additional_products.${index}.mic_package_selected`)}
                          onChange={(data?: MicInsuranceBenefitPackageType) => {
                            onChange(data?.id ?? '');
                            onChangeMicPackage(index, data);
                          }}
                          onSelectMicPackage={(val) => onSelectMicPackage(index, val)}
                          onChangeMicBenefits={(data: string[]) => {
                            onChangeMicBenefits(index, data);
                          }}
                          micBenefits={watch(`mic_additional_products.${index}.mic_benefits`)}
                          errorText={errors?.mic_additional_products?.[index]?.mic_name?.message}
                          isChild={!!handleDataMics(watch(`mic_additional_products`))?.childs?.length}
                          assured={dataMicAssured(watch(`mic_additional_products.${index}.person`))}
                          parentContract={{
                            so_hd_bm: watch(`mic_additional_products.${index}.contract_num`),
                            ten_bm: watch(`mic_additional_products.${index}.contract_name`),
                            ng_sinh_bm: watch(`mic_additional_products.${index}.contract_dob`),
                            cmt_bm: watch(`mic_additional_products.${index}.contract_card_id`),
                            mobi_bm: watch(`mic_additional_products.${index}.contract_phone_number`),
                          }}
                          parentInfo={onGetDataParentInfo(index) as ParentInfoMicType}
                          loading={isLoadingSearchMIC}
                          onLoading={(loading: boolean) => setIsLoadingMICPackages(loading)}
                        />
                      )}
                    />
                  </div>

                  <div className="flex-center">
                    <div className="flex-center">
                      <Button
                        variant="subtle"
                        color="red"
                        onClick={() =>
                          handleRemoveSupportContract(async () => {
                            await micRemove(index);
                            await handleRemoveMicAdditionalProducts(index);
                            triggerDuplicateSupportingProductError();
                          })
                        }>
                        <TrashSVG className="mr-10px" /> Xoá
                      </Button>
                    </div>
                  </div>
                </div>
              ))}

              <div>
                <Button
                  variant="light"
                  fullWidth
                  onClick={() =>
                    micAppend(micAppendAdditionalProducts, {
                      shouldFocus: true,
                    })
                  }>
                  <PlusSVG fill="currentColor" /> Thêm
                </Button>
              </div>
            </div>
          </CollapseHeader>
        </div>

        <div className="mt-16px">
          <CollapseHeader ref={amountFormRef} title="Phí đóng thêm" defaultVisible={false} icon={<BookmarkSVG />}>
            <div className="p-16px">
              <div>
                <FormLabel>Số tiền (VND)</FormLabel>
                <Controller
                  control={control}
                  name={`amount.value`}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <MoneyInputSuggest
                      placeholder="Nhập số tiền"
                      error={errors.amount?.value?.message}
                      onBlur={onBlur}
                      onChange={(val: any) => {
                        onChange(val);
                        triggerAmountRequiredError();
                      }}
                      value={value}
                      rightSection={<span />}
                    />
                  )}
                />
              </div>

              <div className="flex -mx-8px">
                <div className="px-8px w-1/2">
                  <div>
                    <FormLabel>Từ năm</FormLabel>
                    <Controller
                      control={control}
                      name={`amount.start_year`}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <NumberInput
                          placeholder="Nhập số năm"
                          error={errors.amount?.start_year?.message}
                          onBlur={onBlur}
                          onChange={(val) => {
                            onChange(val);
                            if (watch('amount.end_year') != null) trigger('amount.end_year');
                            triggerAmountRequiredError();
                          }}
                          value={value}
                          rightSection={<span />}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="px-8px w-1/2">
                  <div>
                    <FormLabel>Tới năm</FormLabel>
                    <Controller
                      control={control}
                      name={`amount.end_year`}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <NumberInput
                          placeholder="Nhập số năm"
                          error={errors.amount?.end_year?.message}
                          onBlur={onBlur}
                          onChange={(val) => {
                            onChange(val);
                            triggerAmountRequiredError();
                          }}
                          value={value}
                          rightSection={<span />}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </CollapseHeader>
        </div>

        <div className="fixed w-full bottom-0 left-0 z-10">
          <div className="bg-white pt-12px pb-35px px-16px">
            <Button
              fullWidth
              type={Object.values(errors).length === 0 ? 'submit' : 'button'}
              loading={isLoading}
              disabled={isLoading || isLoadingSearchMIC || isLoadingMICPackages}>
              Tiếp tục
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
});

// PWR + tick => Rỗng, không có option chọn được người
// PWR + không tích => Chỉ có duy nhất 1 option để lựa là "Người mua"

// TRMR + Tich + 2.0  => Chỉ có danh sách người được bảo hiểm bổ xung
// TRMR + KHông tích + 2.0 => Người mua và bổ xung, ko có chính
// TMMR + 3.0 => Cả 3 thằng
