/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Select, TextInput } from '@mantine/core';
import dayjs from 'dayjs';
import { Controller, useFieldArray } from 'react-hook-form';

import { DatePicker, NumberInput } from '../../../../../../components';
import { DATE_FORMAT } from '../../../../../../constants';
import { vi } from '../../../../../../i18n/vi';
import { CalendarSVG, ChevronDownSVG } from '../../../../../../icons';
import { dataStepsQuestion } from '../../../../form';

type Props = {
  currentIndex: number;
  questionName: string;
  control: any;
  errors: any;
  questionIndex: number;
  trigger: any;
};
export const Fields = ({ currentIndex, trigger, questionIndex, control, errors }: Props) => {
  const dataIndex = dataStepsQuestion[currentIndex];
  const dataQuestions = dataIndex.questions[questionIndex];
  const dataSubQuestions = dataQuestions.subQuestions;
  const defaultItem = {
    company_name: '',
    insurance_fee: '',
    insurance_no: '',
    reason: '',
    status: '',
    date_time: undefined,
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: `questions.${questionIndex}.insurance_data`,
  });

  return (
    <>
      {fields.map((elm, idx) => (
        <div className="questionElementItem" key={elm.id}>
          <h4 className="text-15px mt-20px mb-10px">HĐBH/ YCBH {idx + 1}</h4>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextInput
                  onBlur={() => onChange(value?.trim())}
                  onChange={(e) => onChange(e?.target?.value)}
                  value={value}
                  placeholder={dataSubQuestions?.[0]?.name}
                  maxLength={dataSubQuestions?.[0]?.maxLength}
                  error={
                    errors?.questions?.[questionIndex]?.insurance_data?.[idx]?.company_name?.message?.toString() || ''
                  }
                />
              );
            }}
            name={`questions.${questionIndex}.insurance_data.${idx}.company_name`}
            rules={{
              required: {
                value: true,
                message: vi.filled,
              },
            }}
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextInput
                  onBlur={() => onChange(value?.trim())}
                  onChange={(e) => onChange(e?.target?.value)}
                  value={value}
                  placeholder={dataSubQuestions?.[1]?.name}
                  maxLength={dataSubQuestions?.[1]?.maxLength}
                  error={
                    errors?.questions?.[questionIndex]?.insurance_data?.[idx]?.insurance_no?.message?.toString() || ''
                  }
                />
              );
            }}
            name={`questions.${questionIndex}.insurance_data.${idx}.insurance_no`}
            rules={{
              required: {
                value: true,
                message: vi.filled,
              },
            }}
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <NumberInput
                  onChange={(val) => onChange(val)}
                  value={value}
                  placeholder={dataSubQuestions?.[2]?.name}
                  maxLength={dataSubQuestions?.[2]?.maxLength}
                  error={
                    errors?.questions?.[questionIndex]?.insurance_data?.[idx]?.insurance_fee?.message?.toString() || ''
                  }
                  rightSection={<div />}
                />
              );
            }}
            name={`questions.${questionIndex}.insurance_data.${idx}.insurance_fee`}
            rules={{
              required: {
                value: true,
                message: vi.filled,
              },
            }}
          />
          {questionIndex !== 2 && (
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextInput
                    onBlur={() => onChange(value?.trim())}
                    onChange={(e) => onChange(e?.target?.value)}
                    value={value}
                    placeholder={dataSubQuestions?.[3]?.name}
                    maxLength={dataSubQuestions?.[3]?.maxLength}
                    error={errors?.questions?.[questionIndex]?.insurance_data?.[idx]?.reason?.message?.toString() || ''}
                  />
                );
              }}
              name={`questions.${questionIndex}.insurance_data.${idx}.reason`}
              rules={{
                required: {
                  value: true,
                  message: vi.filled,
                },
              }}
            />
          )}
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <DatePicker
                  inputFormat={DATE_FORMAT.dob}
                  placeholder={dataSubQuestions?.[questionIndex === 2 ? 3 : 4]?.name}
                  labelFormat="MM/YYYY"
                  allowFreeInput
                  value={value != null ? dayjs(value).toDate() : undefined}
                  onChange={(val) => {
                    trigger(`questions.${questionIndex}.insurance_data.${idx}.date_time`);
                    onChange(val);
                  }}
                  clearable={false}
                  rightSection={<CalendarSVG />}
                  error={
                    errors?.questions?.[questionIndex]?.insurance_data?.[idx]?.date_time?.message?.toString() || ''
                  }
                />
              );
            }}
            name={`questions.${questionIndex}.insurance_data.${idx}.date_time`}
            rules={{
              required: {
                value: true,
                message: vi.filled,
              },
            }}
          />
          {questionIndex !== 2 && (
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    placeholder={dataSubQuestions?.[5]?.name}
                    data={dataSubQuestions?.[5]?.selections || []}
                    value={value}
                    onChange={onChange}
                    rightSection={<ChevronDownSVG />}
                    error={errors?.questions?.[questionIndex]?.insurance_data?.[idx]?.status?.message?.toString() || ''}
                  />
                );
              }}
              name={`questions.${questionIndex}.insurance_data.${idx}.status`}
              rules={{
                required: {
                  value: true,
                  message: vi.filled,
                },
              }}
            />
          )}

          {idx !== 0 && (
            <Button fullWidth onClick={() => remove(idx)}>
              Xóa
            </Button>
          )}
        </div>
      ))}
      <Button
        onClick={(e: any) => {
          e.preventDefault();
          append(defaultItem);
        }}>
        Thêm
      </Button>
    </>
  );
};
