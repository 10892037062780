export const initDataNationalities = [
  {
    id: 'VN',
    activeStatus: 'ACTIVE',
    name: 'Việt Nam',
  },
  {
    id: 'AF',
    activeStatus: 'ACTIVE',
    name: 'Afghanistan',
  },
  {
    id: 'AL',
    activeStatus: 'ACTIVE',
    name: 'Albania',
  },
  {
    id: 'DZ',
    activeStatus: 'ACTIVE',
    name: 'Algeria',
  },
  {
    id: 'AD',
    activeStatus: 'ACTIVE',
    name: 'Andorra',
  },
  {
    id: 'AO',
    activeStatus: 'ACTIVE',
    name: 'Angola',
  },
  {
    id: 'AI',
    activeStatus: 'ACTIVE',
    name: 'Anguilla',
  },
  {
    id: 'AQ',
    activeStatus: 'ACTIVE',
    name: 'Antarctica',
  },
  {
    id: 'AG',
    activeStatus: 'ACTIVE',
    name: 'Antigua/Barbuda',
  },
  {
    id: 'AR',
    activeStatus: 'ACTIVE',
    name: 'Argentina',
  },
  {
    id: 'AM',
    activeStatus: 'ACTIVE',
    name: 'Armenia',
  },
  {
    id: 'AW',
    activeStatus: 'ACTIVE',
    name: 'Aruba',
  },
  {
    id: 'AU',
    activeStatus: 'ACTIVE',
    name: 'Australia',
  },
  {
    id: 'AT',
    activeStatus: 'ACTIVE',
    name: 'Austria',
  },
  {
    id: 'AZ',
    activeStatus: 'ACTIVE',
    name: 'Azerbaijan',
  },
  {
    id: 'BS',
    activeStatus: 'ACTIVE',
    name: 'Bahamas',
  },
  {
    id: 'BH',
    activeStatus: 'ACTIVE',
    name: 'Bahrain',
  },
  {
    id: 'BD',
    activeStatus: 'ACTIVE',
    name: 'Bangladesh',
  },
  {
    id: 'BB',
    activeStatus: 'ACTIVE',
    name: 'Barbados',
  },
  {
    id: 'BY',
    activeStatus: 'ACTIVE',
    name: 'Belarus',
  },
  {
    id: 'BE',
    activeStatus: 'ACTIVE',
    name: 'Belgium',
  },
  {
    id: 'BZ',
    activeStatus: 'ACTIVE',
    name: 'Belize',
  },
  {
    id: 'BJ',
    activeStatus: 'ACTIVE',
    name: 'Benin',
  },
  {
    id: 'BM',
    activeStatus: 'ACTIVE',
    name: 'Bermuda',
  },
  {
    id: 'BT',
    activeStatus: 'ACTIVE',
    name: 'Bhutan',
  },
  {
    id: 'BL',
    activeStatus: 'ACTIVE',
    name: 'Blue',
  },
  {
    id: 'BO',
    activeStatus: 'ACTIVE',
    name: 'Bolivia',
  },
  {
    id: 'BQ',
    activeStatus: 'ACTIVE',
    name: 'Bonaire, Saba',
  },
  {
    id: 'BA',
    activeStatus: 'ACTIVE',
    name: 'Bosnia-Herz.',
  },
  {
    id: 'BW',
    activeStatus: 'ACTIVE',
    name: 'Botswana',
  },
  {
    id: 'BV',
    activeStatus: 'ACTIVE',
    name: 'Bouvet Islands',
  },
  {
    id: 'BR',
    activeStatus: 'ACTIVE',
    name: 'Brazil',
  },
  {
    id: 'IO',
    activeStatus: 'ACTIVE',
    name: 'Brit.Ind.Oc.Ter',
  },
  {
    id: 'VG',
    activeStatus: 'ACTIVE',
    name: 'Brit.Virgin Is.',
  },
  {
    id: 'BN',
    activeStatus: 'ACTIVE',
    name: 'Brunei Daruss.',
  },
  {
    id: 'BG',
    activeStatus: 'ACTIVE',
    name: 'Bulgaria',
  },
  {
    id: 'BF',
    activeStatus: 'ACTIVE',
    name: 'Burkina Faso',
  },
  {
    id: 'MM',
    activeStatus: 'ACTIVE',
    name: 'Burma',
  },
  {
    id: 'BI',
    activeStatus: 'ACTIVE',
    name: 'Burundi',
  },
  {
    id: 'KH',
    activeStatus: 'ACTIVE',
    name: 'Cambodia',
  },
  {
    id: 'CM',
    activeStatus: 'ACTIVE',
    name: 'Cameroon',
  },
  {
    id: 'CA',
    activeStatus: 'ACTIVE',
    name: 'Canada',
  },
  {
    id: 'CV',
    activeStatus: 'ACTIVE',
    name: 'Cape Verde',
  },
  {
    id: 'CF',
    activeStatus: 'ACTIVE',
    name: 'CAR',
  },
  {
    id: 'KY',
    activeStatus: 'ACTIVE',
    name: 'Cayman Islands',
  },
  {
    id: 'TD',
    activeStatus: 'ACTIVE',
    name: 'Chad',
  },
  {
    id: 'CL',
    activeStatus: 'ACTIVE',
    name: 'Chile',
  },
  {
    id: 'CN',
    activeStatus: 'ACTIVE',
    name: 'China',
  },
  {
    id: 'CX',
    activeStatus: 'ACTIVE',
    name: 'Christmas Islnd',
  },
  {
    id: 'CO',
    activeStatus: 'ACTIVE',
    name: 'Colombia',
  },
  {
    id: 'KM',
    activeStatus: 'ACTIVE',
    name: 'Comoros',
  },
  {
    id: 'CK',
    activeStatus: 'ACTIVE',
    name: 'Cook Islands',
  },
  {
    id: 'CR',
    activeStatus: 'ACTIVE',
    name: 'Costa Rica',
  },
  {
    id: 'CI',
    activeStatus: 'ACTIVE',
    name: 'Cote dIvoire',
  },
  {
    id: 'HR',
    activeStatus: 'ACTIVE',
    name: 'Croatia',
  },
  {
    id: 'CU',
    activeStatus: 'ACTIVE',
    name: 'Cuba',
  },
  {
    id: 'CW',
    activeStatus: 'ACTIVE',
    name: 'Curacao',
  },
  {
    id: 'CY',
    activeStatus: 'ACTIVE',
    name: 'Cyprus',
  },
  {
    id: 'CZ',
    activeStatus: 'ACTIVE',
    name: 'Czech Republic',
  },
  {
    id: 'CD',
    activeStatus: 'ACTIVE',
    name: 'Dem. Rep. Congo',
  },
  {
    id: 'DK',
    activeStatus: 'ACTIVE',
    name: 'Denmark',
  },
  {
    id: 'DJ',
    activeStatus: 'ACTIVE',
    name: 'Djibouti',
  },
  {
    id: 'DM',
    activeStatus: 'ACTIVE',
    name: 'Dominica',
  },
  {
    id: 'DO',
    activeStatus: 'ACTIVE',
    name: 'Dominican Rep.',
  },
  {
    id: 'TL',
    activeStatus: 'ACTIVE',
    name: 'East Timor',
  },
  {
    id: 'TP',
    activeStatus: 'ACTIVE',
    name: 'East Timor',
  },
  {
    id: 'EC',
    activeStatus: 'ACTIVE',
    name: 'Ecuador',
  },
  {
    id: 'EG',
    activeStatus: 'ACTIVE',
    name: 'Egypt',
  },
  {
    id: 'SV',
    activeStatus: 'ACTIVE',
    name: 'El Salvador',
  },
  {
    id: 'GQ',
    activeStatus: 'ACTIVE',
    name: 'Equatorial Guin',
  },
  {
    id: 'ER',
    activeStatus: 'ACTIVE',
    name: 'Eritrea',
  },
  {
    id: 'EE',
    activeStatus: 'ACTIVE',
    name: 'Estonia',
  },
  {
    id: 'ET',
    activeStatus: 'ACTIVE',
    name: 'Ethiopia',
  },
  {
    id: 'EU',
    activeStatus: 'ACTIVE',
    name: 'European Union',
  },
  {
    id: 'FK',
    activeStatus: 'ACTIVE',
    name: 'Falkland Islnds',
  },
  {
    id: 'FO',
    activeStatus: 'ACTIVE',
    name: 'Faroe Islands',
  },
  {
    id: 'FJ',
    activeStatus: 'ACTIVE',
    name: 'Fiji',
  },
  {
    id: 'FI',
    activeStatus: 'ACTIVE',
    name: 'Finland',
  },
  {
    id: 'FR',
    activeStatus: 'ACTIVE',
    name: 'France',
  },
  {
    id: 'PF',
    activeStatus: 'ACTIVE',
    name: 'Frenc.Polynesia',
  },
  {
    id: 'GF',
    activeStatus: 'ACTIVE',
    name: 'French Guayana',
  },
  {
    id: 'TF',
    activeStatus: 'ACTIVE',
    name: 'French S.Territ',
  },
  {
    id: 'GA',
    activeStatus: 'ACTIVE',
    name: 'Gabon',
  },
  {
    id: 'GM',
    activeStatus: 'ACTIVE',
    name: 'Gambia',
  },
  {
    id: 'GE',
    activeStatus: 'ACTIVE',
    name: 'Georgia',
  },
  {
    id: 'DE',
    activeStatus: 'ACTIVE',
    name: 'Germany',
  },
  {
    id: 'GH',
    activeStatus: 'ACTIVE',
    name: 'Ghana',
  },
  {
    id: 'GI',
    activeStatus: 'ACTIVE',
    name: 'Gibraltar',
  },
  {
    id: 'GR',
    activeStatus: 'ACTIVE',
    name: 'Greece',
  },
  {
    id: 'GL',
    activeStatus: 'ACTIVE',
    name: 'Greenland',
  },
  {
    id: 'GD',
    activeStatus: 'ACTIVE',
    name: 'Grenada',
  },
  {
    id: 'GP',
    activeStatus: 'ACTIVE',
    name: 'Guadeloupe',
  },
  {
    id: 'GU',
    activeStatus: 'ACTIVE',
    name: 'Guam',
  },
  {
    id: 'GT',
    activeStatus: 'ACTIVE',
    name: 'Guatemala',
  },
  {
    id: 'GG',
    activeStatus: 'ACTIVE',
    name: 'Guernsey',
  },
  {
    id: 'GN',
    activeStatus: 'ACTIVE',
    name: 'Guinea',
  },
  {
    id: 'GW',
    activeStatus: 'ACTIVE',
    name: 'Guinea-Bissau',
  },
  {
    id: 'GY',
    activeStatus: 'ACTIVE',
    name: 'Guyana',
  },
  {
    id: 'HT',
    activeStatus: 'ACTIVE',
    name: 'Haiti',
  },
  {
    id: 'HM',
    activeStatus: 'ACTIVE',
    name: 'Heard/McDon.Isl',
  },
  {
    id: 'HN',
    activeStatus: 'ACTIVE',
    name: 'Honduras',
  },
  {
    id: 'HK',
    activeStatus: 'ACTIVE',
    name: 'Hong Kong',
  },
  {
    id: 'HU',
    activeStatus: 'ACTIVE',
    name: 'Hungary',
  },
  {
    id: 'IS',
    activeStatus: 'ACTIVE',
    name: 'Iceland',
  },
  {
    id: 'IN',
    activeStatus: 'ACTIVE',
    name: 'India',
  },
  {
    id: 'ID',
    activeStatus: 'ACTIVE',
    name: 'Indonesia',
  },
  {
    id: 'IR',
    activeStatus: 'ACTIVE',
    name: 'Iran',
  },
  {
    id: 'IQ',
    activeStatus: 'ACTIVE',
    name: 'Iraq',
  },
  {
    id: 'IE',
    activeStatus: 'ACTIVE',
    name: 'Ireland',
  },
  {
    id: 'IM',
    activeStatus: 'ACTIVE',
    name: 'Isle of Man',
  },
  {
    id: 'IL',
    activeStatus: 'ACTIVE',
    name: 'Israel',
  },
  {
    id: 'IT',
    activeStatus: 'ACTIVE',
    name: 'Italy',
  },
  {
    id: 'JM',
    activeStatus: 'ACTIVE',
    name: 'Jamaica',
  },
  {
    id: 'JP',
    activeStatus: 'ACTIVE',
    name: 'Japan',
  },
  {
    id: 'JE',
    activeStatus: 'ACTIVE',
    name: 'Jersey',
  },
  {
    id: 'JO',
    activeStatus: 'ACTIVE',
    name: 'Jordan',
  },
  {
    id: 'KZ',
    activeStatus: 'ACTIVE',
    name: 'Kazakhstan',
  },
  {
    id: 'CC',
    activeStatus: 'ACTIVE',
    name: 'Keeling Islands',
  },
  {
    id: 'KE',
    activeStatus: 'ACTIVE',
    name: 'Kenya',
  },
  {
    id: 'KI',
    activeStatus: 'ACTIVE',
    name: 'Kiribati',
  },
  {
    id: 'KW',
    activeStatus: 'ACTIVE',
    name: 'Kuwait',
  },
  {
    id: 'KG',
    activeStatus: 'ACTIVE',
    name: 'Kyrgyzstan',
  },
  {
    id: 'LA',
    activeStatus: 'ACTIVE',
    name: 'Laos',
  },
  {
    id: 'LV',
    activeStatus: 'ACTIVE',
    name: 'Latvia',
  },
  {
    id: 'LB',
    activeStatus: 'ACTIVE',
    name: 'Lebanon',
  },
  {
    id: 'LS',
    activeStatus: 'ACTIVE',
    name: 'Lesotho',
  },
  {
    id: 'LR',
    activeStatus: 'ACTIVE',
    name: 'Liberia',
  },
  {
    id: 'LY',
    activeStatus: 'ACTIVE',
    name: 'Libya',
  },
  {
    id: 'LI',
    activeStatus: 'ACTIVE',
    name: 'Liechtenstein',
  },
  {
    id: 'LT',
    activeStatus: 'ACTIVE',
    name: 'Lithuania',
  },
  {
    id: 'LU',
    activeStatus: 'ACTIVE',
    name: 'Luxembourg',
  },
  {
    id: 'MO',
    activeStatus: 'ACTIVE',
    name: 'Macau',
  },
  {
    id: 'MK',
    activeStatus: 'ACTIVE',
    name: 'Macedonia',
  },
  {
    id: 'MG',
    activeStatus: 'ACTIVE',
    name: 'Madagascar',
  },
  {
    id: 'MW',
    activeStatus: 'ACTIVE',
    name: 'Malawi',
  },
  {
    id: 'MY',
    activeStatus: 'ACTIVE',
    name: 'Malaysia',
  },
  {
    id: 'MV',
    activeStatus: 'ACTIVE',
    name: 'Maldives',
  },
  {
    id: 'ML',
    activeStatus: 'ACTIVE',
    name: 'Mali',
  },
  {
    id: 'MT',
    activeStatus: 'ACTIVE',
    name: 'Malta',
  },
  {
    id: 'MH',
    activeStatus: 'ACTIVE',
    name: 'Marshall Islnds',
  },
  {
    id: 'MQ',
    activeStatus: 'ACTIVE',
    name: 'Martinique',
  },
  {
    id: 'MR',
    activeStatus: 'ACTIVE',
    name: 'Mauritania',
  },
  {
    id: 'MU',
    activeStatus: 'ACTIVE',
    name: 'Mauritius',
  },
  {
    id: 'YT',
    activeStatus: 'ACTIVE',
    name: 'Mayotte',
  },
  {
    id: 'MX',
    activeStatus: 'ACTIVE',
    name: 'Mexico',
  },
  {
    id: 'FM',
    activeStatus: 'ACTIVE',
    name: 'Micronesia',
  },
  {
    id: 'UM',
    activeStatus: 'ACTIVE',
    name: 'Minor Outl.Isl.',
  },
  {
    id: 'MD',
    activeStatus: 'ACTIVE',
    name: 'Moldova',
  },
  {
    id: 'MC',
    activeStatus: 'ACTIVE',
    name: 'Monaco',
  },
  {
    id: 'MN',
    activeStatus: 'ACTIVE',
    name: 'Mongolia',
  },
  {
    id: 'MS',
    activeStatus: 'ACTIVE',
    name: 'Montserrat',
  },
  {
    id: 'MA',
    activeStatus: 'ACTIVE',
    name: 'Morocco',
  },
  {
    id: 'MZ',
    activeStatus: 'ACTIVE',
    name: 'Mozambique',
  },
  {
    id: 'MP',
    activeStatus: 'ACTIVE',
    name: 'N.Mariana Islnd',
  },
  {
    id: 'NA',
    activeStatus: 'ACTIVE',
    name: 'Namibia',
  },
  {
    id: 'NT',
    activeStatus: 'ACTIVE',
    name: 'NATO',
  },
  {
    id: 'NR',
    activeStatus: 'ACTIVE',
    name: 'Nauru',
  },
  {
    id: 'NP',
    activeStatus: 'ACTIVE',
    name: 'Nepal',
  },
  {
    id: 'NL',
    activeStatus: 'ACTIVE',
    name: 'Netherlands',
  },
  {
    id: 'NC',
    activeStatus: 'ACTIVE',
    name: 'New Caledonia',
  },
  {
    id: 'NZ',
    activeStatus: 'ACTIVE',
    name: 'New Zealand',
  },
  {
    id: 'NI',
    activeStatus: 'ACTIVE',
    name: 'Nicaragua',
  },
  {
    id: 'NE',
    activeStatus: 'ACTIVE',
    name: 'Niger',
  },
  {
    id: 'NG',
    activeStatus: 'ACTIVE',
    name: 'Nigeria',
  },
  {
    id: 'NU',
    activeStatus: 'ACTIVE',
    name: 'Niue',
  },
  {
    id: 'NF',
    activeStatus: 'ACTIVE',
    name: 'Norfolk Islands',
  },
  {
    id: 'KP',
    activeStatus: 'ACTIVE',
    name: 'North Korea',
  },
  {
    id: 'NO',
    activeStatus: 'ACTIVE',
    name: 'Norway',
  },
  {
    id: 'OM',
    activeStatus: 'ACTIVE',
    name: 'Oman',
  },
  {
    id: 'OR',
    activeStatus: 'ACTIVE',
    name: 'Orange',
  },
  {
    id: 'PK',
    activeStatus: 'ACTIVE',
    name: 'Pakistan',
  },
  {
    id: 'PW',
    activeStatus: 'ACTIVE',
    name: 'Palau',
  },
  {
    id: 'PS',
    activeStatus: 'ACTIVE',
    name: 'Palestine',
  },
  {
    id: 'PA',
    activeStatus: 'ACTIVE',
    name: 'Panama',
  },
  {
    id: 'PG',
    activeStatus: 'ACTIVE',
    name: 'Pap. New Guinea',
  },
  {
    id: 'PY',
    activeStatus: 'ACTIVE',
    name: 'Paraguay',
  },
  {
    id: 'PE',
    activeStatus: 'ACTIVE',
    name: 'Peru',
  },
  {
    id: 'PH',
    activeStatus: 'ACTIVE',
    name: 'Philippines',
  },
  {
    id: 'PN',
    activeStatus: 'ACTIVE',
    name: 'Pitcairn Islnds',
  },
  {
    id: 'PL',
    activeStatus: 'ACTIVE',
    name: 'Poland',
  },
  {
    id: 'PT',
    activeStatus: 'ACTIVE',
    name: 'Portugal',
  },
  {
    id: 'PR',
    activeStatus: 'ACTIVE',
    name: 'Puerto Rico',
  },
  {
    id: 'QA',
    activeStatus: 'ACTIVE',
    name: 'Qatar',
  },
  {
    id: 'CG',
    activeStatus: 'ACTIVE',
    name: 'Rep.of Congo',
  },
  {
    id: 'RE',
    activeStatus: 'ACTIVE',
    name: 'Reunion',
  },
  {
    id: 'RO',
    activeStatus: 'ACTIVE',
    name: 'Romania',
  },
  {
    id: 'RU',
    activeStatus: 'ACTIVE',
    name: 'Russian Fed.',
  },
  {
    id: 'RW',
    activeStatus: 'ACTIVE',
    name: 'Rwanda',
  },
  {
    id: 'GS',
    activeStatus: 'ACTIVE',
    name: 'S. Sandwich Ins',
  },
  {
    id: 'ST',
    activeStatus: 'ACTIVE',
    name: 'S.Tome,Principe',
  },
  {
    id: 'SH',
    activeStatus: 'ACTIVE',
    name: 'Saint Helena',
  },
  {
    id: 'WS',
    activeStatus: 'ACTIVE',
    name: 'Samoa',
  },
  {
    id: 'AS',
    activeStatus: 'ACTIVE',
    name: 'Samoa, America',
  },
  {
    id: 'SM',
    activeStatus: 'ACTIVE',
    name: 'San Marino',
  },
  {
    id: 'SA',
    activeStatus: 'ACTIVE',
    name: 'Saudi Arabia',
  },
  {
    id: 'SN',
    activeStatus: 'ACTIVE',
    name: 'Senegal',
  },
  {
    id: 'CS',
    activeStatus: 'ACTIVE',
    name: 'Serbia/Monten.',
  },
  {
    id: 'SC',
    activeStatus: 'ACTIVE',
    name: 'Seychelles',
  },
  {
    id: 'SL',
    activeStatus: 'ACTIVE',
    name: 'Sierra Leone',
  },
  {
    id: 'SG',
    activeStatus: 'ACTIVE',
    name: 'Singapore',
  },
  {
    id: 'SX',
    activeStatus: 'ACTIVE',
    name: 'Sint Maarten',
  },
  {
    id: 'SK',
    activeStatus: 'ACTIVE',
    name: 'Slovakia',
  },
  {
    id: 'SI',
    activeStatus: 'ACTIVE',
    name: 'Slovenia',
  },
  {
    id: 'SB',
    activeStatus: 'ACTIVE',
    name: 'Solomon Islands',
  },
  {
    id: 'SO',
    activeStatus: 'ACTIVE',
    name: 'Somalia',
  },
  {
    id: 'ZA',
    activeStatus: 'ACTIVE',
    name: 'South Africa',
  },
  {
    id: 'KR',
    activeStatus: 'ACTIVE',
    name: 'South Korea',
  },
  {
    id: 'SS',
    activeStatus: 'ACTIVE',
    name: 'South Sudan',
  },
  {
    id: 'ES',
    activeStatus: 'ACTIVE',
    name: 'Spain',
  },
  {
    id: 'LK',
    activeStatus: 'ACTIVE',
    name: 'Sri Lanka',
  },
  {
    id: 'KN',
    activeStatus: 'ACTIVE',
    name: 'St Kitts&Nevis',
  },
  {
    id: 'LC',
    activeStatus: 'ACTIVE',
    name: 'St. Lucia',
  },
  {
    id: 'VC',
    activeStatus: 'ACTIVE',
    name: 'St. Vincent',
  },
  {
    id: 'PM',
    activeStatus: 'ACTIVE',
    name: 'St.Pier,Miquel.',
  },
  {
    id: 'SD',
    activeStatus: 'ACTIVE',
    name: 'Sudan',
  },
  {
    id: 'SR',
    activeStatus: 'ACTIVE',
    name: 'Suriname',
  },
  {
    id: 'SJ',
    activeStatus: 'ACTIVE',
    name: 'Svalbard',
  },
  {
    id: 'SZ',
    activeStatus: 'ACTIVE',
    name: 'Swaziland',
  },
  {
    id: 'SE',
    activeStatus: 'ACTIVE',
    name: 'Sweden',
  },
  {
    id: 'CH',
    activeStatus: 'ACTIVE',
    name: 'Switzerland',
  },
  {
    id: 'SY',
    activeStatus: 'ACTIVE',
    name: 'Syria',
  },
  {
    id: 'TW',
    activeStatus: 'ACTIVE',
    name: 'Taiwan',
  },
  {
    id: 'TJ',
    activeStatus: 'ACTIVE',
    name: 'Tajikistan',
  },
  {
    id: 'TZ',
    activeStatus: 'ACTIVE',
    name: 'Tanzania',
  },
  {
    id: 'TH',
    activeStatus: 'ACTIVE',
    name: 'Thailand',
  },
  {
    id: 'TG',
    activeStatus: 'ACTIVE',
    name: 'Togo',
  },
  {
    id: 'TK',
    activeStatus: 'ACTIVE',
    name: 'Tokelau Islands',
  },
  {
    id: 'TO',
    activeStatus: 'ACTIVE',
    name: 'Tonga',
  },
  {
    id: 'TT',
    activeStatus: 'ACTIVE',
    name: 'Trinidad,Tobago',
  },
  {
    id: 'TN',
    activeStatus: 'ACTIVE',
    name: 'Tunisia',
  },
  {
    id: 'TR',
    activeStatus: 'ACTIVE',
    name: 'Turkey',
  },
  {
    id: 'TM',
    activeStatus: 'ACTIVE',
    name: 'Turkmenistan',
  },
  {
    id: 'TC',
    activeStatus: 'ACTIVE',
    name: 'Turksh Caicosin',
  },
  {
    id: 'TV',
    activeStatus: 'ACTIVE',
    name: 'Tuvalu',
  },
  {
    id: 'UG',
    activeStatus: 'ACTIVE',
    name: 'Uganda',
  },
  {
    id: 'UA',
    activeStatus: 'ACTIVE',
    name: 'Ukraine',
  },
  {
    id: 'GB',
    activeStatus: 'ACTIVE',
    name: 'United Kingdom',
  },
  {
    id: 'UN',
    activeStatus: 'ACTIVE',
    name: 'United Nations',
  },
  {
    id: 'UY',
    activeStatus: 'ACTIVE',
    name: 'Uruguay',
  },
  {
    id: 'VI',
    activeStatus: 'ACTIVE',
    name: 'US Virgin Isl.',
  },
  {
    id: 'US',
    activeStatus: 'ACTIVE',
    name: 'USA',
  },
  {
    id: 'AE',
    activeStatus: 'ACTIVE',
    name: 'Utd.Arab Emir.',
  },
  {
    id: 'UZ',
    activeStatus: 'ACTIVE',
    name: 'Uzbekistan',
  },
  {
    id: 'VU',
    activeStatus: 'ACTIVE',
    name: 'Vanuatu',
  },
  {
    id: 'VA',
    activeStatus: 'ACTIVE',
    name: 'Vatican City',
  },
  {
    id: 'VE',
    activeStatus: 'ACTIVE',
    name: 'Venezuela',
  },
  {
    id: 'WF',
    activeStatus: 'ACTIVE',
    name: 'Wallis,Futuna',
  },
  {
    id: 'EH',
    activeStatus: 'ACTIVE',
    name: 'West Sahara',
  },
  {
    id: 'YE',
    activeStatus: 'ACTIVE',
    name: 'Yemen',
  },
  {
    id: 'ZM',
    activeStatus: 'ACTIVE',
    name: 'Zambia',
  },
  {
    id: 'ZW',
    activeStatus: 'ACTIVE',
    name: 'Zimbabwe',
  },
];
