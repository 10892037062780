import { PropsWithChildren, ReactNode, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import classNames from 'classnames';

import { ChevronDownSVG } from '../../../../icons';
import './index.less';

type Props = PropsWithChildren<{
  title: ReactNode;
  icon: ReactNode;
  iconContainerSize?: 'default' | 'large';
  defaultVisible?: boolean;
  className?: string;
  preventOpen?: boolean;
  shadowDisabled?: boolean;
  renderCollapseIcon?(val: boolean): ReactNode;
  onCollapseToggle?(val: boolean): void;
}>;

export type CollapseHeaderType = {
  toggle(value: boolean): void;
  visible: boolean;
};

export const CollapseHeader = forwardRef<CollapseHeaderType, Props>(
  (
    {
      children,
      title,
      icon: Icon,
      className,
      iconContainerSize,
      defaultVisible = true,
      preventOpen,
      shadowDisabled = false,
      renderCollapseIcon,
      onCollapseToggle,
    },
    ref,
  ) => {
    const [visible, setVisible] = useState(defaultVisible);

    useEffect(() => {
      onCollapseToggle?.(visible);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useImperativeHandle(ref, () => ({
      toggle: (value) => {
        setVisible(value);
      },
      visible,
    }));

    return (
      <div className={classNames('bg-white', className)}>
        <div
          className={classNames(
            'px-16px py-14px flex flex-row items-center',
            shadowDisabled ? 'shadow-none' : 'collapse-header',
          )}
          onClick={() => !preventOpen && setVisible(!visible)}>
          <div
            className={classNames(
              'rounded-full flex-center bg-primary mr-12px flex-shrink-0',
              iconContainerSize === 'default' ? 'w-32px h-32px' : 'w-44px h-44px',
            )}>
            {Icon}
          </div>
          <div className="text-18px font-semibold leading-24px">{title}</div>
          <div className="ml-auto pl-40px flex-shrink-0">
            {renderCollapseIcon?.(visible) ?? (
              <ChevronDownSVG className={classNames('', visible && !preventOpen ? 'rotate-180' : 'rotate-0')} />
            )}
          </div>
        </div>
        {!preventOpen && visible && children}
      </div>
    );
  },
);
