import { memo, useMemo } from 'react';
import dayjs from 'dayjs';
import { Table } from '@mantine/core';

import { GenderEnum, QuotationEntity } from '../../../../services';
import { useInitialData } from '../../../../contexts';
import { getAge } from '../../../../helpers';

type Props = {
  quotation: QuotationEntity;
};

export const BuyerInformation = memo(({ quotation }: Props) => {
  const { getOccupation } = useInitialData();

  const rows = useMemo(
    () => [
      <tr key={1}>
        <td>Bên mua bảo hiểm</td>
        <td>{quotation?.customer?.full_name ?? quotation?.assured.full_name}</td>
        <td>{dayjs(quotation?.customer?.dob ?? quotation?.assured.dob).format('DD/MM/YYYY')}</td>
        <td>{(quotation?.customer?.gender ?? quotation?.assured.gender) === GenderEnum.FEMALE ? 'Nữ' : 'Nam'}</td>
        <td>{getAge(quotation.customer?.dob ?? quotation.assured?.dob)}</td>
        <td>{getOccupation(quotation?.customer?.occupation_id ?? quotation?.assured.occupation_id ?? -1)?.group_id}</td>
      </tr>,
      <tr key={2}>
        <td>Người được bảo hiểm chính</td>
        <td>{quotation?.assured.full_name}</td>
        <td>{dayjs(quotation?.assured.dob).format('DD/MM/YYYY')}</td>
        <td>{quotation?.assured.gender === GenderEnum.FEMALE ? 'Nữ' : 'Nam'}</td>
        <td>{getAge(quotation?.assured.dob)}</td>
        <td>{getOccupation(quotation?.assured.occupation_id ?? -1)?.group_id}</td>
      </tr>,
      ...(quotation?.additional_assureds ?? [])?.map((it, index) => (
        <tr key={2 + it?.full_name + '/' + index}>
          <td>Người được bảo hiểm bổ sung</td>
          <td>{it?.full_name}</td>
          <td>{dayjs(it.dob).format('DD/MM/YYYY')}</td>
          <td>{it.gender === GenderEnum.FEMALE ? 'Nữ' : 'Nam'}</td>
          <td>{getAge(it.dob)}</td>
          <td>{getOccupation(it.occupation_id ?? 15)?.group_id}</td>
        </tr>
      )),
      ...(quotation.customer?.additional_products?.length
        ? [
            <tr key={2 + 'customer'}>
              <td>Người được bảo hiểm bổ sung</td>
              <td>{quotation?.customer?.full_name}</td>
              <td>{dayjs(quotation?.customer?.dob).format('DD/MM/YYYY')}</td>
              <td>{quotation?.customer?.gender === GenderEnum.FEMALE ? 'Nữ' : 'Nam'}</td>
              <td>{getAge(quotation.customer?.dob)}</td>
              <td>{getOccupation(quotation?.customer?.occupation_id ?? -1)?.group_id}</td>
            </tr>,
          ]
        : []),
    ],
    [getOccupation, quotation],
  );

  return (
    <div className="pb-16px">
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>Họ tên</th>
            <th>Ngày sinh</th>
            <th>Giới tính</th>
            <th>Tuổi bảo hiểm</th>
            <th>Nhóm nghề</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
});
