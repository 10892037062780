/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, LoadingOverlay, TextInput } from '@mantine/core';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { stringify } from 'qs';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { FormLabel } from '../../../components';
import { useDialog } from '../../../contexts';
import { getMessage, regexp, validationMessages } from '../../../helpers';
import { useCurrentUser, useQueryString } from '../../../hooks';
import { vi } from '../../../i18n/vi';
import {
  QRCodeInput,
  RefererType,
  RefererTypeEnum,
  useGenQRCodeMutation,
  useSearchICCodeMutation,
  useSearchRMCodeMutation,
  useSearchSPCodeMutation,
} from '../../../services';
import { emailOptionalValidation, phoneOptionalValidation, ScreenParams } from '../components';

import { FormStepEnum, useIllustration } from './illustration-form-screen';

export interface ReferrerEntity {
  referrer?: RefererType;
  sale?: RefererType;
  supporter?: RefererType;
}

export const SalesForm = memo(() => {
  const { processId = 0 } = useQueryString<ScreenParams>();
  const user = useCurrentUser();
  const navigate = useNavigate();

  const { showDialog } = useDialog();
  const { onUpdateReferrer, referrerData, healths } = useIllustration();

  const validationSchema = yup.object<ReferrerEntity>({
    referrer: yup.object({
      code: yup
        .string()
        .trim()
        .test('invalid-format', validationMessages.invalidRM, (val) => !val || (!!val && regexp.referrer.test(val))),
      phone_number: phoneOptionalValidation,
      email: emailOptionalValidation,
    }),

    sale: yup.object({
      code: yup
        .string()
        .trim()
        .test('invalid-format', validationMessages.invalidIC, (val) => !val || (!!val && regexp.sale.test(val))),
    }),

    supporter: yup.object({
      code: yup
        .string()
        .trim()
        .required()
        .test('invalid-format', validationMessages.invalidSupporter, (val) => !val || (!!val && regexp.sale.test(val))),
      phone_number: phoneOptionalValidation,
      email: emailOptionalValidation,
    }),
  });

  const {
    resetField,
    reset,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<ReferrerEntity>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: useMemo(() => {
      return referrerData
        ? referrerData
        : {
            referrer: {
              code: user?.rm_info?.code,
              name: user?.rm_info?.full_name || user?.rm_info?.name,
              phone_number: user?.rm_info?.phone_number,
              email: user?.rm_info?.email,
              branch_code: user?.rm_info?.branch_code,
              branch_name: user?.rm_info?.branch_name,
            },
            sale: {
              code: user?.ic_info?.code,
              name: user?.ic_info?.full_name || user?.ic_info?.name,
            },
            supporter: {
              code: '',
              name: '',
              phone_number: '',
              email: '',
              branch_code: '',
              branch_name: '',
            },
          };
    }, [user, referrerData]),
  });

  const onResetRM = useCallback(() => {
    setValue('referrer.name', '');
    setValue('referrer.phone_number', '');
    setValue('referrer.email', '');
    setValue('referrer.branch_name', '');
    setValue('referrer.branch_code', '');
    setValue('referrer.department_code', '');
    setValue('referrer.department_name', '');
  }, [setValue]);

  const onResetSP = useCallback(() => {
    setValue('supporter.name', '');
    setValue('supporter.phone_number', '');
    setValue('supporter.email', '');
    setValue('supporter.branch_name', '');
    setValue('supporter.branch_code', '');
    setValue('supporter.department_code', '');
    setValue('supporter.department_name', '');
  }, [setValue]);

  const {
    mutateAsync: searchRMCodeAsync,
    isError: isErrorRM,
    isLoading: isLoadingRM,
  } = useSearchRMCodeMutation({
    onError: () => {
      setValue('referrer.code', '');
      onResetRM();
      showDialog({
        message: vi.error_rm,
        type: 'ALERT',
      });
    },
    onSuccess: (data) => {
      onResetRM();
      setValue('referrer.name', data?.t24_employee?.employee_name || data?.hris_employee?.user_name);
      setValue('referrer.phone_number', data?.t24_employee?.phone);
      setValue('referrer.email', data?.hris_employee?.email);
      setValue('referrer.branch_code', data?.t24_employee?.branch_code);
      setValue('referrer.branch_name', data?.t24_employee?.branch_name);
      setValue('referrer.department_code', data?.t24_employee?.branch_level2);
      setValue('referrer.department_name', data?.t24_employee?.branch_name_level2);
    },
  });

  const {
    mutateAsync: searchICCodeAsync,
    isError: isErrorIC,
    isLoading: isLoadingIC,
  } = useSearchICCodeMutation({
    onError: async () => {
      setValue('sale.code', '');
      resetField('sale.name');
      showDialog({
        message: vi.error_ic,
        type: 'ALERT',
      });
    },
    onSuccess: (data) => {
      setValue('sale.name', data?.full_name);
    },
  });

  const {
    mutateAsync: searchSPCodeAsync,
    isError: isErrorSP,
    isLoading: isLoadingSP,
  } = useSearchSPCodeMutation({
    onError: async () => {
      setValue('supporter.code', '');
      onResetSP();
      showDialog({
        message: vi.error_sp,
        type: 'ALERT',
      });
    },
    onSuccess: (data) => {
      onResetSP();
      setValue(
        'supporter.name',
        data?.t24_employee?.employee_name || data?.hris_employee?.user_name || data?.full_name,
      );
      setValue('supporter.phone_number', data?.t24_employee?.phone);
      setValue('supporter.email', data?.hris_employee?.email);
      setValue('supporter.branch_code', data?.t24_employee?.branch_code);
      setValue('supporter.branch_name', data?.t24_employee?.branch_name);
      setValue('supporter.department_code', data?.t24_employee?.branch_level2);
      setValue('supporter.department_name', data?.t24_employee?.branch_name_level2);
    },
  });

  const onCheckReferer = useCallback(
    async (data: ReferrerEntity) => {
      const rmCode = data?.referrer?.code || '';
      const icCode = data?.sale?.code || '';
      const supportCode = data?.supporter?.code || '';
      if (isErrorRM || rmCode?.length < 10) {
        resetField('referrer.code');
        onResetRM();
      }
      if (data?.referrer?.phone_number?.length === 0) {
        resetField('referrer.phone_number');
      }
      if (data?.referrer?.email?.length === 0) {
        resetField('referrer.email');
      }
      if (isErrorIC || icCode?.length < 10) {
        resetField('sale.code');
        resetField('sale.name');
      }
      if (isErrorSP || supportCode?.length < 10) {
        resetField('supporter.code');
        onResetSP();
      }
      if (data?.supporter?.phone_number?.length === 0) {
        resetField('supporter.phone_number');
      }
      if (data?.supporter?.email?.length === 0) {
        resetField('supporter.email');
      }
    },
    [isErrorIC, isErrorRM, isErrorSP, onResetRM, onResetSP, resetField],
  );

  useEffect(() => {
    if (referrerData) {
      reset(referrerData, { keepDefaultValues: true });
    }
  }, [referrerData, reset]);

  const { mutateAsync: submitAppAsync, isLoading: submitAppLoading } = useGenQRCodeMutation({
    onError: (err) => {
      showDialog({
        type: 'ALERT',
        message: getMessage(err),
      });
    },
    onSuccess: (res) => {
      navigate({
        search: stringify({
          step: FormStepEnum.QR_CODE,
          url: res,
        }),
      });
    },
  });

  const onSubmit = useCallback(
    (data: ReferrerEntity) => {
      onCheckReferer(data);
      onUpdateReferrer(data);
      if (healths?.length) {
        navigate({
          search: stringify({
            step: FormStepEnum.QUESTIONS,
            processId,
          }),
        });
      } else {
        const body: QRCodeInput = {
          healths: [],
          mic_healths: [],
          process_id: processId,
          ...data,
        };
        submitAppAsync(body);
      }
    },
    [healths?.length, navigate, onCheckReferer, onUpdateReferrer, processId, submitAppAsync],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebounce = useCallback(
    debounce((type: RefererTypeEnum, value: string) => {
      if (value.length < 10) return;
      if (type === RefererTypeEnum.RM) {
        searchRMCodeAsync(value);
      }
      if (type === RefererTypeEnum.IC) {
        searchICCodeAsync(value);
      }
      if (type === RefererTypeEnum.SUPPORTER) {
        searchSPCodeAsync(value);
      }
    }, 400),
    [searchRMCodeAsync, searchICCodeAsync, searchSPCodeAsync],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="pb-120px">
        <div className="mt-20px p-16px bg-white">
          <div>
            <FormLabel>{vi.rm_title}</FormLabel>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  onBlur={() => {
                    onChange(value?.trim());
                  }}
                  onChange={(e) => {
                    const val = e?.target?.value?.toUpperCase();
                    onResetRM();
                    onChange(val);
                    if (regexp.referrer.test(val ?? '')) onDebounce(RefererTypeEnum.RM, val?.trim());
                  }}
                  value={value}
                  placeholder={vi.enter_rm_code}
                  error={errors?.referrer?.code?.message?.toString() || ''}
                  maxLength={50}
                  disabled={true}
                />
              )}
              name="referrer.code"
            />
            <Box maw={400} pos="relative">
              <LoadingOverlay visible={isLoadingRM} overlayBlur={2} zIndex={10} />
              <Controller
                control={control}
                render={({ field: { value } }) => (
                  <div className="pointer-events-none mb-15px">
                    <div className={classNames(['hidden', (isLoadingRM || !!value) && '!block'])}>
                      <TextInput value={value ? value : vi.enter_rm_name} disabled={true} />
                    </div>
                  </div>
                )}
                name="referrer.name"
              />
              <Controller
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <div className={classNames(['hidden', (isLoadingRM || !!watch('referrer.name')) && '!block'])}>
                    <TextInput
                      onBlur={onBlur}
                      onChange={(e) => onChange(e?.target?.value)}
                      value={value}
                      placeholder={vi.enter_rm_phone}
                      maxLength={11}
                      error={errors?.referrer?.phone_number?.message?.toString() || ''}
                      disabled={true}
                    />
                  </div>
                )}
                name="referrer.phone_number"
              />
              <Controller
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <div className={classNames(['hidden', (isLoadingRM || !!watch('referrer.name')) && '!block'])}>
                    <TextInput
                      onBlur={onBlur}
                      onChange={(e) => onChange(e?.target?.value)}
                      value={value}
                      placeholder={vi.enter_rm_email}
                      maxLength={50}
                      error={errors?.referrer?.email?.message?.toString() || ''}
                      disabled={true}
                    />
                  </div>
                )}
                name="referrer.email"
              />
              <Controller control={control} render={() => <input type="hidden" />} name="referrer.branch_code" />
              <Controller control={control} render={() => <input type="hidden" />} name="referrer.branch_name" />
              <Controller control={control} render={() => <input type="hidden" />} name="referrer.department_code" />
              <Controller control={control} render={() => <input type="hidden" />} name="referrer.department_name" />
            </Box>
          </div>

          <div>
            <FormLabel>{vi.ic_title}</FormLabel>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  onBlur={() => {
                    onChange(value?.trim());
                  }}
                  onChange={(e) => {
                    const val = e?.target?.value?.toUpperCase();
                    resetField('sale.name');
                    onChange(val);
                    if (regexp.sale.test(val ?? '')) onDebounce(RefererTypeEnum.IC, val?.trim());
                  }}
                  value={value}
                  placeholder={vi.enter_ic_code}
                  error={errors?.sale?.code?.message?.toString() || ''}
                  maxLength={50}
                  disabled={true}
                />
              )}
              name="sale.code"
            />
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <Box maw={400} pos="relative">
                  <LoadingOverlay visible={isLoadingIC} overlayBlur={2} zIndex={10} />
                  <div className="pointer-events-none mb-15px">
                    <div className={classNames(['hidden', (isLoadingIC || !!value) && '!block'])}>
                      <TextInput value={value ? value : vi.enter_ic_name} disabled={true} />
                    </div>
                  </div>
                </Box>
              )}
              name="sale.name"
            />
          </div>

          <div>
            <FormLabel>{vi.supporter_title}</FormLabel>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  onBlur={() => {
                    onChange(value?.trim());
                  }}
                  onChange={(e) => {
                    const val = e?.target?.value?.toUpperCase();
                    onResetSP();
                    onChange(val);
                    if (regexp.sale.test(val ?? '')) onDebounce(RefererTypeEnum.SUPPORTER, val?.trim());
                  }}
                  value={value}
                  placeholder={vi.enter_ic_code}
                  error={errors?.supporter?.code?.message?.toString() || ''}
                  maxLength={50}
                />
              )}
              name="supporter.code"
            />
            <Box maw={400} pos="relative">
              <LoadingOverlay visible={isLoadingSP} overlayBlur={2} zIndex={10} />
              <Controller
                control={control}
                render={({ field: { value } }) => (
                  <div className="pointer-events-none mb-15px">
                    <div className={classNames(['hidden', (isLoadingSP || !!value) && '!block'])}>
                      <TextInput value={value ? value : vi.enter_supporter_name} disabled={true} />
                    </div>
                  </div>
                )}
                name="supporter.name"
              />
              <Controller
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <div
                    className={classNames([
                      'hidden',
                      (isLoadingSP ||
                        (!!watch('supporter.name') && watch('supporter.code')!.toString().search('MB') > -1)) &&
                        '!block',
                    ])}>
                    <TextInput
                      onBlur={onBlur}
                      onChange={(e) => onChange(e?.target?.value)}
                      value={value}
                      placeholder={vi.enter_supporter_phone}
                      maxLength={11}
                      error={errors?.supporter?.phone_number?.message?.toString() || ''}
                    />
                  </div>
                )}
                name="supporter.phone_number"
              />
              <Controller
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <div
                    className={classNames([
                      'hidden',
                      (isLoadingSP ||
                        (!!watch('supporter.name') && watch('supporter.code')!.toString().search('MB') > -1)) &&
                        '!block',
                    ])}>
                    <TextInput
                      onBlur={onBlur}
                      onChange={(e) => onChange(e?.target?.value)}
                      value={value}
                      placeholder={vi.enter_supporter_email}
                      maxLength={50}
                      error={errors?.supporter?.email?.message?.toString() || ''}
                    />
                  </div>
                )}
                name="supporter.email"
              />
              <Controller control={control} render={() => <input type="hidden" />} name="supporter.branch_code" />
              <Controller control={control} render={() => <input type="hidden" />} name="supporter.branch_name" />
              <Controller control={control} render={() => <input type="hidden" />} name="supporter.department_code" />
              <Controller control={control} render={() => <input type="hidden" />} name="supporter.department_name" />
            </Box>
          </div>
        </div>
      </div>

      <div className="bg-white pt-12px pb-34px px-16px fixed bottom-0 left-0 w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
        <Button type="submit" fullWidth disabled={isLoadingRM || isLoadingIC || isLoadingSP || submitAppLoading}>
          Tiếp tục
        </Button>
      </div>
    </form>
  );
});
