import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { LoadingOverlay, Radio } from '@mantine/core';
import imageCompression from 'browser-image-compression';

import { CameraSVG, CloseSVG } from '../../icons';
import { OcrProcessOutput, useOcrProcessMutation } from '../../services';
import { useDialog } from '../../contexts';
import { getMessage } from '../../helpers';
import { MEDIA, imageCompressionOptions } from '../../constants';

import { IdentificationFaceEnum, useOCR } from './ocr-camera-screen';

type Props = {
  person: string;
  handleFillDataFromOCR: (res: OcrProcessOutput, person: string) => void;
};

export const IdentificationPhotoInputs = memo(({ person, handleFillDataFromOCR }: Props) => {
  const { onOpenOCR, capturedOCRs, onClearCapturedOCRs } = useOCR();
  const { showDialog } = useDialog();
  const capturedOCRsValidationPassed = useRef(true);
  const [isCompressing, setIsCompressing] = useState(false);
  const [single, setSingle] = useState(false);

  const validateCapturedOCRs = useCallback(() => {
    capturedOCRs?.forEach((capturedOCR) => {
      if (capturedOCR.imgFile) {
        if (capturedOCR.imgFile.size > MEDIA.MAX_IMAGE_SIZE) {
          showDialog({
            type: 'ALERT',
            message: 'Ảnh không được vượt quá 5MB',
          });
          capturedOCRsValidationPassed.current = false;
          onClearCapturedOCRs(capturedOCR.person, capturedOCR.face);
        } else if (!MEDIA.UPLOAD_ALLOW_IMAGE_TYPE_BASIC.includes(capturedOCR.imgFile.type)) {
          showDialog({
            type: 'ALERT',
            message: 'Ảnh không đúng định dạng. Chỉ chấp nhận định dạng: png, jpg, jpeg',
          });
          capturedOCRsValidationPassed.current = false;
          onClearCapturedOCRs(capturedOCR.person, capturedOCR.face);
        } else {
          capturedOCRsValidationPassed.current = true;
        }
      }
    });
  }, [capturedOCRs, onClearCapturedOCRs, showDialog]);

  useEffect(() => {
    validateCapturedOCRs();
  }, [capturedOCRs, validateCapturedOCRs]);

  const capturedOCRFront = capturedOCRs?.find(
    (capturedOCR) => capturedOCR.person === person && capturedOCR.face === IdentificationFaceEnum.FRONT,
  );
  const capturedOCRBack = capturedOCRs?.find(
    (capturedOCR) => capturedOCR.person === person && capturedOCR.face === IdentificationFaceEnum.BACK,
  );

  const { mutateAsync, isLoading } = useOcrProcessMutation({
    onError: (err) => {
      showDialog({
        type: 'ALERT',
        message: getMessage(err),
      });
      onClearCapturedOCRs(person);
    },
    onSuccess: (res) => {
      handleFillDataFromOCR(res, person);
    },
  });

  useEffect(() => {
    const compressAndAppendFile = async (file: File, formData: FormData) => {
      if (file.size > MEDIA.MAX_COMPRESSED_IMAGE_SIZE) {
        const compressedBlob = await imageCompression(file, imageCompressionOptions);
        const compressedImage = new File([compressedBlob], file.name, {
          type: file.type,
        });
        formData.append('files', compressedImage);
      } else {
        formData.append('files', file);
      }
    };

    const uploadImages = async () => {
      if (single && capturedOCRFront?.imgFile && capturedOCRsValidationPassed.current) {
        const formData = new FormData();
        setIsCompressing(true);
        await compressAndAppendFile(capturedOCRFront.imgFile, formData);
        setIsCompressing(false);
        await mutateAsync(formData);
      } else if (
        !single &&
        capturedOCRBack?.imgFile &&
        capturedOCRFront?.imgFile &&
        capturedOCRsValidationPassed.current
      ) {
        const formData = new FormData();
        setIsCompressing(true);
        await compressAndAppendFile(capturedOCRFront.imgFile, formData);
        await compressAndAppendFile(capturedOCRBack.imgFile, formData);
        setIsCompressing(false);
        await mutateAsync(formData);
      }
    };

    uploadImages();
  }, [capturedOCRBack?.imgFile, capturedOCRFront?.imgFile, mutateAsync, single]);

  const handleInputsSwitch = useCallback(() => {
    onClearCapturedOCRs(person);
    setSingle(!single);
  }, [onClearCapturedOCRs, person, single]);

  return (
    <>
      <LoadingOverlay visible={isLoading || isCompressing} />
      <div className="flex gap-x-10 mb-2">
        <Radio checked={!single} onChange={handleInputsSwitch} label="Giấy tờ 2 mặt" />
        <Radio checked={single} onChange={handleInputsSwitch} label="Giấy tờ 1 mặt" />
      </div>
      <div className="flex gap-3 mb-16px">
        <div className="bg-[#F5F5F5] flex-1 min-h-[98px] rounded-md relative">
          {capturedOCRFront ? (
            <>
              <div
                onClick={() => onClearCapturedOCRs(person, IdentificationFaceEnum.FRONT)}
                className="absolute top-2 right-2 w-5 h-5 flex-center rounded-full bg-white/40">
                <CloseSVG className="w-3" />
              </div>
              <img className="h-full w-full object-contain rounded-md" src={capturedOCRFront.imgUrl} alt="Front" />
            </>
          ) : (
            <div
              className="h-full w-full px-4 py-5 flex-center flex-col gap-y-3 text-center"
              onClick={() => onOpenOCR(person, IdentificationFaceEnum.FRONT)}>
              <CameraSVG />
              Chụp mặt trước
            </div>
          )}
        </div>
        {!single && (
          <div className="bg-[#F5F5F5] flex-1 min-h-[98px] rounded-md relative">
            {capturedOCRBack ? (
              <>
                <div
                  onClick={() => onClearCapturedOCRs(person, IdentificationFaceEnum.BACK)}
                  className="absolute top-2 right-2 w-5 h-5 flex-center rounded-full bg-white/40">
                  <CloseSVG className="w-3" />
                </div>
                <img className="h-full w-full object-contain rounded-md" src={capturedOCRBack.imgUrl} alt="Back" />
              </>
            ) : (
              <div
                className="h-full w-full px-4 py-5 flex-center flex-col gap-y-3 text-center"
                onClick={() => onOpenOCR(person, IdentificationFaceEnum.BACK)}>
                <CameraSVG />
                Chụp mặt sau
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
});
