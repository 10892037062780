import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, Select, SelectItem, TextInput } from '@mantine/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
import { stringify } from 'qs';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { Controller, FieldErrors, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { DatePicker, Empty, FormLabel } from '../../../components';
import { PickAdress } from '../../../components/pick-address/pick-address';
import { DEFAULT_NATIONALITY, useDialog, useInitialData, useLeads, useReverse } from '../../../contexts';
import { getAge, getMessage, validationMessages } from '../../../helpers';
import { vi } from '../../../i18n/vi';
import { CalendarSVG, ChevronDownSVG, PlusSVG, TrashSVG, UserSVG } from '../../../icons';
import {
  GenderEnum,
  IdentityDocumentTypeEnum,
  InsuranceTypeEnum,
  OcrProcessOutput,
  RelationshipTypeEnum,
  useCreateProcessMutation,
  UserEntity,
} from '../../../services';
import {
  AssuredTypeEnum,
  CollapseHeader,
  CollapseHeaderType,
  dobValidation,
  emailValidation,
  GenderRadioGroup,
  genderValidation,
  identityDocumentNumberValidation,
  identityDocumentTypeValidation,
  nameValidation,
  phoneValidation,
} from '../components';
import { MoneyInputSuggest } from '../components/money-suggestion/money-suggestion';
import { IdentificationPhotoInputs } from '../../../components/identification-photo-inputs';

import {
  FormStepEnum,
  identityDocumentTypeOptions,
  InsuredPersonFormData,
  NationalType,
  useIllustration,
  User,
} from './illustration-form-screen';
import { initDataNationalities } from './mock-nationalities';

export const relationshipTypeOptions: SelectItem[] = [
  {
    value: RelationshipTypeEnum.COUPLE,
    label: 'Vợ/Chồng',
  },
  {
    value: RelationshipTypeEnum.CHILDREN,
    label: 'Con',
  },
  {
    value: RelationshipTypeEnum.PARENT,
    label: 'Bố/Mẹ',
  },
];

export const InitializeForm = memo(() => {
  const navigate = useNavigate();

  const {
    insuredPerson,
    onUpdateInsuredPersonForm,
    customer_is_assured,
    toggleIsCustomerIsAssured,
    onUpdatePersonsForm,
    insuranceDetail,
    onUpdateInsuranceDetail,
    insuranceType,
  } = useIllustration();

  const { reverseCustomer } = useReverse();
  const { leadsCustomer, onUpdateLeadsInsuredPersonForm } = useLeads();

  const validationSchema: yup.ObjectSchema<
    Omit<InsuredPersonFormData, 'assured'> & {
      assured?: Partial<User>;
    }
  > = useMemo(
    () =>
      yup.object({
        customer: yup.object({
          full_name: nameValidation,
          dob: dobValidation.test(
            'min-18',
            'Bên mua bảo hiểm phải đủ từ 18 tuổi trở lên',
            (val) => val == null || getAge(val) >= 18,
          ),
          gender: genderValidation,
          occupation_id: yup.number().required(),
          identification_type: identityDocumentTypeValidation,
          identification_id: identityDocumentNumberValidation,
          married: yup.bool().required(),
          phone_number: phoneValidation,
          address: yup.string().required(validationMessages.addressRequired),
          province_name: yup.string().required(validationMessages.provinceNameRequired),
          ward_name: yup.string().required(validationMessages.wardNameRequired),
          district_name: yup.string().required(validationMessages.districtNameRequired),
          email: emailValidation,
          id_issued_place: yup.string().trim().required(),
          identification_date: yup
            .string()
            .required()
            .test(
              'furure-day',
              'Không được nhập ngày tương lai',
              (val) => val === null || dayjs(val).toDate().getTime() < new Date().getTime(),
            ),

          annual_income: yup
            .number()
            .typeError(validationMessages.required)
            .test('check-required', validationMessages.required, (val, context) =>
              getAge(context?.parent?.dob) >= 18 && !val ? false : true,
            ),
          nationality_code: yup.string().required(validationMessages.nationalityRequired),
        }),
        assured: customer_is_assured
          ? yup.object()
          : yup.object({
              full_name: nameValidation,
              dob: dobValidation.test(
                'min-18',
                'Tuổi của người được bảo hiểm phải từ đủ 30 ngày tuổi trở lên',
                (val) => val == null || dayjs().diff(dayjs(val), 'day') >= 30,
              ),
              gender: genderValidation.test(
                'check-gender-required',
                validationMessages.selectGender,
                (val, context: any) => {
                  const { from } = context;
                  const [
                    { value: parent },
                    {
                      value: { customer },
                    },
                  ] = from;
                  return !(parent?.relationship === RelationshipTypeEnum.COUPLE && val === customer?.gender);
                },
              ),
              occupation_id: yup.number().required(),
              identification_type: identityDocumentTypeValidation,
              identification_id: identityDocumentNumberValidation,
              married: yup.bool().required(),
              phone_number: phoneValidation,
              address: yup.string().required(validationMessages.addressRequired),
              province_name: yup.string().required(validationMessages.provinceNameRequired),
              ward_name: yup.string().required(validationMessages.wardNameRequired),
              district_name: yup.string().required(validationMessages.districtNameRequired),
              email: emailValidation,
              relationship: yup.mixed<RelationshipTypeEnum>().required(),
              annual_income: yup
                .number()
                .typeError(validationMessages.required)
                .test('check-required', validationMessages.required, (val, context) =>
                  getAge(context?.parent?.dob) >= 18 && !val ? false : true,
                ),
              nationality_code: yup.string().required(validationMessages.nationalityRequired),
            }),
        additional_assureds: yup.array(
          yup.object({
            full_name: nameValidation,
            dob: dobValidation.test(
              'min-18',
              'Tuổi của người được bảo hiểm bổ sung phải từ đủ 30 ngày tuổi trở lên',
              (val) => val == null || dayjs().diff(dayjs(val), 'day') >= 30,
            ),
            gender: genderValidation.test(
              'check-gender-required',
              validationMessages.selectGender,
              (val, context: any) => {
                const { from } = context;
                const [
                  { value: parent },
                  {
                    value: { customer },
                  },
                ] = from;
                return !(parent?.relationship === RelationshipTypeEnum.COUPLE && val === customer?.gender);
              },
            ),
            occupation_id: yup.number().required(),
            identification_type: identityDocumentTypeValidation,
            identification_id: identityDocumentNumberValidation,
            married: yup.bool().required(),
            phone_number: phoneValidation,
            address: yup.string().required(validationMessages.addressRequired),
            province_name: yup.string().required(validationMessages.provinceNameRequired),
            ward_name: yup.string().required(validationMessages.wardNameRequired),
            district_name: yup.string().required(validationMessages.districtNameRequired),
            email: emailValidation,
            relationship: yup.mixed<RelationshipTypeEnum>().required(),
            annual_income: yup
              .number()
              .typeError(validationMessages.required)
              .test('check-required', validationMessages.required, (val, context) =>
                getAge(context?.parent?.dob) >= 18 && !val ? false : true,
              ),
            nationality_code: yup.string().required(validationMessages.nationalityRequired),
          }),
        ),
      }),
    [customer_is_assured],
  );

  const { occupations } = useInitialData();

  const dataNationalities = useMemo(() => {
    const newData = initDataNationalities?.map((el: NationalType) => {
      return {
        value: el.id,
        label: el.name,
      };
    });
    return uniqBy(newData, 'value');
  }, []);

  const defaultOccupation = occupations.find((it) => it.name_vn === 'Nhân viên văn phòng');
  const studentOccupation = occupations.find((it) => it.name_vn === 'Sinh viên, học sinh');

  const defaultValues = useMemo(
    () => ({
      customer: {
        gender: reverseCustomer?.gender ?? GenderEnum.MALE,
        dob: reverseCustomer?.dob,
        married: false,
        identification_type: IdentityDocumentTypeEnum.NATIONAL_ID,
        occupation_id: defaultOccupation?.id,
        nationality_code: DEFAULT_NATIONALITY,
      },
      assured: {
        gender: GenderEnum.MALE,
        married: false,
        identification_type: IdentityDocumentTypeEnum.NATIONAL_ID,
        occupation_id: defaultOccupation?.id,
        nationality_code: DEFAULT_NATIONALITY,
      },
      additional_assureds: [],
    }),
    [defaultOccupation?.id, reverseCustomer?.dob, reverseCustomer?.gender],
  );
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    watch,
    control,
    clearErrors,
    setError,
    resetField,
    formState: { errors },
  } = useForm<InsuredPersonFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(
      () => (leadsCustomer?.is_loan ? defaultValues : insuredPerson || defaultValues),
      [defaultValues, insuredPerson, leadsCustomer?.is_loan],
    ),
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'additional_assureds',
  });

  const resetAssuredForm = useCallback(() => {
    setValue('assured', {
      gender: GenderEnum.MALE,
      married: true,
      identification_type: IdentityDocumentTypeEnum.NATIONAL_ID,
      occupation_id: defaultOccupation?.id,
      dob: null,
      full_name: null,
      identification_id: null,
      phone_number: null,
    } as any);
  }, [defaultOccupation?.id, setValue]);

  const { mutateAsync: createProcessAsync, isLoading } = useCreateProcessMutation({
    onError: (error) => {
      showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
    },
    onSuccess: (result) => {
      navigate({
        search: stringify({
          step: FormStepEnum.DETAIL,
          processId: result?.process_id,
        }),
      });
    },
  });

  const { showDialog } = useDialog();

  const onSubmit = useCallback(
    (body: InsuredPersonFormData) => {
      const data: InsuredPersonFormData = {
        ...body,
        customer: {
          ...body?.customer,
          full_name: body?.customer?.full_name?.toLocaleUpperCase(),
        },
        assured: {
          ...body?.assured,
          full_name: body?.assured?.full_name?.toLocaleUpperCase(),
        },
        additional_assureds: body?.additional_assureds?.map((it) => ({
          ...it,
          full_name: it?.full_name?.toLocaleUpperCase(),
        })),
      };
      if (leadsCustomer) {
        onUpdateLeadsInsuredPersonForm({
          ...data,
          assured: customer_is_assured ? data.customer : data.assured,
        });
        if (insuranceDetail) {
          if (!customer_is_assured) {
            onUpdateInsuranceDetail({
              ...insuranceDetail,
              additional_products: insuranceDetail.additional_products.map((product) => ({
                ...product,
                person: product.person === '-1' ? '0' : product.person,
              })),
              mic_additional_products: insuranceDetail.mic_additional_products.map((product) => ({
                ...product,
                person: product.person === '-1' ? '0' : product.person,
              })),
            });
          } else if (customer_is_assured) {
            onUpdateInsuranceDetail({
              ...insuranceDetail,
              additional_products: insuranceDetail.additional_products.map((product) => ({
                ...product,
                person: product.person === '0' ? '-1' : product.person,
              })),
              mic_additional_products: insuranceDetail.mic_additional_products.map((product) => ({
                ...product,
                person: product.person === '0' ? '-1' : product.person,
              })),
            });
          }
        }
      }
      onUpdateInsuredPersonForm({
        ...data,
        assured: customer_is_assured ? data.customer : data.assured,
      });
      let persons = [
        {
          ...data.customer,
          id: '-1',
          is_customer: true,
          type: customer_is_assured ? AssuredTypeEnum.LIFE_ASSURED : AssuredTypeEnum.ADDITIONAL_LIVES,
        },
      ];
      if (!customer_is_assured) {
        persons = persons.concat([
          { ...data?.assured, id: '0', type: AssuredTypeEnum.LIFE_ASSURED, is_customer: false },
        ]);
      }
      const newAdditionalAssureds = data?.additional_assureds?.map((it, index) => ({
        ...it,
        id: (index + 1).toString(),
        type: AssuredTypeEnum.ADDITIONAL_LIVES,
        is_customer: false,
      }));
      if (newAdditionalAssureds) {
        persons = persons?.concat(newAdditionalAssureds);
      }
      if (persons.some((person) => person.nationality_code !== 'VN')) {
        showDialog({
          type: 'ALERT',
          message: vi.MSG08,
        });
        return;
      }
      onUpdatePersonsForm(persons?.filter((it) => !!it.full_name));

      if (insuranceType === InsuranceTypeEnum.ULRP_3_0) {
        const c = data?.customer;
        createProcessAsync({
          customer: {
            address: {
              province_name: c?.province_name,
              district_name: c?.district_name,
              ward_name: c?.ward_name,
              line1: c?.address,
              phone_number: c?.phone_number,
            },
            dob: dayjs(c?.dob ?? dayjs()).format('YYYY-MM-DD'),
            email: c?.email,
            full_name: c?.full_name,
            gender: c?.gender,
            identification_id: c?.identification_id,
            identification_type: c?.identification_type,
            id_issued_place: c?.id_issued_place,
            identification_date: dayjs(c?.identification_date ?? dayjs()).format('YYYY-MM-DD'),
            nationality_code: c?.nationality_code,
            occupation_id: c?.occupation_id,
            phone_number: c?.phone_number,
            annual_income: c?.annual_income ? c?.annual_income * 12 : 0,
          } as UserEntity,
          customer_is_assured,
          product_package_id: 0,
          product_package_code: '',
          direct_id: leadsCustomer?.direct_id,
        });
      } else {
        navigate({
          search: stringify({
            step: FormStepEnum.DETAIL,
          }),
        });
      }
    },
    [
      createProcessAsync,
      customer_is_assured,
      insuranceDetail,
      insuranceType,
      leadsCustomer,
      navigate,
      onUpdateInsuranceDetail,
      onUpdateInsuredPersonForm,
      onUpdateLeadsInsuredPersonForm,
      onUpdatePersonsForm,
      showDialog,
    ],
  );

  const handleRemoveReference = useCallback(
    async (resolve: () => void) => {
      const res = await showDialog({
        message: (
          <div className="text-center text-c_EB5757 my-20px font-medium">
            Nếu bạn xác nhận xoá, dữ liệu của bản ghi sẽ không thể khôi phục.
          </div>
        ),
        type: 'CONFIRM',
      });

      res && resolve();
    },
    [showDialog],
  );

  const customerFormRef = useRef<CollapseHeaderType>(null);
  const assuredFormRef = useRef<CollapseHeaderType>(null);
  const additional_assuredsFormRef = useRef<CollapseHeaderType>(null);

  const handleFormSubmitError = useCallback((error: FieldErrors<InsuredPersonFormData>) => {
    if (error.customer != null) {
      customerFormRef.current?.toggle(true);
    }
    if (error.assured != null) {
      assuredFormRef.current?.toggle(true);
    }
    if (error.additional_assureds != null) {
      additional_assuredsFormRef.current?.toggle(true);
    }
  }, []);

  const fillInitAssured = useCallback(
    (age: number, ageDay: number) => {
      const customer = watch('customer');
      trigger('assured.identification_id');
      setValue(`assured.identification_id`, '');
      if (ageDay > 0 && age < 14) {
        setValue(`assured.identification_type`, IdentityDocumentTypeEnum.BIRTH_CERTIFICATE);
      } else {
        setValue(`assured.identification_type`, IdentityDocumentTypeEnum.NATIONAL_ID);
      }
      if (age < 18) {
        setValue(`assured.email`, customer?.email ?? '');
        setValue(`assured.address`, customer?.address ?? '');
        setValue(`assured.phone_number`, customer?.phone_number ?? '');
        setValue(`assured.province_name`, customer?.province_name ?? '');
        setValue(`assured.district_name`, customer?.district_name ?? '');
        setValue(`assured.ward_name`, customer?.ward_name ?? '');
        setValue(`assured.occupation_id`, studentOccupation?.id ?? 0);
        return;
      }
      setValue(`assured.occupation_id`, defaultOccupation?.id ?? 0);
    },
    [defaultOccupation?.id, setValue, studentOccupation?.id, trigger, watch],
  );

  const fillInitAdditonalAssured = useCallback(
    (index: number, age: number, ageDay: number) => {
      const customer = watch('customer');
      trigger(`additional_assureds.${index}.identification_id`);
      setValue(`additional_assureds.${index}.identification_id`, '');
      if (ageDay > 0 && age < 14) {
        setValue(`additional_assureds.${index}.identification_type`, IdentityDocumentTypeEnum.BIRTH_CERTIFICATE);
      } else {
        setValue(`additional_assureds.${index}.identification_type`, IdentityDocumentTypeEnum.NATIONAL_ID);
      }
      if (age < 18) {
        setValue(`additional_assureds.${index}.email`, customer?.email ?? '');
        setValue(`additional_assureds.${index}.address`, customer?.address ?? '');
        setValue(`additional_assureds.${index}.phone_number`, customer?.phone_number ?? '');
        setValue(`additional_assureds.${index}.province_name`, customer?.province_name ?? '');
        setValue(`additional_assureds.${index}.district_name`, customer?.district_name ?? '');
        setValue(`additional_assureds.${index}.ward_name`, customer?.ward_name ?? '');
        setValue(`additional_assureds.${index}.occupation_id`, studentOccupation?.id ?? 0);
        return;
      }
      setValue(`additional_assureds.${index}.occupation_id`, defaultOccupation?.id ?? 0);
    },
    [defaultOccupation?.id, setValue, studentOccupation?.id, trigger, watch],
  );

  const availableIdentityDocumentTypeOptions = useCallback((age: number, ageDay: number) => {
    if (!age && !ageDay) {
      return identityDocumentTypeOptions?.filter((it) => [IdentityDocumentTypeEnum.NATIONAL_ID].includes(it.value));
    }
    if (age < 14) {
      return identityDocumentTypeOptions?.filter((it) =>
        [IdentityDocumentTypeEnum.BIRTH_CERTIFICATE, IdentityDocumentTypeEnum.PASSPORT].includes(it.value),
      );
    }
    if (age >= 14 && age < 18) {
      return identityDocumentTypeOptions?.filter((it) =>
        [
          IdentityDocumentTypeEnum.NATIONAL_ID,
          IdentityDocumentTypeEnum.CITIZEN_ID,
          IdentityDocumentTypeEnum.PASSPORT,
        ].includes(it.value),
      );
    }
    return identityDocumentTypeOptions?.filter((it) => it.value !== IdentityDocumentTypeEnum.BIRTH_CERTIFICATE);
  }, []);

  const [isInvalidPhone, setIsInvalidPhone] = useState<boolean>(false);
  const triggerPhone = useCallback(
    async (val: string, index: number, type: string, age: number) => {
      const insuranceBuyer = watch('customer');
      setIsInvalidPhone(false);
      clearErrors(type === 'main' ? 'assured.phone_number' : `additional_assureds.${index}.phone_number`);
      if (age < 18) {
        return;
      }
      let assureds = [
        {
          phone_number: watch('assured.phone_number'),
          type: 'main',
        },
        ...(watch('additional_assureds') ?? [])?.map((it, i) => ({
          phone_number: it.phone_number,
          type: 'additional_' + i,
        })),
      ];
      if (!customer_is_assured && insuranceBuyer) {
        assureds = assureds.concat([{ phone_number: insuranceBuyer.phone_number, type: 'holder' }]);
      }
      const newAssureds = assureds?.filter((it) => it.type !== type);
      const findIndex = newAssureds?.findIndex((it) => it.phone_number === val);
      if (findIndex > -1) {
        setIsInvalidPhone(true);
        setTimeout(() => {
          setError(type === 'main' ? 'assured.phone_number' : `additional_assureds.${index}.phone_number`, {
            type: 'duplicate-phone',
            message: 'Số điện thoại đã bị trùng',
          });
        }, 200);
      }
    },
    [watch, clearErrors, customer_is_assured, setError],
  );

  const handleFillDataFromOCR = (res: OcrProcessOutput, person: string) => {
    const ocrOutputMapped = {
      full_name: res.full_name,
      dob: res.birth_day,
      gender: res.gender,
      district_name: res.district_name,
      ward_name: res.ward_name,
      address: res.line1,
      nationality_code: res.nationality,
      province_name: res.province_name,
      id_issued_place: res.issue_place,
      identification_date: res.issue_date || res.identification_date,
      identification_id: res.identification_id,
      identification_type: res.identification_type,
    };

    for (const [field, value] of Object.entries(ocrOutputMapped)) {
      resetField(`${person}.${field}` as any);
      if (value) {
        setValue(`${person}.${field}` as any, value);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, handleFormSubmitError)}>
      <div className="pb-120px">
        <CollapseHeader ref={customerFormRef} title="Bên mua bảo hiểm" icon={<UserSVG />} className="mt-16px">
          <div className="px-16px py-20px">
            <div>
              <FormLabel>Hình ảnh Giấy tờ tuỳ thân</FormLabel>
              <IdentificationPhotoInputs person="customer" handleFillDataFromOCR={handleFillDataFromOCR} />
            </div>
            <div>
              <FormLabel required>Họ và tên</FormLabel>
              <Controller
                name={`customer.full_name`}
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <TextInput
                    placeholder="Nhập Họ và tên"
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => onChange(e?.target?.value)}
                    error={errors.customer?.full_name?.message}
                    styles={{ input: { textTransform: 'uppercase' } }}
                    className="placeholder"
                  />
                )}
              />
            </div>

            <div>
              <FormLabel required>Ngày sinh</FormLabel>
              <Controller
                control={control}
                name="customer.dob"
                render={({ field: { onBlur, onChange, value } }) => (
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    placeholder="DD/MM/YYYY"
                    labelFormat="MM/YYYY"
                    allowFreeInput
                    value={value != null ? dayjs(value).toDate() : undefined}
                    onChange={onChange}
                    onBlur={onBlur}
                    clearable={false}
                    rightSection={<CalendarSVG />}
                    excludeDate={(date) => date.getTime() > new Date().getTime()}
                    error={errors.customer?.dob?.message}
                  />
                )}
              />
            </div>

            <div>
              <FormLabel required>Giới tính</FormLabel>
              <Controller
                control={control}
                name="customer.gender"
                render={({ field: { onChange, value } }) => (
                  <GenderRadioGroup
                    value={value}
                    onChange={(val) => {
                      onChange(val);
                      !!watch(`customer.identification_id`) && trigger(`customer.identification_id`);
                    }}
                  />
                )}
              />
            </div>

            <div>
              <FormLabel required>Nghề nghiệp</FormLabel>
              <Controller
                control={control}
                name="customer.occupation_id"
                render={({ field: { value, onBlur, onChange } }) => (
                  <Select
                    data={occupations.map((it) => ({
                      value: it.id?.toString(),
                      label: it.name_vn,
                    }))}
                    searchable
                    maxDropdownHeight={300}
                    nothingFound={<Empty title="Empty" />}
                    filter={(value, item) => (item.label ?? '').toLowerCase().includes(value.toLowerCase().trim())}
                    rightSection={<ChevronDownSVG />}
                    value={value?.toString()}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={errors.customer?.occupation_id?.message}
                  />
                )}
              />
            </div>

            <div>
              <FormLabel required>Quốc tịch</FormLabel>
              <Controller
                control={control}
                name="customer.nationality_code"
                render={({ field: { value, onBlur, onChange } }) => (
                  <Select
                    data={dataNationalities}
                    searchable
                    maxDropdownHeight={300}
                    nothingFound={<Empty title="Empty" />}
                    filter={(value, item) => (item.label ?? '').toLowerCase().includes(value.toLowerCase().trim())}
                    rightSection={<ChevronDownSVG />}
                    value={value?.toString()}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={errors.customer?.nationality_code?.message}
                  />
                )}
              />
            </div>

            <div>
              <FormLabel required>Loại giấy tờ tùy thân</FormLabel>
              <Controller
                control={control}
                name="customer.identification_type"
                render={({ field: { onBlur, onChange, value } }) => (
                  <Select
                    data={identityDocumentTypeOptions?.filter(
                      (it) => it.value !== IdentityDocumentTypeEnum.BIRTH_CERTIFICATE,
                    )}
                    maxDropdownHeight={300}
                    rightSection={<ChevronDownSVG />}
                    value={value}
                    onBlur={onBlur}
                    onChange={(val) => {
                      onChange(val);
                      trigger('customer.identification_id');
                    }}
                    error={errors.customer?.identification_type?.message}
                  />
                )}
              />
            </div>

            <div>
              <FormLabel required>Số giấy tờ tùy thân</FormLabel>
              <TextInput
                placeholder="Nhập số giấy tờ tuỳ thân"
                {...register('customer.identification_id')}
                error={errors.customer?.identification_id?.message}
              />
            </div>

            <div>
              <FormLabel required>Ngày cấp</FormLabel>
              <Controller
                control={control}
                name="customer.identification_date"
                render={({ field: { onBlur, onChange, value } }) => (
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    placeholder="Nhập ngày cấp giấy tờ tuỳ thân"
                    labelFormat="MM/YYYY"
                    allowFreeInput
                    value={value != null ? dayjs(value).toDate() : undefined}
                    onChange={onChange}
                    onBlur={onBlur}
                    clearable={false}
                    rightSection={<CalendarSVG />}
                    excludeDate={(date) => date.getTime() > new Date().getTime()}
                    error={errors.customer?.identification_date?.message}
                  />
                )}
              />
            </div>
            <div>
              <FormLabel required>Nơi cấp</FormLabel>
              <TextInput
                placeholder="Nhập nơi cấp giấy tờ tuỳ thân"
                {...register('customer.id_issued_place')}
                error={errors.customer?.id_issued_place?.message}
              />
            </div>

            {/* <div>
              <FormLabel required>Tình trạng hôn nhân</FormLabel>
              <Controller
                control={control}
                name="customer.married"
                render={({ field: { value, onChange } }) => (
                  <RadioGroup options={marriedOptions} value={value} onChange={onChange} />
                )}
              />
            </div> */}

            <div>
              <PickAdress
                control={control}
                watch={watch}
                setValue={setValue}
                clearErrors={clearErrors}
                nameProvince="customer.province_name"
                nameWard="customer.ward_name"
                nameDistrict="customer.district_name"
                errorTextAddress={errors?.customer?.address?.message?.toString()}
                nameAdress="customer.address"
                errorProvince={errors?.customer?.province_name?.message?.toString()}
                errorDistrict={errors?.customer?.district_name?.message?.toString()}
                errorWard={errors?.customer?.ward_name?.message?.toString()}
              />
            </div>

            <div>
              <FormLabel required>Email</FormLabel>
              <TextInput
                placeholder="Nhập email"
                {...register('customer.email')}
                error={errors.customer?.email?.message}
              />
            </div>
            <div>
              <FormLabel required>Số điện thoại</FormLabel>
              <TextInput
                placeholder="Nhập số điện thoại"
                {...register('customer.phone_number')}
                maxLength={10}
                error={errors.customer?.phone_number?.message}
              />
            </div>
            {getAge(watch('customer.dob')) >= 18 && (
              <div>
                <FormLabel required>Thu nhập bình quân/tháng</FormLabel>
                <Controller
                  control={control}
                  name="customer.annual_income"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <MoneyInputSuggest
                      placeholder="Nhập số tiền"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={errors.customer?.annual_income?.message}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </CollapseHeader>

        <div className="my-16px p-16px bg-white">
          <div className="p-16px bg-c_0099FF-10 bg-white rounded-6px">
            <Checkbox
              label={
                <span
                  className={classNames(
                    'text-14px font-semibold text-primary',
                    customer_is_assured && 'text-opacity-50',
                  )}>
                  Bên mua bảo hiểm đồng thời là người được bảo hiểm chính
                </span>
              }
              checked={customer_is_assured}
              onChange={(e) => {
                toggleIsCustomerIsAssured(e.target.checked);
                if (!e.target.checked) {
                  resetAssuredForm();
                }
              }}
            />
          </div>
        </div>

        <CollapseHeader
          ref={assuredFormRef}
          title="Người được bảo hiểm chính"
          icon={<UserSVG />}
          className="mt-16px"
          preventOpen={customer_is_assured}>
          <div className="p-16px">
            <div>
              <FormLabel>Hình ảnh Giấy tờ tuỳ thân</FormLabel>
              <IdentificationPhotoInputs person="assured" handleFillDataFromOCR={handleFillDataFromOCR} />
            </div>
            <div>
              <FormLabel required>Họ và tên</FormLabel>
              <Controller
                name={`assured.full_name`}
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <TextInput
                    placeholder="Nhập Họ và tên"
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => onChange(e?.target?.value)}
                    error={errors.assured?.full_name?.message}
                    styles={{ input: { textTransform: 'uppercase' } }}
                    className="placeholder"
                  />
                )}
              />
            </div>
            <div>
              <FormLabel required>Mối quan hệ với bên mua bảo hiểm</FormLabel>
              <Controller
                name="assured.relationship"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <Select
                    placeholder="Chọn mối quan hệ"
                    data={relationshipTypeOptions}
                    rightSection={<ChevronDownSVG />}
                    value={value}
                    onBlur={onBlur}
                    onChange={(val) => {
                      onChange(val);
                      trigger('assured.gender');
                    }}
                    error={errors.assured?.relationship?.message}
                  />
                )}
              />
            </div>

            <div>
              <FormLabel required>Ngày sinh</FormLabel>
              <Controller
                control={control}
                name="assured.dob"
                render={({ field: { onBlur, onChange, value } }) => (
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    placeholder="DD/MM/YYYY"
                    labelFormat="MM/YYYY"
                    allowFreeInput
                    value={value != null ? dayjs(value).toDate() : null}
                    onChange={(date) => {
                      onChange(date?.toISOString());
                      if (date) {
                        fillInitAssured(getAge(dayjs(date)), dayjs().diff(dayjs(date?.toISOString()), 'day'));
                        triggerPhone(watch('assured.phone_number'), -1, 'main', getAge(date?.toISOString()));
                      }
                    }}
                    onBlur={onBlur}
                    clearable={false}
                    rightSection={<CalendarSVG />}
                    error={errors.assured?.dob?.message}
                    excludeDate={(date) => date.getTime() > new Date().getTime()}
                  />
                )}
              />
            </div>

            <div>
              <FormLabel required>Giới tính</FormLabel>
              <Controller
                control={control}
                name="assured.gender"
                render={({ field: { onChange, value } }) => (
                  <GenderRadioGroup
                    value={value}
                    onChange={(val) => {
                      onChange(val);
                      !!watch(`assured.identification_id`) && trigger(`assured.identification_id`);
                    }}
                    error={errors?.assured?.gender?.message}
                  />
                )}
              />
            </div>

            <div>
              <FormLabel required>Nghề nghiệp</FormLabel>
              <Controller
                control={control}
                name="assured.occupation_id"
                render={({ field: { value, onBlur, onChange } }) => (
                  <Select
                    data={occupations.map((it) => ({
                      value: it.id?.toString(),
                      label: it.name_vn,
                    }))}
                    searchable
                    maxDropdownHeight={300}
                    nothingFound={<Empty title="Empty" />}
                    filter={(value, item) => (item.label ?? '').toLowerCase().includes(value.toLowerCase().trim())}
                    rightSection={<ChevronDownSVG />}
                    value={value?.toString()}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={errors.assured?.occupation_id?.message}
                  />
                )}
              />
            </div>

            <div>
              <FormLabel required>Quốc tịch</FormLabel>
              <Controller
                control={control}
                name="assured.nationality_code"
                render={({ field: { value, onBlur, onChange } }) => (
                  <Select
                    data={dataNationalities}
                    searchable
                    maxDropdownHeight={300}
                    nothingFound={<Empty title="Empty" />}
                    filter={(value, item) => (item.label ?? '').toLowerCase().includes(value.toLowerCase().trim())}
                    rightSection={<ChevronDownSVG />}
                    value={value?.toString()}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={errors.assured?.nationality_code?.message}
                  />
                )}
              />
            </div>

            <div>
              <FormLabel required>Loại giấy tờ tùy thân</FormLabel>
              <Controller
                name="assured.identification_type"
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <Select
                    data={availableIdentityDocumentTypeOptions(
                      getAge(watch('assured.dob')),
                      dayjs().diff(dayjs(watch('assured.dob')), 'day'),
                    )}
                    rightSection={<ChevronDownSVG />}
                    value={value}
                    onBlur={onBlur}
                    onChange={(val) => {
                      onChange(val);
                      trigger('assured.identification_id');
                    }}
                    error={errors.assured?.identification_type?.message}
                  />
                )}
              />
            </div>

            <div>
              <FormLabel required>Số giấy tờ tùy thân</FormLabel>
              <TextInput
                placeholder="Nhập số giấy tờ tuỳ thân"
                {...register('assured.identification_id')}
                error={errors.assured?.identification_id?.message}
              />
            </div>

            {/* <div>
              <FormLabel required>Tình trạng hôn nhân</FormLabel>
              <Controller
                control={control}
                name="assured.married"
                render={({ field: { value, onChange } }) => (
                  <RadioGroup options={marriedOptions} value={value} onChange={onChange} />
                )}
              />
            </div> */}
            <div>
              <PickAdress
                setValue={setValue}
                control={control}
                watch={watch}
                clearErrors={clearErrors}
                nameProvince="assured.province_name"
                nameWard="assured.ward_name"
                nameDistrict="assured.district_name"
                nameAdress="assured.address"
                errorTextAddress={errors?.assured?.address?.message?.toString()}
                errorProvince={errors?.assured?.province_name?.message?.toString()}
                errorDistrict={errors?.assured?.district_name?.message?.toString()}
                errorWard={errors?.assured?.ward_name?.message?.toString()}
              />
            </div>
            <div>
              <FormLabel required>Email</FormLabel>
              <TextInput
                placeholder="Nhập email"
                {...register('assured.email')}
                error={errors.assured?.email?.message}
              />
            </div>
            <div>
              <FormLabel required>Số điện thoại</FormLabel>
              <Controller
                name="assured.phone_number"
                control={control}
                render={({ field: { value, onBlur, onChange } }) => (
                  <TextInput
                    value={value}
                    onBlur={onBlur}
                    placeholder="Nhập số điện thoại"
                    onChange={(e) => {
                      const val = e?.target?.value?.trim();
                      onChange(val);
                      triggerPhone(val, -1, 'main', getAge(watch('assured.dob')));
                    }}
                    maxLength={10}
                    error={errors.assured?.phone_number?.message}
                  />
                )}
              />
            </div>
            {getAge(watch('assured.dob')) >= 18 && (
              <div>
                <FormLabel required>Thu nhập bình quân/tháng</FormLabel>
                <Controller
                  control={control}
                  name="assured.annual_income"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <MoneyInputSuggest
                      placeholder="Nhập số tiền"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={errors.assured?.annual_income?.message}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </CollapseHeader>

        <CollapseHeader
          ref={additional_assuredsFormRef}
          title="Người được bảo hiểm bổ sung"
          icon={<UserSVG />}
          className="mt-16px"
          renderCollapseIcon={(visible) =>
            !visible && (watch('additional_assureds') ?? []).length === 0 ? <PlusSVG /> : undefined
          }
          onCollapseToggle={(visible) =>
            visible &&
            (watch('additional_assureds') ?? []).length === 0 &&
            append({
              gender: GenderEnum.MALE,
              married: false,
              identification_type: IdentityDocumentTypeEnum.NATIONAL_ID,
              occupation_id: defaultOccupation?.id,
              nationality_code: DEFAULT_NATIONALITY,
            } as User)
          }
          defaultVisible={false}>
          <div className="p-16px">
            {fields.map((field, index) => (
              <div key={field.id} className="p-16px border-0.5px border-solid border-c_DDDDDD rounded-6px mb-16px">
                <div className="mb-16px text-18px font-bold leading-22px">Người hưởng bảo hiểm bổ sung {index + 1}</div>
                <div>
                  <FormLabel>Hình ảnh Giấy tờ tuỳ thân</FormLabel>
                  <IdentificationPhotoInputs
                    person={`additional_assureds.${index}`}
                    handleFillDataFromOCR={handleFillDataFromOCR}
                  />
                </div>
                <div>
                  <FormLabel required>Họ và tên</FormLabel>
                  <Controller
                    name={`additional_assureds.${index}.full_name`}
                    control={control}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <TextInput
                        placeholder="Nhập Họ và tên"
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => onChange(e?.target?.value)}
                        error={errors.additional_assureds?.[index]?.full_name?.message}
                        styles={{ input: { textTransform: 'uppercase' } }}
                        className="placeholder"
                      />
                    )}
                  />
                </div>

                <div>
                  <FormLabel required>Mối quan hệ với bên mua bảo hiểm</FormLabel>
                  <Controller
                    name={`additional_assureds.${index}.relationship`}
                    control={control}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <Select
                        placeholder="Chọn mối quan hệ"
                        data={relationshipTypeOptions}
                        rightSection={<ChevronDownSVG />}
                        value={value}
                        onBlur={onBlur}
                        onChange={(val) => {
                          onChange(val);
                          trigger(`additional_assureds.${index}.gender`);
                        }}
                        error={errors.additional_assureds?.[index]?.relationship?.message}
                      />
                    )}
                  />
                </div>
                <div>
                  <FormLabel required>Ngày sinh</FormLabel>
                  <Controller
                    control={control}
                    name={`additional_assureds.${index}.dob`}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        placeholder="Nhập ngày sinh"
                        labelFormat="MM/YYYY"
                        allowFreeInput
                        value={value != null ? dayjs(value).toDate() : undefined}
                        onChange={(date) => {
                          onChange(date?.toISOString());
                          if (date != null) {
                            fillInitAdditonalAssured(index, getAge(date), dayjs().diff(dayjs(date), 'day'));
                            triggerPhone(
                              watch(`additional_assureds.${index}.phone_number`),
                              index,
                              'additional_' + index,
                              getAge(date?.toISOString() ?? dayjs()),
                            );
                          }
                        }}
                        onBlur={onBlur}
                        clearable={false}
                        rightSection={<CalendarSVG />}
                        error={errors.additional_assureds?.[index]?.dob?.message}
                        excludeDate={(date) => date.getTime() > new Date().getTime()}
                      />
                    )}
                  />
                </div>

                <div>
                  <FormLabel required>Giới tính</FormLabel>
                  <Controller
                    control={control}
                    name={`additional_assureds.${index}.gender`}
                    render={({ field: { onChange, value } }) => (
                      <GenderRadioGroup
                        value={value}
                        onChange={(val) => {
                          onChange(val);
                          !!watch(`additional_assureds.${index}.identification_id`) &&
                            trigger(`additional_assureds.${index}.identification_id`);
                        }}
                        error={errors.additional_assureds?.[index]?.gender?.message}
                      />
                    )}
                  />
                </div>

                <div>
                  <FormLabel required>Nghề nghiệp</FormLabel>
                  <Controller
                    control={control}
                    name={`additional_assureds.${index}.occupation_id`}
                    render={({ field: { value, onBlur, onChange } }) => (
                      <Select
                        data={occupations.map((it) => ({
                          value: it.id?.toString(),
                          label: it.name_vn,
                        }))}
                        searchable
                        maxDropdownHeight={300}
                        nothingFound={<Empty title="Empty" />}
                        filter={(value, item) => (item.label ?? '').toLowerCase().includes(value.toLowerCase().trim())}
                        rightSection={<ChevronDownSVG />}
                        value={value?.toString()}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={errors.additional_assureds?.[index]?.occupation_id?.message}
                      />
                    )}
                  />
                </div>

                <div>
                  <FormLabel required>Quốc tịch</FormLabel>
                  <Controller
                    control={control}
                    name={`additional_assureds.${index}.nationality_code`}
                    render={({ field: { value, onBlur, onChange } }) => (
                      <Select
                        data={dataNationalities}
                        searchable
                        maxDropdownHeight={300}
                        nothingFound={<Empty title="Empty" />}
                        filter={(value, item) => (item.label ?? '').toLowerCase().includes(value.toLowerCase().trim())}
                        rightSection={<ChevronDownSVG />}
                        value={value?.toString()}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={errors.additional_assureds?.[index]?.nationality_code?.message}
                      />
                    )}
                  />
                </div>

                <div>
                  <FormLabel required>Loại giấy tờ tùy thân</FormLabel>
                  <Controller
                    name={`additional_assureds.${index}.identification_type`}
                    control={control}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <Select
                        data={availableIdentityDocumentTypeOptions(
                          getAge(watch(`additional_assureds.${index}.dob`)),
                          dayjs().diff(dayjs(watch(`additional_assureds.${index}.dob`)), 'day'),
                        )}
                        rightSection={<ChevronDownSVG />}
                        value={value}
                        onBlur={onBlur}
                        onChange={(val) => {
                          onChange(val);
                          trigger(`additional_assureds.${index}.identification_id`);
                        }}
                        error={errors.additional_assureds?.[index]?.identification_type?.message}
                      />
                    )}
                  />
                </div>

                <div>
                  <FormLabel required>Số giấy tờ tùy thân</FormLabel>
                  <TextInput
                    placeholder="Nhập số giấy tờ tuỳ thân"
                    {...register(`additional_assureds.${index}.identification_id`)}
                    error={errors.additional_assureds?.[index]?.identification_id?.message}
                  />
                </div>

                {/* <div>
                  <FormLabel required>Tình trạng hôn nhân</FormLabel>
                  <Controller
                    control={control}
                    name={`additional_assureds.${index}.married`}
                    render={({ field: { value, onChange } }) => (
                      <RadioGroup options={marriedOptions} value={value} onChange={onChange} className="px-12px" />
                    )}
                  />
                </div> */}
                <div>
                  <PickAdress
                    setValue={setValue}
                    control={control}
                    clearErrors={clearErrors}
                    watch={watch}
                    nameProvince={`additional_assureds.${index}.province_name`}
                    nameWard={`additional_assureds.${index}.ward_name`}
                    nameDistrict={`additional_assureds.${index}.district_name`}
                    errorTextAddress={errors?.additional_assureds?.[index]?.address?.message?.toString()}
                    nameAdress={`additional_assureds.${index}.address`}
                    errorProvince={errors?.additional_assureds?.[index]?.province_name?.message?.toString()}
                    errorDistrict={errors?.additional_assureds?.[index]?.district_name?.message?.toString()}
                    errorWard={errors?.additional_assureds?.[index]?.ward_name?.message?.toString()}
                  />
                </div>
                <div>
                  <FormLabel required>Email</FormLabel>
                  <TextInput
                    placeholder="Nhập email"
                    {...register(`additional_assureds.${index}.email`)}
                    error={errors.additional_assureds?.[index]?.email?.message}
                  />
                </div>
                <div>
                  <FormLabel required>Số điện thoại</FormLabel>
                  <Controller
                    name={`additional_assureds.${index}.phone_number`}
                    control={control}
                    render={({ field: { value, onBlur, onChange } }) => (
                      <TextInput
                        value={value}
                        onBlur={onBlur}
                        placeholder="Nhập số điện thoại"
                        onChange={(e) => {
                          const val = e?.target?.value?.trim();
                          onChange(val);
                          triggerPhone(
                            val,
                            index,
                            'additional_' + index,
                            getAge(watch(`additional_assureds.${index}.dob`)),
                          );
                        }}
                        maxLength={10}
                        error={errors.additional_assureds?.[index]?.phone_number?.message}
                      />
                    )}
                  />
                </div>

                {getAge(watch(`additional_assureds.${index}.dob`)) >= 18 && (
                  <div>
                    <FormLabel required>Thu nhập bình quân/tháng</FormLabel>
                    <Controller
                      control={control}
                      name={`additional_assureds.${index}.annual_income`}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <MoneyInputSuggest
                          placeholder="Nhập số tiền"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={errors.additional_assureds?.[index]?.annual_income?.message}
                        />
                      )}
                    />
                  </div>
                )}

                <div className="flex-center">
                  <div className="flex-center">
                    <Button variant="subtle" color="red" onClick={() => handleRemoveReference(() => remove(index))}>
                      <TrashSVG className="mr-10px" /> Xoá
                    </Button>
                  </div>
                </div>
              </div>
            ))}

            <div>
              <Button
                variant="light"
                fullWidth
                disabled={fields.length === 3}
                onClick={() =>
                  append({
                    gender: GenderEnum.MALE,
                    married: true,
                    identification_type: IdentityDocumentTypeEnum.NATIONAL_ID,
                    occupation_id: defaultOccupation?.id,
                    nationality_code: DEFAULT_NATIONALITY,
                  } as User)
                }>
                <PlusSVG fill="currentColor" /> Thêm
              </Button>
            </div>
          </div>
        </CollapseHeader>
      </div>

      <div className="bg-white pt-12px pb-34px px-16px fixed bottom-0 left-0 w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
        <Button type="submit" fullWidth loading={isLoading} disabled={isLoading || isInvalidPhone}>
          Tiếp tục
        </Button>
      </div>
    </form>
  );
});
