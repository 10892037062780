import { NumberInput, TextInput } from '@mantine/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { forEach } from 'lodash';
import React, { Fragment, useContext, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { UserComponent } from '..';
import { Question } from '../../../../../components';
import { DATE_FORMAT } from '../../../../../constants';
import { vi } from '../../../../../i18n/vi';
import { PlusRoundWhiteSVG } from '../../../../../icons';
import {
  convertQuestionName,
  dataQuizzForChild,
  dataQuizzForChildLess4,
  MixQuestionsContext,
  QuestionTypeEnum,
  useIllustration,
} from '../../../form';
import { CollapseHeader } from '../../collapse-header';

import { FooterQuestion } from './footer';
type Props = {
  questionName: string;
};
export const StepsQuestion10ForChildScreen = React.memo<Props>(({ questionName }) => {
  const { currentAssured } = useIllustration();
  const { stepLabel, handleChangeStepLabel, handleSubmitRoot, dataDefine: rootData } = useContext(MixQuestionsContext);
  const dataDefine = useMemo(() => rootData?.[currentAssured?.id ?? ''], [rootData, currentAssured]);

  const dataIndex = useMemo(
    () => (!!currentAssured?.age && currentAssured?.age >= 4 ? dataQuizzForChild : dataQuizzForChildLess4),
    [currentAssured?.age],
  );
  const dataQuestions = useMemo(() => dataIndex.questions, [dataIndex]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    resetField,
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        questions: dataQuestions?.map((item, idx) => {
          return {
            response: dataDefine?.[questionName]?.questions[idx]?.response || item?.default || 'K',
            subQuestions: dataQuestions[idx]?.subQuestions?.map((_, i) => {
              return {
                response: dataDefine?.[questionName]?.questions[idx]?.subQuestions[i].response || undefined,
              };
            }),
          };
        }),
      };
    }, [dataDefine, dataQuestions, questionName]),
  });

  const onNext = (stepGo: number) => {
    handleChangeStepLabel(stepGo);
    const questions = watch('questions');
    const params = {};
    forEach(questions, (item, idx) => {
      if (idx === 0 && !!currentAssured?.age && currentAssured?.age < 4) {
        Object.assign(params, {
          [convertQuestionName(questionName, idx)]: item.subQuestions[0].response?.toString(),
        });
      } else if (idx === 1) {
        Object.assign(params, {
          [convertQuestionName(questionName, idx)]: {
            response: item?.response,
            version: item?.subQuestions[0]?.response?.toString(),
            du_sing: item?.subQuestions[1]?.response
              ? dayjs(item.subQuestions[1]?.response)?.format(DATE_FORMAT.server)
              : undefined,
          },
        });
      } else if (idx > 0) {
        Object.assign(params, {
          [convertQuestionName(questionName, idx)]: {
            response: item.response,
            detail: item.subQuestions[0].response,
          },
        });
      }
    });
    handleSubmitRoot(currentAssured?.id ?? '', {
      [questionName]: {
        questions,
      },
      body: params,
      assured: currentAssured,
    });
  };

  const onSubmit = () => {
    onNext(stepLabel + 1);
  };

  const { fields } = useFieldArray({
    control,
    name: 'questions',
  });

  return (
    <>
      <div className="flex-1">
        <CollapseHeader icon={<PlusRoundWhiteSVG />} title={dataIndex.type} className="custom-collapse-header">
          <UserComponent />
          <div className="wrapQuestion">
            <div className="questionViewTitle">
              <div className="questionIndex">
                <p className="questionIndexText">10</p>
              </div>
              <p className="questionTxtTitle">Câu hỏi 10</p>
            </div>
            {fields?.map((item, index) => {
              return (
                <Fragment key={item?.id}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      if (!!currentAssured?.age && currentAssured?.age >= 4 && index === 0) {
                        return <div />;
                      }
                      return (
                        <Question
                          onChange={(newVal) => {
                            onChange(newVal);
                            resetField(`questions.${index}.subQuestions`);
                          }}
                          value={value}
                          desc={dataQuestions[index].name || ''}
                          style="questionItem"
                          isShow={dataQuestions[index].type === QuestionTypeEnum.INPUT ? false : true}>
                          {watch('questions')?.[index].response === 'C' && (
                            <div className={classNames(dataQuestions[index].style)}>
                              {item?.subQuestions?.map((_, i) => (
                                <Fragment key={dataQuestions[index]?.subQuestions?.[i]?.id}>
                                  <div
                                    className={classNames([
                                      dataQuestions[index].type === QuestionTypeEnum.INPUT && 'px-6px',
                                    ])}>
                                    <Controller
                                      control={control}
                                      render={({ field: { onChange, value } }) => {
                                        const errorText =
                                          errors?.questions?.[index]?.subQuestions?.[i]?.response?.message?.toString();
                                        if (dataQuestions[index].subQuestions[i].type === QuestionTypeEnum.BOOLEAN) {
                                          return (
                                            <Question
                                              onChange={(newVal) => onChange(newVal)}
                                              value={value}
                                              desc={dataQuestions?.[index]?.subQuestions?.[i].name}
                                              style="questionItem"
                                              data={dataQuestions?.[index]?.subQuestions?.[i]?.selections}
                                              errorText={errorText}
                                            />
                                          );
                                        }
                                        if (
                                          [QuestionTypeEnum.NUMBER, QuestionTypeEnum.DECIMAL].includes(
                                            dataQuestions[index].subQuestions[i].type,
                                          )
                                        ) {
                                          return (
                                            <NumberInput
                                              onChange={(value) => onChange(value)}
                                              value={value}
                                              placeholder={dataQuestions[index].subQuestions[i].name}
                                              min={1}
                                              max={dataQuestions[index].subQuestions[i].maxLength}
                                              error={errorText}
                                              precision={
                                                dataQuestions[index].subQuestions[i].type === QuestionTypeEnum.DECIMAL
                                                  ? 1
                                                  : 0
                                              }
                                              step={
                                                dataQuestions[index].subQuestions[i].type === QuestionTypeEnum.DECIMAL
                                                  ? 0.1
                                                  : 1
                                              }
                                              rightSection={<div />}
                                            />
                                          );
                                        }
                                        return (
                                          <TextInput
                                            onBlur={() => onChange(value?.trim())}
                                            onChange={(e) => onChange(e?.target?.value)}
                                            value={value}
                                            placeholder={dataQuestions[index].subQuestions[i].name}
                                            maxLength={dataQuestions[index].subQuestions[i].maxLength}
                                            error={errorText}
                                          />
                                        );
                                      }}
                                      name={`questions.${index}.subQuestions.${i}.response`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: vi.filled,
                                        },
                                      }}
                                    />
                                  </div>
                                </Fragment>
                              ))}
                            </div>
                          )}
                        </Question>
                      );
                    }}
                    name={`questions.${index}.response`}
                  />
                </Fragment>
              );
            })}
          </div>
        </CollapseHeader>
      </div>
      <FooterQuestion
        handleChangeStep={(stepGo: number) => handleSubmit(() => onNext(stepGo))()}
        handleSubmit={handleSubmit(onSubmit)}
      />
    </>
  );
});
