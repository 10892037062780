import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { NumberInput, NumberInputProps } from '../../../../components';

type Props = NumberInputProps & {
  onChange: (value: number) => void;
};

export const MoneyInputSuggest = ({ onChange, ...props }: Props) => {
  const [suggest, setSuggest] = useState<boolean>(false);
  const suggestions = useMemo(() => [1, 10, 100, 1000, 10000, 100000, 1000000, 10000000, 100000000, 1000000000], []);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleChange = useCallback(
    (val?: number) => {
      onChange?.(val);
      setSuggest(!!val);
    },
    [onChange],
  );

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setSuggest(false);
      }
    };

    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div ref={containerRef}>
        <NumberInput
          {...props}
          rightSection={props.rightSection || <span className="text-16px font-semibold">VND</span>}
          onChange={handleChange}
        />
        {suggest && (
          <div className="flex flex-wrap gap-2 mb-4">
            {props.value != null &&
              suggestions.map(
                (multiplier) =>
                  props.value !== undefined &&
                  props.value !== null &&
                  props.value * multiplier >= 100000 &&
                  props.value * multiplier < 100000000000 && (
                    <div
                      key={multiplier}
                      className="bg-gray-300 w-fit py-1 px-3 font-semibold text-black text-sm rounded-full"
                      onClick={() => {
                        if (props.value) {
                          onChange?.(props.value * multiplier);
                          setSuggest(false);
                        }
                      }}>
                      {(props.value * multiplier).toLocaleString('en-US')}
                    </div>
                  ),
              )}
          </div>
        )}
      </div>
    </>
  );
};
