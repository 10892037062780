import { memo, useEffect, useMemo } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';

import { FullscreenLoader } from '../../../components';
import { useDialog, usePdf } from '../../../contexts';
import { useDownloadQuotationPDFQuery } from '../../../services';
import { downloadPDFFromBlob, openPDFFromBlob } from '../../../helpers';
import { CloseSVG } from '../../../icons';
import { ApiUrl } from '../../../helpers/api-url';
import { vi } from '../../../i18n/vi';

type Props = {
  id: number | string;
};

export const IllustrationPdf = memo(({ id }: Props) => {
  const { showDialog } = useDialog();

  useEffect(() => {
    const data = {
      type: 'DOWNLOAD_PDF',
      url: process.env.REACT_APP_API_BASE_URL + ApiUrl.quotation + '/' + id + '/crm-download',
      data: { url: process.env.REACT_APP_API_BASE_URL + ApiUrl.quotation + '/' + id + '/crm-download' },
    };
    window.parent.postMessage(JSON.stringify(data));
    (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify(data));
  }, [id]);

  const { data, isLoading } = useDownloadQuotationPDFQuery(id, {
    onError: (error) => {
      showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: error.response?.data?.message ?? error.message,
      });
    },
    onSuccess: (res) => {
      downloadPDFFromBlob(res);
    },
  });

  const url = useMemo(() => (data ? openPDFFromBlob(data) : null), [data]);

  const { onOpenPdf } = usePdf();

  return (
    <div className="w-screen min-h-screen bg-c_F1F6F8">
      <div className="bg-white flex justify-between px-16px py-12px fixed top-0 w-full z-10">
        <div></div>
        <span></span>
        <div className="w-24px h-24px" onClick={() => onOpenPdf(undefined)}>
          <CloseSVG />
        </div>
      </div>
      <div className="w-full h-full relative pt-48px"></div>
      {isLoading ? (
        <FullscreenLoader />
      ) : (
        url != null && (
          <div style={{ height: 'calc(100vh - 48px)' }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.js">
              <Viewer fileUrl={url} renderLoader={() => <FullscreenLoader />} />
            </Worker>
          </div>
        )
      )}
    </div>
  );
});
