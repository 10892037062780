import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useOCR } from '../../components/identification-photo-inputs';
import { useLeads, useReverse } from '../../contexts';
import { ReverseCustomerFormData } from '../../services';
import { SubtractSVG } from '../../icons';

type Option = {
  bgColor: string;
  key: string;
  destination: string;
  icon: React.ReactNode;
  text: string;
  showIcon: boolean;
};
type Props = {
  options: Option[];
};

export const HomeLinks = memo(({ options }: Props) => {
  const navigate = useNavigate();
  const { onUpdateReverseCustomerForm, onUpdateSelectedCombo } = useReverse();
  const { onClearCapturedOCRs } = useOCR();
  const { onResetLeads } = useLeads();

  const handleHomeLinkClick = useCallback(
    (key: string, url: string) => {
      onUpdateReverseCustomerForm({} as ReverseCustomerFormData);
      onClearCapturedOCRs();
      onUpdateSelectedCombo(undefined as any);
      onResetLeads();
      onClearCapturedOCRs();
      if (key === 'googleForms') {
        const data = JSON.stringify({
          type: 'OPEN_BROWSER',
          url,
          data: { url },
        });
        window.parent.postMessage(data);
        (window as any)?.ReactNativeWebView?.postMessage(data);
      } else {
        navigate(url);
      }
    },
    [navigate, onClearCapturedOCRs, onResetLeads, onUpdateReverseCustomerForm, onUpdateSelectedCombo],
  );
  return (
    <>
      {options.map((it) => (
        <div key={it.key} onClick={() => handleHomeLinkClick(it.key, it.destination)} className="block mb-12px">
          <div className="py-12px px-16px flex flex-row items-center bg-white rounded-6px relative">
            {it.showIcon && <SubtractSVG className="absolute top-0 right-0" title="mới" />}
            <div className={`w-44px h-44px rounded-8px ${it.bgColor} flex-shrink-0 flex-center mr-16px`}>{it.icon}</div>
            <span className="text-16px font-semibold">{it.text}</span>
          </div>
        </div>
      ))}
    </>
  );
});
