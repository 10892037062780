const BASE_URL = process.env.REACT_APP_BASE_NAME;

export const AppRoutes = {
  old_home: BASE_URL + '/old',
  home: BASE_URL + '/home',
  illustrations: BASE_URL + '/quotations',
  illustration_new: BASE_URL + '/quotations/new',
  illustration: (id = ':id') => BASE_URL + '/quotations/' + id,
  illustrationEdit: (id = ':id') => BASE_URL + '/quotations/' + id + '/edit',
  qrCode: BASE_URL + '/qr-code',
  reverse: BASE_URL + '/reverse',
  reverse_products: BASE_URL + '/reverse/products',
  reverse_result: (id = ':id') => BASE_URL + '/reverse/result/' + id,
  leads: BASE_URL + '/leads',
  leads_insurance_contract: BASE_URL + '/insurance-contract',
  leads_new: BASE_URL + '/leads/new',
  leads_edit: (id = ':id') => BASE_URL + '/leads/' + id + '/edit',
  leads_detail: (id = ':id') => BASE_URL + '/leads/' + id + '/detail',
  leads_refer_detail: (id = ':id') => BASE_URL + '/leads/' + id + '/refer/detail',
  leads_direct_detail: (id = ':id') => BASE_URL + '/leads/' + id + '/direct/detail',
  mbal: BASE_URL + '/mbal',
  googleForms: BASE_URL + '/google-forms',
};

export const AppTitles = {
  old_home: 'Thông tin khách hàng',
  home: 'Thông tin khách hàng',
};
