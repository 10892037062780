import { LoadingOverlay } from '@mantine/core';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Layout } from '../../../components';
import { DATE_FORMAT, PRODUCT_NAME } from '../../../constants';
import { useDialog, useLeads, useReverse } from '../../../contexts';
import { AppRoutes, getMessage } from '../../../helpers';
import { vi } from '../../../i18n/vi';
import {
  ReverseProductBalanceSVG,
  ReverseProductFlexibleSVG,
  ReverseProductSignatureSVG,
  ReverseProductSpecialSVG,
} from '../../../icons';
import {
  ComboEnum,
  GenderEnum,
  useComboSuggestionMutation,
  useDirectCreateReserve,
  useGetTotalBenefit,
} from '../../../services';

import { ReverseProductEntity, ReverseProductsList } from './reverse-product-list';

export const reverseComboOptions: ReverseProductEntity[] = [
  {
    value: ComboEnum.SIGNATURE,
    logo: <ReverseProductSignatureSVG />,
    label: 'Sống vui khỏe',
    content: 'Ưu tiên bảo vệ sức khỏe gia đình',
    className: 'bg-c_E7F7FD border-c_E7F7FD',
    total_benefit: '920 Triệu',
  },
  {
    value: ComboEnum.SPECIAL_OFFER,
    logo: <ReverseProductSpecialSVG />,
    label: 'Sống cân bằng',
    content: 'Cân bằng bảo vệ sức khỏe và đầu tư',
    className: 'bg-c_FFEEF9 border-c_FFEEF9',
    total_benefit: '920 Triệu',
  },
  {
    value: ComboEnum.MUST_TRY,
    logo: <ReverseProductBalanceSVG />,
    label: 'Sống thảnh thơi',
    content: 'Sản phẩm ưu tiên đầu tư sinh lợi nhuận',
    className: 'bg-c_FFF8E3 border-c_FFF8E3',
    total_benefit: '920 Triệu',
  },
  {
    value: ComboEnum.FLEXIBLE,
    logo: <ReverseProductFlexibleSVG />,
    label: PRODUCT_NAME.ULRP_3_0,
    content: 'Tùy chọn hình thức bảo hiểm theo mong muốn',
    className: 'bg-c_F3F0FF border-c_F3F0FF',
  },
];

export const ReverseProductsScreen = () => {
  const { reverseCustomer, onUpdateSelectedCombo, selectedCombo } = useReverse();
  const { leadsCustomer } = useLeads();
  const { data } = useGetTotalBenefit(leadsCustomer?.id, { enabled: !!leadsCustomer?.id });
  const navigate = useNavigate();

  const newReverseComboOptions = useMemo(
    () =>
      reverseComboOptions?.map((it) => {
        return {
          ...it,
          total_benefit: data?.find((e) => e?.code === it?.value)?.attributes?.total_benefit,
        };
      }),
    [data],
  );

  const { showDialog } = useDialog();
  const { mutateAsync: reverseComboSuggestionAsync, isLoading: reverseComboSuggestionLoading } =
    useComboSuggestionMutation({
      onError: (error) => {
        showDialog({
          type: 'ALERT',
          title: vi.notification,
          message: error.response?.data?.message ?? error.message,
        });
      },
      onSuccess: (id) => {
        navigate(AppRoutes.reverse_result(id));
      },
    });

  const { mutateAsync: leadsDirectComboSuggestionAsync, isLoading: leadsDirectComboSuggestionLoading } =
    useDirectCreateReserve({
      onError: (error) => {
        showDialog({
          type: 'ALERT',
          title: vi.notification,
          message: getMessage(error),
        });
      },
      onSuccess: (result) => {
        navigate(AppRoutes.reverse_result(result.id.toString()));
      },
    });

  const handleOptionChange = useCallback(
    (value: ComboEnum) => {
      // Navigate to bmh if selected Flexible
      if (value === ComboEnum.FLEXIBLE) {
        onUpdateSelectedCombo(value);
        navigate(AppRoutes.illustration_new);
      }
      // Navigate to payment method if choose other options
      else {
        onUpdateSelectedCombo(value);
        if (leadsCustomer?.direct_id) {
          leadsDirectComboSuggestionAsync({ combo_code: value, direct_id: leadsCustomer.direct_id });
        } else {
          reverseComboSuggestionAsync({
            combo_code: value ?? ComboEnum.SIGNATURE,
            dob: dayjs(reverseCustomer?.dob ? reverseCustomer?.dob : leadsCustomer?.dob ?? '').format(DATE_FORMAT.dob),
            gender: reverseCustomer?.gender ? reverseCustomer?.gender : leadsCustomer?.gender ?? GenderEnum.MALE,
            input_amount: reverseCustomer?.input_amount
              ? reverseCustomer?.input_amount
              : leadsCustomer?.input_amount ?? 0,
          });
        }
      }
    },
    [
      onUpdateSelectedCombo,
      navigate,
      leadsCustomer?.direct_id,
      leadsCustomer?.dob,
      leadsCustomer?.gender,
      leadsCustomer?.input_amount,
      leadsDirectComboSuggestionAsync,
      reverseComboSuggestionAsync,
      reverseCustomer?.dob,
      reverseCustomer?.gender,
      reverseCustomer?.input_amount,
    ],
  );

  return (
    <Layout title="Phương án bảo hiểm tối ưu">
      <div className="pb-120px">
        <LoadingOverlay visible={reverseComboSuggestionLoading || leadsDirectComboSuggestionLoading} opacity={70} />
        <ReverseProductsList options={newReverseComboOptions} onChange={handleOptionChange} value={selectedCombo} />
      </div>
    </Layout>
  );
};
