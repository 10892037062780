import classNames from 'classnames';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { CheckBox } from '../checkbox';

import './index.less';

export const dataDefaultselections = [
  { value: 'C', label: 'Có' },
  { value: 'K', label: 'Không' },
];
type Props = PropsWithChildren<{
  desc: string;
  descStyle?: string;
  value: string;
  onChange: (val: string) => void;
  style?: string;
  isShow?: boolean;
  data?: { value: string; label: string }[];
  errorText?: string;
  isBox?: boolean;
}>;

export const Question = React.memo(
  ({
    isShow = true,
    desc,
    descStyle,
    value,
    onChange,
    children,
    data = dataDefaultselections,
    errorText,
    isBox,
    ...props
  }: Props) => {
    const [checked, setChecked] = useState<string>('');

    useEffect(() => {
      setChecked(value || '');
    }, [value]);

    const onTouch = useCallback(
      (val: string) => {
        setChecked(val);
        onChange(val);
      },
      [onChange],
    );

    return (
      <div className={classNames(['py-15px wrapQuestionElm', props.style])}>
        {!!desc && <p className={classNames(['desc', descStyle])}>{desc}</p>}
        {isShow && (
          <div className="group">
            {data.map((el) => (
              <CheckBox
                primary
                key={'CheckBox' + el.value}
                type={isBox ? 'box' : 'radio'}
                isReverse={true}
                value={el.value === checked}
                onPress={() => onTouch(el.value)}
                style={classNames([
                  'select',
                  el.value === checked && isBox && 'activeBoxSelect',
                  !!errorText && 'errorStyle',
                ])}>
                <p className="selectText">{el.label}</p>
              </CheckBox>
            ))}
          </div>
        )}

        {children}

        {!!errorText && <p className="errorTextStyle">{errorText}</p>}
      </div>
    );
  },
);
