import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { request } from '../helpers';
import { ApiUrl } from '../helpers/api-url';

import { APIError } from './types';

export const VERIFY_QUERY_KEY = 'CRM';
export interface ReferrerEntity {
  active?: true;
  branch_code?: string;
  branch_name?: string;
  code?: string;
  email?: string;
  full_name?: string;
  name?: string;
  phone_number?: string;
}
export type VerifyCRMQueryResponse = {
  access_token: string;
  accessToken: string;
  refresh_token: string;
  refreshToken: string;
  expired_time: number;
  expiredTime: number;
  code?: string;
  username?: string;
  phone_number?: string;
  email?: string;
  branch_name?: string;
  ic_info: ReferrerEntity;
  rm_info: ReferrerEntity;
};
export const useVerifyCRMQuery = (token: string, options?: UseQueryOptions<VerifyCRMQueryResponse, APIError>) => {
  return useQuery(
    VERIFY_QUERY_KEY as QueryKey,
    () =>
      request
        .post(ApiUrl.verify, {
          token,
        })
        .then((data) => data.data),
    options,
  );
};
