import { SelectItemProps } from '@mantine/core';
import classNames from 'classnames';
import { memo, useCallback, useEffect, useState } from 'react';

import { Colors } from '../../constants';
export type SelectItemType = SelectItemProps & {
  note?: string;
  extra?: string;
  url?: string;
};

type Props = {
  options: SelectItemType[];
  value: string;
  onChange(value: string): void;
  error?: string;
  colClass?: string;
  disabled?: boolean;
};

export const RadioCircleGroup = memo(({ options, value = '', onChange, error, colClass, disabled }: Props) => {
  const [selectedValue, setSelectedValue] = useState<string>(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const onTouch = useCallback(
    (value: string) => {
      if (disabled) return;
      setSelectedValue(value);
      onChange(value);
    },
    [disabled, onChange],
  );

  return (
    <div className="mb-16px">
      <div className="-mx-4px flex flex-row flex-wrap">
        {options.map((it) => (
          <div key={it.value} className={classNames(['w-1/2 px-4px', colClass])}>
            <div
              className={classNames(
                'h-48px rounded-6px border-solid border-0.5px px-16px flex justify-between items-center',
                'border-c_DDDDDD bg-white',
                !!error && 'border-red',
              )}
              onClick={() => onTouch(it?.value || '')}>
              <span className={classNames('text-16px font-semibold text-c_161616')}>{it.label}</span>
              <div
                className={classNames([
                  'btn-radio',
                  { borderColor: Colors.primary },
                  selectedValue === it.value && 'activeRadio',
                ])}>
                <div className={classNames(['dot', selectedValue === it.value && 'activeDot'])} />
              </div>
            </div>
          </div>
        ))}
      </div>
      {!!error && <div className="text-red text-14px mt-8px">{error}</div>}
    </div>
  );
});
