import { memo } from 'react';

import { Layout } from '../../components';
import { AppRoutes } from '../../helpers';
import { ArchiveSVG, BillSVG, ElementSVG, ElemntPlusSVG, Profile2UserSVG, UserTagSVG } from '../../icons';
import { LeadsFlowEnum } from '../../services';

import { HomeLinks } from './home-links';

const links = [
  {
    destination: AppRoutes.illustration_new,
    key: 'illustration_new',
    icon: <ElementSVG />,
    text: 'Tạo bảng minh họa',
    bgColor: 'bg-c_ffedf6',
    isShow: true,
    showIcon: false,
  },
  {
    destination: `${AppRoutes.leads}?type=${LeadsFlowEnum.NEW}`,
    key: 'illustration_new',
    icon: <UserTagSVG />,
    text: 'Tư vấn/Bán bảo hiểm',
    bgColor: 'bg-c_e6f6fd',
    isShow: true,
    showIcon: true,
  },
  {
    destination: `${AppRoutes.leads_insurance_contract}?type=${LeadsFlowEnum.REFER}`,
    key: 'insurance_contract',
    icon: <ArchiveSVG />,
    text: 'Theo dõi HĐBH phát hành',
    bgColor: 'bg-c_dcf6f8',
    isShow: true,
    showIcon: true,
  },
  {
    destination: AppRoutes.reverse,
    key: 'reverse',
    icon: <ElemntPlusSVG />,
    text: 'Tạo phương án minh họa nhanh',
    bgColor: 'bg-c_ffedf6',
    isShow: true,
    showIcon: false,
  },

  {
    destination: AppRoutes.illustrations,
    key: 'illustrations',
    icon: <BillSVG />,
    text: 'Danh sách PA bảo hiểm đã tạo',
    bgColor: 'bg-c_f3edff',
    isShow: true,
    showIcon: false,
  },
  {
    destination: AppRoutes.leads,
    key: 'leads',
    icon: <Profile2UserSVG />,
    text: 'Danh sách khách hàng tiềm năng',
    bgColor: 'bg-c_fff7d9',
    isShow: false,
    showIcon: false,
  },
  {
    destination: '',
    key: 'insurance',
    icon: <ArchiveSVG />,
    text: 'Danh sách HĐBH phát hành',
    bgColor: 'bg-c_dcf6f8',
    isShow: false,
    showIcon: false,
  },
  {
    destination: process.env.REACT_APP_GOOGLE_FORMS_URL || '',
    key: 'googleForms',
    icon: <Profile2UserSVG />,
    text: process.env.REACT_APP_GOOGLE_FORMS_TITLE || 'Report lỗi cổng BH MB',
    bgColor: 'bg-c_dcf6e7',
    isShow: true,
    showIcon: false,
  },
];
export const HomeScreen = memo(() => {
  const onBackHome = () => {
    const data = {
      type: 'BACK_HOME',
      data: null,
    };
    window.parent.postMessage(JSON.stringify(data));
    (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify(data));
  };
  return (
    <Layout title="Bảng minh hoạ" onLeft={onBackHome} onRight={onBackHome}>
      <div className="px-16px py-20px ">
        <HomeLinks options={links.filter((it) => it.isShow)} />
      </div>
    </Layout>
  );
});
