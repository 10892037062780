import React, { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';

type CONTEXT = {
  stepLabel: number;
  handleChangeStepLabel: (e: number) => void;
  dataDefine: any;
  handleDataDefine: (values: any) => void;
  handleSubmitRoot: (assuredId: string, values: any) => void;
  isRead: number;
  handleRead: (e: number) => void;
  onScrollToTop: () => void;
  onResetMixQuestionsContext: () => void;
};
export const MixQuestionsContext = createContext({} as CONTEXT);

export const MixQuestionsProvider = React.memo<PropsWithChildren>(({ children }) => {
  const [stepLabel, setStepLabel] = useState<number>(0);
  const [dataDefine, setDataDefine] = React.useState<any>();
  const [isRead, setIsRead] = useState<number>(2);

  const handleDataDefine = useCallback(
    () => (values: any) => {
      setDataDefine(values);
    },
    [],
  );

  const onResetMixQuestionsContext = useCallback(() => {
    setStepLabel(0);
    setDataDefine(undefined);
    setIsRead(0);
  }, [setStepLabel, setDataDefine, setIsRead]);

  const handleChangeStepLabel = useCallback(
    (stepGo: number) => {
      setStepLabel(stepGo);
    },
    [setStepLabel],
  );
  const handleRead = useCallback(
    (i: number) => {
      setIsRead(i);
    },
    [setIsRead],
  );
  const onScrollToTop = useCallback(() => {
    const elmnt = document.getElementById('page-content');
    if (elmnt) {
      elmnt.scrollTop = 0;
    }
  }, []);

  const handleSubmitRoot = useCallback(
    (assuredId: string, values: any) => {
      const newDataDefine = dataDefine?.[assuredId];
      const newData = {
        ...dataDefine,
        [assuredId]: {
          ...newDataDefine,
          ...values,
          body: {
            ...newDataDefine?.body,
            ...values?.body,
          },
        },
      };
      setDataDefine(newData);
      onScrollToTop();
    },
    [dataDefine, onScrollToTop],
  );
  const contextValue = useMemo(
    () => ({
      stepLabel,
      handleChangeStepLabel,
      dataDefine,
      handleDataDefine,
      handleSubmitRoot,
      isRead,
      handleRead,
      onScrollToTop,
      onResetMixQuestionsContext,
    }),
    [
      stepLabel,
      handleChangeStepLabel,
      dataDefine,
      handleDataDefine,
      handleSubmitRoot,
      isRead,
      handleRead,
      onScrollToTop,
      onResetMixQuestionsContext,
    ],
  );

  return <MixQuestionsContext.Provider value={contextValue}>{children}</MixQuestionsContext.Provider>;
});
