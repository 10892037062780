import { Button } from '@mantine/core';
import classNames from 'classnames';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { BigQuestionComponent } from '..';
import { Question } from '../../../../../components';
import { useDialog } from '../../../../../contexts';
import { vi } from '../../../../../i18n/vi';
import { PlusRoundWhiteSVG, RejectedSVG } from '../../../../../icons';
import { useIllustration } from '../../../form';
import { combo_small_questions, MixQuestionsContext } from '../../../form/insurance-request';
import { dataSmallQuizzes } from '../../../form/insurance-request/mock';
import { CollapseHeader } from '../../collapse-header';
import { UserComponent } from '../user';
import { SwitchFlowToReferButton } from '../../../../leads/components';

interface FormType {
  questions: string[];
  otherQuestions: string[];
  checked: boolean;
}

export const SmallQuestionComponent = React.memo(() => {
  const { healths, onUpdateHealthPersons, currentAssured, onUpdateCurrentAssured } = useIllustration();
  const { handleChangeStepLabel, handleSubmitRoot } = useContext(MixQuestionsContext);

  const [isBigQuizzes, setIsBigQuizzes] = useState<boolean>(false);

  const { handleSubmit, control } = useForm<FormType>({
    defaultValues: useMemo(() => {
      return {
        questions: ['K', 'K', 'K'],
      };
    }, []),
  });

  const handleNextPerson = useCallback(
    (stepGo: number) => {
      if (healths) {
        const lastIndex = healths?.length - 1;
        const findIndex = healths?.findIndex((it) => it.id === currentAssured?.id);
        if (findIndex === lastIndex) {
          handleChangeStepLabel(-1);
        } else if (findIndex < lastIndex) {
          handleChangeStepLabel(0);
          onUpdateCurrentAssured(healths[findIndex + 1]);
        } else {
          handleChangeStepLabel(stepGo);
        }
      }
    },
    [healths, currentAssured?.id, handleChangeStepLabel, onUpdateCurrentAssured],
  );

  const { showDialog } = useDialog();
  const onSubmit = async (data: FormType) => {
    const isYes = data?.questions?.findIndex((el: string) => el === 'C') > -1;
    if (isYes && currentAssured?.app_question_number == 3 && currentAssured?.is_mbal) {
      const alert = await showDialog({
        type: 'CONFIRM',
        icon: <RejectedSVG />,
        message:
          'Thông tin sức khỏe của Quý khách chưa đủ để đáp ứng yêu cầu bảo hiểm. Vui lòng ấn tiếp tục để trả lời câu hỏi sức khỏe chi tiết hơn',
        confirmText: 'Tiếp tục',
        cancelText: 'Từ chối',
      });
      if (alert) {
        const newHealths = healths?.map((it) => {
          const app_question_number = it.is_mic ? 15 : 12;
          if (it?.id === currentAssured?.id) {
            return {
              ...it,
              app_question_number,
              from3_to12: true,
            };
          }
          return it;
        });
        onUpdateHealthPersons(newHealths || []);
        onUpdateCurrentAssured({
          ...currentAssured,
          app_question_number: currentAssured?.is_mic ? 15 : 12,
          from3_to12: true,
        });
        setIsBigQuizzes(true);
      }
      return;
    }
    if (isYes) {
      showDialog({
        type: 'ALERT',
        message: vi.MSG05,
      });
      return;
    }
    handleSubmitRoot(currentAssured?.id ?? '', {
      combo_small_question: {
        c1: data?.questions?.[0],
        c2: data?.questions?.[1],
        c3: data?.questions?.[2],
      },
      assured: currentAssured,
      body: { quizzes: combo_small_questions(data)?.quizzes },
      isSmall: true,
    });
    handleNextPerson(0);
  };

  return (
    <>
      {isBigQuizzes ? (
        <BigQuestionComponent />
      ) : (
        <>
          <div className="flex-1">
            <CollapseHeader
              icon={<PlusRoundWhiteSVG />}
              title="Câu hỏi sức khoẻ"
              defaultVisible={true}
              className="custom-collapse-header">
              <div className="">
                <UserComponent />
                {dataSmallQuizzes?.map((el, index) => (
                  <Controller
                    key={'questions' + el?.id || index.toString()}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <div className={classNames(['questions', index === 0 && 'borderTopNone'])}>
                          <div className="questionViewTitle">
                            <div className="questionIndex">
                              <p className="questionIndexText">{index + 1}</p>
                            </div>
                            <p className="questionTxtTitle">{'Câu hỏi ' + (index + 1)}</p>
                          </div>
                          <Question
                            onChange={(newVal) => {
                              if (value !== undefined) {
                                onChange(newVal);
                              }
                            }}
                            value={value}
                            desc={el.name || ''}
                          />
                        </div>
                      </>
                    )}
                    name={`questions.${index}`}
                  />
                ))}
              </div>
            </CollapseHeader>
          </div>
          <div className="bg-white p-16px flex flex-col gap-y-16px">
            <SwitchFlowToReferButton />
            <Button fullWidth onClick={handleSubmit(onSubmit)}>
              Tiếp theo
            </Button>
          </div>
        </>
      )}
    </>
  );
});
