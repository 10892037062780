/* eslint-disable @typescript-eslint/no-explicit-any */
import { forEach } from 'lodash';

import { QuizzesType } from '../../../../services';

export const combo_small_questions = (data: { questions: string[]; otherQuestions: string[] }) => {
  const quizzes: QuizzesType[] = [];
  forEach(data.questions, function (el: string, index: number) {
    let value = el;
    switch (el) {
      case 'C':
        value = 'YES';
        break;
      case 'K':
        value = 'NO';
        break;
      default:
        break;
    }
    const item: QuizzesType = {
      label: (index + 1).toString(),
      answers: [
        {
          ordinal: 1,
          value,
        },
      ],
    };
    quizzes.push(item);
  });
  const customer = {
    usCitizen: data?.otherQuestions?.[0] === 'C' ? true : false,
  };
  return { quizzes, customer };
};
