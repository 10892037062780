import { memo, PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  required?: boolean;
  htmlFor?: string;
}>;

export const FormLabel = memo(({ children, required, htmlFor }: Props) => (
  <label className="text-14px font-semibold mb-8px" htmlFor={htmlFor}>
    {children}
    {required && <span className="text-c_FB4390 inline-block relative left-1">*</span>}
  </label>
));
