import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronLeftSVG, CloseSVG } from '../../icons';
import { AppRoutes } from '../../helpers';

export type NavbarProps = {
  title: string;
  onLeft?: () => void;
  onRight?: () => void;
};

export const Navbar = memo(({ title, onLeft, onRight }: NavbarProps) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white flex items-end justify-between px-16px py-12px fixed top-0 w-full z-10">
      <div
        className="w-24px h-24px"
        onClick={() => {
          if (onLeft) {
            onLeft();
          } else {
            navigate(-1);
          }
        }}>
        <ChevronLeftSVG />
      </div>
      <span className="font-bold text-18px mx-auto leading-18px">{title}</span>
      <div
        className="w-24px h-24px"
        onClick={() => {
          if (onRight) {
            onRight();
          } else {
            navigate(AppRoutes.home);
          }
        }}>
        <CloseSVG />
      </div>
    </div>
  );
});
