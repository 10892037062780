import { Button } from '@mantine/core';
import { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDialog, useLeads } from '../../../contexts';
import { AppRoutes, getMessage } from '../../../helpers';
import { useQueryString } from '../../../hooks';
import { vi } from '../../../i18n/vi';
import { InsuranceMethodEnum, useInitDirect } from '../../../services';
import { MethodCardsSelectable } from '../components';

import { LeadsScreenParams } from './leads-form-screen';
import { fakeMethodOptions } from './leads-method-form';

export const LeadsDirectForm = memo(() => {
  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const { leadsId = 0 } = useQueryString<LeadsScreenParams>();
  const { leadsCustomer, onUpdateLeadsCustomer } = useLeads();
  const id = useMemo(() => Number(leadsId || leadsCustomer?.id || 0), [leadsId, leadsCustomer]);

  const { mutateAsync, isLoading } = useInitDirect({
    onError: (error) => {
      showDialog({
        type: 'ALERT',
        title: vi.notification,
        message: getMessage(error),
      });
    },
    onSuccess: (result) => {
      if (leadsCustomer) {
        onUpdateLeadsCustomer({ ...leadsCustomer, direct_id: result.direct_id, id: id });
        navigate(AppRoutes.reverse_products);
      }
    },
  });

  const onSubmit = useCallback(() => {
    mutateAsync({ potential_customer_id: id });
  }, [id, mutateAsync]);

  return (
    <>
      <div className="pb-120px p-16px -mt-6 -mx-4">
        <MethodCardsSelectable options={fakeMethodOptions} value={InsuranceMethodEnum.DIRECT} disabled />
      </div>
      <div className="bg-white pt-12px pb-34px px-16px fixed bottom-0 left-0 w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
        <Button type="submit" onClick={onSubmit} fullWidth loading={isLoading} disabled={isLoading}>
          Tiếp tục
        </Button>
      </div>
    </>
  );
});
