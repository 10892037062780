import React, { ReactNode } from 'react';
import { Button } from '@mantine/core';

import { CloseSVG } from '../../../../icons';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
  addRadioGroup: () => void;
  isLoading?: boolean;
};

export const ModalButton = ({ isOpen, onClose, children, title, addRadioGroup, isLoading }: Props) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-end justify-center z-50 overflow-auto">
      <div className="fixed inset-0 transition-opacity" onClick={onClose}>
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div className="z-50 relative transform transition-transform ease-in-out duration-500">
        <div className="bg-white w-screen max-w-screen-lg mx-auto rounded-t-30px shadow-lg p-4 translate-y-0 opacity-100 scale-100 transform transition-transform ease-in-out duration-300">
          <div className="flex justify-between items-center mb-4">
            <div></div>
            <h2 className="ml-30px text-xl font-semibold">{title}</h2>
            <Button className="border-none bg-white" onClick={onClose}>
              <CloseSVG />
            </Button>
          </div>
          <div>{children}</div>

          <div className="bg-white pt-12px pb-34px  w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
            <Button fullWidth onClick={addRadioGroup} loading={isLoading}>
              Chọn
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
