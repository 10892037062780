import dayjs, { Dayjs } from 'dayjs';

import { DATE_FORMAT } from '../constants';

export const dateFormat = (date: string | Dayjs | Date, format?: string) => {
  return dayjs(date || dayjs()).format(format || DATE_FORMAT.dob);
};

export const getAge = (date: string | Dayjs | Date) => {
  const year = dayjs(date).get('year');
  const month = dayjs(date).get('months');
  const day = dayjs(date).get('date');

  if (dayjs().get('months') < month) {
    return dayjs().get('year') - year - 1;
  }

  if (dayjs().get('month') > month) {
    return dayjs().get('year') - year;
  }

  return dayjs().get('date') < day ? dayjs().get('year') - year - 1 : dayjs().get('year') - year;
};

export const getIndexSumAssured = (date: string | Dayjs | Date | undefined) => {
  if (!date) return 0;
  const age = getAge(date);
  if (age > 60) {
    return 10;
  }
  if (age >= 51 && age <= 60) {
    return 15;
  }
  return 25;
};

export const MIN_BASE_PREMIUM_RATE = [
  7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.0,
  7.0, 7.0, 7.0, 7.0, 7.0, 7.5, 8.0, 8.0, 8.5, 9.0, 9.5, 10.0, 10.5, 11.0, 11.5, 12.0, 12.5, 13.0, 14.0, 14.5, 15.5,
  16.0, 17.0, 18.0, 19.0, 20.0, 21.0, 22.0, 23.5, 24.5, 26.0, 27.5, 29.0, 30.5, 32.5, 34.0, 36.0, 38.0, 40.0, 42.0,
  44.5, 47.5, 50.0,
];
export const MAX_BASE_PREMIUM_RATE = 200;

export const getValidateMinSA = (amount: number) => {
  return Number(((1000 * amount) / 200).toFixed(0));
};

export const getValidateMaxSA = (age: number, amount: number) => {
  return Number(((1000 * amount) / MIN_BASE_PREMIUM_RATE[age]).toFixed(0));
};

export const getValidateMinBP = (amount: number) => {
  return Number(((200 * amount) / 1000).toFixed(0));
};

export const getValidateMaxBP = (age: number, amount: number) => {
  return Number(((MIN_BASE_PREMIUM_RATE[age] * amount) / 1000).toFixed(0));
};
