import { PropsWithChildren, createContext, memo, useCallback, useContext, useMemo, useState } from 'react';

import { ComboEnum, ReverseCustomerFormData } from '../services';

type ContextProps = {
  reverseCustomer?: ReverseCustomerFormData;
  selectedCombo?: ComboEnum;
  onUpdateReverseCustomerForm(data: ReverseCustomerFormData): void;
  onUpdateSelectedCombo(selectedCombo?: ComboEnum): void;
};

const ReverseContext = createContext<ContextProps>({
  reverseCustomer: {} as ReverseCustomerFormData,
  selectedCombo: undefined,
  onUpdateReverseCustomerForm() {
    console.log('not ready');
  },
  onUpdateSelectedCombo() {
    console.log('not ready');
  },
});

type Props = PropsWithChildren;

export const ReverseContextProvider = memo(({ children }: Props) => {
  const [reverseCustomer, setReverseCustomer] = useState<ReverseCustomerFormData>({} as ReverseCustomerFormData);
  const [selectedCombo, setSelectedCombo] = useState<ComboEnum | undefined>(undefined);

  const onUpdateReverseCustomerForm = useCallback((data: ReverseCustomerFormData) => {
    setReverseCustomer(data);
  }, []);

  const onUpdateSelectedCombo = useCallback((selectedCombo: ComboEnum) => {
    setSelectedCombo(selectedCombo);
  }, []);

  const contextValue = useMemo(() => {
    return {
      onUpdateReverseCustomerForm,
      onUpdateSelectedCombo,
      reverseCustomer,
      selectedCombo,
    };
  }, [onUpdateReverseCustomerForm, onUpdateSelectedCombo, reverseCustomer, selectedCombo]);

  return <ReverseContext.Provider value={contextValue}>{children}</ReverseContext.Provider>;
});

export const useReverse = () => useContext(ReverseContext);
