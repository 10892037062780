import { PropsWithChildren, ReactNode, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import classNames from 'classnames';

import { ChevronDownSVG } from '../../icons';

type Props = PropsWithChildren<{
  title: ReactNode;
  icon: ReactNode;
  iconContainerClassName?: string;
  defaultVisible?: boolean;
  containerClassName?: string;
  headerClassName?: string;
  preventOpen?: boolean;
  shadowDisabled?: boolean;
  renderCollapseIcon?(val: boolean): ReactNode;
  onCollapseToggle?(val: boolean): void;
}>;

export type CollapseHeaderType = {
  toggle(value: boolean): void;
  visible: boolean;
};

export const CollapseHeaderCustomizable = forwardRef<CollapseHeaderType, Props>(
  (
    {
      children,
      title,
      icon: Icon,
      containerClassName,
      iconContainerClassName,
      headerClassName,
      defaultVisible = true,
      preventOpen,
      shadowDisabled = false,
      renderCollapseIcon,
      onCollapseToggle,
    },
    ref,
  ) => {
    const [visible, setVisible] = useState(defaultVisible);

    useEffect(() => {
      onCollapseToggle?.(visible);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useImperativeHandle(ref, () => ({
      toggle: (value) => {
        setVisible(value);
      },
      visible,
    }));

    return (
      <div className={classNames('bg-white', containerClassName)}>
        <div
          className={classNames(
            'flex flex-row items-center justify-between gap-x-8px',
            headerClassName,
            shadowDisabled && 'shadow-none',
          )}
          onClick={() => !preventOpen && setVisible(!visible)}>
          <div className={classNames('flex-center', iconContainerClassName)}>{Icon}</div>
          <div className="flex-1 text-18px font-semibold leading-24px">{title}</div>
          <div className="flex-shrink-0 flex-center">
            {renderCollapseIcon?.(visible) ?? (
              <ChevronDownSVG className={classNames('', visible && !preventOpen ? 'rotate-180' : 'rotate-0')} />
            )}
          </div>
        </div>
        {!preventOpen && visible && children}
      </div>
    );
  },
);
