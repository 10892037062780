import { stringify } from 'qs';
import { memo, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { LeadsLayout } from '../../../components';
import { useQueryString } from '../../../hooks';
import { useLeads } from '../../../contexts';
import { LeadsFlowEnum, ReferOrDirectTabEnum } from '../../../services';

import { LeadsCustomerForm } from './leads-customer-form';
import { LeadsMethodForm } from './leads-method-form';
import { LeadsReferForm } from './leads-refer-form';
import { LeadsDirectForm } from './leads-direct-form';
import { LeadsReferReasonForm } from './leads-refer-reason';
import { LoanInformaitonForm } from './loan-information-form';
import { SelectInsurancePackageForm } from './select-insurance-package-form';

export enum LeadsFormStepEnum {
  CUSTOMER = 'CUSTOMER',
  METHOD = 'METHOD',
  REFER = 'REFER',
  DIRECT = 'DIRECT',
  REASON_TO_REFER = 'REASON_TO_REFER',
  LOAN_INFO = 'LOAN_INFO',
  PACKAGES = 'PACKAGES',
}
export type LeadsScreenParams = {
  step: LeadsFormStepEnum;
  flow?: LeadsFormStepEnum;
  leadsId?: number;
  reason?: string;
  type?: LeadsFlowEnum | ReferOrDirectTabEnum;
  health_checkup?: string;
};

type Props = {
  edit?: boolean;
};

export const LeadsFormScreen = memo(({ edit = false }: Props) => {
  const { leadsCustomer } = useLeads();

  const { step = LeadsFormStepEnum.CUSTOMER } = useQueryString<LeadsScreenParams>();

  const activeStep = useMemo(() => LeadsFormStepEnum[step] ?? LeadsFormStepEnum.CUSTOMER, [step]);

  const navigate = useNavigate();

  useEffect(() => {
    if (leadsCustomer == null && activeStep !== LeadsFormStepEnum.CUSTOMER) {
      navigate(
        {
          search: stringify({
            step: LeadsFormStepEnum.CUSTOMER,
          }),
        },
        {
          replace: true,
        },
      );
    }
  }, [activeStep, leadsCustomer, navigate]);

  const title = useMemo(() => {
    switch (activeStep) {
      case LeadsFormStepEnum.CUSTOMER:
        return edit ? 'Chỉnh sửa thông tin' : 'Tạo khách hàng mới';
      case LeadsFormStepEnum.METHOD:
        return 'Phương thức mua bảo hiểm';
      case LeadsFormStepEnum.REFER:
        return 'Chuyển luồng bán Refer';
      case LeadsFormStepEnum.DIRECT:
        return 'Chuyển luồng bán Direct';
      case LeadsFormStepEnum.REASON_TO_REFER:
        return 'Chuyển luồng bán Refer';
      case LeadsFormStepEnum.LOAN_INFO:
        return 'Thông tin vay vốn';
      case LeadsFormStepEnum.PACKAGES:
        return 'Chọn gói bảo hiểm';
      default:
        return 'DEFAULT LEADS TITLE';
    }
  }, [activeStep, edit]);

  return (
    <LeadsLayout title={title}>
      <div className="px-16px py-24px">
        {activeStep === LeadsFormStepEnum.CUSTOMER && <LeadsCustomerForm edit={edit} />}
        {activeStep === LeadsFormStepEnum.METHOD && <LeadsMethodForm />}
        {activeStep === LeadsFormStepEnum.REFER && <LeadsReferForm />}
        {activeStep === LeadsFormStepEnum.DIRECT && <LeadsDirectForm />}
        {activeStep === LeadsFormStepEnum.REASON_TO_REFER && <LeadsReferReasonForm />}
        {activeStep === LeadsFormStepEnum.LOAN_INFO && <LoanInformaitonForm />}
        {activeStep === LeadsFormStepEnum.PACKAGES && <SelectInsurancePackageForm />}
      </div>
    </LeadsLayout>
  );
});
