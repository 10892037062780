import { Loader } from '@mantine/core';
import { memo, PropsWithChildren } from 'react';

export const SpinLoader = memo<PropsWithChildren & { spining?: boolean }>(({ children, spining }) => {
  return (
    <div className="relative">
      {spining && (
        <div className="w-full h-full flex items-center justify-center absolute z-100 top-0 left-0 py-40px">
          <Loader size="lg" variant="dots" />
        </div>
      )}

      {children}
    </div>
  );
});
