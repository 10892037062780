export const vi = {
  'welcome-to-react': 'Chào mừng đến với React và react-i18next',
  referrer_information: 'Thông tin người giới thiệu',
  rm_title: 'Người giới thiệu',
  enter_rm_code: 'Lực lượng bán hàng MBXXX',
  enter_rm_name: 'Tên lực lượng bán hàng',
  enter_rm_phone: 'Nhập số điện thoại người giới thiệu',
  enter_rm_email: 'Nhập email người giới thiệu',
  ic_title: 'Nhân viên tư vấn',
  ic_information: 'Tư vấn viên (IC)',
  enter_ic_code: 'Nhập mã IC',
  enter_ic_name: 'Tên tư vấn viên',
  enter_supporter_code: 'Nhập mã MBXXX hoặc IC',
  enter_supporter_name: 'Tên người hỗ trợ',
  enter_supporter_phone: 'Nhập số điện thoại người hỗ trợ',
  enter_supporter_email: 'Nhập email người hỗ trợ',
  supporter_title: 'Người hỗ trợ',
  error_rm: 'Thông tin người giới thiệu không hợp lệ. Bạn vui lòng nhập lại thông tin hợp lệ hoặc bỏ trống',
  error_ic: 'Thông tin nhân viên tư vấn không hợp lệ. Bạn vui lòng nhập lại thông tin hợp lệ hoặc bỏ trống',
  error_sp: 'Thông tin người hỗ trợ không hợp lệ. Bạn vui lòng nhập lại thông tin hợp lệ hoặc bỏ trống',
  MSG01:
    'Thông tin Tuổi bảo hiểm không hợp lệ. Tuổi tham gia bảo hiểm hợp lệ đối với {{gender} tham gia gói sản phẩm {{packageName}} từ {minAge} tuổi đến {maxAge} tuổi. Chuyên viên tư vấn sẽ liên hệ bạn trong 24h hoặc vui lòng liên hệ Hotline MB Ageas 024 7309 9866 để được tư vấn và hỗ trợ.',
  MSG02:
    'Thông tin nghề nghiệp không hợp lệ. Chuyên viên tư vấn sẽ liên hệ bạn trong 24h hoặc vui lòng liên hệ Hotline MB Ageas 024 7309 9866 để được tư vấn và hỗ trợ.',
  MSG03: 'Quý khách vui lòng đọc kỹ nội dung cam kết và lưu ý.',
  MSG04:
    'Quý khách vui lòng đọc, đồng ý với Quy tắc, điều khoản sản phẩm và cam kết đồng ý tham gia bảo hiểm để tiếp tục.',
  MSG05:
    'Rất tiếc!!! Chúng tôi chưa hỗ trợ được yêu cầu bảo hiểm của Qúy khách với tình trạng sức khỏe hiện tại. Chúng tôi sẽ chuyển thông tin cho Chuyên viên tư vấn để hỗ trợ Qúy khách trong thời gian sớm nhất. Vui lòng liên hệ Hotline 024 7309 9866 để được hỗ trợ.',
  MSG06: 'Thông tin email không hợp lệ. Vui lòng nhập lại thông tin.',
  MSG08:
    'Sản phẩm chưa hỗ trợ cho khách hàng nước ngoài. Vui lòng lên hệ hotline MB Ageas Life 024 7309 9866 để được tư vấn và hỗ trợ.',
  MSG10:
    'Khách hàng đã là khách hàng hiện hữu của MB Ageas Life, không thỏa mãn điều kiện mua sản phẩm. Vui lòng liên hệ hotline 024 7309 9866 để được tư vấn và hỗ trợ.',
  MSG11: 'Bạn có chắc muốn thoát khỏi tiến trình mua bảo hiểm trực tuyến không?',
  MSG12: 'Rất tiếc đã có lỗi xảy ra! Vui lòng thử lại sau',
  MSG15: 'Thông tin đang được xử lý, vui lòng đợi trong giây lát!',
  MSG16: 'Rất tiếc đã có lỗi xảy ra! Vui lòng thử lại sau',
  MSG18:
    'Khách hàng không đủ điều kiện tham gia chương trình giảm phí CBNV. Vui lòng liên hệ Hotline MB Ageas Life 024 7309 9866 để được tư vấn và hỗ trợ.',
  MSG19:
    'Mã khuyến mại không tồn tại hoặc đã hết hạn. Vui lòng liên hệ Hotline MB Ageas Life 024 7309 9866 để được tư vấn và hỗ trợ.',
  MSG20:
    'Đối với tình trạng của quý khách, chúng tôi chưa thể chấp nhận yêu cầu bảo hiểm. Để tiếp tục mua sản phẩm, quý khách vui lòng cung cấp thêm thông tin.',
  MSG21:
    'Đăng ký không thành công do vượt quá hạn mức số tiền bảo hiểm tối đa. Vui lòng liên hệ hotline MB Ageas Life 024 7309 9866 để được hỗ trợ. ',
  MSG22:
    'Thông tin của quý khách hiện đang không không khớp với thông tin có sẵn tại MB Ageas Life. Vui lòng liên hệ hotline MB Ageas Life 024 7309 9866 để được hỗ trợ.',
  filled: 'Vui lòng nhập đầy đủ thông tin',
  user: {
    name: 'Họ và tên',
    enter_name: 'Nhập họ và tên',
    dob: 'Ngày tháng năm sinh',
    enter_dob: 'Chọn ngày tháng năm sinh',
    gender: 'Giới tính',
    enter_gender: 'Chọn giới tính',
    job: 'Nghề nghiệp',
    select_job: 'Chọn nghề nghiệp',
    close: 'Đóng',
    notification: 'Thông báo',
    loading_message: 'Thông tin đang được xử lý, vui lòng đợi trong giây lát.',
    idcardType: 'Loại giấy tờ tùy thân',
    enter_idcardType: 'Chọn loại giấy tờ tùy thân',
    idcard: 'Số giấy tờ tùy thân',
    enter_idcard: 'Nhập Số giấy tờ tùy thân',
    country: 'Quốc tịch',
    enter_country: 'Chọn quốc tịch',
    email: 'Email',
    enter_email: 'Nhập Email',
    phone: 'Số điện thoại',
    enter_phone: 'Nhập số điện thoại',
  },
  notification: 'Thông báo từ hệ thống',
  referCustomerToSale_success:
    'Bạn đã chuyển thông tin Khách hàng thành công. Vui lòng kiểm tra thông tin Lead KH Refer tại Mục Danh sách KH Refer',
  directLeadsReverseComboProceed: 'Tạo bảng minh họa sẽ ảnh hưởng tới mức phí và quyền lợi.',
  confirmChanges: 'Bạn có đồng ý lưu thông tin được thay đổi',
  abortChanges: 'Bạn có đồng ý huỷ thông tin đã nhập',
  gender: {
    MALE: 'Nam',
    FEMALE: 'Nữ',
  },
  identification_type: {
    /**
     * Chứng minh thư nhân dân
     */
    NATIONAL_ID: 'Chứng minh thư nhân dân',
    /**
     * Căn cước công dân
     */
    CITIZEN_ID: 'Căn cước công dân',
    /**
     * Hộ chiếu
     */
    PASSPORT: 'Hộ chiếu',
    /**
     * Giấy khai sinh
     */
    BIRTH_CERTIFICATE: 'Giấy khai sinh',
    /**
     * CMT Quân đội
     */
    MILITARY_ID: 'CMT Quân đội',
  },
  mic_alert:
    'Thẻ bảo lãnh viện phí MIC Care cho con sẽ không được giảm giá do các sản phẩm và quyền lợi bổ sung của con khác bố/mẹ. Quý khách có muốn tiếp tục không?',
};
