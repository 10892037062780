import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { request } from '../helpers';
import { ApiUrl } from '../helpers/api-url';

import { APIError } from './types';

export type Province = {
  code: string;
  id: number;
  name: string;
};
export type District = {
  name: string;
  id: number;
  code: string;
  province_code: string;
};
export type Ward = {
  id: number;
  name: string;
  code: string;
  district_code: string;
};

export const queryListProvinceKey = 'ListProvinceQuery';
export const queryListDistrictKey = 'ListDistrictQuery';
export const queryListWardKey = 'ListWardQuery';

export type GetAllProvince = Province[];
export type GetAllDistrict = District[];
export type GetAllWard = Ward[];

export const useGetAllProvince = (options?: UseQueryOptions<GetAllProvince, APIError>) => {
  return useQuery(
    [queryListProvinceKey] as QueryKey,
    () => request.get(ApiUrl.province).then((res) => res.data),
    options,
  );
};

export const useGetAllDistricts = (provinceCode: string, options?: UseQueryOptions<GetAllDistrict, APIError>) => {
  return useQuery(
    [queryListDistrictKey, provinceCode] as QueryKey,
    () => request.get(ApiUrl.district(provinceCode)).then((res) => res.data),
    options,
  );
};

export const useGetAllWard = (disTrictCode: string, options?: UseQueryOptions<GetAllWard, APIError>) => {
  return useQuery(
    [queryListWardKey, disTrictCode] as QueryKey,
    () => request.get(ApiUrl.Ward(disTrictCode)).then((res) => res.data),
    options,
  );
};
