import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';

import { request } from '../helpers';
import { ApiUrl } from '../helpers/api-url';
import { MicInsuranceBenefitPackageType } from '../screens/illustrations/components/choose-package/utils';

import { APIError } from './types';

import { AddressEntity, MicRequestDTO, RefererType, RelationshipTypeEnum, UserEntity } from '.';

export const QUERY_KEY = 'QUOTATION';

export enum GenderEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export enum PackagePaymentPeriodEnum {
  ANNUAL = 'ANNUAL',
  SEMI_ANNUAL = 'SEMI_ANNUAL',
  QUARTERLY = 'QUARTERLY',
}

export enum IdentityDocumentTypeEnum {
  /**
   * Chứng minh thư nhân dân
   */
  NATIONAL_ID = 'NATIONAL_ID',
  /**
   * Căn cước công dân
   */
  CITIZEN_ID = 'CITIZEN_ID',
  /**
   * Hộ chiếu
   */
  PASSPORT = 'PASSPORT',
  /**
   * Giấy khai sinh
   */
  BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
  /**
   * CMT Quân đội
   */
  MILITARY_ID = 'MILITARY_ID',
}

export enum InsuranceBenefitEnum {
  BASIC = 'BASIC',
  ADVANCED = 'ADVANCED',
}

export enum SourceTypeEnum {
  'MBAL' = 'MBAL',
  'MIC' = 'MIC',
}

export enum InsuranceTypeEnum {
  /**
   * Vững bước tương lai - Sản phẩm bảo hiểm Liên kết chung Vững Tương Lai - ULRP
   */
  ULRP = 'ULRP',
  /**
   * Kiến Tạo Tương Lai - Sản phẩm bảo hiểm Liên kết chung Kiến Tạo Ước Mơ - ULK
   */
  ULK = 'ULK',
  /**
   * Sống Trọn Ước Mơ - Sản phẩm bảo hiểm Liên kết chung Sống Trọn Ước Mơ
   */
  ULRP_3_0 = 'ULRP_3_0',
  /**
   * Món Quà Phú Quý - Sản phẩm bảo hiểm Liên kết chung Món Quà Phú Quý - ULSP
   */
  ULSP = 'ULSP',
}

export enum AdditionalAssuredProductEnum {
  /**
   * Bảo hiểm tử vong & thương tật toàn bộ vĩnh viễn
   */
  TRMR = 'TRMR',
  /**
   * Bảo hiểm tai nạn
   */
  ADDR = 'ADDR',
  /**
   * Bảo hiểm hỗ trợ viện phí & chi phí phẫu thuật
   */
  HSCR = 'HSCR',
  /**
   * Bảo hiểm bệnh hiểm nghèo
   */
  CIR = 'CIR',
  /**
   * Bảo hiểm miễn thu phí bảo hiểm
   */
  PWR = 'PWR',
  /**
   * Bảo hiểm tử vong & thương tật toàn bộ vĩnh viễn do tai nạn
   */
  COI_RIDER = 'COI_RIDER',
}

export enum AdditionalMicProductEnum {
  /**
   * Gói Đồng
   */
  MIC01 = 'MIC01',
  /**
   * Gói Bạc
   */
  MIC02 = 'MIC02',
  /**
   * Gói Vàng
   */
  MIC03 = 'MIC03',
  /**
   * Gói Bạch Kim
   */
  MIC04 = 'MIC04',
  /**
   * Gói Kim Cương
   */
  MIC05 = 'MIC05',
}

export enum InsuranceDiscountCodeEnum {
  NOT_APPLY = 'NOT_APPLY',
  DISCOUNT_FOR_MBAL_STAFF = 'DISCOUNT_FOR_MBAL_STAFF',
}

export enum QuotationStateEnum {
  CREATED = 'CREATED',
  CONFIRMED = 'CONFIRMED',
  SUBMITTED = 'SUBMITTED',
  COMPLETED = 'COMPLETED',
  UN_MATCH = 'UN_MATCH',
  RE_CREATED = 'RE_CREATED',
  RE_CONFIRMED = 'RE_CONFIRMED',
  RE_SUBMITTED = 'RE_SUBMITTED',
  RE_COMPLETED = 'RE_COMPLETED',
}

export type CreateQuotationMutationVariables = {
  additional_assureds: {
    additional_products: {
      policy_term: number;
      premium_term: number;
      sum_assured: number;
      type: AdditionalAssuredProductEnum;
    }[];
    dob: string; //'2023-03-10'
    full_name: string;
    gender: GenderEnum;
    identification_id: string;
    identification_type: IdentityDocumentTypeEnum;
    married: boolean;
    occupation_id: number;
    phone_number: string;
    address?: AddressEntity;
    email?: string;
    nationality_code: string;
    relationship_with_policy_holder: string;
    annual_income?: number;
    mic_request?: MicRequestDTO;
  }[];
  amount?: {
    end_year?: number;
    start_year?: number;
    value?: number;
  };
  assured?: {
    additional_products: {
      policy_term: number;
      premium_term: number;
      sum_assured: number;
      type: AdditionalAssuredProductEnum;
    }[];
    dob: string; //'2023-03-10'
    full_name: string;
    gender: GenderEnum;
    identification_id: string;
    identification_type: IdentityDocumentTypeEnum;
    married: boolean;
    occupation_id: number;
    phone_number: string;
    address?: AddressEntity;
    email?: string;
    nationality_code: string;
    relationship_with_policy_holder: string;
    annual_income?: number;
    id_issued_place?: string;
    identification_date?: string;
    mic_request?: MicRequestDTO;
  };
  customer?: {
    additional_products: {
      policy_term: number;
      premium_term: number;
      sum_assured: number;
      type: AdditionalAssuredProductEnum;
    }[];
    dob: string;
    full_name: string;
    gender: GenderEnum;
    identification_id: string;
    identification_type: IdentityDocumentTypeEnum;
    married: boolean;
    occupation_id: number;
    phone_number: string;
    address?: AddressEntity;
    email?: string;
    nationality_code: string;
    annual_income?: number;
    id_issued_place?: string;
    identification_date?: string;
    mic_request?: MicRequestDTO;
  };
  customer_is_assured: boolean;
  id?: number;
  package_benefit_type: InsuranceBenefitEnum;
  package_payment_period: PackagePaymentPeriodEnum;
  package_periodic_premium: number;
  package_policy_term: number;
  package_premium_term: number;
  package_sum_assured: number;
  discount_code: InsuranceDiscountCodeEnum;
  raider_deduct_fund: boolean;
  type: InsuranceTypeEnum;
  beneficiary?: UserEntity;
  process_id?: number;
  referrer?: {
    code?: string;
    name?: string;
    phone_number?: string;
  };
  sale?: {
    code?: string;
    name?: string;
  };
  supporter?: {
    code?: string;
    name?: string;
  };
};
export interface AdditionalProductEntity {
  policy_term: number;
  premium_term: number;
  sum_assured: number;
  type: AdditionalAssuredProductEnum;
  base_premium?: number;
  product_type?: AdditionalAssuredProductEnum;
}

export type AdditionalAssuredsEntity = UserEntity & {
  id?: string;
  uuid?: string;
  mini_assured_id?: string;
  additional_products: AdditionalProductEntity[];
  dob: string; //'2023-03-10'
  full_name: string;
  gender: GenderEnum;
  identification_id: string;
  identification_type: IdentityDocumentTypeEnum;
  married: boolean;
  occupation_id: number;
  phone_number: string;
  insurance_age: number;
  app_question_number: number;
  mic_additional_product?: MicAdditionalProductDT0;
  nationality_code: string;
  annual_income: number;
  address?: {
    province_name?: string;
    district_name?: string;
    ward_name?: string;
    line1?: string;
  };
  relationship_with_policy_holder?: RelationshipTypeEnum;
};
export interface MicAdditionalProductDT0 {
  mic_benefit: MicInsuranceBenefitPackageType;
  mic_request: MicRequestDTO;
  mic_result: {
    code: string;
    message: string;
    mic_sum_benefit: number;
    mic_transaction_id: string;
    phi: number;
  };
}
export type QuotationEntity = {
  additional_assureds?: AdditionalAssuredsEntity[];
  amount?: {
    end_year: number;
    start_year: number;
    value: number;
  };
  assured: {
    additional_products: AdditionalProductEntity[];
    dob: string; //'2023-03-10'
    full_name: string;
    gender: GenderEnum;
    identification_id: string;
    identification_type: IdentityDocumentTypeEnum;
    married: boolean;
    occupation_id: number;
    phone_number: string;
    insurance_age: number;
    app_question_number: number;
    id: string;
    nationality_code: string;
    mic_additional_product?: MicAdditionalProductDT0;
    annual_income: number;
    address?: {
      province_name?: string;
      district_name?: string;
      ward_name?: string;
      line1?: string;
    };
    relationship_with_policy_holder?: RelationshipTypeEnum;
  };
  customer: {
    additional_products: AdditionalProductEntity[];
    dob: string;
    full_name: string;
    gender: GenderEnum;
    identification_id: string;
    identification_type: IdentityDocumentTypeEnum;
    married: boolean;
    occupation_id: number;
    phone_number: string;
    insurance_age: number;
    app_question_number: number;
    id: string;
    mic_request?: MicRequestDTO;
    mic_additional_product?: MicAdditionalProductDT0;
    nationality_code: string;
    annual_income: number;
    address?: {
      province_name?: string;
      district_name?: string;
      ward_name?: string;
      line1?: string;
    };
  };
  customer_is_assured: boolean;
  details: [
    {
      base_premium: number;
      committed_rate_account_value: number;
      committed_rate_base_value: number;
      committed_rate_surender_value: number;
      committed_rate_topup_value: number;
      high_rate_account_value: number;
      high_rate_death_benefit: number;
      high_rate_loyalty_bonus: number;
      high_rate_surender_value: number;
      insured_age: number;
      low_rate_account_value: number;
      low_rate_death_benefit: number;
      low_rate_loyalty_bonus: number;
      low_rate_surender_value: number;
      policy_year: number;
      selected_rate_account_value: number;
      selected_rate_base_value: number;
      selected_rate_surender_value: number;
      selected_rate_topup_value: number;
      topup_premium: number;
      withdrawal: number;
    },
  ];
  id: number;
  package_benefit_type: InsuranceBenefitEnum;
  package_payment_period: PackagePaymentPeriodEnum;
  package_periodic_premium: number;
  package_policy_term: number;
  package_premium_term: number;
  package_sum_assured: number;
  discount_code?: InsuranceDiscountCodeEnum;
  raider_deduct_fund: boolean;
  type: InsuranceTypeEnum;
  beneficiary: UserEntity;
  uuid?: string;
  process_id?: string;
  app_question_number: number;
  application_number: string;
  application_status: string;
  healths?: any[];
  state?: QuotationStateEnum;
  supporter?: RefererType;
  sale?: RefererType;
  referrer?: RefererType;
  topup_amount_str?: string;
  total_amount?: number;
  total_amount_str?: string;
  total_mbal_amount_str?: string;
  total_mic_amount_str?: string;
  mic_healths?: any[];
  quotation_code?: string;
  quotation_date?: string;
  quotation_status?: string;
};

export type QuotationSearchContentEntity = {
  created_date?: string;
  id: number;
  name: string;
  phone_number: string;
  email?: string;
  quotation_state?: QuotationStateEnum;
  combo_name?: string;
};

export type QuotationSearchEntity = {
  content: QuotationSearchContentEntity[];
  size: number;
  total_elements: number;
  total_pages: number;
  page: number;
};

export const useCreateQuotationMutation = (
  options?: UseMutationOptions<QuotationEntity, APIError, CreateQuotationMutationVariables>,
) => {
  return useMutation(
    (input) =>
      request.post(ApiUrl.quotation, input).then((res) => ({
        ...res.data,
        details: (res.data.details ?? []).map((it: any) =>
          Object.assign({}, ...Object.keys(it).map((k) => ({ [k]: it[k] < 0 ? 0 : it[k] }))),
        ),
      })),
    options,
  );
};

export const useGetQuotationByIdQuery = (id: number | string, options?: UseQueryOptions<QuotationEntity, APIError>) => {
  return useQuery(
    [QUERY_KEY, id] as QueryKey,
    () =>
      request.get(ApiUrl.quotation + '/' + id).then((res) => ({
        ...res.data,
        details: (res.data.details ?? []).map((it: any) =>
          Object.assign({}, ...Object.keys(it).map((k) => ({ [k]: it[k] < 0 ? 0 : it[k] }))),
        ),
      })),
    options,
  );
};

export type GetQuotationsQueryParams = {
  q?: string;
  date?: string;
  page?: number;
  size?: number;
  from?: string;
  to?: string;
  type?: string;
};

export const useGetQuotations = (
  input: GetQuotationsQueryParams,
  options?: UseInfiniteQueryOptions<QuotationSearchEntity, APIError>,
) => {
  return useInfiniteQuery(
    [QUERY_KEY, input] as QueryKey,
    ({ pageParam }) =>
      request
        .get(ApiUrl.quotation + '/search', {
          params: { ...input, page: pageParam || 0 },
        })
        .then((res) => res.data),
    options,
  );
};

export const useSaveQuotationMutation = (options?: UseMutationOptions<boolean, APIError, string | number>) => {
  return useMutation((id) => request.put(ApiUrl.quotation + '/' + id).then((res) => res.data), options);
};

export type DeleleteQuotationsInput = {
  ids: number[];
  type?: string;
};

export const useDeleteQuotationMutation = (
  options?: UseMutationOptions<number[], APIError, DeleleteQuotationsInput>,
) => {
  return useMutation((payload) => request.post(ApiUrl.quotation + '/delete', payload), options);
};

export const useDownloadQuotationPDFMutation = (options?: UseMutationOptions<Blob, APIError, string | number>) => {
  return useMutation(
    (id) =>
      request
        .get(ApiUrl.quotation + '/' + id + '/download', {
          responseType: 'blob',
        })
        .then((res) => res.data),
    options,
  );
};

export const useDownloadQuotationPDFQuery = (id: string | number, options?: UseQueryOptions<Blob, APIError>) => {
  return useQuery(
    [QUERY_KEY, id, 'pdf'] as QueryKey,
    () =>
      request
        .get(ApiUrl.quotation + '/' + id + '/download', {
          responseType: 'blob',
        })
        .then((res) => res.data),
    options,
  );
};
