import { memo, useMemo } from 'react';
import { Table } from '@mantine/core';

import { InsuranceTypeEnum, QuotationEntity } from '../../../../services';
import { numberWithCommas } from '../../../../helpers';

type Props = {
  quotation: QuotationEntity;
};

export const SummaryTable = memo(({ quotation }: Props) => {
  const data = useMemo(
    () =>
      quotation.type === InsuranceTypeEnum.ULRP
        ? quotation.details.filter((it) => it.insured_age <= 99)
        : quotation.details?.filter((it) =>
            [1, 5, 10, 30, quotation?.package_policy_term].includes(it.policy_year ?? 1),
          ),
    [quotation.details, quotation?.package_policy_term, quotation.type],
  );

  const rows = data.map((it, index) => (
    <tr key={index}>
      <td>{it.policy_year !== 0 ? it.policy_year : '-'}</td>
      <td>{it.insured_age !== 0 ? it.insured_age : '-'}</td>
      <td>{it.base_premium !== 0 ? numberWithCommas(it.base_premium) : '-'}</td>
      <td>{it.topup_premium !== 0 ? numberWithCommas(it.topup_premium) : '-'}</td>
      <td>{it.withdrawal !== 0 ? numberWithCommas(it.withdrawal) : '-'}</td>
      <td>{it.selected_rate_base_value !== 0 ? numberWithCommas(it.selected_rate_base_value) : '-'}</td>
      <td>{it.selected_rate_topup_value !== 0 ? numberWithCommas(it.selected_rate_topup_value) : '-'}</td>
      <td>{it.selected_rate_account_value !== 0 ? numberWithCommas(it.selected_rate_account_value) : '-'}</td>
      <td>{it.selected_rate_surender_value !== 0 ? numberWithCommas(it.selected_rate_surender_value) : '-'}</td>
      <td>{it.committed_rate_base_value !== 0 ? numberWithCommas(it.committed_rate_base_value) : '-'}</td>
      <td>{it.committed_rate_topup_value !== 0 ? numberWithCommas(it.committed_rate_topup_value) : '-'}</td>
      <td>{it.committed_rate_account_value !== 0 ? numberWithCommas(it.committed_rate_account_value) : '-'}</td>
      <td>{it.committed_rate_surender_value !== 0 ? numberWithCommas(it.committed_rate_surender_value) : '-'}</td>
    </tr>
  ));

  return (
    <div className="p-16px">
      <div className="flex justify-end mb-10px">
        <span className="italic">
          Đơn vị: <span className="font-medium">Nghìn đồng</span>
        </span>
      </div>
      <div className="overflow-x-auto pb-16px">
        <Table className="illustration">
          <thead>
            <tr>
              <th colSpan={5}>Thông tin</th>
              <th colSpan={4}>Lãi suất minh hoạ</th>
              <th colSpan={4}>Lãi suất cam kết</th>
            </tr>
            <tr>
              <th>
                <div className="w-60px px-12px py-8px">Năm HĐ</div>
              </th>
              <th>
                <div className="w-80px px-12px">Tuổi NĐBD</div>
              </th>
              <th>
                <div className="w-120px px-12px">Phí bảo hiểm cơ bản</div>
              </th>
              <th>
                <div className="w-120px px-12px">Phí bảo hiểm đóng thêm</div>
              </th>
              <th>
                <div className="w-80px px-12px">Rút tiền</div>
              </th>
              <th>
                <div className="w-130px px-12px">Giá trị tài khoản cơ bản</div>
              </th>
              <th>
                <div className="w-130px px-12px">Giá trị tài khoản đóng thêm</div>
              </th>
              <th>
                <div className="w-120px px-12px">Giá trị tài khoản</div>
              </th>
              <th>
                <div className="w-120px px-12px">Giá trị hoàn lại</div>
              </th>
              <th>
                <div className="w-130px px-12px">Giá trị tài khoản cơ bản</div>
              </th>
              <th>
                <div className="w-130px px-12px">Giá trị tài khoản đóng thêm</div>
              </th>
              <th>
                <div className="w-120px px-12px">Giá trị tài khoản</div>
              </th>
              <th>
                <div className="w-120px px-12px">Giá trị hoàn lại</div>
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </div>

      <div className="py-16px">
        <span className="text-primary text-16px font-semibold">Lưu ý</span>
        <div className="mt-12px">
          <ul className="p-start-20px text-14px text-c_767676">
            <li>
              Các số liệu được minh họa với giả định Hợp đồng đã được ưu đãi giảm phí (áp dụng Tỷ lệ Phí ban đầu sau khi
              giảm).
            </li>
            <li className="mt-16px">
              Giá trị tài khoản đã được khấu trừ Khoản khấu trừ hàng tháng của sản phẩm chính và Phí bảo hiểm rủi ro của
              Quyền lợi bảo hiểm bổ trợ Tử vong hoặc Thương tật toàn bộ vĩnh viễn do tai nạn, và đã bao gồm các khoản
              Thưởng duy trì hợp đồng (nếu có).
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});
