import { QueryClient } from 'react-query';
import dayjs from 'dayjs';

import { i18nInit } from '../i18n';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

export async function init() {
  localStorage.clear();

  const query = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });
  await i18nInit();

  return {
    query,
  };
}
