import {
  InsuranceClaimsStatusTypeEnum,
  InsuranceDelcinesStatusTypeEnum,
  RelationshipTypeEnum,
} from '../../../../services';

export const dataSmallQuizzes = [
  {
    id: '4',
    activeStatus: 'ACTIVE',
    name: 'Trong 6 tháng gần đây, Quý khách có được tư vấn hoặc có kế hoạch thực hiện bất kỳ xét nghiệm y tế, kiểm tra, chụp chiếu, sinh thiết, các xét nghiệm để chẩn đoán hoặc đang chờ kết quả hoặc cần theo dõi.',
  },
  {
    id: '5',
    activeStatus: 'ACTIVE',
    name: 'Trong 2 năm gần đây, Quý khách có phải nhập viện từ 3 ngày trở lên, hoặc đã trải qua phẫu thuật, hoặc cần liên tục dùng thuốc/ điều trị (trừ bệnh cúm hoặc ho) từ 2 tuần trở lên không?',
  },
  {
    id: '6',
    activeStatus: 'ACTIVE',
    name: 'Quý khách đã từng mắc hoặc đang nghi ngờ mắc ung thư, ung thư biểu mô tại chỗ, khối u hoặc khối phát triển, đau ngực, đột quỵ, cơn thiếu máu não cục bộ thoáng qua, tăng huyết áp, tiền đái tháo đường, đái tháo đường, bệnh tim, bệnh thận, bệnh gan, bệnh phổi, Viêm gan virus B, viêm gan virus C, bệnh lý hệ tiêu hóa, bệnh lý sinh dục, HIV hoặc AIDS, bệnh tự miễn dịch, rối loạn tâm/ thần kinh, thoát vị đĩa đệm, viêm đa rễ dây thần kinh, viêm tủy, bại não, bất kỳ dạng khiếm khuyết nào về mắt hoặc thính giác hoặc khuyết tật về thể chất?',
  },
];
export const DataCommitted = [
  {
    id: 'committed_1',
    title: 'Cam kết của bên mua bảo hiểm/người được bảo hiểm/người đại diện theo pháp luật của người được bảo hiểm',
    content: [
      'Tôi/Chúng tôi cam kết và đồng ý rằng:',
      '1.	Tại thời điểm yêu cầu tham gia bảo hiểm, Tôi hiện đang cư trú tại Việt Nam. ',
      '2.	Tôi/Chúng tôi đã được tư vấn, giải thích đầy đủ các thông tin, số liệu trong Tài liệu minh họa Hợp đồng bảo hiểm, Yêu cầu bảo hiểm, Quy tắc, Điều khoản sau sản phẩm bảo hiểm/ Quyền lợi bảo hiểm bổ trợ, Quyền và nghĩa vụ của Bên mua bảo hiểm/Người được bảo hiểm, hiểu rõ các trường hợp loại trừ bảo hiểm, các loại phí, chi phí và phương thức phân bổ phí bảo hiểm. ',
      '3.	Tôi đã đọc và hiểu đầy đủ các chính sách và mục tiêu đầu tư của Quỹ liên kết chung. ',
      '4.	Tôi/Chúng tôi cam kết những nội dung kê khai tại Yêu cầu bảo hiểm này và các thông tin khác mà Tôi/Chúng tôi cung cấp cho MBAL, bác sỹ hoặc Bên thứ ba do MBAL chỉ định là đầy đủ, chính xác và trung thực, là cơ sở để MBAL đánh giá khả năng chấp nhận bảo hiểm. Mọi thông tin trao đổi bằng lời nói trái với các nội dung của Yêu cầu bảo hiểm và Quy tắc, Điều khoản của Hợp đồng bảo hiểm sẽ không có hiệu lực. Tôi/Chúng tôi hiểu rõ và đồng ý rằng MBAL có quyền đề nghị Người được bảo hiểm đi kiểm tra sức khỏe và việc MBAL tiến hành kiểm tra, thẩm định sức khỏe của Người được bảo hiểm (nếu có) không thay thế cho nghĩa vụ kê khai trung thực của Tôi/Chúng tôi, Tôi/Chúng tôi đồng ý rắng nếu Bên mua bảo hiểm/ Người được bảo hiểm/ Người đại diện theo pháp luật của Người được bảo hiểm có ý vi phạm nghĩa vụ kê khai trung thực những thông tin mà nếu với thông tin chính xác MBAL sẽ không chấp nhận bảo hiểm, MBAL sẽ không có nghĩa vụ giải quyết bất cứ Quyền lợi bảo hiểm nào liên quan trực tiếp đến thông tin không trung thực đó. ',
      '5.	Tôi/Chúng tôi đồng ý cho các cơ quan y tế, bệnh viện, bác sĩ, (các) công ty bảo hiểm khác, tổ chức, cá nhân, khi nhận được bản sao Yêu cầu bảo hiểm này, được phép cung cấp cho MBAL tất cả các tài liệu hồ sơ bệnh án về Tôi/Chúng tôi, bao gồm cả những thông tin, tài liệu về sức khỏe, y tế có liên quan đến Bên mua bảo hiểm/Người được bảo hiểm nhằm mục đích giao kết và thực hiện Hợp đồng bảo hiểm. Tôi đồng ý sử dụng số điện thoại di động, địa chỉ thư email mà Tôi đăng ký với MB Bank để nhận các thông báo liên quan đến giao dịch Yêu cầu bảo hiểm/Hợp đồng bảo hiểm và các thông tin về các chương trình chăm sóc Khách hàng, giới thiệu sản phẩm, dịch vụ của MB Ageas. ',
      '6.	Tôi/Chúng tôi đồng ý cho MBAL được phép tiết lộ, chuyển giao hoặc sử dụng các thông tin cá nhân do Tôi/Chúng tôi cung cấp trong các trường hợp sau: thu thập, sử dụng, chuyển giao theo yêu cầu của cơ quan nhà nước có thẩm quyền hoặc cho mục đích thẩm định, tính toán phí bảo hiểm/ phí bảo hiểm rủi ro, phát hành hợp đồng bảo hiểm, thu phí bảo hiểm/ phí bảo hiểm rủi ro, tái bảo hiểm, trích lập dự phòng nghiệp vụ, dịch vụ khách hàng, giải quyết chi trả quyền lợi bảo hiểm, nghiên cứu thiết kế sản phẩm, phòng chống gian lận, trục lợi bảo hiểm, nghiên cứu, đánh giá tình hình tài chính, khả năng thanh toán, mức độ đầy đủ vốn, yêu cầu vốn, xử lý, lưu trữ và quản trị dữ liệu. Các trường hợp chuyển giao thông tin khác phải được Tôi/Chúng tôi đồng ý bằng văn bản. ',
      '7.	Tôi/Chúng tôi sẽ hoàn toàn chịu trách nhiệm về việc ủy quyền cho người khác thay Tôi/Chúng tôi nộp Phí bảo hiểm hay hoàn trả các khoản tạm ứng từ Giá trị hoàn lại cho MBAL. MBAL không có trách nhiệm hoặc nghĩa vụ phải xác minh việc Tôi/Chúng tôi ủy quyền cho bên thứ ba. Tôi/Chúng tôi sẽ cung cấp các chứng từ liên quan đến nguồn gốc các khoản thanh toán nếu cơ quan nhà nước có thẩm quyền yêu cầu. Tôi/Chúng tôi xác nhận rằng mọi khoản tiền dùng để đóng Phí bảo hiểm và/ hoặc hoàn trả các khoản tạm ứng từ Giá trị hoàn lại không có nguồn gốc từ hành vi, hoạt động vi phạm pháp luật phòng chống rửa tiền của Việt Nam. ',
      '8.	Tôi/Chúng tôi đồng ý sử dụng địa chỉ thư điện tử (email) mà Tôi/Chúng tôi đăng ký với MBAL để nhận bộ Hợp đông bảo hiểm và các thông báo/ tài liệu liên quan đến Hợp đồng bảo hiểm. Tôi/Chúng tôi có trách nhiệm bảo mật tài khoản email, mật khẩu sử dụng và các thông tin mà Công ty đã chuyển cho Tôi/Chúng tôi, tự chịu trách nhiệm với mọi thiệt hại, tổn thất liên quan đến Hợp đồng bảo hiểm do việc Tôi/Chúng tôi để lộ thông tin về Hợp đồng bảo hiểm và/hoặc thông tin trong thư điện tử, mật khẩu sử dụng dẫn đến người khác có thể tiếp cận các thông tin mà MBAL cung cấp và/ hoặc thông tin trong thư điện tử bị sử dụng bởi một bên không có thẩm quyền, trừ khi do lỗi của MBAL. Tôi/Chúng tôi cam kết sẽ cập nhật kịp thời cho MBAL bất kỳ thay đổi nào về các thông tin cá nhân đã cung cấp cho MBAL bao gồm cả thư điện tử, điện thoại di động và địa chỉ liên lạc.',
      '9.	Liên quan đến quy đinh về thuế tại Hoa Kỳ, khi cơ quan có thẩm quyền yêu cầu cung cấp thông tin, Tôi/Chúng tôi đồng ý và cho phép MBAL được tiết lộ thông tin mà Tôi/Chúng tôi đã cung cấp cho MBAL. TÔi/Chúng tôi đồng ý cung cấp thêm thông tin khi được yêu cầu.',
    ],
  },
  {
    id: 'committed_2',
    title: 'Lưu ý quan trọng',
    content: [
      '1.	Đề nghị Quý khách kiểm tra lại toàn bộ Yêu cầu bảo hiểm để đảm bảo rằng Quý khách hoàn toàn hài lòng và đồng ý với thông tin đã khai báo trước khi tự mình ký và ghi rõ họ tên ở phần dưới đây. Nếu Công ty phát hiện Bên mua bảo hiểm/Người được bảo hiểm/Người đại diện theo pháp luật của Người được bảo hiểm không tự mình ký vào Yêu cầu bảo hiểm, Công ty sẽ có quyền hủy bỏ Hợp đồng bảo hiểm đã ký và giải quyết theo quy định của pháp luật và Quy tắc, Điều khoản sản phẩm.',
      '2.	Yêu cầu bảo hiểm này được xem là một bộ phận của Hợp đồng bảo hiểm khi yêu cầu bảo hiểm được MBAL chấp nhận. ',
      '3.	Hợp đồng bảo hiếm nhân thọ là một cam kết dài hạn, việc hủy bỏ Hợp đồng trong những năm đầu của Hợp đồng sẽ dẫn tới những thiệt hại tài chính đáng kể. Quý khách vui lòng tham khảo Giá trị hoàn lại theo từng Năm hợp đồng tại Tài liệu minh họa hợp đồng bảo hiểm trước khi quyết định tham gia hủy bỏ Hợp đồng bảo hiểm (nếu có) và tự chịu trách nhiệm về quyết định của mình.',
      '4. Hợp đồng bảo hiểm sức khỏe MIC không được hủy trong 21 ngày.',
      '5. Phí bảo hiểm MIC Care có thể sẽ thay đổi theo độ tuổi và tỷ lệ bồi thường năm liền trước của Người được bảo hiểm',
    ],
  },
];

export const FlexibleDataCommitted = [
  {
    id: 'committed_1',
    title: 'Cam kết của bên mua bảo hiểm/người được bảo hiểm/người đại diện theo pháp luật của người được bảo hiểm',
    content: [
      'Tôi/Chúng tôi cam kết và đồng ý rằng:',
      '1.	Tại thời điểm yêu cầu tham gia bảo hiểm, Tôi hiện đang cư trú tại Việt Nam. ',
      '2.	Tôi/Chúng tôi đã được tư vấn, giải thích đầy đủ các thông tin, số liệu trong Tài liệu minh họa Hợp đồng bảo hiểm, Yêu cầu bảo hiểm, Quy tắc, Điều khoản sau sản phẩm bảo hiểm/ Quyền lợi bảo hiểm bổ trợ, Quyền và nghĩa vụ của Bên mua bảo hiểm/Người được bảo hiểm, hiểu rõ các trường hợp loại trừ bảo hiểm, các loại phí, chi phí và phương thức phân bổ phí bảo hiểm. ',
      '3.	Tôi đã đọc và hiểu đầy đủ các chính sách và mục tiêu đầu tư của Quỹ liên kết chung. ',
      '4.	Tôi/Chúng tôi cam kết những nội dung kê khai tại Yêu cầu bảo hiểm này và các thông tin khác mà Tôi/Chúng tôi cung cấp cho MBAL, bác sỹ hoặc Bên thứ ba do MBAL chỉ định là đầy đủ, chính xác và trung thực, là cơ sở để MBAL đánh giá khả năng chấp nhận bảo hiểm. Mọi thông tin trao đổi bằng lời nói trái với các nội dung của Yêu cầu bảo hiểm và Quy tắc, Điều khoản của Hợp đồng bảo hiểm sẽ không có hiệu lực. Tôi/Chúng tôi hiểu rõ và đồng ý rằng MBAL có quyền đề nghị Người được bảo hiểm đi kiểm tra sức khỏe và việc MBAL tiến hành kiểm tra, thẩm định sức khỏe của Người được bảo hiểm (nếu có) không thay thế cho nghĩa vụ kê khai trung thực của Tôi/Chúng tôi, Tôi/Chúng tôi đồng ý rắng nếu Bên mua bảo hiểm/ Người được bảo hiểm/ Người đại diện theo pháp luật của Người được bảo hiểm có ý vi phạm nghĩa vụ kê khai trung thực những thông tin mà nếu với thông tin chính xác MBAL sẽ không chấp nhận bảo hiểm, MBAL sẽ không có nghĩa vụ giải quyết bất cứ Quyền lợi bảo hiểm nào liên quan trực tiếp đến thông tin không trung thực đó. ',
      '5.	Tôi/Chúng tôi đồng ý cho các cơ quan y tế, bệnh viện, bác sĩ, (các) công ty bảo hiểm khác, tổ chức, cá nhân, khi nhận được bản sao Yêu cầu bảo hiểm này, được phép cung cấp cho MBAL tất cả các tài liệu hồ sơ bệnh án về Tôi/Chúng tôi, bao gồm cả những thông tin, tài liệu về sức khỏe, y tế có liên quan đến Bên mua bảo hiểm/Người được bảo hiểm nhằm mục đích giao kết và thực hiện Hợp đồng bảo hiểm. Tôi đồng ý sử dụng số điện thoại di động, địa chỉ thư email mà Tôi đăng ký với MB Bank để nhận các thông báo liên quan đến giao dịch Yêu cầu bảo hiểm/Hợp đồng bảo hiểm và các thông tin về các chương trình chăm sóc Khách hàng, giới thiệu sản phẩm, dịch vụ của MB Ageas. ',
      '6.	Tôi/Chúng tôi đồng ý cho MBAL được phép tiết lộ, chuyển giao hoặc sử dụng các thông tin cá nhân do Tôi/Chúng tôi cung cấp trong các trường hợp sau: thu thập, sử dụng, chuyển giao theo yêu cầu của cơ quan nhà nước có thẩm quyền hoặc cho mục đích thẩm định, tính toán phí bảo hiểm/ phí bảo hiểm rủi ro, phát hành hợp đồng bảo hiểm, thu phí bảo hiểm/ phí bảo hiểm rủi ro, tái bảo hiểm, trích lập dự phòng nghiệp vụ, dịch vụ khách hàng, giải quyết chi trả quyền lợi bảo hiểm, nghiên cứu thiết kế sản phẩm, phòng chống gian lận, trục lợi bảo hiểm, nghiên cứu, đánh giá tình hình tài chính, khả năng thanh toán, mức độ đầy đủ vốn, yêu cầu vốn, xử lý, lưu trữ và quản trị dữ liệu. Các trường hợp chuyển giao thông tin khác phải được Tôi/Chúng tôi đồng ý bằng văn bản. ',
      '7.	Tôi/Chúng tôi sẽ hoàn toàn chịu trách nhiệm về việc ủy quyền cho người khác thay Tôi/Chúng tôi nộp Phí bảo hiểm hay hoàn trả các khoản tạm ứng từ Giá trị hoàn lại cho MBAL. MBAL không có trách nhiệm hoặc nghĩa vụ phải xác minh việc Tôi/Chúng tôi ủy quyền cho bên thứ ba. Tôi/Chúng tôi sẽ cung cấp các chứng từ liên quan đến nguồn gốc các khoản thanh toán nếu cơ quan nhà nước có thẩm quyền yêu cầu. Tôi/Chúng tôi xác nhận rằng mọi khoản tiền dùng để đóng Phí bảo hiểm và/ hoặc hoàn trả các khoản tạm ứng từ Giá trị hoàn lại không có nguồn gốc từ hành vi, hoạt động vi phạm pháp luật phòng chống rửa tiền của Việt Nam. ',
      '8.	Tôi/Chúng tôi đồng ý sử dụng địa chỉ thư điện tử (email) mà Tôi/Chúng tôi đăng ký với MBAL để nhận bộ Hợp đông bảo hiểm và các thông báo/ tài liệu liên quan đến Hợp đồng bảo hiểm. Tôi/Chúng tôi có trách nhiệm bảo mật tài khoản email, mật khẩu sử dụng và các thông tin mà Công ty đã chuyển cho Tôi/Chúng tôi, tự chịu trách nhiệm với mọi thiệt hại, tổn thất liên quan đến Hợp đồng bảo hiểm do việc Tôi/Chúng tôi để lộ thông tin về Hợp đồng bảo hiểm và/hoặc thông tin trong thư điện tử, mật khẩu sử dụng dẫn đến người khác có thể tiếp cận các thông tin mà MBAL cung cấp và/ hoặc thông tin trong thư điện tử bị sử dụng bởi một bên không có thẩm quyền, trừ khi do lỗi của MBAL. Tôi/Chúng tôi cam kết sẽ cập nhật kịp thời cho MBAL bất kỳ thay đổi nào về các thông tin cá nhân đã cung cấp cho MBAL bao gồm cả thư điện tử, điện thoại di động và địa chỉ liên lạc.',
      '9.	Liên quan đến quy đinh về thuế tại Hoa Kỳ, khi cơ quan có thẩm quyền yêu cầu cung cấp thông tin, Tôi/Chúng tôi đồng ý và cho phép MBAL được tiết lộ thông tin mà Tôi/Chúng tôi đã cung cấp cho MBAL. TÔi/Chúng tôi đồng ý cung cấp thêm thông tin khi được yêu cầu.',
    ],
  },
  {
    id: 'committed_2',
    title: 'Lưu ý quan trọng',
    content: [
      '1.	Đề nghị Quý khách kiểm tra lại toàn bộ Yêu cầu bảo hiểm để đảm bảo rằng Quý khách hoàn toàn hài lòng và đồng ý với thông tin đã khai báo trước khi tự mình ký và ghi rõ họ tên ở phần dưới đây. Nếu Công ty phát hiện Bên mua bảo hiểm/Người được bảo hiểm/Người đại diện theo pháp luật của Người được bảo hiểm không tự mình ký vào Yêu cầu bảo hiểm, Công ty sẽ có quyền hủy bỏ Hợp đồng bảo hiểm đã ký và giải quyết theo quy định của pháp luật và Quy tắc, Điều khoản sản phẩm.',
      '2.	Yêu cầu bảo hiểm này được xem là một bộ phận của Hợp đồng bảo hiểm khi yêu cầu bảo hiểm được MBAL chấp nhận. ',
      '3.	Hợp đồng bảo hiếm nhân thọ là một cam kết dài hạn, việc hủy bỏ Hợp đồng trong những năm đầu của Hợp đồng sẽ dẫn tới những thiệt hại tài chính đáng kể. Quý khách vui lòng tham khảo Giá trị hoàn lại theo từng Năm hợp đồng tại Tài liệu minh họa hợp đồng bảo hiểm trước khi quyết định tham gia hủy bỏ Hợp đồng bảo hiểm (nếu có) và tự chịu trách nhiệm về quyết định của mình.',
    ],
  },
];

export const DataTerms = `<b style="text-align:center">CHƯƠNG I: QUYỀN LỢI CỦA HỢP ĐỒNG </b>
<h3>Điều 1  Quyền lợi bảo hiểm </h3>
<h4>1.1. Quyền lợi đáo hạn </h4>
<p>Khi Hợp đồng đang có hiệu lực, MB Ageas Life sẽ trå Quyền lợi đáo hạn bằng Giá trị tài khoản trừ đi Khoản nợ (nếu có) nếu Người được bảo hiểm còn sống vào Ngày đáo hạn Hợp đồng.</p>
<h4>1.1.1. Quyền lợi bảo hiểm tử vong hoặc Thương tật toàn bộ vĩnh viễn (TTTBVV) </h4>
<p>1.2.1.	Bên mua bảo hiểm có quyền lựa chọn một trong hai Quyền lợi bảo hiểm tử vong hoặc TTTBVV như sau: </p>
<p>-  Quyền lợi Cơ bản: bằng giá trị lớn hơn giữa Số tiền bảo hiểm và Giá trị tài khoản cơ bản; cộng với Giá trị tài khoản đóng thêm. </p>
<p>-  Quyển lợi Nâng cao: bằng Số tiền bảo hiểm cộng với Giá trị tài khoản. Giá trị của Quyền lợi tử vong hoặc TTTBVV được xác định tại thời điểm Người được bảo hiểm tử vong hoặc bị TTTBVV, tùy sự kiện nào ảy ra trước. </p>
<p>1.2.2 Tỷ lệ % Số tiền bảo hiểm nêu tại bảng dưới đây sẽ được sử dụng thay thế cho Số tiền bảo hiểm khi xác định Quyền lợi bảo hiểm tử vong hoặc TTTBVV theo Điểm 1.2.1: </p>
<table style="width:540px;border:1px solid #d9d9d9;vertical-align:center;text-align:center;">
  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">Tuổi bảo hiểm khi tử vong hoặc bị TTTBVV</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">Tỷ lệ % Số tiền bảo hiểm</td>
  </tr>

  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">0</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">20% Số tiền bảo hiểm</td>
  </tr>
  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">1</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">40% Số tiền bảo hiểm</td>
  </tr>
  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">2</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">60% Số tiền bảo hiểm</td>
  </tr>
  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">3</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">80% Số tiền bảo hiểm</td>
  </tr>
  <tr>
    <td style="padding:10px;width:50%;border-right:1px solid #d9d9d9;">Từ 4 tuổi trở lên</td>
    <td style="padding:10px;width:50%;">100% Số tiền bảo hiểm</td>
  </tr>
</table>
<p>1.2.3 	Nếu Tuổi bảo hiểm từ 66 trở lên tại Ngày hiệu lực Hợp đồng, Bên mua bảo hiểm chỉ được lựa chọn Quyền lợi Cơ bản theo Điểm 1.2.1. <br/>
Quyền lợi Nâng cao (nếu đang có hiệu lực) sẽ tự động chuyễn thành Quyền lợi Cơ bản kể từ Ngày kỷ niệm năm Hợp đồng tại Tuổi bảo hiểm 66 (sáu mươi sáu). 
</p>
<p>1.2.4 	Khi Hợp đồng đang có hiệu lực, nếu Người được bảo hiểm tử vong hoặc bị TTTBVV, MB Ageas Life sẽ chi trả Quyền lợi bảo hiểm tử vong hoặc TTTBVV theo Khoản 1.2 này trừ đi Khoản Nợ (nếu có). </p>
<h4>1.3. Quyền lợi gia tăng bảo hiểm tử vong hoặc TTTBVV do Tai nạn </h4>
<p>Quyền lợi gia tăng bảo hiểm tử vong hoặc TTTBVV do Tai nạn thông thường </p>
<p>Khi Quyền lợi gia tăng bảo hiểm theo Khoản 1.3 đang có hiệu lực, nếu Người được bảo hiểm tử vong hoặc bị TTTBVV do Tai nạn thông thường, và Quyền lợi bảo hiểm theo Khoản 1.2 được chấp nhận chi trả thì MB Ageas Life sẽ trả thêm Số tiền nêu tại bảng dưới đây nhưng không vượt quá Số tiền trả thêm tối đa, trừ đi Khoản nợ (nếu có): </p>
<table style="width:540px;border:1px solid #d9d9d9;vertical-align:center;text-align:center;">
  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">Tuổi bảo hiểm khi tử vong hoặc bị TTTBVV</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">Số tiền trả thêm</td>
  </tr>

  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">0</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">20% Số tiền bảo hiểm</td>
  </tr>
  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">1</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">40% Số tiền bảo hiểm</td>
  </tr>
  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">2</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">60% Số tiền bảo hiểm</td>
  </tr>
  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">3</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">80% Số tiền bảo hiểm</td>
  </tr>
  <tr>
    <td style="padding:10px;width:50%;border-right:1px solid #d9d9d9;">Từ 4 tuổi trở lên</td>
    <td style="padding:10px;width:50%;">100% Số tiền bảo hiểm</td>
  </tr>
</table>
<p>Số tiền trả thêm tối đa theo Điểm 1.3.1 này bằng 5.000.000.000 (năm tỷ) đồng, trừ trường hợp MB Ageas Life và Bên mua bảo hiểm có thỏa thuận khác. </p>
<p>1.3.2 	Quyền lợi gia tăng bảo hiểm tử vong hoặc TTTBVV do Tai nạn giao thông công cộng Khi Quyền lợi gia tăng bảo hiểm theo Khoản 1.3 đang có hiệu lực, nếu Người được bảo hiểm tử vong hoặc bị TTTBVV do Tai nạn giao thông công cộng, và Quyền lợi bảo hiểm theo Khoản 1.2 được chấp nhận chi trả thì MB Ageas Life se trả thêm Số tiền nêu tại bảng dưới đây nhưng không quá Số tiền trả thêm tối đa, trừ đi Khoản nợ (nếu có): </p>
<table style="width:540px;border:1px solid #d9d9d9;vertical-align:center;text-align:center">
  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">Tuổi bảo hiểm khi tử vong hoặc bị TTTBVV</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">Số tiền trả thêm</td>
  </tr>

  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">0</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">20% Số tiền bảo hiểm</td>
  </tr>
  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">1</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">40% Số tiền bảo hiểm</td>
  </tr>
  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">2</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">60% Số tiền bảo hiểm</td>
  </tr>
  <tr>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;border-right:1px solid #d9d9d9;">3</td>
    <td style="padding:10px;width:50%;border-bottom:1px solid #d9d9d9;">80% Số tiền bảo hiểm</td>
  </tr>
  <tr>
    <td style="padding:10px;width:50%;border-right:1px solid #d9d9d9;">Từ 4 tuổi trở lên</td>
    <td style="padding:10px;width:50%;">100% Số tiền bảo hiểm</td>
  </tr>
</table>
<p>Số tiền trả thêm tối đa theo Điểm 1.3.2 này bằng 10.000.000.000 (mười tỷ) đồng, trừ trường hợp MB Ageas Life và Bên mua bảo hiểm có thỏa thuận khác. </p>
<p>1.3.3 Bên mua bảo hiểm có quyền lựa chọn không tham gia Quyền lợi gia tăng bảo hiểm m theo Khoản này khi Yêu cầu bảo hiểm hoặc có quyền chấm dứt hiệu lực trước hạn Quyền lợi bảo hiểm này khi Quyền lợi bảo hiểm này đang có hiệu lực. </p>
<h4>1.4. Trong Thời hạn Hợp đồng, MB Ageas Life chỉ chấp nhận trả một lần cho Quyền lợi bảo hiểm theo Khoản 1.2 và một lần cho Quyền lợi bảo hiểm theo Khoản 1.3 (nếu có tham gia). </h4>
<h4>1.5. Hợp đồng chấm dứt hiệu lực ngay sau khi xảy ra Sự kiện bảo hiểm được chấp nhận chi trả Quyền lợi bảo hiểm theo Khoản 1.2 nhưng không muộn hơn Ngày Điều 2 Quyền lợi đầu tư đáo hạn Hợp đồng. </h4>
`;

export const dataOtherQuestions = [
  {
    id: 'dataOtherQuestions_2',
    name: 'Quý Khách hàng có thuộc trường hợp: Là công dân hoặc thường trú tại Hoa Kỳ; hoặc sinh tại Hoa Kỳ; hoặc có địa chỉ thường trú, địa chỉ hòm thư, địa chỉ liên lạc, gửi giữ thư tại Hoa Kỳ; hoặc có số điện thoại tại Hoa Kỳ; hoặc có Yêu cầu thanh toán từ một tài khoản này sang tài khoản khác ở Hoa Kỳ; hoặc có Giấy tờ ủy quyền hiện hành cho người có địa chỉ tại Hoa Kỳ hay không?',
  },
];
export const convertQuestionName = (questioName: string, val: number) => {
  let txt = 'a';
  switch (val) {
    case 1:
      txt = 'b';
      break;
    case 2:
      txt = 'c';
      break;
    case 3:
      txt = 'd';
      break;
    case 4:
      txt = 'e';
      break;
    case 5:
      txt = 'f';
      break;
    case 6:
      txt = 'g';
      break;
    case 7:
      txt = 'h';
      break;
    case 8:
      txt = 'i';
      break;
    case 9:
      txt = 'j';
      break;
    case 10:
      txt = 'k';
      break;
    case 11:
      txt = 'l';
      break;
    case 12:
      txt = 'm';
      break;
    case 13:
      txt = 'n';
      break;
    default:
      break;
  }
  return questioName + txt;
};

export const dataFrequency = [
  { value: 'DAILY', label: 'Hàng ngày' },
  { value: 'WEEKLY', label: 'Hàng tuần' },
  { value: 'SOMETIMES', label: 'Thỉnh thoảng' },
];

export enum QuestionTypeEnum {
  INPUT = 'INPUT',
  DECIMAL = 'DECIMAL',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  FILE = 'FILE',
  SELECT = 'SELECT',
  MULTI = 'MULTI',
}
export const dataStepsQuestion = [
  {
    id: '1',
    name: 'Câu hỏi 1',
    type: 'Câu hỏi về sức khỏe',
    questions: [
      {
        id: '1_1',
        name: 'Chiều cao, cân nặng của bạn là bao nhiêu ?',
        type: QuestionTypeEnum.INPUT,
        default: 'C',
        style: 'flex mx-minus6',
        selections: undefined,
        subQuestions: [
          {
            id: '1_1_1',
            name: 'Chiều cao (cm)',
            type: QuestionTypeEnum.DECIMAL,
            selections: undefined,
            maxLength: 999.9,
          },
          {
            id: '1_1_2',
            name: 'Cân nặng (kg)',
            type: QuestionTypeEnum.DECIMAL,
            selections: undefined,
            maxLength: 999.9,
          },
        ],
      },
      {
        id: '1_2',
        name: 'Quý khách có tăng hoặc giảm quá 5 kg cân nặng trong một năm vừa qua không?',
        type: QuestionTypeEnum.MULTI,
        default: 'K',
        style: 'block !mt-0',
        selections: [
          { value: 'INCREASE', label: 'Tăng' },
          { value: 'DECREASE', label: 'Giảm' },
          { value: 'K', label: 'Không' },
        ],
        subQuestions: [
          {
            id: '1_2_1',
            name: 'Tăng/Giảm',
            type: QuestionTypeEnum.DECIMAL,
            maxLength: 999.9,
          },
          {
            id: '1_2_2',
            name: 'Lý do',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 255,
          },
        ],
      },
    ],
  },
  {
    id: '2',
    name: 'Câu hỏi 2',
    type: 'Nghề nghiệp và dự định đi nước ngoài',
    questions: [
      {
        id: '2_1',
        name: 'a. Quý khách có đang tham gia hoặc dự định tham gia các hoạt động nguy hiểm như leo núi, nhảy dù, nhào lộn trên không, bay cá nhân, đua xe ô tô/mô tô/xe đạp, đua ngựa, săn bắn, đấm bốc, lặn không ?',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '2_1_1',
            name: 'Chi tiết các hoạt động nguy hiểm',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 255,
          },
        ],
      },
      {
        id: '2_2',
        name: 'b. Quý khách có tham gia vào hoạt động hoặc nghề nghiệp nào liên quan đến tốc độ cao, di chuyển bằng đường hàng không, di chuyển bằng đường biển mà không phải với tư cách là hành khách.',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '2_2_1',
            name: 'Chi tiết',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 255,
          },
        ],
      },
      {
        id: '2_3',
        name: 'c. Quý khách có dự định (trong vòng 06 tháng) đi nước ngoài không ?',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '2_1_1',
            name: 'Quốc gia',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: '2_1_2',
            name: 'Mục đích di chuyển',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 255,
          },
        ],
      },
    ],
  },
  {
    id: '3',
    name: 'Câu hỏi 3',
    type: 'Các thói quen sinh hoạt cá nhân',
    questions: [
      {
        id: '3_1',
        name: 'Quý khách có hút thuốc lá, thuốc lào, xì gà, tẩu thuốc, thuốc lá điện tử không ?',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '3_1_1',
            name: 'Loại thuốc',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 60,
          },
          {
            id: '3_1_2',
            name: 'Số lượng sử dụng hàng ngày (điếu/ngày)',
            type: QuestionTypeEnum.NUMBER,
            selections: undefined,
            maxLength: 99,
          },
          {
            id: '3_1_3',
            name: 'Số năm sử dụng tính đến hiện tại (năm)',
            type: QuestionTypeEnum.NUMBER,
            selections: undefined,
            maxLength: 99,
          },
        ],
      },
      {
        id: '4_1',
        name: 'Quý khách có sử dụng rượu, bia không ?',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '4_1_1',
            name: 'Loại đồ uống',
            type: QuestionTypeEnum.BOOLEAN,
            selections: [
              { value: 'BEER', label: 'Bia' },
              { value: 'ALCOHOL', label: 'Rượu' },
            ],
            maxLength: 255,
          },
          {
            id: '4_1_4',
            name: 'Số lượng bia uống mỗi lần (ml)',
            type: QuestionTypeEnum.NUMBER,
            selections: undefined,
            maxLength: 4,
          },
          {
            id: '4_1_3',
            name: 'Tần suất uống',
            type: QuestionTypeEnum.SELECT,
            selections: dataFrequency,
            maxLength: 255,
          },
          {
            id: '4_1_2',
            name: 'Số lượng rượu uống mỗi lần (ml)',
            type: QuestionTypeEnum.NUMBER,
            selections: undefined,
            maxLength: 4,
          },
          {
            id: '4_1_5',
            name: 'Tần suất uống',
            type: QuestionTypeEnum.SELECT,
            selections: dataFrequency,
            maxLength: 255,
          },
        ],
      },
      {
        id: '5_1',
        name: 'Quý khách đã từng hoặc đang sử dụng bất kỳ loại chất ma túy, thuốc kích thích, chất gây nghiện hay không ?',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '5_1_1',
            name: 'Chi tiết',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 255,
          },
        ],
      },
    ],
  },
  {
    id: '6',
    name: 'Câu hỏi 6',
    type: 'Thông tin về gia đình',
    questions: [
      {
        id: '6_1',
        name: 'Có ai trong số Cha/ Mẹ/ Anh chị em ruột đang mắc phải: Nhiễm virus suy giảm miễn dịch ở người (HIV), hội chứng suy giảm miễn dịch mắc phải (AIDS), bệnh tâm thần, trầm cảm, bệnh thận đa nang, bệnh đa xơ cứng, đái tháo đường, tăng huyết áp, các bệnh di truyền khác, bệnh truyền nhiễm, bệnh hệ thống, bệnh tim (bệnh cơ phì đại, triệu chứng Brugada), bệnh Parkinson, dị tật bẩm sinh không?',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '6_1_1',
            name: 'Họ và tên',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 40,
          },
          {
            id: '6_1_2',
            name: 'Mối quan hệ với BMBH',
            type: QuestionTypeEnum.SELECT,
            selections: [
              {
                value: RelationshipTypeEnum.POLICY_HOLDER,
                label: 'Người mua bảo hiểm',
              },
              {
                value: RelationshipTypeEnum.INSURED_PERSON,
                label: 'Người được bảo hiểm',
              },
              {
                value: RelationshipTypeEnum.COUPLE,
                label: 'Vợ/Chồng',
              },
              {
                value: RelationshipTypeEnum.CHILDREN,
                label: 'Con',
              },
              {
                value: RelationshipTypeEnum.PARENT,
                label: 'Bố/Mẹ',
              },
              {
                value: RelationshipTypeEnum.MOTHER,
                label: 'Mẹ',
              },
              {
                value: RelationshipTypeEnum.FATHER,
                label: 'Bố',
              },
            ],
            maxLength: 255,
          },
          {
            id: '6_1_3',
            name: 'Tuổi còn sống/đã mất',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 3,
          },
          {
            id: '6_1_4',
            name: 'Tình trạng sức khoẻ hoặc Nguyên nhân tử vong (nếu đã chết)',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 40,
          },
        ],
      },
    ],
  },
  {
    id: '7',
    name: 'Câu hỏi 7',
    type: 'Lịch sử về sức khoẻ và y tế',
    questions: [
      {
        id: 'questions_7_1',
        name: 'Quý khách đã từng được khám, từng được chẩn đoán, được thông báo hoặc được điều trị về các chứng bệnh sau không?',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: 'questions_7_1_1',
            name: 'a. Đau ngực, các rối loạn nhịp tim, đánh trống ngực, tăng huyết áp, huyết áp thấp, tiếng thổi tâm thu - tâm trương, bệnh tăng áp động mạch phổi hay các rối loạn/ bệnh lý về tim, về mạch máu.',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: 'questions_7_1_2',
            name: 'b. Bệnh nội tiết, đái tháo đường, đái tháo đường thai kỳ, cường giáp, suy giáp, viêm tuyến giáp; các bệnh tuyến giáp (nang giáp, nhân giáp...), rối loạn dinh dưỡng, các bệnh chuyển hóa khác.',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: 'questions_7_1_3',
            name: 'c. Bệnh về mắt, rối loạn thị giác, tăng nhãn áp (Glocom), giảm thị lực, chấn thương mắt, xuất huyết võng mạc, nhìn đôi, đục thủy tinh thể, rối loạn thính giác, rối loạn tiền đình, mất thính giác, khiếm thị, khó nuốt, nghẹn, ù tai mạn tính, mù, điếc.',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: 'questions_7_1_4',
            name: 'd. Bệnh Hen phế quản, ho ra máu, ho mạn tính, viêm phổi/ viêm màng phổi, viêm phế quản, lao, bệnh phổi tắc nghẽn mạn tính hoặc bất kỳ rối loạn/ bệnh lý hô hấp nào khác.',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: 'questions_7_1_5',
            name: 'e. Bệnh hệ thống tạo máu, bệnh tủy xương, bệnh bạch cầu, bệnh hồng cầu, bệnh tiểu cầu, bệnh thiếu máu, bệnh Thalassemia, bệnh máu khó đông, bệnh tan máu, bệnh tăng sinh tủy, bệnh rối loạn sinh tủy, bất kỳ rối loạn máu nào khác.',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: 'questions_7_1_6',
            name: 'f. Xơ gan, bệnh não gan, nang gan, u máu gan, viêm gan (bao gồm nhưng không chỉ gây ra bởi viêm gan siêu vi, viêm gan rượu...), vàng da, viêm loét dạ dày tá tràng, thoát vị, các bệnh túi mật (bao gồm nhưng không chỉ gây ra bởi nhiễm trùng túi mật hoặc sỏi mật, polyp túi mật), viêm tụy..., xuất huyết đường tiêu hóa hoặc các bệnh lý khác của gan, đường mật hoặc các rối loạn/ bệnh lý khác của hệ tiêu hóa.',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: 'questions_7_1_7',
            name: 'g. Albumin niệu, hồng cầu niệu, mủ/ dưỡng chấp niệu, hoặc đường niệu, sỏi thận hoặc bất kỳ rối loạn nào khác của thận, niệu quản, niệu đạo, bàng quang, tuyến tiền liệt, rối loạn kinh nguyệt, kinh nguyệt không đều hoặc các rối loạn/ bệnh lý khác của cơ quan sinh dục, tử cung, u nang buồng trứng/ buồng trứng đa nang và hệ thống sinh sản.',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: 'questions_7_1_8',
            name: 'h. Đột quỵ, động kinh, co giật, tê liệt, nhức đầu mạn tính, rối loạn thăng bằng, rối loạn mạch máu não, hôn mê, sa sút trí tuệ (bệnh Alzheimer), bệnh Parkinson, viêm não, viêm màng não, bại não, não úng thủy, dị dạng mạch máu não, teo não, khác hệ bệnh thần kinh.',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: 'questions_7_1_9',
            name: 'i. Bệnh lý ung thư hoặc u lành tính; u nang, u xơ, u mô đệm, chửa trứng, bệnh máu ác tính/ mạn tính hoặc các khối phát triển bất thường nào khác.',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: 'questions_7_1_10',
            name: 'j. Bệnh miễn dịch, bệnh hệ thống, lupus ban đỏ, bệnh tuyến thượng thận, nhược cơ, viêm bì cơ, viêm khớp, viêm đa khớp dạng thấp bệnh gút, chấn thương nặng, khuyết tật về thể chất hoặc các rối loạn/ bệnh lý liên quan đến cơ/ xương/ sụn/ khớp/ mô liên kết.',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: 'questions_7_1_11',
            name: 'k. Nghiện rượu, lạm dụng chất kích thích hoặc ma túy, rối loạn tâm thần, trầm cảm, các rối loạn/ bệnh lý tâm lý hoặc tâm thần, rối loạn hành vi và các rối loạn tâm thần khác.',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: 'questions_7_1_12',
            name: 'l. Các bệnh qua đường tình dục (bao gồm nhưng không chỉ gây ra do bệnh lậu, giang mai, viêm sinh dục...), nhiễm HIV/AIDS',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: 'questions_7_1_13',
            name: 'm. Dị tật, khuyết tật, bệnh lý bẩm sinh .',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: 'questions_7_1_14',
            name: 'n. Bệnh lý về da liễu, nổi hạch bất thường trên cơ thể, áp xe.',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
        ],
      },
      {
        id: '8_1',
        name: 'Hiện tại Quý khách có đang được điều trị bằng loại thuốc nào không ?',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        placeholder: 'Nhập các thông tin liên quan',
        selections: undefined,
        subQuestions: [
          {
            id: '8_1_1',
            name: 'Loại thuốc',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 60,
          },
          {
            id: '8_1_2',
            name: 'Lý do sử dụng',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 255,
          },
        ],
      },
      {
        id: '9_1',
        name: 'Trong vòng 5 năm trở lại đây, Quý khách có khám, thực hiện hay được yêu cầu thực hiện các xét nghiệm máu hay các phương pháp cận lâm sàng nào không (bao gồm nhưng không giới hạn như chụp X-quang, siêu âm, điện tim, điện não, chụp cắt lớp, chụp cộng hưởng từ, thử máu, nội soi, hay sinh thiết ....).',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '9_2_1',
            name: 'Do khám sức khỏe định kỳ, thai sản, xin việc hoặc lấy bằng lái với kết quả bình thường',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: '9_2_2',
            name: 'Do khám sức khỏe định kỳ, thai sản, xin việc hoặc lấy bằng lái với kết quả có yếu tố bất thường',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: '9_2_3',
            name: 'Do các bệnh lý, triệu chứng bất thường',
            type: QuestionTypeEnum.BOOLEAN,
            selections: undefined,
            maxLength: 255,
          },
        ],
      },
    ],
  },
  {
    id: '10',
    name: 'Câu hỏi 10',
    type: 'Câu hỏi chi tiết sức khoẻ',
    questions: [
      {
        id: '10_1',
        name: 'a. Số con hiện tại:',
        type: QuestionTypeEnum.INPUT,
        default: 'C',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '10_1_1',
            name: 'Điền số lượng',
            type: QuestionTypeEnum.NUMBER,
            selections: undefined,
            maxLength: 99,
          },
        ],
      },
      {
        id: '10_2',
        name: 'b. Hiện tại có đang mang thai không ?',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '10_2_1',
            name: 'Đây là lần mang thai thứ mấy',
            type: QuestionTypeEnum.NUMBER,
            selections: undefined,
            maxLength: 99,
          },
          {
            id: '10_2_2',
            name: 'Ngày dự kiến sinh (ngày/tháng/năm)',
            type: QuestionTypeEnum.DATE,
            selections: undefined,
            maxLength: 255,
          },
        ],
      },
      {
        id: '10_3',
        name: 'c. Có bất thường gì trong các lần mang thai và sinh đẻ trước đây không ?',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '10_3_1',
            name: 'Chi tiết',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 255,
          },
        ],
      },
      {
        id: '10_4',
        name: 'd. Quý khách đã từng có rối loạn về phụ khoa như chảy máu bất thường âm đạo, lạc nội mạc tử cung.',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '10_4_1',
            name: 'Chi tiết',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 255,
          },
        ],
      },
      {
        id: '10_5',
        name: 'e. Quý khách đã từng có kết quả bất thường của xét nghiệm phết tế bào cổ tử cung (Pap’s), của chụp nhũ ảnh hay của siêu âm tuyến vú không ?',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '10_5_1',
            name: 'Chi tiết',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 255,
          },
        ],
      },
    ],
  },
  {
    id: '11',
    name: 'Câu hỏi 11',
    type: 'Câu hỏi chi tiết sức khoẻ',
    questions: [
      {
        id: '11_2',
        name: 'a. Quý khách có Yêu cầu bảo hiểm nào về nhân thọ, tai nạn, thương tật, bệnh hiểm nghèo, nằm viện có quyết định bị tạm hoãn, từ chối, chấp thuận bảo hiểm có điều kiện (tăng phí, loại trừ trách nhiệm bảo hiểm) hay không ?',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '11_2_1',
            name: 'Tên công ty bảo hiểm',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 100,
          },
          {
            id: '11_2_2',
            name: 'Số hợp đồng',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 20,
          },
          {
            id: '11_2_3',
            name: 'Số tiền bảo hiểm',
            type: QuestionTypeEnum.NUMBER,
            selections: undefined,
            maxLength: 11,
          },
          {
            id: '11_2_4',
            name: 'Nguyên nhân từ chối/tạm hoãn/chấp thuận bảo hiểm',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: '11_2_5',
            name: 'Ngày/tháng/năm',
            type: QuestionTypeEnum.DATE,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: '11_2_6',
            name: 'Tình trạng',
            type: QuestionTypeEnum.SELECT,
            selections: [
              {
                value: InsuranceDelcinesStatusTypeEnum.ACTIVE,
                label: 'Chấp nhận',
              },
              {
                value: InsuranceDelcinesStatusTypeEnum.PENDING,
                label: 'Tạm hoãn',
              },
              {
                value: InsuranceDelcinesStatusTypeEnum.CANCELED,
                label: 'Từ chối',
              },
            ],
            maxLength: 255,
          },
        ],
      },
      {
        id: '11_3',
        name: 'b. Quý khách đã từng nộp đơn yêu cầu giải quyết quyền lợi bảo hiểm cho bất kỳ Hợp đồng bảo hiểm nào trước đây chưa ?',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '11_3_1',
            name: 'Tên công ty bảo hiểm',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 100,
          },
          {
            id: '11_3_2',
            name: 'Số hợp đồng',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 20,
          },
          {
            id: '11_3_3',
            name: 'Số tiền bảo hiểm',
            type: QuestionTypeEnum.NUMBER,
            selections: undefined,
            maxLength: 11,
          },
          {
            id: '11_3_4',
            name: 'Nguyên nhân nộp yêu cầu giải quyết quyền lợi',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: '11_3_5',
            name: 'Ngày/tháng/năm',
            type: QuestionTypeEnum.DATE,
            selections: undefined,
            maxLength: 255,
          },
          {
            id: '11_2_6',
            name: 'Tình trạng',
            type: QuestionTypeEnum.SELECT,
            selections: [
              {
                value: InsuranceClaimsStatusTypeEnum.APPROVED,
                label: 'Chấp thuận',
              },
              {
                value: InsuranceClaimsStatusTypeEnum.REJECTED,
                label: 'Đã từ chối',
              },
              {
                value: InsuranceClaimsStatusTypeEnum.PAUSING,
                label: 'Tạm dừng',
              },
            ],
            maxLength: 255,
          },
        ],
      },
      {
        id: '11_4',
        name: 'c. Quý khách có Yêu cầu bảo hiểm và/ hoặc Hợp đồng bảo hiểm nhân thọ với MBAL hoặc Công ty bảo hiểm khác không ?',
        type: QuestionTypeEnum.BOOLEAN,
        default: 'K',
        style: 'block',
        selections: undefined,
        subQuestions: [
          {
            id: '11_4_1',
            name: 'Tên công ty bảo hiểm',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 100,
          },
          {
            id: '11_4_2',
            name: 'Số YCBH/HĐBH',
            type: QuestionTypeEnum.INPUT,
            selections: undefined,
            maxLength: 20,
          },
          {
            id: '11_4_3',
            name: 'Số tiền bảo hiểm',
            type: QuestionTypeEnum.NUMBER,
            selections: undefined,
            maxLength: 11,
          },
          {
            id: '11_4_4',
            name: 'Ngày YCBH/Ngày hiệu lực HĐBH',
            type: QuestionTypeEnum.DATE,
            selections: undefined,
            maxLength: 255,
          },
        ],
      },
    ],
  },
];

export const dataQuizzForChildLess4 = {
  id: '10',
  name: 'Câu hỏi 10',
  type: 'Câu hỏi chi tiết sức khoẻ',
  questions: [
    {
      id: '10_1',
      name: 'a. Cân nặng lúc sinh nếu Người được bảo hiểm dưới 4 tuổi (kg)',
      type: QuestionTypeEnum.INPUT,
      default: 'C',
      style: 'tw-block',
      selections: undefined,
      subQuestions: [
        {
          id: '10_1_1',
          name: 'Điền cân nặng (kg)',
          type: QuestionTypeEnum.DECIMAL,
          selections: undefined,
          maxLength: 999.9,
        },
      ],
    },
    {
      id: '10_2',
      name: 'b. Trẻ sinh có phải can thiệp bằng bất cứ biện pháp nào hay không?',
      type: QuestionTypeEnum.BOOLEAN,
      default: 'K',
      style: 'tw-block',
      selections: undefined,
      subQuestions: [
        {
          id: '10_2_1',
          name: 'Chi tiết',
          type: QuestionTypeEnum.INPUT,
          selections: undefined,
          maxLength: 255,
        },
      ],
    },
    {
      id: '10_3',
      name: 'c. Có bị bệnh hoặc khuyết tật nào sau khi sinh không?',
      type: QuestionTypeEnum.BOOLEAN,
      default: 'K',
      style: 'tw-block',
      selections: undefined,
      subQuestions: [
        {
          id: '10_3_1',
          name: 'Chi tiết',
          type: QuestionTypeEnum.INPUT,
          selections: undefined,
          maxLength: 255,
        },
      ],
    },

    {
      id: '10_4',
      name: 'd. Có còi xương,suy dinh dưỡng, bất thường về chậm phát triển, thần kinh,tim mạch không?',
      type: QuestionTypeEnum.BOOLEAN,
      default: 'K',
      style: 'tw-block',
      selections: undefined,
      subQuestions: [
        {
          id: '10_4_1',
          name: 'Chi tiết',
          type: QuestionTypeEnum.INPUT,
          selections: undefined,
          maxLength: 255,
        },
      ],
    },
  ],
};

export const dataQuizzForChild = {
  id: '10',
  name: 'Câu hỏi 10',
  type: 'Câu hỏi chi tiết sức khoẻ',
  questions: [
    {
      id: '10_1',
      name: 'a. Cân nặng lúc sinh nếu Người được bảo hiểm dưới 4 tuổi (kg)',
      type: QuestionTypeEnum.INPUT,
      default: 'C',
      style: 'tw-block',
      selections: undefined,
      subQuestions: [
        {
          id: '10_1_1',
          name: 'Điền cân nặng (kg)',
          type: QuestionTypeEnum.DECIMAL,
          selections: undefined,
          maxLength: 999.9,
        },
      ],
    },
    {
      id: '10_2',
      name: 'a. Trẻ sinh có phải can thiệp bằng bất cứ biện pháp nào hay không?',
      type: QuestionTypeEnum.BOOLEAN,
      default: 'K',
      style: 'tw-block',
      selections: undefined,
      subQuestions: [
        {
          id: '10_2_1',
          name: 'Chi tiết',
          type: QuestionTypeEnum.INPUT,
          selections: undefined,
          maxLength: 255,
        },
      ],
    },
    {
      id: '10_3',
      name: 'b. Có bị bệnh hoặc khuyết tật nào sau khi sinh không?',
      type: QuestionTypeEnum.BOOLEAN,
      default: 'K',
      style: 'tw-block',
      selections: undefined,
      subQuestions: [
        {
          id: '10_3_1',
          name: 'Chi tiết',
          type: QuestionTypeEnum.INPUT,
          selections: undefined,
          maxLength: 255,
        },
      ],
    },

    {
      id: '10_4',
      name: 'c. Có còi xương,suy dinh dưỡng, bất thường về chậm phát triển, thần kinh,tim mạch không?',
      type: QuestionTypeEnum.BOOLEAN,
      default: 'K',
      style: 'tw-block',
      selections: undefined,
      subQuestions: [
        {
          id: '10_4_1',
          name: 'Chi tiết',
          type: QuestionTypeEnum.INPUT,
          selections: undefined,
          maxLength: 255,
        },
      ],
    },
  ],
};
