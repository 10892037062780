import * as yup from 'yup';
import dayjs from 'dayjs';

import {
  getAge,
  reverseCustomerDobValidationMessages,
  reverseCustomerYearlyExpenseValidationMessages,
  validationMessages,
} from '../../../helpers';

export const dobValidation = yup
  .string()
  .required(reverseCustomerDobValidationMessages.required)
  .test(
    'min-15-days',
    reverseCustomerDobValidationMessages.minDays,
    (val) => val == null || dayjs().diff(dayjs(val), 'day') >= 15,
  )
  .test('max-65', reverseCustomerDobValidationMessages.maxAge, (val) => val == null || getAge(val) <= 65);

export const yearlyExpenseValidation = yup
  .number()
  .required(validationMessages.required)
  .test(
    'multiples-of-1-mil',
    reverseCustomerYearlyExpenseValidationMessages.multiples,
    (val) => val == null || val % 1000000 === 0,
  )
  .test(
    'min-10-mils',
    reverseCustomerYearlyExpenseValidationMessages.minValue,
    (val) => val == null || val >= 10000000,
  );
