import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mantine/core';
import { stringify } from 'qs';
import { memo, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { SelectItemType } from '../../../components';
import { useLeads } from '../../../contexts';
import { AppRoutes, validationMessages } from '../../../helpers';
import { useQueryString } from '../../../hooks';
import { InsuranceTypeEnum, SourceTypeEnum } from '../../../services';
import { LoanInformationFormData, RadioGroupPackages } from '../components';

import { LeadsScreenParams } from './leads-form-screen';

export type FormData = {
  packageType: string;
};
export const SelectInsurancePackageForm = memo(() => {
  const navigate = useNavigate();
  const { leadsId = 0, flow, health_checkup } = useQueryString<LeadsScreenParams>();
  const { leadsCustomer, onUpdateLeadsCustomer, loanInformationForm, onUpdateLoanInformationForm } = useLeads();

  const dataOptions: SelectItemType[] = useMemo(
    () => [
      {
        value: SourceTypeEnum.MBAL,
        label: process.env.REACT_APP_CREDIT_LIFE_MBAL_TITLE || '',
        url: process.env.REACT_APP_CREDIT_LIFE_MBAL_URL || '',
        extra: '(Đối tượng KH không đổi)',
        note: '(User: accountrm/ Pass: rm@123456)',
      },
      {
        value: SourceTypeEnum.MIC,
        label: process.env.REACT_APP_CREDIT_LIFE_MIC_TITLE || '',
        url: process.env.REACT_APP_CREDIT_LIFE_MIC_URL || '',
        extra: '(Đối tượng KH không đổi)',
        note: '',
      },
      {
        value: InsuranceTypeEnum.ULRP_3_0,
        label: 'Sống trọn ước mơ',
        url: '',
        extra: '(Tư vấn BH cho người thân KH)',
        note: '',
      },
    ],
    [],
  );

  const validationSchema = yup.object<LoanInformationFormData>({
    packageType: yup.string().trim().required(validationMessages.required),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoanInformationFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(
      () => ({ packageType: loanInformationForm?.packageType || undefined }),
      [loanInformationForm?.packageType],
    ),
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    async (body: LoanInformationFormData) => {
      onUpdateLoanInformationForm(body);
      if (body.packageType !== InsuranceTypeEnum.ULRP_3_0) {
        const url = dataOptions?.find((it) => it.value == body.packageType)?.url || '';
        const data = JSON.stringify({
          type: 'OPEN_BROWSER',
          url,
          data: { url },
        });
        (window as any)?.parent?.postMessage(data);
        (window as any)?.ReactNativeWebView?.postMessage(data);
        return;
      }
      !!leadsCustomer &&
        onUpdateLeadsCustomer({
          ...leadsCustomer,
          is_loan: true,
        });
      navigate({
        pathname: AppRoutes.leads_new,
        search: stringify({
          step: flow,
          leadsId,
          health_checkup,
        }),
      });
    },
    [
      dataOptions,
      flow,
      health_checkup,
      leadsCustomer,
      leadsId,
      navigate,
      onUpdateLeadsCustomer,
      onUpdateLoanInformationForm,
    ],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="pb-120px bg-white -mx-16px -my-24px px-16px py-24px">
        <div>
          <p className="text-16px font-semibold my-0 mb-[15px]">Tuân thủ Thông tư 67/BTC</p>
          <p className="text-14px my-0 mb-10px">
            <span className="font-semibold">Lực lượng bán vui lòng lựa chọn:</span>
          </p>
          <Controller
            control={control}
            name="packageType"
            render={({ field: { onChange, value } }) => (
              <RadioGroupPackages
                options={dataOptions?.filter((it) => !!it?.label)}
                value={value}
                onChange={onChange}
                error={errors?.packageType?.message}
              />
            )}
          />
        </div>
      </div>

      <div className="bg-white pt-12px pb-34px px-16px fixed bottom-0 left-0 w-full border-0 border-t-0.5px border-solid border-c_5BC5F2-15 z-10">
        <Button type="submit" fullWidth>
          Tiếp tục
        </Button>
      </div>
    </form>
  );
});
