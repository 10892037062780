import * as yup from 'yup';

import { formatMoney, getValidateMaxSA, getValidateMinSA, validationMessages } from '../../../helpers';
import {
  AdditionalAssuredProductEnum,
  InsuranceBenefitEnum,
  InsuranceTypeEnum,
  PackagePaymentPeriodEnum,
} from '../../../services';
import { InsuranceDetailFormData } from '../form';
import { AssuredOptionType } from '../form/insurance-detail-form';

import { AssuredTypeEnum } from './types';

const yupDuplicateResolver = (_val: any, context: any) => {
  const {
    from = [],
    options: { index },
  } = context;

  const [
    { value: parent },
    {
      value: { additional_products },
    },
  ] = from;

  if (parent.name == null || parent.person == null) return true;

  return (
    (additional_products as any[]).some(
      (it, _index) => _index !== index && it.name === parent.name && it.person === parent.person,
    ) === false
  );
};

const yupOnlyMicResolver = (_val: any, context: any) => {
  const {
    from = [],
    options: { index },
  } = context;

  const [
    { value: parent },
    {
      value: { mic_additional_products },
    },
  ] = from;

  return (
    (mic_additional_products as any[]).some((it, _index) => {
      return (
        _index !== index &&
        ((!!parent.mic_name && parent.mic_name === it.mic_name) || (!!parent.mic_name && !!it.mic_name)) &&
        it.person === parent.person
      );
    }) === false
  );
};

export const useInsuranceDetailValidationSchema = (
  assuredPersons: AssuredOptionType[],
  type?: InsuranceTypeEnum,
  annualIncome?: number,
) =>
  yup.object<InsuranceDetailFormData>({
    prime: yup.object().shape(
      {
        package_benefit_type: yup.mixed<InsuranceBenefitEnum>().oneOf(Object.values(InsuranceBenefitEnum)).required(),
        package_payment_period: yup
          .mixed<PackagePaymentPeriodEnum>()
          .oneOf(Object.values(PackagePaymentPeriodEnum))
          .required(),
        package_policy_term: yup.number().max(100, validationMessages.packagePolicyTermMax).required(),
        package_premium_term: yup
          .number()
          .typeError(validationMessages.required)
          .required()
          .min(3, validationMessages.packagePremiumTermMin)
          .max(100, validationMessages.packagePremiumTermMax)
          .test('max-policy-term', validationMessages.packagePremiumTermMaxPolicyTerm, (val, context) => {
            return (
              context.parent.package_policy_term == null || val == null || val <= context.parent.package_policy_term
            );
          }),
        package_sum_assured: yup
          .number()
          .test(
            'multiples-of-100000',
            validationMessages.multiplesOfHundredThousands,
            (val) => val == null || val % 100000 === 0,
          )
          .when(
            ['package_payment_period', 'package_periodic_premium'],
            ([package_payment_period, package_periodic_premium], scheme, { value }) => {
              const age = assuredPersons.find((it) => it.type === AssuredTypeEnum.LIFE_ASSURED)?.assuredAge;
              if (!age || !package_periodic_premium) return scheme;
              switch (package_payment_period) {
                case PackagePaymentPeriodEnum.QUARTERLY:
                  return scheme
                    .test(
                      'min-value',
                      `Số tiền tổi thiểu là ${formatMoney(getValidateMinSA(package_periodic_premium * 4))}`,
                      () => !!value && value >= getValidateMinSA(package_periodic_premium * 4),
                    )
                    .test(
                      'max-of-amount',
                      `Số tiền không được vượt quá ${formatMoney(getValidateMaxSA(age, package_periodic_premium * 4))}`,
                      (val) => !!val && val < getValidateMaxSA(age, package_periodic_premium * 4),
                    );
                case PackagePaymentPeriodEnum.SEMI_ANNUAL:
                  return scheme
                    .test(
                      'min-value',
                      `Số tiền tổi thiểu là ${formatMoney(getValidateMinSA(package_periodic_premium * 2))}`,
                      () => !!value && value >= getValidateMinSA(package_periodic_premium * 2),
                    )
                    .test(
                      'max-of-amount',
                      `Số tiền không được vượt quá ${formatMoney(getValidateMaxSA(age, package_periodic_premium * 2))}`,
                      (val) => !!val && val < getValidateMaxSA(age, package_periodic_premium * 2),
                    );
                case PackagePaymentPeriodEnum.ANNUAL:
                  return scheme
                    .test(
                      'min-value',
                      `Số tiền tổi thiểu là ${formatMoney(getValidateMinSA(package_periodic_premium))}`,
                      () => !!value && value >= getValidateMinSA(package_periodic_premium),
                    )
                    .test(
                      'max-of-amount',
                      `Số tiền không được vượt quá ${formatMoney(getValidateMaxSA(age, package_periodic_premium))}`,
                      (val) => !!val && val < getValidateMaxSA(age, package_periodic_premium),
                    );
                default:
                  return scheme;
              }
            },
          )
          .required(),
        package_periodic_premium: yup
          .number()
          .test(
            'multiples-of-1000',
            validationMessages.multiplesOfOneThousand,
            (val) => val == null || val % 1000 === 0,
          )
          .when('package_payment_period', ([package_payment_period], scheme, { value }) => {
            switch (package_payment_period) {
              case PackagePaymentPeriodEnum.QUARTERLY:
                return scheme
                  .test('min-value', validationMessages.minValueQuarterly, () => !!value && value >= 2000000)
                  .test(
                    'max-of-amount',
                    validationMessages.maxValue,
                    (val) => !!val && val * 4 < (annualIncome ?? 0) * 12 * 0.3,
                  );
              case PackagePaymentPeriodEnum.SEMI_ANNUAL:
                return scheme
                  .test('min-value', validationMessages.minValueSemiAnnual, () => !!value && value >= 3000000)
                  .test(
                    'max-of-amount',
                    validationMessages.maxValue,
                    (val) => !!val && val * 2 < (annualIncome ?? 0) * 12 * 0.3,
                  );
              case PackagePaymentPeriodEnum.ANNUAL:
                return scheme
                  .test('min-value', validationMessages.minValueAnnual, () => !!value && value >= 6000000)
                  .test(
                    'max-of-amount',
                    validationMessages.maxValue,
                    (val) => !!val && val < (annualIncome ?? 0) * 12 * 0.3,
                  );
              default:
                return scheme;
            }
          })
          .required(),
      },
      [['package_sum_assured', 'package_periodic_premium']],
    ),
    additional_products: yup
      .array(
        yup.object({
          person: yup
            .string()
            .required()
            .test('check-duplicate-person', validationMessages.additionalProductDuplicated, yupDuplicateResolver),
          name: yup
            .mixed<AdditionalAssuredProductEnum>()
            .oneOf(Object.values(AdditionalAssuredProductEnum), validationMessages.required)
            .required()
            .test('check-duplicate-name', validationMessages.additionalProductDuplicated, yupDuplicateResolver)
            .test('check-trmr-name', validationMessages.invalidTrmrName, (val, context) => {
              return (
                type !== InsuranceTypeEnum.ULRP ||
                val !== AdditionalAssuredProductEnum.TRMR ||
                context.parent.person !== '0'
              );
            })
            .when('person', ([person], scheme, { value }) => {
              const p = assuredPersons.find((it) => it.value === person);

              if (person == null || value == null || p == null) return scheme;

              switch (value) {
                case AdditionalAssuredProductEnum.COI_RIDER:
                  return scheme.test(
                    'valid-age',
                    validationMessages.ageInvalid30to65,
                    () => (p.assuredAge > 0 || p.assuredDays >= 30) && p.assuredAge <= 65,
                  );
                case AdditionalAssuredProductEnum.ADDR:
                case AdditionalAssuredProductEnum.CIR:
                  return scheme.test(
                    'valid-age',
                    validationMessages.ageInvalid30to60,
                    () => (p.assuredAge > 0 || p.assuredDays >= 30) && p.assuredAge <= 60,
                  );
                case AdditionalAssuredProductEnum.PWR:
                  return scheme.test(
                    'valid-age',
                    validationMessages.ageInvalid18to60,
                    () => p.assuredAge <= 60 && p.assuredAge >= 18,
                  );
                case AdditionalAssuredProductEnum.HSCR:
                  return scheme.test(
                    'valid-age',
                    validationMessages.ageInvalid30to70,
                    () => (p.assuredDays >= 30 || p.assuredAge > 0) && p.assuredAge <= 70,
                  );
                case AdditionalAssuredProductEnum.TRMR:
                  return scheme.test(
                    'valid-age',
                    validationMessages.ageInvalid30to60,
                    () => (p.assuredDays >= 30 || p.assuredAge > 0) && p.assuredAge <= 60,
                  );

                default:
                  return scheme;
              }
            }),
          policy_term: yup
            .number()
            .max(99, validationMessages.maxYear)
            .required()
            .when(['name', 'person'], ([name, personId], scheme) => {
              const person = assuredPersons.find((it) => it.value === personId);

              if (name == null) return scheme;

              switch (name) {
                case AdditionalAssuredProductEnum.COI_RIDER:
                  return person
                    ? scheme.test(
                        'valid-term',
                        `Thời hạn hợp đồng không được vượt quá số tuổi của người được bảo hiểm là 66 tuổi (${
                          66 - person.assuredAge
                        } năm) và không vượt quá thời hạn bảo hiểm chính`,
                        (value, { from }) => {
                          const package_policy_term = from?.[1]?.value?.prime?.package_policy_term;

                          return (
                            package_policy_term == null ||
                            (value >= 1 && value <= 66 - person.assuredAge && value <= package_policy_term)
                          );
                        },
                      )
                    : scheme;
                case AdditionalAssuredProductEnum.PWR:
                  return scheme.test(
                    'valid-term',
                    'Thời hạn hợp đồng phải trong khoảng từ 5 - 25 năm và không vượt quá thời hạn bảo hiểm chính',
                    (value, { from }) => {
                      const package_policy_term = from?.[1]?.value?.prime?.package_policy_term;

                      return (
                        package_policy_term == null ||
                        (!!value && value >= 5 && value <= 25 && value <= package_policy_term)
                      );
                    },
                  );
                case AdditionalAssuredProductEnum.TRMR:
                  return person == null
                    ? scheme
                    : scheme.test(
                        'valid-term',
                        `Thời hạn hợp đồng phải không được vượt quá 25 năm và không vượt quá thời hạn bảo hiểm chính`,
                        (value, { from }) => {
                          const package_policy_term = from?.[1]?.value?.prime?.package_policy_term;

                          return (
                            package_policy_term == null || (!!value && value <= 25 && value <= package_policy_term)
                          );
                        },
                      );
                case AdditionalAssuredProductEnum.HSCR:
                  return person
                    ? scheme.test(
                        'valid-term',
                        `Thời hạn hợp đồng không được vượt quá số tuổi của người được bảo hiểm là 80 tuổi (${
                          80 - person.assuredAge
                        } năm) và không vượt quá thời hạn bảo hiểm chính`,
                        (value, { from }) => {
                          const package_policy_term = from?.[1]?.value?.prime?.package_policy_term;

                          return (
                            package_policy_term == null ||
                            (!!value && value >= 1 && value <= 80 - person.assuredAge && value <= package_policy_term)
                          );
                        },
                      )
                    : scheme;
                default:
                  return scheme;
              }
            }),
          premium_term: yup
            .number()
            .test('check-required', validationMessages.required, (val, context) => {
              return (
                (context.parent.name === AdditionalAssuredProductEnum.COI_RIDER && !!val) === true ||
                (context.parent.name !== AdditionalAssuredProductEnum.COI_RIDER && !val) === false
              );
            })
            .when(['name', 'person'], ([name, personId], scheme) => {
              const person = assuredPersons.find((it) => it.value === personId);

              if (name == null || person === null) return scheme;

              switch (name) {
                case AdditionalAssuredProductEnum.COI_RIDER:
                case AdditionalAssuredProductEnum.HSCR:
                  return scheme.test(
                    'valid_premium_term',
                    `Thời gian đóng phí không được vượt quá thời hạn hợp đồng của bảo hiểm bổ sung`,
                    (value, { parent }) => {
                      return parent.policy_term == null || value == null || parent.policy_term >= value;
                    },
                  );
                case AdditionalAssuredProductEnum.PWR:
                  return scheme.test(
                    'valid_premium_term',
                    'Thời gian đóng phí phải trong khoảng từ 5 - 25 năm và không vượt quá thời hạn hợp đồng của bảo hiểm bổ sung',
                    (value, { parent }) => {
                      return (
                        parent.policy_term == null ||
                        value == null ||
                        (value >= 5 && value <= 25 && value <= parent.policy_term)
                      );
                    },
                  );
                case AdditionalAssuredProductEnum.TRMR:
                  return person == null
                    ? scheme
                    : scheme.test(
                        'valid_premium_term',
                        `Thời gian đóng phí phải không được vượt quá 25 năm và không vượt quá thời hạn hợp đồng của bảo hiểm bổ sung`,
                        (value, { parent }) => {
                          return (
                            parent.policy_term == null || value == null || (25 >= value && parent.policy_term >= value)
                          );
                        },
                      );

                default:
                  return scheme;
              }
            }),
          sum_assured: yup
            .number()
            .test(
              'multiples-of-100000',
              validationMessages.multiplesOfHundredThousands,
              (val) => val == null || val % 100000 === 0,
            )
            .test('check-required', validationMessages.required, (val, context) => {
              return (
                (context.parent.name === AdditionalAssuredProductEnum.PWR && !!val) === true ||
                (context.parent.name !== AdditionalAssuredProductEnum.PWR && !val) === false
              );
            }),
        }),
      )
      .required(),

    mic_additional_products: yup
      .array(
        yup.object().shape({
          person: yup
            .string()
            .required()
            .test(
              'check-duplicate-person',
              'Sản phẩm bổ trợ đã được chọn cho Người được bảo hiểm này. Vui lòng chọn sản phẩm bổ trợ hoặc Người được bảo hiểm khác.',
              yupOnlyMicResolver,
            ),
          mic_name: yup
            .string()
            .test('check-mic-name-required', 'Vui lòng chọn sản phẩm Mic', (val, context) => {
              return !context.parent.name === false || !!val;
            })
            .test(
              'check-only-mic',
              'Sản phẩm Mic đã được chọn cho Người được bảo hiểm này. Vui lòng chọn sản phẩm Mic với Người được bảo hiểm khác.',
              yupOnlyMicResolver,
            ),
        }),
      )
      .required(),
    amount: yup.object({
      value: yup
        .number()
        .min(2000000, 'Số tiền tối thiểu là 2.000.000 VND')
        .test(
          'amount-required',
          validationMessages.required,
          (val, { parent }) => val != null || (parent.start_year == null && parent.end_year == null),
        )
        .test(
          'multiples-of-100000',
          validationMessages.multiplesOfHundredThousands,
          (val) => val == null || Number(val) % 100000 === 0,
        ),
      start_year: yup
        .number()
        .max(99, validationMessages.maxYear)
        .min(1, validationMessages.minYear)
        .test(
          'amount-required',
          validationMessages.required,
          (val, { parent }) => val != null || (parent.value == null && parent.end_year == null),
        ),
      end_year: yup
        .number()
        .min(1, validationMessages.minYear)
        .max(99, validationMessages.maxYear)
        .test(
          'amount-required',
          validationMessages.required,
          (val, { parent }) => val != null || (parent.start_year == null && parent.value == null),
        )
        .when('start_year', ([from], scheme) => scheme.min(from, 'Từ năm phải nhỏ hơn hoặc bằng Tới năm'))
        .test(
          'less-than-package_premium_term',
          'Tới năm phải nhỏ hơn hoặc bằng thời hạn đóng phí',
          (value, context: any) => {
            const { from = [] } = context ?? [];

            return (
              value == null ||
              from[1]?.value?.prime?.package_premium_term == null ||
              value <= from[1]?.value?.prime?.package_premium_term
            );
          },
        ),
    }),
    raider_deduct_fund: yup
      .boolean()
      .test('is-required', 'Bạn vui lòng lựa chọn đáp án để được đi tiếp.', (val, context) => {
        return (
          val != null || context.parent?.additional_products == null || context.parent?.additional_products.length === 0
        );
      }),
  });
