import { memo, useEffect, useState } from 'react';
import classNames from 'classnames';

import { IC } from '../../../../services/leads';
import { ReverseProductCheckboxCheckedSVG, ReverseProductCheckboxUncheckedSVG } from '../../../../icons';
import { formatPhoneNumber } from '../../../../helpers';

type Value = string | number | boolean;

type Props = {
  options: IC[];
  value?: Value;
  onChange?(value?: Value): void;
  className?: string;
  error?: string;
};

export const RadioGroupICs = memo(({ value, onChange, className, options, error }: Props) => {
  const [selectedValue, setSelectedValue] = useState<Value | undefined>(value);

  useEffect(() => {
    onChange?.(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    if (value !== selectedValue) {
      setSelectedValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="relative">
      <div className="flex flex-col gap-y-16px">
        {options?.map((it, i) => (
          <div className="w-full" key={i}>
            <div
              className={classNames(
                'rounded-6px border-solid border-0.5px p-16px flex justify-between items-center',
                className,
                selectedValue === it?.code ? 'border-primary bg-c_E2F5FE' : 'border-c_DDDDDD bg-white',
              )}
              onClick={() => setSelectedValue(it?.code)}>
              <div className="flex-1 flex flex-col gap-y-4px">
                <span
                  className={classNames(
                    'font-semibold',
                    selectedValue === it?.code ? 'text-primary' : 'text-c_111111',
                  )}>
                  {it?.full_name}
                </span>
                <span
                  className={classNames('text-14px', selectedValue === it?.code ? 'text-primary' : 'text-c_999999')}>
                  {it?.code}
                  {!!it?.phone_number && ` - ${formatPhoneNumber(it?.phone_number)}`}
                </span>
              </div>
              {selectedValue === it?.code ? (
                <ReverseProductCheckboxCheckedSVG />
              ) : (
                <ReverseProductCheckboxUncheckedSVG />
              )}
            </div>
          </div>
        ))}
      </div>
      {error && <div className="errorTextStyle mt-10px mb-0">{error}</div>}
    </div>
  );
});
