import React, { useContext } from 'react';

import { useIllustration } from '..';
import { HealthInfoAssured } from '../../../../services';
import { BigQuestionComponent, LastQuizzComponent, SmallQuestionComponent } from '../../components';

import { MixQuestionsContext } from './contexts';
export const quizzesChild = (obj: any) => {
  const questionValue: string[] = [];
  Object.keys(obj)?.forEach(function (key) {
    if (obj[key]?.response) {
      questionValue.push(obj[key]?.response);
    }
  });
  return questionValue;
};

export const isCheckFile = (dataDefine?: any) => {
  if (!dataDefine) return false;
  return false;
};

export const isCheckFile2 = (dataDefine: any) => {
  let combo_big_questionValue: string[] = [];
  if (!dataDefine) return false;
  Object.keys(dataDefine)?.forEach(function (key) {
    const dataItem = dataDefine[key];
    Object.keys(dataItem?.body)?.forEach(function (k) {
      if (k === 'c1') {
        combo_big_questionValue.push(dataItem?.body[k]?.change);
      }
      if (['c7', 'c9'].includes(k)) {
        combo_big_questionValue = combo_big_questionValue.concat(quizzesChild(dataItem?.body[k]));
      }
      if (dataItem?.body[k]?.response) {
        combo_big_questionValue.push(dataItem?.body[k]?.response);
      }
    });
  });
  if (combo_big_questionValue?.findIndex((el: string) => el !== 'K') > -1) {
    return true;
  }
  return false;
};

export const InsuranceRequestForm = React.memo(() => {
  const { healths, currentAssured } = useIllustration();
  const { stepLabel } = useContext(MixQuestionsContext);

  return (
    <>
      {stepLabel === -1 ? (
        <LastQuizzComponent />
      ) : (
        healths?.map((it: HealthInfoAssured) => {
          if (currentAssured?.id !== it.id) return null;
          if (it?.app_question_number === 12 || it?.app_question_number === 15) {
            return <BigQuestionComponent key={it.id} />;
          }
          return <SmallQuestionComponent key={it.id} />;
        })
      )}
    </>
  );
});
