import classNames from 'classnames';
import React, { PropsWithChildren, useMemo, useState } from 'react';

import { CheckBox } from '..';
import { ChervonDownSVG } from '../../icons';

import './index.less';

type Props = PropsWithChildren<{
  type?: 'outline' | 'default';
  id?: string;
  label?: string;
  text?: string;
  style?: string;
  labelStyle?: string;
  textStyle?: string;
  hasToggle?: boolean;
  isCheckBox?: boolean;
  checked?: string[];
  onCheck?: (id: string) => void;
}>;
export const Collapse = React.memo<Props>(
  ({
    type = 'default',
    style,
    labelStyle,
    textStyle,
    hasToggle = false,
    isCheckBox = false,
    checked,
    onCheck,
    children,
    label,
    text,
    ...props
  }) => {
    const [isToggle, setToggle] = useState<boolean>(false);
    const content = useMemo(() => {
      return (
        <>
          <div className={classNames(['col-label text-14px', labelStyle])}>{label}</div>
          <div className={classNames(['col-text text-14px', textStyle])}>{text}</div>
          {hasToggle && (
            <>
              <div style={{ display: isToggle ? 'flex' : 'none' }}>{children}</div>
              <div
                className={'col-viewIcon'}
                onClick={() => setToggle((prev) => !prev)}
                style={{ transform: isToggle ? 'rotateX(180deg)' : 'none' }}>
                <ChervonDownSVG />
              </div>
            </>
          )}
        </>
      );
    }, [children, hasToggle, isToggle, label, labelStyle, text, textStyle]);

    return (
      <div
        className={classNames([
          'col-element',
          type === 'outline' && 'col-outline',
          isCheckBox && 'col-elmCheckbox',
          style,
        ])}>
        {isCheckBox ? (
          <CheckBox
            value={checked?.includes(props.id || '') || false}
            onPress={() => onCheck?.(props.id || '')}
            style={'col-checkbox'}
            checkStyle={'col-checkStyle'}>
            {content}
          </CheckBox>
        ) : (
          content
        )}
      </div>
    );
  },
);
