import { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { ImplementationSteps } from '../implementation-steps';
import { InsuranceMethodEnum } from '../../../../services/leads';

type Value = string | number | boolean;

export type LeadsMethodOptionType = {
  label: string;
  value: Value;
  point: number;
  staticConditions: string[];
  selectableConditions: string[];
  steps: string[];
  mbalSteps: string[];
};

type Props = {
  options: LeadsMethodOptionType[];
  value?: Value;
  onChange?(value?: Value): void;
  error?: string;
  disabled?: boolean;
};

export const MethodCardsSelectable = memo(({ value, onChange, options, disabled }: Props) => {
  const [selectedValue, setSelectedValue] = useState<Value | undefined>(value);
  const selectedOption = options.find((option) => option.value === selectedValue);

  useEffect(() => {
    onChange?.(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    if (value !== selectedValue) {
      setSelectedValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleClick = useCallback(
    (value: Value) => {
      if (value !== selectedValue) {
        setSelectedValue(value);
      } else {
        setSelectedValue(undefined);
      }
    },
    [selectedValue],
  );

  return (
    <div>
      <div className={classNames(['my-12px flex gap-x-[11px] max-w-full', disabled && 'pointer-events-none'])}>
        {options.map((option, i) => (
          <div
            key={i}
            onClick={() => handleClick(option.value)}
            className={classNames(
              'flex-[1_0_0] rounded-8px px-16px py-24px flex flex-col items-center gap-y-12px text-center',
              selectedValue === option.value
                ? ' bg-primary text-white'
                : disabled
                ? 'bg-white text-primary opacity-30'
                : 'bg-white text-primary',
            )}>
            <span className="font-bold text-18px leading-22px uppercase">Luồng {option.label}</span>
            <div className="flex-center py-4px px-10px rounded-[100px] bg-gradient-to-b from-c_65F6FF to-c_06F text-sm font-semibold text-white">
              Nhận {option.point} điểm
            </div>
          </div>
        ))}
      </div>
      {selectedValue && (
        <div className="mt-26px">
          <ImplementationSteps
            conditions={disabled ? selectedOption?.staticConditions : selectedOption?.selectableConditions}
            steps={selectedOption?.steps}
            mbalSteps={selectedOption?.mbalSteps}
            polygonClassName={classNames([
              'absolute -top-4 ',
              selectedValue === InsuranceMethodEnum.REFER ? 'left-[21%]' : 'left-[72%]',
            ])}
          />
        </div>
      )}
    </div>
  );
});
