import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { request } from '../helpers';
import { ApiUrl } from '../helpers/api-url';

import { APIError } from './types';

const QUERY_KEY = 'OCCUPATION';

export type Occupation = {
  active: boolean;
  group_id: number;
  id: number;
  name_eng: string;
  name_vn: string;
};

export type GetAllOccupationQueryResponse = Occupation[];
export const useGetAllOccupationQuery = (options?: UseQueryOptions<GetAllOccupationQueryResponse, APIError>) => {
  return useQuery(QUERY_KEY as QueryKey, () => request.get(ApiUrl.occupations).then((res) => res.data), options);
};
