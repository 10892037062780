/* eslint-disable react-hooks/exhaustive-deps */
import { Select, TextInput } from '@mantine/core';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { Empty, FormLabel } from '..';
import { ChevronDownSVG } from '../../icons';
import { District, Province, Ward, useGetAllDistricts, useGetAllProvince, useGetAllWard } from '../../services/address';

type Props = {
  clearErrors: any;
  setValue: any;
  control: any;
  watch: any;
  nameProvince: string;
  nameWard: string;
  nameDistrict: string;
  nameAdress: string;
  errorTextAddress?: string;
  errorProvince?: string;
  errorDistrict?: string;
  errorWard?: string;
};
export const PickAdress = ({
  clearErrors,
  control,
  setValue,
  nameProvince,
  nameWard,
  nameDistrict,
  nameAdress,
  watch,
  errorTextAddress,
  errorProvince,
  errorDistrict,
  errorWard,
}: Props) => {
  const { data: provinces = [], isLoading: loadingProvinces } = useGetAllProvince();

  const selectedProvince = useMemo(() => {
    const selected = provinces.find((province) => province.name === watch(nameProvince));
    return selected?.code || '';
  }, [provinces, watch(nameProvince)]);

  const { data: districts = [], isLoading: loadingDistricts } = useGetAllDistricts(selectedProvince, {
    enabled: !!selectedProvince,
  });
  const selectedDistrict = useMemo(() => {
    const selected = districts.find((district) => district.name === watch(nameDistrict));
    return selected?.code || '';
  }, [districts, watch(nameDistrict), watch(nameProvince)]);

  const { data: wards = [], isLoading: loadingWards } = useGetAllWard(selectedDistrict, {
    enabled: !!selectedDistrict && selectedDistrict !== '',
  });

  const getProvinceName = useMemo(() => {
    const newData = provinces?.map((it: Province) => {
      return {
        value: it.name,
        label: it.name || '',
        code: it.code,
      };
    });
    const uniqueData = newData.filter((el, index, self) => self.findIndex((m) => m.value === el.value) === index);
    return uniqueData || [];
  }, [provinces]);

  const getDistrictName = useMemo(() => {
    const newData = districts?.map((it: District) => {
      return {
        value: it.name,
        label: it.name || '',
        code: it.code,
      };
    });
    const uniqueData = newData.filter((el, index, self) => self.findIndex((m) => m.value === el.value) === index);
    return uniqueData || [];
  }, [districts]);

  const getWardName = useMemo(() => {
    const newData = wards?.map((it: Ward) => {
      return {
        value: it.name,
        label: it.name || '',
        code: it.code,
      };
    });
    const uniqueData = newData.filter((el, index, self) => self.findIndex((m) => m.value === el.value) === index);
    return uniqueData || [];
  }, [wards]);

  const onChangeProvince = (selectedOption: any) => {
    setValue(nameDistrict, '');
    setValue(nameWard, '');
    setValue(nameAdress, '');
    setValue(nameProvince, selectedOption);
    clearErrors(nameProvince);
  };

  const onChangeDistrict = (selectedOption: any) => {
    setValue(nameWard, '');
    setValue(nameAdress, '');
    setValue(nameDistrict, selectedOption);
    clearErrors(nameDistrict);
  };

  const onChangeWard = (selectedOption: any) => {
    setValue(nameAdress, '');
    setValue(nameWard, selectedOption);
    clearErrors(nameWard);
  };
  return (
    <>
      <div>
        <FormLabel required>Tỉnh/Thành phố</FormLabel>
        <Controller
          control={control}
          render={({ field: { ref, value, onBlur } }) => (
            <Select
              ref={ref}
              placeholder="Chọn tỉnh/thành phố"
              value={value?.toString()}
              data={getProvinceName}
              onChange={onChangeProvince}
              nothingFound={<Empty title="Empty" />}
              rightSection={<ChevronDownSVG />}
              searchable
              onBlur={onBlur}
              error={errorProvince}
              disabled={loadingProvinces}
            />
          )}
          name={nameProvince}
        />
      </div>
      <div>
        <FormLabel required>Quận/Huyện</FormLabel>
        <Controller
          control={control}
          render={({ field: { ref, value, onBlur } }) => (
            <Select
              ref={ref}
              placeholder="Chọn quận/huyện"
              value={value?.toString()}
              data={getDistrictName}
              onChange={onChangeDistrict}
              nothingFound={<Empty title="Empty" />}
              rightSection={<ChevronDownSVG />}
              searchable
              onBlur={onBlur}
              error={errorDistrict}
              disabled={!watch(nameProvince) || loadingDistricts}
            />
          )}
          name={nameDistrict}
        />
      </div>
      <div>
        <FormLabel required>Xã/Phường</FormLabel>
        <Controller
          control={control}
          render={({ field: { ref, value, onBlur } }) => (
            <Select
              ref={ref}
              placeholder="Chọn xã/phường"
              value={value?.toString()}
              data={getWardName}
              onChange={onChangeWard}
              nothingFound={<Empty title="Empty" />}
              rightSection={<ChevronDownSVG />}
              searchable
              onBlur={onBlur}
              error={errorWard}
              disabled={!watch(nameDistrict) || loadingWards}
            />
          )}
          name={nameWard}
        />
      </div>

      <div>
        <FormLabel required>Địa chỉ chi tiết</FormLabel>
        <Controller
          control={control}
          render={({ field: { ref, onChange, value } }) => (
            <TextInput
              ref={ref}
              value={value}
              onChange={onChange}
              placeholder="Nhập địa chỉ"
              error={errorTextAddress}
            />
          )}
          name={nameAdress}
        />
      </div>
    </>
  );
};
