import { SelectItem } from '@mantine/core';
import { stringify } from 'qs';
import { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Layout } from '../../../components';
import { useDialog } from '../../../contexts';
import { AppRoutes } from '../../../helpers';
import { useQueryString } from '../../../hooks';
import { vi } from '../../../i18n/vi';
import {
  AdditionalAssuredProductEnum,
  GenderEnum,
  HealthInfoAssured,
  IdentityDocumentTypeEnum,
  InsuranceBenefitEnum,
  InsuranceTypeEnum,
  PackagePaymentPeriodEnum,
  QuotationEntity,
  RefererType,
  RelationshipTypeEnum,
} from '../../../services';
import { AssuredTypeEnum, IllustrationFormStep, InsuranceResult } from '../components';

import { BenificiriesPersonForm } from './benificiries-person-form';
import { InitializeForm } from './initialize-form';
import { InsuranceDetailForm } from './insurance-detail-form';
import { InsuranceRequestForm, MixQuestionsContext } from './insurance-request';
import { QrCodeScreen } from './qr-code';
import { ReferrerEntity, SalesForm } from './referrer';
import { usePreloadImage } from './use-pre-load-image';

export type User = {
  id?: string;
  full_name: string;
  dob: string;
  gender: GenderEnum;
  occupation_id: number;
  id_issued_place?: string;
  identification_date?: string;
  identification_type: IdentityDocumentTypeEnum;
  identification_id: string;
  married: boolean;
  phone_number: string;
  address?: string;
  email?: string;
  relationship?: RelationshipTypeEnum;
  annual_income?: number;
  benefit_rate?: number;
  type?: AssuredTypeEnum;
  province_name?: string;
  district_name?: string;
  ward_name?: string;
  nationality_code: string;
  is_customer?: boolean;
};

export interface NationalType {
  id: string;
  activeStatus: string;
  name: string;
}

export type InsuredPersonReference = {
  name: string;
  dob: string;
  gender: GenderEnum;
  identityDocumentType: IdentityDocumentTypeEnum;
  identityDocumentNumber: string;
};

export type InsuredPersonFormData = {
  customer: User;
  assured: User;
  additional_assureds?: User[];
};
export type BeneficiariesPersonFormData = {
  person: string;
  relationship_with_main_assured?: RelationshipTypeEnum;
  benefit_rate?: number;
  beneficiaries?: User[];
};

export type AdditionalProductEntity = {
  name: AdditionalAssuredProductEnum;
  policy_term: number;
  premium_term: number;
  sum_assured: number;
  mic_name?: string;
  mic_package_selected?: any;
  mic_benefits?: string[];
  person: string;
  // Bố mẹ
  contract_num?: string;
  contract_name?: string;
  contract_dob?: string;
  contract_card_id?: string;
  contract_phone_number?: string;
};

export type InsuranceDetailFormData = {
  prime: {
    package_benefit_type: InsuranceBenefitEnum;
    package_payment_period: PackagePaymentPeriodEnum;
    /**
     * phí đóng định kỳ
     */
    package_periodic_premium: number;
    /**
     * thời hạn hợp đồng
     */
    package_policy_term: number;
    /**
     * Thời hạn đóng phí
     */
    package_premium_term: number;
    package_sum_assured: number;
  };
  additional_products: AdditionalProductEntity[];
  mic_additional_products: AdditionalProductEntity[];
  amount?: {
    value?: number;
    end_year?: number;
    start_year?: number;
  };
  raider_deduct_fund?: boolean;

  referrer?: RefererType & {
    branchCode?: string;
    branchName?: string;
    deparmentCode?: string;
    deparmentName?: string;
  };
  supporter?: RefererType;
  sale?: RefererType;
};

type ContextProps = {
  insuredPerson?: InsuredPersonFormData;
  onUpdateInsuredPersonForm(data: InsuredPersonFormData): void;
  insuranceType?: InsuranceTypeEnum;
  onSelectInsuranceType(data: InsuranceTypeEnum): void;
  insuranceDetail?: InsuranceDetailFormData;
  onUpdateInsuranceDetail(data: InsuranceDetailFormData): void;
  customer_is_assured?: boolean;
  toggleIsCustomerIsAssured(val: boolean): void;
  quotation?: QuotationEntity;
  onUpdateQuotation(val: QuotationEntity): void;
  referrerData?: ReferrerEntity;
  onUpdateReferrer(val: ReferrerEntity): void;

  //ULRP 3.0
  persons?: User[];
  onUpdatePersonsForm(data: User[]): void;
  beneficiariesPerson?: User;
  onUpdateBeneficiariesPersonForm(data?: User): void;
  healthPersons?: HealthInfoAssured[];
  onUpdateHealthPersons(data: HealthInfoAssured[]): void;
  healths?: HealthInfoAssured[];
  onUpdateHealths(data: HealthInfoAssured[]): void;
  currentAssured?: HealthInfoAssured;
  onUpdateCurrentAssured(data?: HealthInfoAssured): void;
};

const IllustrationContext = createContext<ContextProps>({
  onUpdateInsuredPersonForm() {
    throw new Error('not ready');
  },
  onSelectInsuranceType() {
    throw new Error('not ready');
  },
  onUpdateInsuranceDetail() {
    throw new Error('not ready');
  },
  toggleIsCustomerIsAssured() {
    throw new Error('not ready');
  },
  onUpdateQuotation() {
    throw new Error('not ready');
  },
  onUpdateReferrer() {
    throw new Error('not ready');
  },
  onUpdateHealthPersons() {
    throw new Error('not ready');
  },
  onUpdateHealths() {
    throw new Error('not ready');
  },
  onUpdateCurrentAssured() {
    throw new Error('not ready');
  },
  onUpdatePersonsForm() {
    throw new Error('not ready');
  },
  onUpdateBeneficiariesPersonForm() {
    throw new Error('not ready');
  },
});

export const useIllustration = () => useContext(IllustrationContext);

export enum FormStepEnum {
  INITIALIZE = 'INITIALIZE',
  TYPE = 'TYPE',
  BENEFICIARIRES = 'BENEFICIARIRES',
  DETAIL = 'DETAIL',
  RESULT = 'RESULT',
  SALES = 'SALES',
  QR_CODE = 'QR_CODE',
  QUESTIONS = 'QUESTIONS',
}

export const identityDocumentTypeOptions: (SelectItem & { value: IdentityDocumentTypeEnum })[] = [
  {
    value: IdentityDocumentTypeEnum.NATIONAL_ID,
    label: 'Chứng minh nhân dân',
  },
  {
    value: IdentityDocumentTypeEnum.CITIZEN_ID,
    label: 'Căn cước công dân',
  },
  {
    value: IdentityDocumentTypeEnum.BIRTH_CERTIFICATE,
    label: 'Giấy khai sinh',
  },
  {
    value: IdentityDocumentTypeEnum.MILITARY_ID,
    label: 'CMT Quân đội',
  },
  {
    value: IdentityDocumentTypeEnum.PASSPORT,
    label: 'Hộ chiếu',
  },
];

type ScreenParams = {
  step: FormStepEnum;
};

type Props = {
  insuredPerson?: InsuredPersonFormData;
  customer_is_assured?: boolean;
  insuranceType?: InsuranceTypeEnum;
  insuranceDetail?: InsuranceDetailFormData;
  quotation?: QuotationEntity;
  referrerData?: ReferrerEntity;
  persons?: User[];
  beneficiariesPerson?: User;
  healthPersons?: HealthInfoAssured[];
  healths?: HealthInfoAssured[];
  currentAssured?: HealthInfoAssured;
};

export const IllustrationFormScreen = memo((props: Props) => {
  const { stepLabel, handleChangeStepLabel, onScrollToTop } = useContext(MixQuestionsContext);

  const [insuredPerson, setInsuredPerson] = useState<InsuredPersonFormData | undefined>(
    props.insuredPerson ?? undefined,
  );
  const [customer_is_assured, toggleIsCustomerIsAssured] = useState(props.customer_is_assured ?? true);
  const [insuranceType, setInsuranceType] = useState<InsuranceTypeEnum | undefined>(
    props.insuranceType ?? InsuranceTypeEnum.ULRP_3_0,
  );
  const [insuranceDetail, setInsuranceDetail] = useState<InsuranceDetailFormData | undefined>(props.insuranceDetail);
  usePreloadImage();

  const [quotation, setQuotation] = useState<QuotationEntity | undefined>(props.quotation);
  const [referrerData, setReferrerData] = useState<ReferrerEntity | undefined>(props.referrerData);

  const [persons, setPersons] = useState<User[] | undefined>(props.persons || undefined);
  const [beneficiariesPerson, setBenificiariesPerson] = useState<User | undefined>(
    props.beneficiariesPerson || undefined,
  );
  const [healthPersons, setHealthPersons] = useState<HealthInfoAssured[] | undefined>(props.healthPersons || undefined);
  const [healths, setHealths] = useState<HealthInfoAssured[] | undefined>(props.healths || undefined);
  const [currentAssured, setCurretnAssured] = useState<HealthInfoAssured | undefined>(
    props.currentAssured || undefined,
  );

  const handleUpdateReferrerData = useCallback((data: ReferrerEntity) => {
    setReferrerData(data);
  }, []);

  const handleUpdateInsuredPersonForm = useCallback((data: InsuredPersonFormData) => {
    setInsuredPerson(data);
  }, []);

  const handleSelectInsuranceType = useCallback((data: InsuranceTypeEnum) => {
    setInsuranceType(data);
  }, []);

  const handleSelectInsuranceDetail = useCallback((data: InsuranceDetailFormData) => {
    setInsuranceDetail(data);
  }, []);
  const handleUpdateQuotation = useCallback((data: QuotationEntity) => {
    setQuotation(data);
  }, []);

  const handleUpdatePersons = useCallback((data: User[]) => {
    setPersons(data);
  }, []);

  const handleUpdateBenificiariesPersonForm = useCallback((data: User) => {
    setBenificiariesPerson(data);
  }, []);

  const handleUpdateHealthPersons = useCallback((data: HealthInfoAssured[]) => {
    setHealthPersons(data);
  }, []);
  const handleUpdateHealths = useCallback((data: HealthInfoAssured[]) => {
    setHealths(data);
  }, []);

  const handleUpdateCurrentAssured = useCallback((data: HealthInfoAssured) => {
    setCurretnAssured(data);
  }, []);

  const { step = FormStepEnum.INITIALIZE } = useQueryString<ScreenParams>();

  const activeStep = useMemo(() => FormStepEnum[step] ?? FormStepEnum.INITIALIZE, [step]);

  const navigate = useNavigate();

  useEffect(() => {
    if (insuredPerson == null && activeStep !== FormStepEnum.INITIALIZE) {
      navigate(
        {
          search: stringify({
            step: FormStepEnum.INITIALIZE,
          }),
        },
        {
          replace: true,
        },
      );
    }
  }, [activeStep, insuredPerson, navigate]);

  const { showDialog } = useDialog();
  const onBackHome = useCallback(async () => {
    if ([FormStepEnum.QUESTIONS].includes(activeStep)) {
      const res = await showDialog({
        message: 'Oopps!!! Bạn sẽ phải nhập lại thông tin từ đầu khi muốn quay lại các màn hình',
        type: 'CONFIRM',
      });
      res && navigate(AppRoutes.home);
    } else {
      navigate(-1);
    }
  }, [activeStep, showDialog, navigate]);

  const handlePrevPerson = useCallback(() => {
    if (healths) {
      const findIndex = healths?.findIndex((it) => it.id === currentAssured?.id);
      const prevPerson = healths[findIndex - 1];
      const number = prevPerson?.app_question_number === 12 ? 6 : 0;
      handleChangeStepLabel(number);
      setCurretnAssured(prevPerson);
    }
  }, [healths, currentAssured, handleChangeStepLabel]);

  const onBack = useCallback(async () => {
    if (activeStep === FormStepEnum.QUESTIONS) {
      onScrollToTop();
      if (stepLabel === 0 && healths?.[0]?.id === currentAssured?.id) {
        onBackHome();
      } else if (stepLabel === -1) {
        handleChangeStepLabel(0);
      } else if (stepLabel === 0) {
        handlePrevPerson();
      } else if (
        currentAssured?.app_question_number === 12 &&
        currentAssured?.gender !== GenderEnum.FEMALE &&
        stepLabel === 6
      ) {
        handleChangeStepLabel(stepLabel - 2);
      } else {
        handleChangeStepLabel(stepLabel - 1);
      }
    } else {
      onBackHome();
    }
  }, [
    activeStep,
    onScrollToTop,
    stepLabel,
    healths,
    currentAssured?.id,
    currentAssured?.app_question_number,
    currentAssured?.gender,
    onBackHome,
    handleChangeStepLabel,
    handlePrevPerson,
  ]);

  const title = useMemo(() => {
    switch (activeStep) {
      case FormStepEnum.INITIALIZE:
        return 'Thông tin người hưởng bảo hiểm';
      case FormStepEnum.TYPE:
        return 'Chọn gói sản phẩm';
      case FormStepEnum.BENEFICIARIRES:
        return 'Thông tin người thụ hưởng';
      case FormStepEnum.DETAIL:
        return 'Thông tin gói sản phẩm';
      case FormStepEnum.RESULT:
        return 'BMH quyền lợi bảo hiểm';
      case FormStepEnum.SALES:
        return vi.referrer_information;
      case FormStepEnum.QUESTIONS:
        return 'Nộp hồ sơ yêu cầu bảo hiểm';
      default:
        return 'BMH';
    }
  }, [activeStep]);

  return (
    <IllustrationContext.Provider
      value={{
        insuredPerson,
        onUpdateInsuredPersonForm: handleUpdateInsuredPersonForm,
        onSelectInsuranceType: handleSelectInsuranceType,
        insuranceType,
        insuranceDetail,
        onUpdateInsuranceDetail: handleSelectInsuranceDetail,
        toggleIsCustomerIsAssured,
        customer_is_assured,
        quotation,
        onUpdateQuotation: handleUpdateQuotation,
        referrerData,
        onUpdateReferrer: handleUpdateReferrerData,
        persons,
        onUpdatePersonsForm: handleUpdatePersons,
        beneficiariesPerson,
        onUpdateBeneficiariesPersonForm: handleUpdateBenificiariesPersonForm,
        healthPersons,
        onUpdateHealthPersons: handleUpdateHealthPersons,
        healths,
        onUpdateHealths: handleUpdateHealths,
        currentAssured,
        onUpdateCurrentAssured: handleUpdateCurrentAssured,
      }}>
      {activeStep === FormStepEnum.QR_CODE ? (
        <QrCodeScreen />
      ) : (
        <Layout title={title} onLeft={onBack}>
          {activeStep !== FormStepEnum.SALES && (
            <div className="px-16px pt-8px pb-12px bg-white fixed top-48px left-0 w-full z-10">
              <IllustrationFormStep step={activeStep} />
            </div>
          )}

          <div id="page-content" className={activeStep !== FormStepEnum.SALES ? 'pt-56px' : ''}>
            {activeStep === FormStepEnum.INITIALIZE && <InitializeForm />}
            {/* {activeStep === FormStepEnum.TYPE && <InsuranceTypeForm />} */}
            {activeStep === FormStepEnum.BENEFICIARIRES && <BenificiriesPersonForm />}
            {activeStep === FormStepEnum.DETAIL && <InsuranceDetailForm />}
            {activeStep === FormStepEnum.RESULT && insuranceType && quotation != null && (
              <InsuranceResult insuranceType={insuranceType} quotation={quotation} />
            )}
            {activeStep === FormStepEnum.SALES && <SalesForm />}
            {activeStep === FormStepEnum.QUESTIONS && <InsuranceRequestForm />}
          </div>
        </Layout>
      )}
    </IllustrationContext.Provider>
  );
});
