import { DatePickerProps, DatePicker as MTDatePicker } from '@mantine/dates';
import dayjs from 'dayjs';
import { memo, useCallback } from 'react';

type Props = DatePickerProps;

export const DatePicker = memo(({ onChange, ...rest }: Props) => {
  const handleDateChange = useCallback(
    (data: Date | null) => {
      onChange?.(data);
    },
    [onChange],
  );

  const handleInputChange = (t?: string) => {
    if (t == null || t.length !== 8) return;

    if (dayjs(t, 'DD/MM/YYYY').isValid()) {
      return onChange?.(dayjs(t, 'DD/MM/YYYY').toDate());
    }

    if (dayjs(`${t[0]}${t[1]}/${t[2]}${t[3]}/${t[4]}${t[5]}${t[6]}${t[7]}`, 'DD/MM/YYYY').isValid()) {
      return onChange?.(dayjs(`${t[0]}${t[1]}/${t[2]}${t[3]}/${t[4]}${t[5]}${t[6]}${t[7]}`, 'DD/MM/YYYY').toDate());
    }
  };

  return (
    <MTDatePicker onInput={(e) => handleInputChange(e.currentTarget.value)} onChange={handleDateChange} {...rest} />
  );
});
