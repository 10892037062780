import { createContext, memo, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

import { InsuranceMethodEnum, LeadReferorDirectEntity, LeadsCustomerFormData } from '../services/leads';
import { InsuranceDetailFormData, InsuredPersonFormData } from '../screens/illustrations/form';
import { HealthInfoAssured, QuotationEntity } from '../services';
import { LoanInformationFormData } from '../screens/leads/components';

type ContextProps = {
  leadsCustomer?: LeadsCustomerFormData;
  leadsDetail?: LeadReferorDirectEntity;
  leadsQuotation?: QuotationEntity;
  insuranceMethod?: InsuranceMethodEnum;
  leadsInsuredPersonForm?: InsuredPersonFormData;
  leadsInsuranceDetailForm?: InsuranceDetailFormData;
  leadsHealths?: HealthInfoAssured[];
  leadsCurrentAssured?: HealthInfoAssured;
  onUpdateLeadsCustomer(data?: LeadsCustomerFormData): void;
  onUpdateLeadsDetail(data?: LeadReferorDirectEntity): void;
  onUpdateLeadsQuotation(data?: QuotationEntity): void;
  onUpdateLeadsInsuranceMethod(data?: InsuranceMethodEnum): void;
  onUpdateLeadsInsuredPersonForm(data?: InsuredPersonFormData): void;
  onUpdateLeadsInsuranceDetailForm(data?: InsuranceDetailFormData): void;
  onUpdateLeadsHealths(data: HealthInfoAssured[]): void;
  onUpdateLeadsCurrentAssured(data?: HealthInfoAssured): void;
  onResetLeads(): void;
  loanInformationForm?: LoanInformationFormData;
  onUpdateLoanInformationForm(data?: LoanInformationFormData): void;
};

const LeadsContext = createContext<ContextProps>({
  onUpdateLeadsCustomer() {
    throw new Error('not ready');
  },
  onUpdateLeadsDetail() {
    throw new Error('not ready');
  },
  onUpdateLeadsQuotation() {
    throw new Error('not ready');
  },
  onUpdateLeadsInsuranceMethod() {
    throw new Error('not ready');
  },
  onUpdateLeadsInsuredPersonForm() {
    throw new Error('not ready');
  },
  onUpdateLeadsInsuranceDetailForm() {
    throw new Error('not ready');
  },
  onResetLeads() {
    throw new Error('not ready');
  },
  onUpdateLeadsHealths() {
    throw new Error('not ready');
  },
  onUpdateLeadsCurrentAssured() {
    throw new Error('not ready');
  },

  onUpdateLoanInformationForm() {
    throw new Error('not ready');
  },
});

type Props = PropsWithChildren;

export const LeadsContextProvider = memo(({ children }: Props) => {
  const [leadsCustomer, setLeadsCustomer] = useState<LeadsCustomerFormData | undefined>(undefined);
  const [leadsDetail, setLeadsDetail] = useState<LeadReferorDirectEntity | undefined>(undefined);
  const [leadsQuotation, setLeadsQuotation] = useState<QuotationEntity | undefined>(undefined);
  const [insuranceMethod, setInsuranceMethod] = useState<InsuranceMethodEnum | undefined>(undefined);
  const [leadsInsuredPersonForm, setLeadsInsuredPersonForm] = useState<InsuredPersonFormData | undefined>(undefined);
  const [leadsInsuranceDetailForm, setLeadsInsuranceDetailForm] = useState<InsuranceDetailFormData | undefined>(
    undefined,
  );
  const [leadsHealths, setLeadsHealths] = useState<HealthInfoAssured[] | undefined>(undefined);
  const [leadsCurrentAssured, setLeadsCurrentAssured] = useState<HealthInfoAssured | undefined>(undefined);
  const [loanInformationForm, setLoanInformationForm] = useState<LoanInformationFormData | undefined>(undefined);

  const onUpdateLeadsCustomer = useCallback((data: LeadsCustomerFormData) => {
    setLeadsCustomer(data);
  }, []);

  const onUpdateLeadsDetail = useCallback((data: LeadReferorDirectEntity) => {
    setLeadsDetail(data);
  }, []);
  const onUpdateLeadsQuotation = useCallback((data: QuotationEntity) => {
    setLeadsQuotation(data);
  }, []);

  const onUpdateLeadsInsuranceMethod = useCallback((data: InsuranceMethodEnum) => {
    setInsuranceMethod(data);
  }, []);

  const onUpdateLeadsInsuredPersonForm = useCallback((data: InsuredPersonFormData) => {
    setLeadsInsuredPersonForm(data);
  }, []);

  const onUpdateLeadsInsuranceDetailForm = useCallback((data: InsuranceDetailFormData) => {
    setLeadsInsuranceDetailForm(data);
  }, []);
  const onUpdateLeadsHealths = useCallback((data: HealthInfoAssured[]) => {
    setLeadsHealths(data);
  }, []);
  const onUpdateLeadsCurrentAssured = useCallback((data: HealthInfoAssured) => {
    setLeadsCurrentAssured(data);
  }, []);

  const onUpdateLoanInformationForm = useCallback(
    (data: LoanInformationFormData) => {
      setLoanInformationForm({ ...loanInformationForm, ...data });
    },
    [loanInformationForm],
  );

  const onResetLeads = useCallback(() => {
    setLeadsCustomer(undefined);
    setLeadsDetail(undefined);
    setInsuranceMethod(undefined);
    setLeadsInsuredPersonForm(undefined);
    setLeadsInsuranceDetailForm(undefined);
    setLeadsHealths(undefined);
    setLeadsCurrentAssured(undefined);
  }, []);

  const contextValue = useMemo(() => {
    return {
      leadsCustomer,
      leadsDetail,
      leadsQuotation,
      insuranceMethod,
      leadsInsuredPersonForm,
      leadsInsuranceDetailForm,
      leadsHealths,
      leadsCurrentAssured,
      onUpdateLeadsCustomer,
      onUpdateLeadsDetail,
      onUpdateLeadsQuotation,
      onUpdateLeadsInsuranceMethod,
      onUpdateLeadsInsuredPersonForm,
      onUpdateLeadsInsuranceDetailForm,
      onResetLeads,
      onUpdateLeadsCurrentAssured,
      onUpdateLeadsHealths,
      loanInformationForm,
      onUpdateLoanInformationForm,
    };
  }, [
    leadsCustomer,
    leadsDetail,
    leadsQuotation,
    insuranceMethod,
    leadsInsuredPersonForm,
    leadsInsuranceDetailForm,
    leadsHealths,
    leadsCurrentAssured,
    onUpdateLeadsCustomer,
    onUpdateLeadsDetail,
    onUpdateLeadsQuotation,
    onUpdateLeadsInsuranceMethod,
    onUpdateLeadsInsuredPersonForm,
    onUpdateLeadsInsuranceDetailForm,
    onResetLeads,
    onUpdateLeadsCurrentAssured,
    onUpdateLeadsHealths,
    loanInformationForm,
    onUpdateLoanInformationForm,
  ]);

  return <LeadsContext.Provider value={contextValue}>{children}</LeadsContext.Provider>;
});

export const useLeads = () => useContext(LeadsContext);
