import { useEffect } from 'react';

const APP_URL = process.env.REACT_APP_ENV === 'development' ? '' : process.env.PUBLIC_URL;

export const usePreloadImage = () => {
  function preloadImage(url: string) {
    const img = new Image();
    img.src = url;
  }

  useEffect(() => {
    preloadImage(APP_URL + '/bancas/images/ulrp30-banner.png');
    preloadImage(APP_URL + '/bancas/images/ulrp30.png');
    preloadImage(APP_URL + '/bancas/images/ulrp20-banner.png');
    preloadImage(APP_URL + '/bancas/images/ulrp20.png');
    preloadImage(APP_URL + '/bancas/images/ukl-banner.png');
    preloadImage(APP_URL + '/bancas/images/ukl.png');
    preloadImage(APP_URL + '/bancas/images/ulsp-banner.png');
    preloadImage(APP_URL + '/bancas/images/ulsp.png');
  }, []);
};
